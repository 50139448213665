import {
  Avatar,
  Checkbox,
  Hidden,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React from "react";
import clsx from "clsx";

import { LocationOnOutlined, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../components/CustomRowStyles";
import { IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import { formatDate, history } from "../_helpers";
import { URLS } from "../apis/urls";

const MobileCustomRow = ({
  item,
  handleCheckboxChange,
  listToMapRedirectHandler,
  selectedCheckBoxs=[],
}) => {
  const app = useSelector((state) => state.app);
  const theme = useTheme()
  const classes = { ...useRowStyles({ app }), ...useStyles() };
  const { t } = useTranslation();

  const attachmentSrc =
    item.attachments && item.attachments.length
      ? URLS.GET_ATTACHMENT_THUMBNAIL + item.attachments[0].attachmentId
      : null;
  const completedWorkorders = item.resolvers
    ? item.resolvers.filter((a) => a.status === 4).length
    : 0;

  return (
    <React.Fragment>
      <TableRow
        onClick={(e) => {
          e.stopPropagation();
          /**
           * TODO: need to fix in future
          * it added because of when filter pannel open at that time, when we click out side then it consider row click
           */
          if (!item.disabled && !document.getElementsByClassName("MuiDataGrid-panelContent")?.length) {
            history.push(
              `/nazorg/${
                item.isAfterCareEmployeeOrSiteManager ? "melding" : "details"
              }/${item.requestId}`
            );
          }
        }}
        className={clsx([classes.customGridRow, classes.row])}
      >
        <TableCell>
          <div className={classes.customSmallDeviceRowContainer} style={{padding:0}}>
            <div className={classes.verticallyCenterContainer}>
              <Avatar
                src={attachmentSrc}
                className={clsx(
                  classes.thumbnail,
                  classes.avatarThumbnail,
                  "thumb-exception"
                )}
              >
                {item.buildingNoExtern}
              </Avatar>
            </div>
            <div className={clsx(classes.detailContainer, classes.maxWidth_70)}>
              <Typography className={classes.requestTitle}>
                {`${item.number} - ${item.desc}`}
              </Typography>
              <Typography
                className={clsx(
                  classes.requestTypeLabel,
                  classes.requestTypeContainer
                )}
              >
                {item.carryOutAsType}
                {item.resolvers && !!item.resolvers.length && (
                  <>
                    <div
                      style={{ marginLeft: 10 }}
                      className={classes.werkbonnenContainer}
                    >
                      <span>{`${completedWorkorders}/${
                        item.resolvers.length
                      } ${t("general.workOrders")}`}</span>
                    </div>
                  </>
                )}
              </Typography>

              <Hidden smUp>
                <div className={classes.requestContainer}>
                  <div>
                    <Typography className={classes.requestBuildingLabel}>
                      <span>
                        {item.addressText} {item.addressPlace}
                      </span>
                    </Typography>
                  </div>
                </div>
              </Hidden>

              <div className={classes.requestContainer}>
                <div>
                  <Typography className={classes.requestBuildingLabel}>
                    <IconButton
                      color="primary"
                      disabled={!item.drawingPinLocation || item.disabled}
                      className={clsx(
                        classes.locationIconContainer,
                        classes.locationButton,
                        (!item.drawingPinLocation || item.disabled) &&
                          classes.disabledLocationBtn
                      )}
                      aria-label="Repair request map redirect"
                      component="span"
                      onClick={(e) => {
                        e.stopPropagation();
                        listToMapRedirectHandler(e, item);
                      }}
                      size="large"
                    >
                      <LocationOnOutlined
                        className={clsx(
                          classes.locationIcon,
                          classes.locationButtonText
                        )}
                      />
                    </IconButton>
                    <span>{item.buildingNoIntern}</span>
                    <Hidden smDown>
                      <div
                        style={{ marginLeft: 10 }}
                        className={classes.werkbonnenContainer}
                      >
                        <span>{item.addressText}</span>
                      </div>
                      <div
                        style={{ marginLeft: 10 }}
                        className={classes.werkbonnenContainer}
                      >
                        <span>{item.addressPlace}</span>
                      </div>
                    </Hidden>
                  </Typography>
                </div>
              </div>
            </div>
            <div
              className={clsx(
                classes.detailContainer,
                classes.rightAlignedContainer
              )}
            >
              <div>
                <Typography noWrap className={classes.flexContainer}>
                  {item.isAfterCareEmployeeOrSiteManager && (
                    <>
                      {item.attention && (
                        <>
                          <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                            <PriorityHigh color="secondary" fontSize="small" size="small" />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      )}
                      {item.overdue ? (
                        <>
                          <Tooltip
                            title={
                              <Typography>{t("general.overdue")}</Typography>
                            }
                          >
                            <Schedule
                              fontSize="small"
                              size="small"
                              color="error"
                              className={classes.marginRight_5}
                            />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : item.is48HoursReminder ? (
                        <>
                          <Tooltip
                            title={
                              <Typography>{t("general.48hours")}</Typography>
                            }
                          >
                            <Schedule
                              fontSize="small"
                              size="small"
                              className={clsx(
                                classes.warning,
                                classes.marginRight_5
                              )}
                            />
                          </Tooltip>
                          <div className={classes.borderRight} />
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                  <Checkbox
                    size="small"
                    className={clsx(
                      classes.customCheckbox,
                      classes.noPaddingContainer
                    )}
                    checked={selectedCheckBoxs.includes(item.requestId)}
                    onClick={(e) => e.stopPropagation()}
                    onChange={() => {
                      handleCheckboxChange(item.requestId);
                    }}
                  />
                </Typography>
              </div>
              <Typography
                className={classes.requestStatusLabel}
                style={{
                  fontSize:'12px',
                  color: !item.isAfterCareEmployeeOrSiteManager
                    ? theme.palette.grey[700]
                    : item.completed
                    ? theme.palette.success.light
                    : item.overdue
                    ? theme.palette.error.main
                    : theme.palette.grey[700],
                }}
              >
                {item.status}
              </Typography>
              <Typography className={classes.requestBuildingLabel}>
                {formatDate(new Date(item.date))}
              </Typography>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default MobileCustomRow;

const useStyles = makeStyles((theme) => ({
  avatarThumbnail: {
    background: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: "bold",
    borderRadius: 6,
    boxShadow: "none",
  },
  row: {
    "& td": {
      padding: "2px",
      width: "100%",
    },
    display: "flex",
  },
  labelSpacing: {
    marginLeft: 10,
    position: "relative",
    fontSize: 12,
  },
  overflowEllipse: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  locationButton: {
    padding: 0,
    height: 18,
    width: 18,
  },
  locationButtonText: {
    fontSize: 14,
  },
  disabledLocationBtn: {
    background: `${theme.palette.grey[400]} !important`,
  },
  requestContainer: {
    display: "flex",
  },
  requestStatusLabel:{
    maxWidth: "70px !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    whiteSpace:"nowrap"
  }
}));
