import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Popover,
  FormControlLabel,
  useTheme,
  Button,
  CircularProgress,
  useMediaQuery,
  Grow,
  Slide,
  Typography,
  Divider,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Badge,
  IconButton,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {
  GetApp,
  Lock,
  Warning,
  ArrowRightAlt,
  Block,
  CheckCircle,
  Close,
  DragIndicator,
  ErrorOutline,
  Image,
  Message,
  PriorityHigh,
  Schedule,
} from "@mui/icons-material";
import { Parser } from "json2csv";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { URLS } from "../apis/urls";
import { downloadFile } from "../_helpers";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import clsx from "clsx";
import { fontSizeConstant } from "../_constants/grid.constants";

const { webApiUrl } = window.appConfig;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ColumnsSelector({
  rowsSelected,
  type,
  onClose,
  anchorEl,
  title,
  getData,
  muiTableRef,
  columns,
  ...props
}) {
  const { t } = useTranslation();
  const [printLoading, setPrintLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const classes = useStyles();
  const app = useSelector((state) => state.app);
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [selectedColumnsNames, setSelectedColumnsNames] = useState([]);
  const muiTheme = useTheme();
  const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [defaultFontSize, setDefaultFontSize] = useState(10);
  const printStyle = usePrintStyles({ defaultFontSize });

  useEffect(() => {
    if (anchorEl) {
      const data = columns.reduce((p, c) => {
        if (
          type &&
          c &&
          c.options &&
          c.options.display !== "excluded" &&
          c.options[type] !== false
        )
          return [...p, c];
        return p;
      }, []);
      setDisplayedColumns(data);
      setSelectedColumnsNames(
        data.reduce((p, c) => (c && c.options.display ? [...p, c.name] : p), [])
      );
    }
  }, [anchorEl]);

  const handlePrint = async () => {
    setPrintLoading(true);
    try {
      let modifiedData = await getData(muiTableRef);
      if (
        modifiedData &&
        modifiedData.length &&
        rowsSelected &&
        rowsSelected.length
      ) {
        modifiedData = rowsSelected.map((rowIndex) => modifiedData[rowIndex]);
      }
      const indexOfMaxIconsInData = modifiedData.reduce(
        (p, c, ind) =>
          (c.isRequiredHandling && p >= c.isRequiredHandling.length) ||
          (c.extraInformation && p >= c.extraInformation.length)
            ? p
            : ind,
        0
      );
      const printedColumns = displayedColumns.filter((a) =>
        selectedColumnsNames.includes(a.name)
      );

      const table = renderToStaticMarkup(
        <table style={printStyle.tableContainer} className="print-table">
          {
            <caption style={printStyle.caption}>
              <img
                style={printStyle.companyCaptionLogo}
                src={`${webApiUrl}api/config/WebLogo`}
                alt="company-logo"
              />
            </caption>
          }
          <thead>
            <tr>
              {printedColumns.map((c, j) => {
                if (c.options.print !== false) {
                  if (
                    c.name === "isRequiredHandling" ||
                    c.name === "extraInformation"
                  ) {
                    return (
                      <th key={`th-${j}`} style={printStyle.tableHeadCell}>
                        {c.options.customHeadLabelRender ? (
                          <div
                            style={{
                              ...printStyle.iconHeadCell,
                              height: defaultFontSize,
                            }}
                            className="print-icon-header"
                          >
                            <c.options.customHeadLabelRender />
                          </div>
                        ) : (
                          <div style={printStyle.iconHeadCell}>
                            {modifiedData[indexOfMaxIconsInData][c.name] &&
                              modifiedData[indexOfMaxIconsInData][c.name].map(
                                (ic) => {
                                  switch (ic.icon) {
                                    case "PriorityHigh": {
                                      return (
                                        <PriorityHigh
                                          style={printStyle.priorityIcon}
                                        />
                                      );
                                    }
                                    case "Schedule": {
                                      return (
                                        <Schedule
                                          style={printStyle.headCellIcon}
                                        />
                                      );
                                    }
                                    default:
                                      break;
                                  }
                                }
                              )}
                          </div>
                        )}
                      </th>
                    );
                  } else if (
                    c.name.toLowerCase().includes("attachment") ||
                    c.name.toLowerCase().includes("image")
                  )
                    return (
                      <th
                        key={`th-${j}`}
                        style={{
                          ...printStyle.tableHeadCell,
                          textAlign: "center",
                        }}
                      >
                        <Image style={printStyle.headCellIcon} />
                      </th>
                    );
                  return (
                    <th key={`th-${j}`} style={printStyle.tableHeadCell}>
                      {t(c.label)}
                    </th>
                  );
                }
                return null;
              })}
            </tr>
          </thead>
          <tbody>
            {modifiedData &&
              modifiedData.map((p, i) => {
                return (
                  <tr key={`table-row-${i}`}>
                    {printedColumns.map((c, j) => {
                      if (c.options.print !== false) {
                        switch (typeof p[c.name]) {
                          case "object": {
                            if (
                              c.name.toLowerCase().includes("attachment") ||
                              c.name.toLowerCase().includes("image")
                            )
                              return (
                                <td
                                  key={`table-cell-${i}-${j}`}
                                  style={printStyle.priorityIcons}
                                >
                                  {p[c.name].length ? (
                                    <img
                                      style={printStyle.image}
                                      alt="print-img"
                                      src={
                                        c.name === "assignmentAttachments"
                                          ? URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL +
                                            p[c.name][0].id
                                          : URLS.GET_ATTACHMENT_THUMBNAIL +
                                            p[c.name][0].attachmentId
                                      }
                                    />
                                  ) : (
                                    <Image style={printStyle.headCellIcon} />
                                  )}
                                </td>
                              );
                            else if (
                              c.name === "isRequiredHandling" ||
                              c.name === "extraInformation"
                            ) {
                              return (
                                <td
                                  key={`table-cell-${i}-${j}`}
                                  style={printStyle.tableBodyCell}
                                >
                                  <div style={printStyle.iconHeadCell}>
                                    {p[c.name].map((ic) => {
                                      switch (ic.icon) {
                                        case "PriorityHigh": {
                                          return (
                                            <PriorityHigh
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={printStyle.priorityIcon}
                                            />
                                          );
                                        }
                                        case "Schedule": {
                                          return (
                                            <Schedule
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "ErrorOutline": {
                                          return (
                                            <ErrorOutline
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "CheckCircle": {
                                          return (
                                            <CheckCircle
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "Block": {
                                          return (
                                            <Block
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "GetApp": {
                                          return (
                                            <GetApp
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "Lock": {
                                          return (
                                            <Lock
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "Warning": {
                                          return (
                                            <Warning
                                              key={`table-cell-${i}-${j}-ic`}
                                              style={{
                                                ...printStyle.priorityIcon,
                                                ...printStyle[ic.color],
                                              }}
                                            />
                                          );
                                        }
                                        case "Message": {
                                          return (
                                            !!ic.count && (
                                              <span
                                                key={`table-cell-${i}-${j}-ic`}
                                                style={printStyle.priorityIcon}
                                              >
                                                {ic.count}
                                              </span>
                                            )
                                          );
                                        }
                                        default:
                                          break;
                                      }
                                    })}
                                  </div>
                                </td>
                              );
                            } else if (Array.isArray(p[c.name])) {
                              return (
                                <td
                                  key={`table-cell-${i}`}
                                  style={printStyle.tableBodyCell}
                                >
                                  <div style={printStyle.resolversContainer}>
                                    {p[c.name].map((r, k) => {
                                      return (
                                        <span key={`table-cell-${i}-${j}-${k}`}>
                                          {typeof r === "object" ? r.name : r}
                                        </span>
                                      );
                                    })}
                                  </div>
                                </td>
                              );
                            }
                            return (
                              <td
                                key={`table-cell-${i}`}
                                style={printStyle.tableBodyCell}
                              ></td>
                            );
                          }
                          default: {
                            if (c.name === "priority")
                              return (
                                <td
                                  key={`table-cell-${i}`}
                                  style={printStyle.priorityIcons}
                                >
                                  {p[c.name] === 2 ? (
                                    <ArrowRightAlt
                                      style={printStyle.rotate45AntiClockwise}
                                    />
                                  ) : p[c.name] === 1 ? (
                                    <ArrowRightAlt
                                      style={printStyle.rotate0Clockwise}
                                    />
                                  ) : p[c.name] === 0 ? (
                                    <ArrowRightAlt
                                      style={printStyle.rotate45Clockwise}
                                    />
                                  ) : (
                                    ""
                                  )}
                                </td>
                              );
                            return (
                              <td
                                key={`table-cell-${i}`}
                                style={printStyle.tableBodyCell}
                              >
                                <span>{p[c.name]}</span>
                              </td>
                            );
                          }
                        }
                      }
                      return null;
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      );

      const iframe = document.createElement("iframe");
      const link = document.createElement("link");
      link.href = "./print.css";
      link.rel = "stylesheet";
      link.type = "text/css";
      document.body.appendChild(iframe);
      iframe.style.display = "none";

      iframe.onload = function () {
        iframe.focus();
        // for other browsers
        iframe.contentDocument.head.appendChild(link);
        iframe.contentDocument.body.innerHTML = table;
      };

      // for chrome browsers
      iframe.contentDocument.head.appendChild(link);
      iframe.contentDocument.body.innerHTML = table;

      setTimeout(() => {
        iframe.contentWindow.print();
        setPrintLoading(false);
      }, 2000);
    } catch (er) {
      console.log(er, "error occured");
      setPrintLoading(false);
    }
  };

  const handleDownload = async () => {
    try {
      setDownloadLoading(true);
      let modifiedData = await getData(muiTableRef);
      if (
        modifiedData &&
        modifiedData.length &&
        rowsSelected &&
        rowsSelected.length
      ) {
        modifiedData = rowsSelected.map((rowIndex) => modifiedData[rowIndex]);
      }

      const printedColumns = displayedColumns.filter((a) =>
        selectedColumnsNames.includes(a.name)
      );

      const data = modifiedData.map((c, i) => {
        return printedColumns.reduce((p, curr) => {
          if (curr.options.download !== false)
            return {
              ...p,
              [curr.label]:
                curr.name === "priority"
                  ? t("repairRequest.priority." + c[curr.name])
                  : Array.isArray(c[curr.name])
                  ? c[curr.name].join(",")
                  : c[curr.name],
            };
          return p;
        }, {});
      });

      const parser = new Parser({
        fields: printedColumns.reduce(
          (p, c) => (c.options.download !== false ? [...p, c.label] : p),
          []
        ),
      });
      const csv = parser.parse(data);
      await downloadFile(
        "data:attachment/csv;charset=utf-8," + encodeURI(csv),
        `${t(title)}.csv`
      );
      setDownloadLoading(false);
    } catch (er) {
      console.log(er, "er");
      setDownloadLoading(false);
    }
  };

  const handleClick = () => {
    if (type === "print") handlePrint();
    else if (type === "download") handleDownload();
    else return;
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const updatedOrderColumns = arrayMove(
      Object.assign([], displayedColumns),
      oldIndex,
      newIndex
    );
    if (oldIndex !== newIndex) setDisplayedColumns(updatedOrderColumns);
  };

  const handleChange = (e) => {
    const data = Object.assign([], selectedColumnsNames);
    const index = data.findIndex((a) => a === e.target.name);
    if (index >= 0) data.splice(index, 1);
    else data.push(e.target.name);
    setSelectedColumnsNames(data);
  };

  const handleChangeAll = (e) => {
    if (selectedColumnsNames.length === displayedColumns.length)
      setSelectedColumnsNames([]);
    else setSelectedColumnsNames(displayedColumns.map((a) => a.name));
  };

  const SortableItem = SortableElement(({ column, itemIndex, ...rest }) => (
    <Grid
      item
      xs={6}
      className={classes.listItem}
      id={`draggableItem-${column.name}`}
    >
      <DragIndicator className={classes.dragIndicator} />
      <span className={classes.orderText}>
        {(itemIndex + 1).toString().padStart(2, "0")}
      </span>
      <FormControlLabel
        color="primary"
        className={classes.formLabel}
        classes={{ label: classes.formLabelContent }}
        control={
          <Checkbox
            onChange={handleChange}
            checked={selectedColumnsNames.includes(column.name)}
            color="primary"
            name={column.name}
          />
        }
        label={
          column.label.trim() ? (
            column.label
          ) : column.options.customHeadLabelRender ? (
            <column.options.customHeadLabelRender />
          ) : (
            column.name
          )
        }
      />
    </Grid>
  ));

  const SortableList = SortableContainer(({ columnsData }) => {
    return (
      <Grid container className={classes.gridContainer}>
        {columnsData.map((column, index) => (
          <SortableItem
            index={index}
            key={`${column.name}`}
            itemIndex={index}
            column={column}
          />
        ))}
      </Grid>
    );
  });

  const handleChangeFontSize = ({ target: { value } }) => {
    setDefaultFontSize(value);
  };

  const selectedAllColumns =
    selectedColumnsNames.length === displayedColumns.length;

  return (
    <Popover
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      TransitionComponent={matchWidthDownSm ? Transition : Grow}
      className={classes.popover}
    >
      <div className={classes.popoverRoot}>
        {matchWidthDownSm ? (
          <div className={classes.stickyHeader}>
            <div className={classes.drawerHeader}>
              <div
                className={clsx(classes.titleContent, classes.maxWidth50Per)}
              >
                <Checkbox
                  className={classes.smallDeviceAllCheckbox}
                  indeterminate={
                    selectedColumnsNames.length && !selectedAllColumns
                  }
                  onChange={handleChangeAll}
                  checked={selectedAllColumns}
                  color="primary"
                />
                <Typography className={classes.drawerTitle}>{`${t(
                  "columns.for",
                  { columnLabel: t(title) }
                )}`}</Typography>
              </div>
              <div className={classes.titleContent}>
                {type === "print" && (
                  <>
                    <label
                      className={classes.fontSizeLabel}
                      htmlFor="font-size-selector"
                    >
                      {t("print.fontsize")}
                    </label>
                    <FormControl className={classes.formControl}>
                      <Select
                        id="font-size-selector"
                        value={defaultFontSize}
                        onChange={handleChangeFontSize}
                        input={<Input />}
                      >
                        {fontSizeConstant.map((a) => (
                          <MenuItem key={a} value={a}>
                            {a}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                )}
                <IconButton
                  onClick={onClose}
                  className={classes.drawerCloseIcon}
                  size="large"
                >
                  <Close />
                </IconButton>
              </div>
            </div>
            <Divider />
          </div>
        ) : (
          <Grid container className={classes.titleContent}>
            <Grid item xs={6}>
              <FormControlLabel
                color="primary"
                className={classes.formLabel}
                control={
                  <Checkbox
                    indeterminate={
                      selectedColumnsNames.length && !selectedAllColumns
                    }
                    onChange={handleChangeAll}
                    checked={selectedAllColumns}
                    color="primary"
                  />
                }
                label={t(
                  selectedAllColumns
                    ? "columns.unselect.all"
                    : "columns.select.all"
                )}
              />
            </Grid>
            {type === "print" && (
              <Grid item xs={6}>
                <div className={classes.titleContent}>
                  <label
                    className={classes.fontSizeLabel}
                    htmlFor="font-size-selector"
                  >
                    {t("print.fontsize")}
                  </label>
                  <FormControl className={classes.formControl}>
                    <Select
                      id="font-size-selector"
                      value={defaultFontSize}
                      onChange={handleChangeFontSize}
                      input={<Input />}
                    >
                      {fontSizeConstant.map((a) => (
                        <MenuItem key={a} value={a}>
                          {a}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </Grid>
            )}
            <IconButton
              onClick={onClose}
              className={classes.drawerCloseIcon}
              size="large"
            >
              <Close />
            </IconButton>
          </Grid>
        )}
        <SortableList
          transitionDuration={400}
          columnsData={displayedColumns}
          axis="xy"
          distance={10}
          onSortEnd={onSortEnd}
        />
        <Button
          disabled={printLoading || downloadLoading}
          className={classes.actionButton}
          onClick={handleClick}
          variant="outlined"
          color="primary"
        >
          {printLoading || downloadLoading ? (
            <CircularProgress size={18} />
          ) : type === "print" ? (
            t("general.print")
          ) : type === "download" ? (
            t("datatable.label.toolbar.downloadCsv")
          ) : (
            ""
          )}
        </Button>
      </div>
    </Popover>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: 0,
    minWidth: 80,
    maxWidth: 200,
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    background: theme.palette.common.white,
    zIndex: 1,
  },
  drawerHeader: {
    display: "flex",
    padding: theme.spacing(1.5),
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleContent: {
    display: "flex",
    alignItems: "center",
  },
  maxWidth50Per: {
    maxWidth: "50%",
  },
  drawerTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.common.black,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  drawerCloseIcon: {
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(0.5),
    "& > span > svg": {
      fill: theme.palette.common.white,
      fontSize: 16,
    },
    "&:hover": {
      backgroundColor: theme.palette.grey[500],
    },
    [theme.breakpoints.only("xs")]: {
      marginLeft: 10,
    },
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      right: 8,
    },
  },
  popover: {
    "& .MuiPopover-paper": {
      [theme.breakpoints.only("xs")]: {
        top: "auto !important",
        bottom: "0px !important",
        left: "0px !important",
        maxWidth: "100% !important",
        width: "100%",
        boxShadow: theme.shadows[24],
        borderRadius: 20,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
      },
    },
  },
  popoverRoot: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
  },
  columnsContainer: {
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    maxHeight: "70vh",
    overflow: "auto",
  },
  actionButton: {
    margin: "auto",
    marginBottom: theme.spacing(1),
  },
  formLabel: {
    margin: 0,
    marginLeft: -5,
    width: "90%",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  fontSizeChanger: {
    width: 50,
    "& input": {
      padding: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  fontSizeLabel: {
    whiteSpace: "nowrap",
    margin: 0,
    marginRight: 8,
  },
  formLabelContent: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  listItem: {
    margin: `0 !important`,
    opacity: `1 !important`,
    zIndex: 1500,
    display: "flex",
    fontSize: 12,
    alignItems: "center",
  },
  dragIndicator: {
    width: 12,
    height: 12,
    fontSize: 12,
    cursor: "grab",
  },
  orderText: {
    fontSize: 12,
    lineHeight: "12px",
  },
  gridContainer: {
    width: 500,
    maxWidth: "100%",
    padding: theme.spacing(1),
  },
  smallDeviceAllCheckbox: {
    padding: theme.spacing(0.5),
  },
}));

const usePrintStyles = ({ defaultFontSize, ...rest }) => {
  const theme = useTheme();
  return {
    tableContainer: {
      borderSpacing: 0,
      width: "100%",
      fontSize: defaultFontSize,
    },
    tableHeadCell: {
      textAlign: "left",
      width: 40,
      margin: 0,
      padding: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
    },
    iconHeadCell: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    priorityIcon: {
      fill: theme.palette.error.main,
      height: defaultFontSize,
      width: defaultFontSize,
      fontSize: defaultFontSize,
    },
    image: {
      width: defaultFontSize,
      objectFit: "cover",
    },
    error: {
      fill: theme.palette.error.main,
    },
    primary: {
      fill: theme.palette.primary.main,
    },
    warning: {
      fill: theme.palette.warning.main,
    },
    success: {
      fill: theme.palette.success.main,
    },
    priorityIcons: {
      textAlign: "center",
      margin: 0,
      padding: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      fontWeight: "normal",
    },
    headCellIcon: {
      height: defaultFontSize,
      width: defaultFontSize,
      fontSize: defaultFontSize,
    },
    tableBodyCell: {
      margin: 0,
      padding: theme.spacing(0.5),
      borderBottom: `1px solid ${theme.palette.grey[200]}`,
      fontWeight: "normal",
    },
    rotate45Clockwise: {
      height: defaultFontSize,
      width: defaultFontSize,
      fontSize: defaultFontSize,
      fill: theme.palette.primary.main,
      transform: "rotate(45deg)",
    },
    rotate0Clockwise: {
      fill: theme.palette.primary.main,
      height: defaultFontSize,
      width: defaultFontSize,
      fontSize: defaultFontSize,
    },
    rotate45AntiClockwise: {
      height: defaultFontSize,
      width: defaultFontSize,
      fontSize: defaultFontSize,
      fill: theme.palette.primary.main,
      transform: "rotate(-45deg)",
    },
    resolversContainer: {
      display: "flex",
      flexDirection: "column",
    },
    caption: {
      captionSide: "top",
      textAlign: "left",
      paddingBottom: theme.spacing(2.5),
    },
    companyCaptionLogo: {
      maxHeight: 35,
    },
    printTable: {
      backgroundColor: "red !important",
    },
  };
};
