import {
    Avatar,
    Checkbox,
    IconButton,
    TableCell,
    TableRow,
    Tooltip,
    Typography,
    useMediaQuery,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { URLS } from "../../apis/urls";
import clsx from "clsx";
import { formatDate, history } from "../../_helpers";
import { useSelector } from "react-redux";
import { LocationOnOutlined, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../components/CustomRowStyles";


const WorkOrderRequestCustomRow = (props) => {
    const { setRowsSelected, rowsSelected, columns, data, identifier, currentRow, dataIndex } = props;
    const classes = { ...useRowStyles(), ...useStyles() };
    const { selected, app } = useSelector(state => state.buildings);
    const { t } = useTranslation();

    const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
    const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};

    if (!selectedRow || !columns.length) return "";
    const attachmentSrc = selectedRow.repairRequestImages && selectedRow.repairRequestImages.length ? URLS.GET_ATTACHMENT_THUMBNAIL + selectedRow.repairRequestImages[0].attachmentId : null;

    const listToMapRedirectHandler = (e) => {
        e.stopPropagation();
        e.preventDefault();
        history.push({
            pathname: `/werk/${selected.projectNo}/${app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"}`,
            state: {
                selectedRequest: {
                    ...selectedRow,
                    desc: selectedRow.description,
                    textResolvers: [selectedRow.name],
                    attachments: selectedRow.repairRequestImages || [],
                    number: selectedRow.workOrderNumber,
                    status: t("resolver.status." + selectedRow.status),
                },
                requests: data.map(p => ({
                    ...p,
                    desc: selectedRow.description,
                    textResolvers: [p.name],
                    attachments: p.repairRequestImages || [],
                    number: p.workOrderNumber,
                    status: t("resolver.status." + p.status),
                })),
                buildingId: selectedRow.buildingId
            }
        })
    }

    return (
        <React.Fragment >
                <TableRow onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    history.push({
                        pathname: "/werk/" + selected.projectNo + "/werkbon/" + selectedRow.resolverId,
                        state: { fromWorkOrderGrids: true }
                    });
                }} className={clsx(classes.customGridRow)}>
                    <TableCell>
                        <div className={classes.customSmallDeviceRowContainer}>
                            <div className={classes.verticallyCenterContainer}>
                                <div className={classes.relativeContainer}>
                                    <Avatar
                                        src={attachmentSrc}
                                        className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')}>
                                        {selectedRow.buildingNoExtern}
                                    </Avatar>
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={clsx(classes.requestTitle, classes.grow)}>
                                            {selectedRow.description}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography noWrap className={classes.flexContainer}>
                                            {selectedRow.overdue ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : selectedRow.is48HoursReminder ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" className={clsx(classes.warning, classes.marginRight_5)} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : ("")}
                                            <Checkbox size="small" className={clsx(classes.customCheckbox, classes.noPaddingContainer)} checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                                                const list = Object.assign([], rowsSelected.list);
                                                const isExist = list.findIndex(p => p === dataIndex);
                                                const rowsData = Object.assign([], rowsSelected.data);
                                                if (isExist >= 0) {
                                                    list.splice(isExist, 1)
                                                    rowsData.splice(isExist, 1)
                                                } else {
                                                    rowsData.push({ [identifier]: selectedRow[identifier] })
                                                    list.push(dataIndex);
                                                };

                                                setRowsSelected({ ...rowsSelected, data: rowsData, list });
                                            }} color='primary' onClick={(e) => e.stopPropagation()} />
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)} >
                                    <div>
                                        <Typography className={classes.requestTypeLabel}>
                                            {selectedRow.explanation}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            className={classes.requestStatusLabel}
                                            style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                                            {t(selectedRow.statusText)}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}  >
                                    <div className={classes.requestContainer}>
                                        <div>
                                            <Typography className={classes.requestBuildingLabel}>
                                                <IconButton
                                                    color="primary"
                                                    disabled={!selectedRow.drawingPinLocation}
                                                    className={clsx(classes.locationIconContainer, classes.locationButton, !selectedRow.drawingPinLocation && classes.disabledLocationBtn)}
                                                    aria-label="Repair request map redirect"
                                                    component="span"
                                                    onClick={listToMapRedirectHandler}
                                                    size="large">
                                                    <LocationOnOutlined className={clsx(classes.locationIcon, classes.locationButtonText)} />
                                                </IconButton>
                                                <span>
                                                    {selectedRow.buildingNoIntern}
                                                </span>
                                            </Typography>
                                        </div>
                                        <div className={classes.labelSpacing}>
                                            <Typography className={classes.requestTypeLabel}>
                                                {selectedRow.name}
                                            </Typography>
                                        </div>
                                    </div>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel} >
                                            {formatDate(new Date(selectedRow.targetCompletionDate))}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    );
};

export default WorkOrderRequestCustomRow;

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    descriptionContainer: {
        flexDirection: "row"
    },
    offlineIconContainer: {
        position: "absolute",
        right: 0,
        top: 0
    },
    offlineIcon: {
        fontSize: 14,
        fill: theme.palette.common.white
    },
    avatarThumbnail: {
        background: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 'bold',
        borderRadius: 6,
        boxShadow: "0px 0px 3px #eee"
    },
    imageBadgeContainer1: {
        bottom: 0,
        right: 0,
        borderRadius: 0,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 6,
        fontSize: 10,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    overflowEllipse: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
    },
    locationButton: {
        padding: 0,
        height: 18,
        width: 18,
    },
    locationButtonText: {
        fontSize: 14,
    },
    disabledLocationBtn: {
        background: `${theme.palette.grey[400]} !important`
    },
    requestContainer: {
        display: 'flex'
    },
    labelSpacing: {
        marginLeft: 5
    },
}));
