import React from "react";
import { Button } from "@mui/material";
import { Container, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import img from "../assets/Group 411.png";
import decorationLine from "../assets/Lines.png";
import Image from "../assets/Background/House-900x604.svg";
import { history } from "../_helpers";

export const nl2br = (text) =>
  text &&
  text.split(/(?:\r\n|\r|\n)/g).map((item, key) => {
    return (
      <React.Fragment key={key}>
        {item}
        <br />
      </React.Fragment>
    );
  });

function FallBackPage({
  status = "500",
  error,
  componentStack,
  resetError,
  ...props
}) {
  const classes = useStyles();
  const { selectedBuilding } = props;
  const { t } = useTranslation();
  const resetErrorHandler = () => {
    resetError();
    history.replace("/");
  };

  const { webApiUrl } = window.appConfig;
  const logo =
    webApiUrl +
    (selectedBuilding
      ? "api/home/ProjectLogo/" + selectedBuilding.projectId
      : "api/config/WebLogo");

  return (
    <div className={classes.blocksContainer}>
      <Container className={classes.containerStyle}>
        <div className={classes.logoHeader}>
          <Link
            onClick={resetErrorHandler}
            to=""
            className={classes.logoAnchor}
          >
            <img src={logo} alt="JPDS" className={classes.logo} />
          </Link>
        </div>
        <div className={classes.innerContainer}>
          <div className={classes.innerSubContainer}>
            <img
              src={decorationLine}
              alt="decorationLine"
              className={classes.decorationLine}
            />
            <Typography className={classes.mainTitle}>
              {t("error.header")}
            </Typography>
            <Typography className={classes.subHeading}>
              {" "}
              {t("error.subheader.content")}
            </Typography>
            <Button
              variant="outlined"
              onClick={resetErrorHandler}
              color="primary"
              className={classes.backButton}
            >
              {t("error.button")}
            </Button>
          </div>
          <div className={classes.imageContainer}>
            <img src={img} alt="error" className={classes.imageElement} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  imageElement: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
  imageContainer: {
    position: "absolute",
    [theme.breakpoints.down("md")]: {
      height: "auto",
    },
    right: 0,
    display: "flex",
    justifyContent: "flex-end",
    zIndex: -1,
    height: "calc(100% - 84px)",
    bottom: 0,
  },
  containerStyle: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  blocksContainer: {
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&::before": {
      content: '""',
      backgroundImage: `url(${Image})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center bottom",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "auto",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
    },
  },
  logoHeader: {
    padding: theme.spacing(2.8, 0),
    height: 84,
  },
  logoAnchor: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "calc(100% - 216px)",
    },
  },
  logo: {
    maxHeight: 36,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
    },
  },
  grow: {
    flexGrow: 1,
  },
  decorationLine: {
    position: "absolute",
    left: -31,
    top: -24,
  },
  innerSubContainer: {
    position: "relative",
  },
  innerContainer: {
    display: "flex",
    height: "calc(100% - 84px)",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      marginTop: 90,
    },
  },
  mainTitle: {
    fontSize: 93,
    fontWeight: "bold",
    color: theme.palette.common.black,
    lineHeight: 1,
    maxWidth: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: 66,
    },
  },
  subHeading: {
    fontSize: 30,
    maxWidth: 500,
    fontWeight: "500",
    color: theme.palette.common.black,
    margin: "10px 0",
    [theme.breakpoints.down("md")]: {
      fontSize: 21,
    },
  },
  backButton: {
    borderRadius: "10px 10px 0px 10px",
    textTransform: "capitalize",
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 20,
  },
  errorDialog: {
    "& .MuiPaper-root": {
      height: "30%",
      borderRadius: 10,
      marginTop: 26,
    },
    "& .MuiDialog-container": {
      alignItems: "center",
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",
    },
  },
  appBar: {
    borderRadius: "0 !important",
    margin: "0 !important",
    padding: theme.spacing(0, 1, 0, 1),
    height: "initial !important",
    width: "100% !important",
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    overflow: "auto",
    position: "relative",
  },
  errorMessage: {
    textAlign: "center",
  },

  appBarContainer: {
    display: "flex",
  },
  closeButton: {
    padding: 0,
    margin: 5,
  },
}));

export default FallBackPage;
