import {
  AppBar,
  Button,
  Grid,
  Toolbar,
  Typography,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ArrowBack, GetApp, Lock, Warning } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { Route, Switch, useHistory } from "react-router-dom";
import SurveysLists from "../components/SurveysLists";
import { useDispatch, useSelector } from "react-redux";
import InspectionsDetail from "./components/InspectionsDetail";
import { surveyActions } from "../../../_actions";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import { SurveyType } from "../../../_helpers/enumsHelper";
import RepairRequestForm from "../RepairRequestForm";
import Image from "../../../assets/Background/House-900x604.svg";
import { surveyConstants } from "../../../_constants";
import { useRedirectionPath } from "../../../_helpers/redirectHelper";
import {
  useCanLockedInspection,
  useInternetStatusDetector,
} from "../../../_helpers";
import PdfPreview from "../components/PdfPreview";

export default function Inspections(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isRepairRequest, setIsRepairRequest] = useState(false);
  const [inspectionId, setInspectionId] = useState(null);
  const [openRepairRequestMap, setOpenRepairRequestMap] = useState(null);
  const [offlineRepairRequestData, setOfflineRepairRequestData] =
    useState(null);
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({
    pages: 1,
  });
  const [canGoBack, setCanGoBack] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isUpdatingStatus, setUpdatingStatus] = useState(false);
  const [pdfPreview, setPdfPreview] = useState(false);

  //Redux State
  const { selected } = useSelector((state) => state.buildings);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { availableSurveyModules } = useSelector(
    (state) => state.availableSurveyModules
  );
  const { user } = useSelector((state) => state.authentication);
  const {
    getSurveysLoading,
    inspections,
    selectedInspection,
    isSurveyUpdating,
    assignmentLockIds,
    repairRequests,
    loadingRepairRequests
  } = useSelector((state) => state.surveying);

  const redirectPatch = useRedirectionPath(SurveyType.Inspection);
  const canLockedInspection = useCanLockedInspection();
  const isOnline = useInternetStatusDetector();

  const survey =
    inspections.find(
      (a) => a.surveyId === (selectedInspection && selectedInspection.surveyId)
    ) || {};
  const { isCracked } = survey;
  const lockInfo =
    assignmentLockIds &&
    assignmentLockIds.find(
      (a) =>
        a.lockId === (selectedInspection && selectedInspection.lockId) &&
        !a.isSecondSignature
    );
  const isDownloadedSurvey =
    selectedInspection &&
    lockInfo &&
    lockInfo.userId === user.id &&
    selectedInspection.lockId;

  useEffect(() => {
    if (redirectPatch) history.push(redirectPatch);
  }, [availableSurveyModules, user]);

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes("inspecties")) {
        dispatch(
          surveyActions.success({
            type: surveyConstants.GET_INSPECTIONS_SUCCESS,
            inspections: [],
          })
        );
      }
    };
  }, []);

  useEffect(() => {
    if (selected && inspectionId && !selectedInspection) {
      dispatch(surveyActions.getSurveyDetailAction(inspectionId, false));
    }
  }, [inspectionId, isOnline]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      setSelectedDrawingFiles({
        ...drawingFiles[0],
        groupBy: drawingFiles[0].isInternal ? "Internal" : "External",
      });
    } else {
      setSelectedDrawingFiles({});
    }
  }, [drawingFiles]);

  useEffect(() => {
    if (selectedInspection) {
      dispatch(
        dossiersActions.getDrawingFiles(
          selectedInspection.projectId,
          selectedInspection.buildingId
        )
      );
    }
  }, [selectedInspection]);

  const handleGoBack = () => {
    let url = props.match.url;
    if (isRepairRequest) {
      setIsRepairRequest(false);
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
    } else if (pdfPreview) {
      url = `${props.match.url}/${
        (selectedInspection && selectedInspection.surveyId) || inspectionId
      }`;
      setPdfPreview(false);
    } else if (selectedInspection && inspectionId) {
      setInspectionId(null);
      dispatch(
        surveyActions.customDispatcher({
          type: surveyConstants.GET_INSPECTION_DETAIL_SUCCESS,
          data: null,
        })
      );
    }
    history.replace(url);
  };

  const updateInspection = async (status) => {
    setUpdatingStatus(true);
    await dispatch(
      surveyActions.updateSurvey(
        {
          ...selectedInspection,
          status,
          surveyId: selectedInspection.surveyId,
        },
        selectedInspection.surveyType
      )
    );
    setUpdatingStatus(false);
  };

  const canShowBackButton =
    props &&
    props.match &&
    props.location &&
    props.match.url !== props.location.pathname;

    const previewHandler = () => {
      history.push(`${props.match.url}/${inspectionId}/preview`);
    };

  return (
    <div className={classes.appbar}>
      <Grid
        item
        xs={12}
        className={clsx(
          classes.blocksContainer,
          selectedInspection && selectedInspection.surveyId
        )}
      >
        {inspectionId && selectedInspection && selectedInspection.surveyId && (
          <AppBar
            position="static"
            color="transparent"
            className={classes.surveyMomentsMainHeaderSecond}
          >
            <Toolbar
              variant="dense"
              className={classes.surveyMomentsHeaderSecond}
            >
              {
                <React.Fragment>
                  {canShowBackButton && (
                    <Grid item>
                      <IconButton
                        edge="start"
                        aria-label="GoBack"
                        color="inherit"
                        onClick={handleGoBack}
                        size="large"
                      >
                        <ArrowBack className={classes.arrowBlackIcon} />
                      </IconButton>
                    </Grid>
                  )}
                  <div
                    className={clsx(classes.grow, classes.bold)}
                    style={{ display: "flex" }}
                    noWrap
                  >
                    <div className={classes.appHeader}>
                      <Typography className={classes.appBarText}>
                        {`${t("survey.inspections")}: ${
                          selectedInspection.buildingNoIntern
                        }`}
                      </Typography>
                      <div className={classes.detailIconContainer}>
                        {isDownloadedSurvey && (
                          <Tooltip
                            title={t(`general.downloaded`, {
                              moduleType: t("survey.inspection"),
                            })}
                          >
                            <GetApp color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {isCracked && (
                          <Tooltip
                            title={t(`general.locked`, {
                              moduleType: t("survey.inspection"),
                            })}
                          >
                            <Warning color="primary" className={classes.icon} />
                          </Tooltip>
                        )}

                        {!lockInfo && selectedInspection.lockId && (
                          <Tooltip
                            title={t(`general.lockBroken`, {
                              moduleType: t("survey.inspection"),
                            })}
                          >
                            <Lock color="primary" className={classes.icon} />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                  {inspectionId && !isRepairRequest && selectedInspection && (
                    <div>
                      {selectedInspection.status !== 2 && !pdfPreview && (
                        <>
                        <Button
                          disabled={canLockedInspection}
                          className={classes.signButton}
                          variant="contained"
                          color="primary"
                          onClick={() =>
                            updateInspection(
                              selectedInspection.status === 0 ? 1 : 2
                            )
                          }
                        >
                          {isSurveyUpdating && isUpdatingStatus ? (
                            <CircularProgress
                              className={classes.loading}
                              size={20}
                            />
                          ) : (
                            t(
                              selectedInspection.status === 0
                                ? "survey.button.start"
                                : "general.button.complete"
                            )
                          )}
                        </Button>
                              {
                                  selectedInspection.status !== 0 &&
                                  <Button
                                      className={classes.signButton}
                                      variant="contained"
                                      disabled={
                                          canLockedInspection ||
                                          loadingRepairRequests ||
                                          !selectedInspection.hasRights
                                      }
                                      color="primary"
                                      onClick={previewHandler}
                                  >
                                      {t("general.preview")}
                                  </Button>
                              }
                        </>
                      )}
                    </div>
                  )}
                </React.Fragment>
              }
            </Toolbar>
          </AppBar>
        )}
        <div
          className={clsx(
            classes.scrollContainer,
            selectedInspection && classes.calculateHeight
          )}
        >
          <div className={classes.appbar} style={{ width: "100%" }}>
            <Switch>
              <Route
                exact
                path={`${props.match.path}`}
                render={(rest) => (
                  <SurveysLists
                    {...rest}
                    canShowPdfColumn
                    moduleType={SurveyType.Inspection}
                    onlyOfflinePDFVersion
                    preDeliveryOrInspectionPDF
                    createInspection
                    title={t("survey.inspections")}
                    parentUrl={props.match.url}
                    pathKey={"inspecties"}
                    moduleTitle={t("survey.inspection")}
                    gridDataTitle={t("general.general")}
                    loading={getSurveysLoading}
                    data={(inspections && inspections.length
                      ? inspections.sort(
                          (p, c) => new Date(c.date) - new Date(p.date)
                        )
                      : []
                    ).filter((a) => a.surveyType === SurveyType.Inspection)}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId`}
                render={(rest) => (
                  <InspectionsDetail
                    {...rest}
                    openRepairRequestMap={openRepairRequestMap}
                    canGoBack={canGoBack}
                    setOpenRepairRequestMap={(data) => {
                      setOpenRepairRequestMap(true);
                      data && setOfflineRepairRequestData(data);
                    }}
                    setId={(id) => setInspectionId(id)}
                  />
                )}
              />
              <Route
                exact
                path={`${props.match.path}/:inspectionId/preview`}
                render={(rest) => (
                  <PdfPreview
                    id={inspectionId}
                    onlyShowPDF
                    isDownloadedSurvey={isDownloadedSurvey}
                    showMeldingImage
                    hideSignatureFromPDF
                    offlinePDFOnly
                    setPdfPreview={(data) => setPdfPreview(data)}
                    setId={(id) => setInspectionId(id)}
                    {...rest}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </Grid>
      <RepairRequestForm
        repairRequests={repairRequests}
        isUpdate={!!offlineRepairRequestData}
        values={offlineRepairRequestData}
        moduleType={SurveyType.Inspection}
        setSelectedDrawingFiles={setSelectedDrawingFiles}
        setCurrentPage={setCurrentPage}
        surveyId={selectedInspection && selectedInspection.surveyId}
        buildingId={selectedInspection && selectedInspection.buildingId}
        projectId={selectedInspection && selectedInspection.projectId}
        selectedDrawingFiles={selectedDrawingFiles}
        currentPage={currentPage}
        redirectToMomentDetail={console.log}
        onPreview={console.log}
        setID={(id) => setInspectionId(id)}
        // setIsRepairRequest={(data) => setIsRepairRequest(data)}
        openRepairRequestMap={openRepairRequestMap}
        onClose={(reload) => {
          reload && setCanGoBack(new Date().getTime());
          offlineRepairRequestData && setOfflineRepairRequestData(null);
          setOpenRepairRequestMap(false);
          setCurrentPage(1);
        }}
        drawingLocation={
          offlineRepairRequestData && {
            xrel: +offlineRepairRequestData["DrawingPinLocationModel.Xrel"],
            yrel: +offlineRepairRequestData["DrawingPinLocationModel.Yrel"],
            dossierFileId:
              offlineRepairRequestData["DrawingPinLocationModel.DossierFileId"],
          }
        }
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  surveyMomentsMainHeaderSecond: {
    boxShadow: "none",
  },
  surveyMomentsHeaderSecond: {
    padding: theme.spacing(0, 2),
  },
  loading: {
    color: theme.palette.common.white,
  },
  blocksContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
    "&::before": {
      content: '""',
      [theme.breakpoints.down("md")]: {
        background: theme.palette.common.white,
      },
      backgroundImage: `url(${Image})`,
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      backgroundPosition: "center bottom",
      height: "100%",
      width: "100%",
      overflowX: "hidden",
      overflowY: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "fixed",
    },
  },
  calculateHeight: {
    height: "calc(100% - 48px) !important",
  },

  signButton: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
      padding: theme.spacing(0.2, 1.9),
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
      padding: theme.spacing(0.2, 1.9),
    },
    fontWeight: "normal",
    marginLeft: 8,
  },
  appHeader: {
    display: "flex",
    // maxWidth: '50%',
    overflow: "hidden",
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
    overflow: "hidden",
  },
  arrowBlackIcon: {
    cursor: "pointer",
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  appBarText: {
    fontSize: 16,
    marginLeft: 10,
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  appbar: {
    height: "100%",
    overflow: "auto",
  },
  scrollContainer: {
    //  height: 'calc(100% - 48px)',
    height: "100%",
    // overflow: 'auto'
  },
  detailIconContainer: {
    marginLeft: 5,
    [theme.breakpoints.down("sm")]: {
      "& svg": {
        fontSize: 15,
      },
    },
  },
}));
