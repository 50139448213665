import React, { useMemo } from 'react'
import { Box, StyledEngineProvider, ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro'
import { grey } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import { getDataTableTextLocale } from "../_helpers/textHelper"

const DataGridV2 = ({localeText ,currentView, ...rest}) => {
  const { i18n } = useTranslation();
  const classes = useStyles();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  const locale = useMemo(() => {
    let val = { ...getDataTableTextLocale(i18n.language) }
    if (localeText) {
      val = { ...val, ...localeText }
    }
    return val
  }, [i18n.language, localeText])

  return (<>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Box height="100%">
          <DataGridPro
            density="compact"
            rowHeight={34}
            localeText={locale}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                printOptions: {
                  allColumns: false,
                  fileName: "Print",
                  hideFooter: true,
                  hideToolbar: true,
                },
              },
            }}
            sx={{
              background: "white",
              fontSize: "12px",
              lineHeight: 1.43,
              letterSpacing: "0.01071em",
              padding: "0.8px 4px",
              ".MuiDataGrid-virtualScrollerRenderZone": {
                width: "100%",
              },
              "& .MuiDataGrid-row:hover": {
                "& .img-col-data": {
                  transform: "scale(3)",
                  position: "absolute",
                  zIndex: "1000000",
                },
                "& .img-col-first-data": {
                  marginTop: '10px',
                },
              },
              ".MuiDataGrid-virtualScroller": {
                [theme.breakpoints.down("md")]: {
                  overflowX: currentView === "list" ? "hidden" : "scroll",
                },
              },
              ".MuiDataGrid-columnHeaderTitleContainer": {
                justifyContent: "left",
              },
              ".MuiCheckbox-colorPrimary ": {
                color: grey[500]
              },
              ".grey": {
                backgroundColor: grey[50]
              },

              ".MuiDataGrid-footerContainer": {
                minHeight: isTabletOrMobile ? "30px " : "45px",
              }
            }}
            className={classes.hideHeader}
            pageSizeOptions={[5, 10, 20, 50, 75, 100]}
            disableVirtualization
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'grey' : ''
            }
            {...rest}
          />
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  </>
  )
}

export default DataGridV2

const theme = createTheme({
  components: {
    PrivateSwitchBase: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          ".MuiDataGrid-panelWrapper": {
            maxWidth: "100%"
          }
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaderTitle: {
            fontWeight: "bold",
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "12px",
          },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            fontSize: "20.57px",
          },
        },
      },
    },
  }
});

const useStyles = makeStyles((theme) => ({
  hideHeader: {
    [[theme.breakpoints.down("md")]]: {
      "& .MuiDataGrid-columnHeaders": {
        display: "none",
        borer: "none ",
      },
    },
  },
}));
