import React, { useEffect, useRef, useState } from "react";
import { createTheme } from "@mui/material/styles";
import { Grid, Button, CircularProgress, IconButton, ListItemIcon, Menu, MenuItem, MenuList, ThemeProvider, StyledEngineProvider, Typography, useMediaQuery, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { ArrowBack, ArrowUpward, CloudDownload, Print, SwapVert } from "@mui/icons-material";
import MUIDataTable from "mui-datatables";
import { useTranslation } from "react-i18next";
import { getDataTableTextLabels, history } from "../_helpers";
import clsx from "clsx";
import ColumnsSelector from "./ColumnsSelector";
import { createWorkorderReportsZip } from "../apis/aftercareApis";
import { URLS } from "../apis/urls";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(1),
    "& div": {
      minWidth: 30,
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.grey[700],
      fontSize: 12,
    },
  },
}))(MenuItem);

const DataGrid = React.memo((props) => {
  const {
    defaultSort,
    localColumnSortIdentifier,
    canListType,
    className,
    IconComponent,
    rowsPerPageIdentifier,
    CustomRowRender,
    localColumnOrdersIdentifier,
    identifier,
    loading,
    title,
    canGoBack,
    columns: columnsPersist,
    options = {},
    data = [],
    onRowSelect = () => 0,
    getData,
    fontSize,
  } = props;
  const theme = useTheme();
  const matchWidthDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();
  const muiTableRef = useRef();
  const { t } = useTranslation();
  const [rowsSelected, setRowsSelected] = useState({ data: [], list: [] });
  const [columnOrders, setColumnOrders] = useState([]);
  const [columns, setColumns] = useState(columnsPersist);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null);
  const [sortAnchorEl, setSortAnchorEl] = React.useState(null);
  const [selectedSortItem, setSelectedSortItem] = useState();
  const [sortOrder, setSortOrder] = useState();
  const [openColumnsSelector, setOpenColumnsSelector] = useState(false);
  const [columnSelectorType, setColumnSelectorType] = useState(null);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);
  const [isStartDownloading, setIsStartDownloading] = useState(false);
  const [previousZipFileName, setPreviousZipFileName] = useState(null);
  const [localData, setLocalData] = useState([]);

  useEffect(() => {
    const localSortType = JSON.parse(localStorage.getItem(localColumnSortIdentifier));
    if (localSortType) updateSort(localSortType);
  }, [canListType, matchWidthDownSm]);

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  useEffect(() => {
    const localRowsPerPage = localStorage.getItem(rowsPerPageIdentifier);
    // eslint-disable-next-line radix
    if (localRowsPerPage) setRowsPerPage(parseInt(localRowsPerPage));
    return () => {
      window.removeEventListener("resize", () => 0);
    };
  }, []);

  useEffect(() => {
    if (columnsPersist && columnsPersist.length) {
      const localOrders = localStorage.getItem(localColumnOrdersIdentifier);
      const columnOrdersToUpdate = !localOrders ? columnsPersist.map((column, index) => index) : JSON.parse(localOrders);
      if (JSON.stringify(columnOrders) !== JSON.stringify(columnOrdersToUpdate)) {
        setColumnOrders(columnOrdersToUpdate);
      }
      setColumns(columnsPersist);
    }
  }, [columnsPersist]);

  const handleRowsSelection = (selectedRows) => {
    setRowsSelected(selectedRows);
    onRowSelect(selectedRows);
  };

  const handleOpenDownloadMenu = (event) => {
    setDownloadAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setIsStartDownloading(false);
    setIsDownloadLoading(false);
    setDownloadAnchorEl(null);
  };

  const handleOpenSortMenu = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSortAnchorEl(null);
  };

  const handleDownloadWorkordersZip = () => {
    if (identifier === "resolverId" && rowsSelected && rowsSelected.list && rowsSelected.list.length > 0) {
      setIsDownloadLoading(true);
      let resolverIds = [];
      for (var i = 0; i < rowsSelected.list.length; i++) {
        if (rowsSelected.list[i] < localData.length) {
          resolverIds.push(localData[rowsSelected.list[i]].resolverId);
        }
      }
      createWorkorderReportsZip(resolverIds)
        .then(({ data: fileName }) => {
          downloadCreatedZipFile(encodeURIComponent(fileName));
          setPreviousZipFileName(encodeURIComponent(fileName));
          setIsStartDownloading(true);
          setIsDownloadLoading(false);
          // onClose();
        })
        .catch(() => {
          setIsStartDownloading(false);
          setIsDownloadLoading(false);
          // onClose();
        });
    }
  };

  const downloadCreatedZipFile = (fileName) => {
    if (fileName) {
      let link = document.createElement("a");
      link.href = URLS.DOWNLOAD_ZIP_FILE + fileName;
      link.download = fileName;
      document.body.appendChild(link).click();
    }
  };

  const updateSort = (column) => {
    if (column) {
      const id = column.key;
      const type = column.sortType || (selectedSortItem && selectedSortItem.id === column.key ? (selectedSortItem.type === "asc" ? "desc" : "asc") : "asc");
      setSelectedSortItem((p) => ({ ...p, id, type }));
      setSortOrder(() => ({ name: column.key, direction: type }));
      localStorage.setItem(localColumnSortIdentifier, JSON.stringify({ ...column, sortType: type }));
    }
  };

  const customRowRender = CustomRowRender
    ? (currentRow, dataIndex, rowIndex) => (
        <CustomRowRender setRowsSelected={handleRowsSelection} rowsSelected={rowsSelected} columns={columns} data={localData} columnOrders={columnOrders} identifier={identifier} currentRow={currentRow} dataIndex={dataIndex} rowIndex={rowIndex} />
      )
    : null;

  const staticOptions = {
    filterType: "dropdown",
    draggableColumns: { enabled: true },
    showColumnsDraggable: true,
    rowsSelected: rowsSelected.list,
    pagination: localData.length < 50 ? false : true,
    rowsPerPageOptions: [10, 25, 50, 75, 100],
    rowsPerPage: rowsPerPage,
    selectToolbarPlacement: "none",
    viewColumns: !canListType || !matchWidthDownSm,
    columnOrder: columnOrders,
    textLabels: getDataTableTextLabels(t, loading),
    responsive: canListType && matchWidthDownSm ? "stacked" : "scroll",
    customRowRender,
    sortOrder: sortOrder || defaultSort,
    onColumnSortChange: (name, direction) => {
      setSortOrder({ name, direction });
      if (localColumnSortIdentifier) {
        const column = (options.sortingList && options.sortingList.find((a) => t(a.label) === t(name))) || {};
        localStorage.setItem(localColumnSortIdentifier, JSON.stringify({ ...column, sortType: direction }));
      }
    },
    onRowSelectionChange: (cuRow, allRow, selected) => {
      const selectedData = selected.map((rowIndex) => ({
        [identifier]: localData[rowIndex][identifier],
      }));
      handleRowsSelection({ data: selectedData, list: selected });
    },
    onColumnOrderChange: (newColumnOrder) => {
      localStorage.setItem(localColumnOrdersIdentifier, JSON.stringify(newColumnOrder));
      setColumnOrders(newColumnOrder);
    },
    onChangeRowsPerPage: (rows) => {
      setRowsPerPage(rows);
      localStorage.setItem(rowsPerPageIdentifier, rows);
    },
    ...options,
    print: !getData,
    download: !getData,
    customToolbar: () => {
      return (
        <>
          {getData && options.download && (
            <>
              <IconButton
                aria-label="download"
                id="custom-csv-download-for-datagrid"
                dataid="data-id-custom-csv-download-for-datagrid"
                aria-controls="download"
                aria-haspopup="true"
                onClick={(e) => {
                  if (identifier === "resolverId") {
                    handleOpenDownloadMenu(e);
                  } else {
                    setColumnSelectorType("download");
                    setOpenColumnsSelector(e.currentTarget);
                  }
                }}
                size="large"
              >
                <CloudDownload />
              </IconButton>
              {identifier === "resolverId" && (
                <Menu
                  id="download-menu"
                  className={classes.menuContent}
                  anchorEl={downloadAnchorEl}
                  keepMounted
                  open={Boolean(downloadAnchorEl)}
                  onClose={handleCloseDownloadMenu}
                  elevation={1}
                  getContentAnchorEl={null}
                  disableRestoreFocus
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <MenuList className={classes.menuListStyle} style={{ padding: 0 }}>
                    <StyledMenuItem
                      onClick={(e) => {
                        setColumnSelectorType("download");
                        setOpenColumnsSelector(e.currentTarget);
                      }}
                    >
                      <ListItemIcon>
                        <ListItemIcon>
                          <CloudDownload fontSize="small" />
                        </ListItemIcon>
                      </ListItemIcon>
                      <Typography variant="inherit">{t("workOrders.download.overviewWorkOrders")}</Typography>
                    </StyledMenuItem>
                  </MenuList>
                  <MenuList className={classes.menuListStyle} style={{ padding: 0 }}>
                    {!isDownloadLoading && !isStartDownloading ? (
                      <StyledMenuItem disabled={!rowsSelected || !rowsSelected.data || !rowsSelected.data.length} onClick={handleDownloadWorkordersZip}>
                        <ListItemIcon>
                          <ListItemIcon>
                            <CloudDownload fontSize="small" />
                          </ListItemIcon>
                        </ListItemIcon>
                        <Typography variant="inherit">{t("workOrders.download.individualWorkOrders")}</Typography>
                      </StyledMenuItem>
                    ) : (
                      <Grid container spacing={1} justifyContent="flex-end" style={{ padding: 12 }}>
                        {isStartDownloading && (
                          <Grid item xs={12}>
                            <Typography variant="inherit" style={{ textAlign: "center" }}>
                              {t("files.download.started.text")}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item>
                          {isDownloadLoading ? (
                            <Grid container justifyContent="center">
                              <CircularProgress color="primary" />
                              <Typography variant="inherit">{t("files.download.createZip.text")}</Typography>
                            </Grid>
                          ) : (
                            <Button
                              disabled={isDownloadLoading}
                              onClick={() => {
                                if (isStartDownloading) downloadCreatedZipFile(previousZipFileName);
                              }}
                              variant="outlined"
                              color="primary"
                            >
                              {t("general.download")}
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    )}
                  </MenuList>
                </Menu>
              )}
            </>
          )}
          {getData && options.print && (
            <IconButton
              aria-label="print"
              id="custom-print-for-datagrid"
              dataid="data-id-custom-print-for-datagrid"
              aria-controls="print"
              aria-haspopup="true"
              onClick={(e) => {
                setColumnSelectorType("print");
                setOpenColumnsSelector(e.currentTarget);
              }}
              size="large"
            >
              <Print />
            </IconButton>
          )}
          {canListType && matchWidthDownSm && options.sortingList && options.sortingList.length && (
            <>
              <IconButton aria-label="sort" aria-controls="sort-menu" aria-haspopup="true" onClick={handleOpenSortMenu} size="large">
                <SwapVert />
              </IconButton>
              <Menu
                id="sort-menu"
                className={classes.menuContent}
                anchorEl={sortAnchorEl}
                keepMounted
                open={Boolean(sortAnchorEl)}
                onClose={handleClose}
                elevation={1}
                getContentAnchorEl={null}
                disableRestoreFocus
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
              >
                <MenuList className={classes.menuListStyle} style={{ padding: 0 }}>
                  {options.sortingList.map((column) => (
                    <StyledMenuItem key={column.key} selected={selectedSortItem && selectedSortItem.id === column.key} onClick={() => updateSort(column)}>
                      {selectedSortItem && selectedSortItem.id && (
                        <ListItemIcon>
                          <ListItemIcon className={classes.sortListIconStyle}>
                            {selectedSortItem && selectedSortItem.id === column.key && <ArrowUpward fontSize="small" className={clsx(classes.expandCollapseIcon, selectedSortItem && selectedSortItem.type === "desc" && classes.expandLess)} />}
                          </ListItemIcon>
                        </ListItemIcon>
                      )}
                      <Typography variant="inherit">{t(column.label)}</Typography>
                    </StyledMenuItem>
                  ))}
                </MenuList>
              </Menu>
            </>
          )}
          {options.customToolbar && options.customToolbar()}
        </>
      );
    },
  };

  if (!columns.length) return "";
  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={getMuiTheme({ fontSize })}>
          <MUIDataTable
            title={
              <div className={classes.headerTitleContainer}>
                {canGoBack && (
                  <IconButton aria-label="GoBack" color="inherit" onClick={() => history.goBack()} size="large">
                    <ArrowBack />
                  </IconButton>
                )}
                <div className={classes.headerTitleSubContainer}>
                  <Typography style={{ paddingLeft: canGoBack ? 4 : 0 }} className={classes.headerTitle} variant="h6">
                    {t(title)}
                  </Typography>
                </div>
                {IconComponent && <IconComponent />}
              </div>
            }
            ref={muiTableRef}
            className={clsx(classes.dataTable, className)}
            data={localData}
            columns={columns}
            options={staticOptions}
          />
        </ThemeProvider> 
      </StyledEngineProvider>
      {openColumnsSelector && (
        <ColumnsSelector
          anchorEl={openColumnsSelector}
          title={title}
          getData={getData}
          onClose={() => {
            setColumnSelectorType(null);
            setOpenColumnsSelector(null);
          }}
          type={columnSelectorType}
          muiTableRef={muiTableRef}
          columnOrders={columnOrders}
          columns={columns}
          rowsSelected={rowsSelected.list}
        />
      )}
    </>
  );
});

const theme = createTheme();
const getMuiTheme = (props) =>
  createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: `${props.fontSize}px`,
          },
        },
      },

      MuiTableHead: {
        styleOverrides: {
          root: {
            "& tr > th:nth-child(1) > div > span": {
              padding: `${theme.spacing(0.25)} !important`,
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          data: {
            fontSize: `${props.fontSize}px`,
          },
        },
      },
      MUIDataTableSelectCell: {
        styleOverrides: {
          fixedLeft: {
            left: "auto !important",
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          responsiveScroll: {
            maxHeight: "none !important",
            height: "100% !important",
          },
          responsiveStacked: {
            overflow: "auto !important",
            [theme.breakpoints.down("lg")]: {
              height: "100%",
              overflowX: "hidden",
            },
            height: "100% !important",
          },
        },
      },
      MUIDataTableToolbar: {
        styleOverrides: {
          root: {
            display: "flex !important",
            alignItems: "center",
            paddingLeft: theme.spacing(0.7),
            paddingRight: theme.spacing(2.575),
          },
          left: {
            padding: "0px !important",
            overflow: "hidden",
          },
          actions: {
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            textAlign: "right !important",
            whiteSpace: "nowrap",
            "& button": {
              [theme.breakpoints.down("sm")]: {
                padding: `${theme.spacing(0.15, 0.7)} !important`,
              },
            },
            "& button svg": {
              [theme.breakpoints.down("sm")]: {
                fontSize: "20px !important",
              },
            },
          },

          filterPaper: {
            width: `500px !important`,
            boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
            borderRadius: 9,
            backgroundColor: `${theme.palette.common.white} !important`,
            "& > button": {
              backgroundColor: theme.palette.grey[800],
              top: "12px !important",
              right: "15px !important",
              padding: theme.spacing(0.5),
              "& > svg": {
                fill: theme.palette.common.white,
                fontSize: 16,
              },
              "&:hover": {
                backgroundColor: theme.palette.grey[500],
              },
            },
            [theme.breakpoints.only("xs")]: {
              boxShadow: "rgb(0 0 0 / 10%) -9px -5px 23px",
              width: "100% !important",
              maxWidth: "100% !important",
              left: "0px !important",
              bottom: "0px !important",
              top: "auto !important",
              borderRadius: 20,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              transform: "none !important",
            },
          },
        },
      },
      MUIDataTableFilter: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0),
            backgroundColor: `${theme.palette.common.white} !important`,
          },
          title: {
            fontWeight: "bold !important",
            color: "#505B78 !important",
          },
          checkboxFormControlLabel: {
            color: "#505B78 !important",
            fontSize: "14px !important",
            fontWeight: "bold",
          },
          header: {
            padding: theme.spacing(1.5),
            marginBottom: 0,
            borderBottom: `1px solid lightgrey`,
            "& + div": {
              width: "100% !important",
              margin: "0 !important",
              padding: theme.spacing(1.5),
            },
          },
          reset: {
            display: "flex",
            alignItems: "center",
          },
          resetLink: {
            padding: 0,
            fontSize: 14,
          },
          gridListTile: {
            marginTop: "0px !important",
            padding: `${theme.spacing(0.5, 1)} !important`,
            "& div > div:before": {
              borderBottom: "none !important",
            },
            "& label": {
              color: "#505B78 !important",
              fontSize: "14px !important",
              fontWeight: "normal",
              marginBottom: "0px !important",
              width: '100%',
              "& .MuiCheckbox-root":{
                  padding: theme.spacing(0.5)
              },
              "& .MuiFormControlLabel-label":{
                  fontSize:"inherit"
              }
            },
            '& label[data-shrink="true"]': {
              left: "0px !important",
            },
            '& label[data-shrink="false"]': {
              left: "14px !important",
            },
            "& div > div > div": {
              boxShadow: "rgba(0, 0, 0, 0.16) 0px 2px 2px",
              borderRadius: "6px",
              padding: `${theme.spacing(0.5, 1.5)}`,
            },
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          responsiveStackedSmall: {
            width: "100% !important",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            position: "relative",
            // color: theme.palette.grey[700],
            fontWeight: "normal",
            padding: `${theme.spacing(0.1, 0.5)} !important`,
            //borderBottom: "none",
            fontSize: props.fontSize,
            "& svg": {
              fontSize: (props.fontSize * 24) / 14,
            },
          },

          head: {
            whiteSpace: "nowrap",
            backgroundColor: `${theme.palette.background.default} !important`,
            fontSize: props.fontSize,
            "& svg": {
              fontSize: (props.fontSize * 24) / 14,
            },
          },
        },
      },

      PrivateSwitchBase: {
        styleOverrides: {
          root: {
            padding: theme.spacing(0.25),
          },
        },
      },

      MuiTableRow: {
        styleOverrides: {
          root: {
            "&:hover": {
              cursor: "pointer",
              background: theme.palette.grey[100],
              "& > td > div > div > span > aside > div": {
                height: "60px",
                width: "60px",
                opacity: 1,
                zIndex: theme.zIndex.drawer,
              },
            },
          },
        },
      },

      MuiAvatar: {
        styleOverrides: {
          root: {
            "&:not(.thumb-exception)": {
              fontSize: (props.fontSize * 9) / 14 + "px !important",
              width: (props.fontSize / 14) * 20 + "px !important",
              height: (props.fontSize / 14) * 20 + "px !important",
            },
          },
        },
      },

      MuiTablePagination: {
        styleOverrides: {
          actions: {
            marginLeft: "10px !important",
            "& button": {
              [theme.breakpoints.down("md")]: {
                padding: "0px !important",
              },
            },
          },
          root: {
            "& > div": {
              [theme.breakpoints.down("md")]: {
                padding: "0px !important",
                minHeight: "25px !important",
              },
            },
          },
        },
      },
    },
  });

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  headerTitle: {
    // color: theme.palette.grey[800],
    // fontWeight: 500,
    // fontSize: 16,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "nowrap",
  },
  headerTitleSubContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  dataTable: {
    backgroundColor: theme.palette.background.default,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& .MUIDataTableToolbar-root": {
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("md")]: {
          fontSize: 18,
        },
      },
      "& .MuiButtonBase-root": {
        padding: theme.spacing(1.2),
        "&:nth-last-child()": {
          paddingRight: 0,
        },
      },
      display: "flex",
      padding: theme.spacing(0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: theme.spacing(0),
        textAlign: "right",
        // flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      borderCollapse: "separate",
      borderSpacing: "0 1px",
      marginTop: "auto",
      "& caption": {
        display: "none",
      },
    },
    "& .MuiTableCell-root": {
      [theme.breakpoints.between("xs", "md")]: {
        borderBottom: "transparent",
        paddingBottom: 9,
      },
      "& .datatables-noprint": {
        [theme.breakpoints.down("md")]: {
          display: "flex",
          justifyContent: "center",
          width: "100%",
        },
      },
      position: "relative",
      // color: theme.palette.grey[700],
      fontWeight: "normal",
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
          [theme.breakpoints.down("md")]: {
            paddingRight: "5px",
          },
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
          "& .MuiSvgIcon-root": {
            [theme.breakpoints.down("md")]: {
              fontSize: 18,
            },
          },
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: 500,
        "& .MuiButtonBase-root": {
          "& .MuiButton-label": {
            // color: theme.palette.grey[700],
            fontWeight: "bold",
            [theme.breakpoints.only("xs")]: {
              fontSize: 14,
            },
            [theme.breakpoints.only("sm")]: {
              fontSize: 16,
            },
          },
        },
      },
    },
    "& .MuiTablePagination-toolbar": {
      minHeight: "fit-content !important",
      paddingLeft: theme.spacing(0),
    },
    "& .MuiTableCell-footer": {
      padding: `${theme.spacing(0)} !important`,
    },
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  menuContent: {
    "& .MuiPopover-paper": {
      borderRadius: 10,
      boxShadow: `${theme.shadows[24]} !important`,
    },
    "& ul": {
      padding: theme.spacing(0),
    },
    "& ul > ul": {
      padding: theme.spacing(2),
    },
  },
  menuListStyle: {
    borderRadius: 10,
    maxWidth: 325,
    padding: theme.spacing(2),
  },
  expandCollapseIcon: {
    fill: theme.palette.grey[700],
    transition: "0.6s all",
    transform: "rotate(0deg)",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  expandLess: {
    transform: "rotate(-180deg)",
  },
  sortListIconStyle: {
    width: 40,
    minWidth: 40,
    justifyContent: "center",
  },
}))

export default DataGrid;
