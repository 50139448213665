import React, { useEffect, useState } from 'react'
import { Container } from '@mui/material'
import SelectSingleObjectModal from "../../components/SelectSingleObjectModal";
import { useDispatch, useSelector } from 'react-redux';
import RepairRequestForm from '../Survey/RepairRequestForm';
import { SurveyType } from '../../_helpers/enumsHelper';
import { dossiersActions } from '../../_actions/dossiers.actions';

export default function AddRepairRequest({ isOpenObjectModal, setIsOpenObjectModal, repairRequestCreateCallBack, ...props }) {
    const { all, selected } = useSelector(state => state.buildings);
    const {  drawingFiles, drawingFilesLoading  } = useSelector(state => state.dossier);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({ pages: 1 });
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedBuildingId, setSelectedBuildingId] = useState(null);
    const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!drawingFilesLoading && isLoading) {
            if (drawingFiles && drawingFiles.length) {
                setSelectedDrawingFiles({
                    ...drawingFiles[0],
                    groupBy: drawingFiles[0].isInternal ? 'Internal' : 'External'
                });
            } else {
                setSelectedDrawingFiles({});
            }
            setTimeout(() => {
                setIsLoading(false);
                setOpenRepairRequestMap(true);
                handleCloseObjectModal();
            }, 100)
        }
    }, [isLoading, drawingFilesLoading, drawingFiles])

    const handleCloseObjectModal = () => {
        setIsOpenObjectModal(false);
    }

    const handleSaveObject = (buildingId) => {
        setSelectedBuildingId(buildingId)
        setIsLoading(true);
        dispatch(dossiersActions.getDrawingFiles(selected.projectId, buildingId));
    }

    return (
        <Container>
            {isOpenObjectModal &&
                <SelectSingleObjectModal open={isOpenObjectModal}
                    // canProjectLevel
                                         buildings={all.filter(p => p.projectId === selected.projectId)}
                                         onSave={handleSaveObject}
                                         isLoading={isLoading}
                                         onClose={handleCloseObjectModal} />
            }
            <RepairRequestForm
                setSelectedDrawingFiles={setSelectedDrawingFiles}
                setCurrentPage={setCurrentPage}
                buildingId={selectedBuildingId}
                projectId={selected && selected.projectId}
                selectedDrawingFiles={selectedDrawingFiles}
                currentPage={currentPage}
                redirectToMomentDetail={console.log}
                onPreview={console.log}
                setID={(id) => console.log(id)}
                canSelectSortManual
                // setIsRepairRequest={(data) => setIsRepairRequest(data)}
                openRepairRequestMap={openRepairRequestMap}
                onClose={(reload) => {
                    reload && repairRequestCreateCallBack();
                    setOpenRepairRequestMap(false)
                    setCurrentPage(1)
                }}
            />
        </Container>
    )
}
