/* eslint-disable no-loop-func */
import axios from "axios";
import { surveyActions } from ".";
import {
  getDrawingFiles,
  getExistingImagesFromRepairRequest,
} from "../apis/dossiersApi";
import {
  addRepairRequestResolver,
  addSurveyRepairRequest,
  addUpdateRework,
  canRequestExistCache,
  completeAssignments,
  completeSignature,
  deleteAssignmentImage,
  getAllSurveys,
  getAssignmentAttachment,
  getAssignmentAttachmentThumbnail,
  getAssignmentInfo,
  getAvailableSurveyModules,
  getOrganizations,
  getOrganizationsByProject,
  getPinLocationImage,
  getProductsServices,
  getProjectLogoForReport,
  getRecipientsEmails,
  getRepairRequestCarryOutAsTypeList,
  getRepairRequestCauseList,
  getRepairRequestCauserList,
  getRepairRequestDetails,
  getRepairRequestLocations,
  getRepairRequestNatureList,
  getRepairRequestsApi,
  getRepairRequestsAttachments,
  getRepairRequestTypeList,
  getSettlementTerms,
  getSurveyDetailApi,
  getSurveyMomentInfo,
  getSurveyReport,
  getSurveys,
  getWBProjectInfo,
  internalCompleteAssignments,
  lockUnits,
  markCompleteRR,
  releaseUnits,
  resetAssignmentStatus,
  sendRecipientsEmails,
  sendRecipientsEmailsSecondSignature,
  startSecondSignature,
  updateAssignmentInfo,
  updateRepairRequest,
  updateResponsiblePersonForAssignments,
  updateSurveyApi,
  uploadAssignmentFiles,
} from "../apis/surveyingApis";
import { URLS } from "../apis/urls";
import { syncsConstants } from "../_constants";
import {
  authHeader,
  deleteAssignmentFromDB,
  deleteAssignmentsRepairRequest,
  deleteCompleteAssignments,
  deletedAssignmentsAttachments,
  deleteInternalCompleteAssignments,
  deleteMoment,
  deleteRepairRequests,
  deleteSignatures,
  deleteSendingEmails,
  deleteSSRRChanges,
  deleteSurvey,
  deleteSurveyDetail,
  deleteSurveyFromDB,
  deleteUpdatedAssignmentInfo,
  deleteUpdatedDelegator,
  deleteUpdatedSurveyInfo,
  getAppsBuildings,
  getAssignmentsAttachments,
  getAssignmentsRepairRequestsFromIDB,
  getCompleteAssignments,
  getDeletedAssignmentsAttachments,
  getFile,
  getInternalCompleteAssignments,
  getRecipientsEmailsIDB,
  getRepairRequestDetailsIDB,
  getRepairRequestsFromIDB,
  getSendingEmails,
  getSignatures,
  getSSRRChanges,
  getStartSecondSignature,
  getSurveys as getSurveysFromDB,
  getUpdatedAssignmentInfo,
  getUpdatedDelegator,
  getUpdatedSurveyInfo,
  getWorkOrders,
  groupBy,
  releaseUserLockIds,
  removeSecondSignature,
  storeAppsBuildings,
  storeAssignment,
  storeDrawingFile,
  storeEmailsForRepairRequest,
  storeFile,
  storeMoment,
  storeOrganizations,
  storeOrganizationsByProject,
  storePinImages,
  storeProductsServices,
  storeRecipientsEmails,
  storeRepairRequest,
  storeRepairRequestCarryOutAsTypeList,
  storeRepairRequestCauseList,
  storeRepairRequestCauserList,
  storeRepairRequestDetails,
  storeRepairRequestLocation,
  storeRepairRequestNatureList,
  storeRepairRequests,
  storeRepairRequestTypeList,
  storeSettlementTerms,
  storeSurvey,
  storeSurveyDetails,
  storeSurveyDetailsInBulk,
  storeSurveyingMomentInDB,
  storeSurveys,
  storeUserLockIds,
  storeWBProjectInfo,
  updateAssignmentRepairRequests,
  updateAssignmentsAttachments,
  updateAttachments,
  updateBuilding,
  updateRepairRequests,
  updateSendingEmails,
  updateSignatures,
} from "../_helpers";
import { commonService } from "../_services";
import i18n from "../i18n";

const { webApiUrl } = window.appConfig;

const SurveyType = {
  PreDelivery: 0,
  Delivery: 1,
  Inspection: 2,
};

const t = (key, params) => i18n.t(key, params);

const keys = {
  pointOfContact: "pointofcontact",
  locationId: "locationid",
  productServiceId: "productserviceid",
  subProductService1Id: "subproductservice1id",
  subProductService2Id: "subproductservice2id",
  carryOutAsTypeId: "carryoutastypeid",
  priority: "priority",
  completionTermId: "completiontermid",
  typeId: "typeid",
  natureId: "natureid",
  causeId: "causeid",
  causerId: "causerid",
  causerOrganisationId: "causerorganisationid",
  workOrderText: "workordertext",
  clientReference: "clientreference",
  desc: "desc",
};

let dispatchAction = () => {};

const formatLog = (message, type, id) => ({
  time: new Date().toLocaleString("nl-NL"),
  message,
  type,
  createdAt: new Date(),
  id,
});

function dailySync(isManual = false, selectedBuildings) {
  return async (dispatch, getState) => {
    dispatchAction = dispatch;
    if (window.navigator.onLine) {
      dispatch(
        success({ type: "UPDATE_LOG", log: formatLog("Sync Data", "loading") })
      );
      dispatch(
        surveyActions.customDispatcher({
          type: "SYNC_UPDATE_LOADING",
          isManual,
        })
      );

      const {
        syncs: { dailySync },
      } = getState();
      const buildings = isManual
        ? selectedBuildings
        : await getAppsBuildings({ sync: 1 });
      const surveyTypesData = Object.values(SurveyType).map((p) => ({
        surveyType: p,
        isSecondSignature: false,
      }));
      surveyTypesData.push({ surveyType: 1, isSecondSignature: true });

      // all post requests sync
      await surveyingMomentsPostRequestHandler(buildings);
      await surveyPostRequestHandler(surveyTypesData[0]);
      await surveyPostRequestHandler(surveyTypesData[1]);
      await surveyPostRequestHandler(surveyTypesData[2]);
      await surveyPostRequestHandler(surveyTypesData[3]);

      //all get requests sync
      await getLocations();
      await getAvailableModulesSync(buildings);
      await getSettlementTerms();
      await getCarryOutList();
      await dispatch(syncBuildings(buildings));

      dispatch(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Get surveys list loading`, "loading"),
        })
      );
      const surveys = await surveysSyncHandler(surveyTypesData, buildings);
      const allProjectsSurveys = groupBy(surveys.flat(), "buildingId");

      const data = Object.keys(allProjectsSurveys).reduce((prevRecord, key) => {
        const isExist = buildings.find(
          (building) => building.buildingId === key
        );
        if (isExist) {
          return [...prevRecord, ...allProjectsSurveys[key]];
        } else return prevRecord;
      }, []);
      try {
        await storeSurveys(data);
        dispatch(
          success({
            type: "UPDATE_LOG",
            log: formatLog(`Get surveys list success`, "success"),
          })
        );
      } catch (er) {
        console.log(er);
      }

      Promise.all(
        buildings.map(async (b, i) => {
          if (b.lastSync < dailySync || !b.lastSync || isManual) {
            await updateBuilding(
              {
                app: b.app >= 0 ? b.app : "",
                projectId: b.projectId || "",
                buildingId: b.buildingId || "",
              },
              { lastSync: new Date() }
            );
            return buildings[i];
          }
        })
      )
        .then((res) => {
          if (!isManual) {
            dailySync.setDate(new Date().getDate() + 1);
            localStorage.setItem("dailySyncTime", dailySync);
          }

          dispatch(
            success({
              type: "UPDATE_LOG",
              log: formatLog("Sync Data successfully", "success"),
            })
          );
          dispatch(
            surveyActions.customDispatcher({
              type: "SYNC_UPDATE_SUCCESS",
              isManual,
            })
          );
        })
        .catch((er) => {
          dispatch(
            surveyActions.customDispatcher({
              type: "SYNC_UPDATE_ERROR",
              isManual,
            })
          );
          console.log(er, "er");
        });
    }
  };
}

async function getAvailableModulesSync(buildings) {
  const projectIds = Object.keys(groupBy(buildings, "projectId"));
  for (let i = 0; i < projectIds.length; i++) {
    const projectId = projectIds[i];
    await getAvailableSurveyModules(projectId);
  }
}

async function getLocations() {
  dispatchAction(
    success({
      type: "UPDATE_LOG",
      log: formatLog(`Get Repair Request Location list loading`, "loading"),
    })
  );
  try {
    const { data } = await getRepairRequestLocations();
    await storeRepairRequestLocation(
      data.map((p) => ({ id: p.id, name: p.name }))
    );
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Get Repair Request Location list success`, "success"),
      })
    );
  } catch (er) {
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Get Repair Request Location list error ${er}`, "error"),
      })
    );
  }
}

async function getCarryOutList() {
  dispatchAction(
    success({
      type: "UPDATE_LOG",
      log: formatLog(
        `Get Repair Request Carry out as type list loading`,
        "loading"
      ),
    })
  );
  try {
    const { data } = await getRepairRequestCarryOutAsTypeList();
    await storeRepairRequestCarryOutAsTypeList(
      data.map((p) => ({ id: p.id, name: p.name }))
    );
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(
          `Get Repair Request Carry out as type list success`,
          "success"
        ),
      })
    );
  } catch (er) {
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(
          `Get Repair Request Carry out as type list error ${er}`,
          "error"
        ),
      })
    );
  }
}

async function surveySyncHelper(survey, buildings) {
  const projects = groupBy(buildings, "projectId");
  const projectsIds = Object.keys(projects);
  let syncedSurveys = [];
  for (let i = 0; i < projectsIds.length; i++) {
    // eslint-disable-next-line no-loop-func
    await new Promise(async (resolve, reject) => {
      const projectId = projectsIds[i];
      try {
        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get surveys for SurveyType:${survey.surveyType} and isSecondSignature=${survey.isSecondSignature} loading`,
              "loading"
            ),
          })
        );
        const { data } = await getSurveys(
          survey.surveyType,
          window.navigator.onLine,
          survey.isSecondSignature,
          projectId
        );
        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get surveys for SurveyType:${survey.surveyType} and isSecondSignature=${survey.isSecondSignature} success`,
              "success"
            ),
          })
        );
        const isExist = buildings.find((building) =>
          data.find((d) => d.buildingId === building.buildingId)
        );
        if (isExist) {
          const surveysDetails = await surveyDetailsSyncHandler(
            data,
            buildings,
            survey
          );
          const filteredSurveysDetails = surveysDetails.filter((p) => !!p);
          if (filteredSurveysDetails && filteredSurveysDetails.length) {
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(`Store survey details into localDB`, "loading"),
              })
            );
            await storeSurveyDetailsInBulk(filteredSurveysDetails);
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(`Store survey details into localDB`, "success"),
              })
            );
          }
          syncedSurveys = data.map((p) => ({
            ...p,
            isSecondSignature: survey.isSecondSignature ? 1 : 0,
          }));
        }
        resolve(syncedSurveys);
      } catch (er) {
        reject(er);
      }
    });
  }
  return syncedSurveys;

  // return await Promise.all(Object.keys(projects).map(async projectId => {
  //     dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Get surveys for SurveyType:${survey.surveyType} and isSecondSignature=${survey.isSecondSignature} loading`, "loading") }))
  //     const { data } = await getSurveys(survey.surveyType, window.navigator.onLine, survey.isSecondSignature, projectId);
  //     dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Get surveys for SurveyType:${survey.surveyType} and isSecondSignature=${survey.isSecondSignature} success`, "success") }))
  //     const isExist = buildings.find(building => data.find(d => d.buildingId === building.buildingId));
  //     if (isExist) {
  //         const surveysDetails = await surveyDetailsSyncHandler(data, buildings, survey);
  //         const filteredSurveysDetails = surveysDetails.filter(p => !!p);
  //         if (filteredSurveysDetails && filteredSurveysDetails.length) {
  //             dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Store survey details into localDB`, "loading") }))
  //             await storeSurveyDetailsInBulk(filteredSurveysDetails);
  //             dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Store survey details into localDB`, "success") }))
  //         }
  //     }
  //     return data.map(p => ({ ...p, isSecondSignature: survey.isSecondSignature ? 1 : 0 }))
  // }));
}

async function surveysSyncHandler(surveyTypes, buildings) {
  const res1 = await surveySyncHelper(surveyTypes[0], buildings);
  const res2 = await surveySyncHelper(surveyTypes[1], buildings);
  const res3 = await surveySyncHelper(surveyTypes[2], buildings);
  const res4 = await surveySyncHelper(surveyTypes[3], buildings);
  return [res1, res2, res3, res4];
}

async function workOrderUpdate(request, newRequestId) {
  const workOrders = await getWorkOrders({
    requestId: request.requestId,
    sync: 0,
  });
  if (workOrders && workOrders.length) {
    for (let i = 0; i < workOrders.length; i++) {
      if (newRequestId !== request) {
        const workOrder = workOrders[i];
        await new Promise(async (resolve, reject) => {
          try {
            const formData = new FormData();
            formData.append("organisationId", workOrder.organisatonId);
            const data = await addRepairRequestResolver(newRequestId, formData);
            resolve(data);
          } catch (er) {
            reject(er);
          }
        });
      }
    }
  }
}

async function updateRepairRequestData(request, newRequestId) {
  if (request.updatedValues && request.updatedValues.length) {
    await Promise.all(
      request.updatedValues.map(async (key) => {
        try {
          await updateRepairRequest(newRequestId, [
            {
              id: keys[key],
              name: request[key].toString(),
            },
          ]);
        } catch (er) {
          console.log(er, "r.u.e.");
        }
      })
    );
  }
}

async function requestSyncHandler(requests, isAssignment = false) {
  if (requests && requests.length) {
    // return await Promise.all(requests.map(async request => {
    for (let i = 0; i < requests.length; i++) {
      const request = requests[i];
      if (request) {
        await new Promise(async (resolve, reject) => {
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Store ${request.surveyId} survey repair request from server `,
                "loading"
              ),
            })
          );
          const formData = new FormData();

          formData.append(
            "resolverOrganisations",
            request.resolverOrganisations
          );
          if (request.buildingId)
            formData.append("buildingId", request.buildingId);
          else formData.append("projectId", request.projectId);

          formData.append("OfflineNo", request.OfflineNo);
          formData.append("locationId", request.locationId);
          formData.append("desc", request.desc);
          request.completionTermId &&
            formData.append("completionTermId", request.completionTermId);
          formData.append("detailedDesc", request.detailedDesc);
          formData.append(
            isAssignment ? "assignmentId" : "surveyId",
            request[isAssignment ? "assignmentId" : "surveyId"]
          );
          if (request["DrawingPinLocationModel.DossierFileId"]) {
            formData.append(
              "DrawingPinLocationModel.DossierFileId",
              request["DrawingPinLocationModel.DossierFileId"]
            );
            formData.append(
              "DrawingPinLocationModel.PageNumber",
              request["DrawingPinLocationModel.PageNumber"]
            );
            formData.append(
              "DrawingPinLocationModel.Xrel",
              request["DrawingPinLocationModel.Xrel"]
            );
            formData.append(
              "DrawingPinLocationModel.Yrel",
              request["DrawingPinLocationModel.Yrel"]
            );
          }
          if (request.attachments)
            for (var i = 0; i < request.attachments.length; i++) {
              formData.append("files", request.attachments[i]);
            }

          // request.buildingId && formData.append('buildingId', request.buildingId);
          // formData.append('locationId', request.locationId);
          // formData.append('completionTermId', request.completionTermId);
          // formData.append('desc', request.desc);
          // formData.append('detailedDesc', request.detailedDesc);
          // formData.append(isAssignment ? 'assignmentId' : 'surveyId', request[isAssignment ? 'assignmentId' : 'surveyId']);

          // if (request["DrawingPinLocationModel.DossierFileId"]) {
          //     formData.append('DrawingPinLocationModel.DossierFileId', request["DrawingPinLocationModel.DossierFileId"]);
          //     formData.append('DrawingPinLocationModel.PageNumber', request["DrawingPinLocationModel.PageNumber"]);
          //     formData.append('DrawingPinLocationModel.Xrel', request["DrawingPinLocationModel.Xrel"]);
          //     formData.append('DrawingPinLocationModel.Yrel', request["DrawingPinLocationModel.Yrel"]);
          // }

          // if (request.attachments)
          //     for (var i = 0; i < request.attachments.length; i++) {
          //         formData.append('files', request.attachments[i])
          //     }
          try {
            let body = {};
            for (let key of formData.keys())
              body[key === "files" ? "attachments" : key] =
                formData[key === "files" ? "getAll" : "get"](key);
            const data = await addSurveyRepairRequest(formData);
            const requestDetails = await getRepairRequestDetails(
              data.data.requestId
            );
            if (isAssignment) {
              await deleteAssignmentsRepairRequest({
                requestId: request.requestId,
              });
            } else {
              await deleteRepairRequests({ requestId: request.requestId });
            }
            try {
              await storeRepairRequest(
                {
                  ...requestDetails.data,
                  [isAssignment ? "assignmentId" : "surveyId"]:
                    request[isAssignment ? "assignmentId" : "surveyId"],
                },
                isAssignment
              );
              await updateRepairRequestData(request, data.data.requestId);
              await workOrderUpdate(request, data.data.requestId);
              await updateAttachments(
                { requestId: request.requestId },
                { sync: 1 }
              );
              dispatchAction(
                success({
                  type: "UPDATE_LOG",
                  log: formatLog(`updating repair request`, "loading"),
                  hasUpdate: `${new Date().getTime()}-${Math.random()}`,
                })
              );
            } catch (er) {
              resolve(request);
              console.log(er, "errrr-1");
            }
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Store ${request.surveyId} survey repair request from server successfully `,
                  "success"
                ),
              })
            );
            resolve(request);
          } catch (er) {
            console.log(er, "er");
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Store ${request.surveyId} survey repair request from server error ${er} `,
                  "error"
                ),
              })
            );
            resolve(er);
          }
        });
      }
    }
  }
  return;
}

async function updateSignaturesRequest(
  surveyId,
  surveyType,
  isSecondSignature
) {
  let requests = await getSignatures({
    surveyId,
    surveyType,
    sync: 0,
    isSecondSignature: isSecondSignature ? 1 : 0,
  });
  if (requests) {
    try {
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Syncing ${surveyId} survey signatures `, "loading"),
        })
      );
      await (!isSecondSignature ? updateSurveyApi : completeSignature)(
        requests
      );
      await updateSignatures(
        {
          surveyId,
          surveyType,
          sync: 0,
          isSecondSignature: isSecondSignature ? 1 : 0,
        },
        { sync: 1 }
      );
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Sync ${surveyId} survey signatures `, "success"),
        })
      );
    } catch (er) {
      console.log(er);
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Syncing ${surveyId} survey signatures error ${er}`,
            "error"
          ),
        })
      );
    }
  }
  await sendingEmailsRequests(surveyId, surveyType, isSecondSignature);
}

async function sendingEmailsRequests(surveyId, surveyType, isSecondSignature) {
  const requests = await getSendingEmails({
    surveyId,
    surveyType,
    sync: 0,
    isSecondSignature: +isSecondSignature,
  });
  if (requests) {
    try {
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Sending ${surveyId} survey emails`, "loading"),
        })
      );
      await (!isSecondSignature
        ? sendRecipientsEmails
        : sendRecipientsEmailsSecondSignature)(surveyId, requests.emails);
      await updateSendingEmails(
        {
          surveyId,
          surveyType,
          sync: 0,
          isSecondSignature: +isSecondSignature,
        },
        { sync: 1 }
      );
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Sending ${surveyId} survey emails successfully`,
            "success"
          ),
        })
      );
    } catch (er) {
      console.log(er, "email error");
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Sending ${surveyId} survey emails error ${er}`,
            "error"
          ),
        })
      );
    }
  }
}

async function handleSyncMapFiles(projectId, buildingId) {
  const { data: files } = await getDrawingFiles(projectId, buildingId);
  await storeDrawingFile(
    files.map((p) => ({
      ...p,
      projectId,
      buildingId: !buildingId ? "null" : buildingId,
    }))
  );
  if (files && files.length) {
    for (let i = 0; i < files.length; i++) {
      // eslint-disable-next-line no-loop-func
      await new Promise(async (resolve, reject) => {
        const file = files[i];
        for (let page = 1; page <= file.pages; page++) {
          try {
            const { data } = await axios.get(
              `${URLS.GET_DOSSIER_PDF_IMAGE}${file.dossierFileId}?page=${page}`,
              { responseType: "arraybuffer" }
            );
            const fileData = { fileId: file.dossierFileId, file: data };
            await storeFile(fileData);
          } catch (e) {
            console.log(e, "e");
          }
        }
        resolve(file);
      });
    }
  }
}

async function surveyDetailsSyncHandler(
  surveys,
  buildings,
  selectedSurveyType
) {
  return await Promise.all(
    surveys.map(async (survey, i) => {
      const selectedBuilding = buildings.find(
        (p) => p.buildingId === survey.buildingId
      );
      if (selectedBuilding) {
        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get ${survey.surveyId} survey detail loading`,
              "loading"
            ),
          })
        );
        const { data } = await getSurveyDetailApi(
          survey.surveyId,
          window.navigator.onLine,
          selectedSurveyType.isSecondSignature
        );
        try {
          await handleSyncMapFiles(data.projectId, data.buildingId, i);
        } catch (er) {
          console.log(er);
        }

        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get ${survey.surveyId} survey detail success`,
              "success"
            ),
          })
        );
        try {
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get ${survey.surveyId} survey Recipient email list`,
                "loading"
              ),
            })
          );
          await getRecipientsEmails(survey.surveyId);
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get ${survey.surveyId} survey Recipient email list`,
                "success"
              ),
            })
          );
        } catch (er) {
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get ${survey.surveyId} survey Recipient email list error ${er}`,
                "error"
              ),
            })
          );
        }

        if (selectedSurveyType.surveyType === 1) {
          try {
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Get ${survey.surveyId} survey report`,
                  "loading"
                ),
              })
            );
            await getSurveyReport(
              data.surveyId,
              selectedSurveyType.isSecondSignature
            );
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Get ${survey.surveyId} survey report successfully`,
                  "success"
                ),
              })
            );
          } catch (er) {
            console.log(er, "er get report");
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Get ${survey.surveyId} survey report error ${er}`,
                  "error"
                ),
              })
            );
          }
        }

        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get ${survey.surveyId} survey repair request from server `,
              "loading"
            ),
          })
        );
        try {
          const { data: repairRequests } = await getRepairRequestsApi(
            survey.surveyId,
            window.navigator.onLine
          );
          await storeRepairRequests(repairRequests);
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get ${survey.surveyId} survey repair request from server successfully`,
                "success"
              ),
            })
          );
          const attachmentsArray = repairRequests.map((p) => ({
            attachments: p.attachments.map((a) => ({
              ...a,
              requestId: p.requestId,
            })),
          }));
          if (attachmentsArray.length) {
            const attachments = attachmentsArray.flatMap((p) => p.attachments);
            for (let i = 0; i < attachments.length; i++) {
              await new Promise(async (resolve, reject) => {
                try {
                  const attachment = attachments[i];
                  dispatchAction(
                    success({
                      type: "UPDATE_LOG",
                      log: formatLog(
                        `Download ${attachment.requestId} survey repair request Attachments`,
                        "loading"
                      ),
                    })
                  );
                  const isExistCache = await canRequestExistCache(
                    `${URLS.GET_REPAIR_REQUEST_ATTACHMENTS}${attachment.requestId}/${attachment.attachmentId}`
                  );
                  if (!isExistCache)
                    await getRepairRequestsAttachments(
                      attachment.requestId,
                      attachment.attachmentId
                    );
                  dispatchAction(
                    success({
                      type: "UPDATE_LOG",
                      log: formatLog(
                        `Download ${attachment.requestId} survey repair request Attachments successfully`,
                        "success"
                      ),
                    })
                  );
                  resolve(attachment);
                } catch (er) {
                  reject(er);
                }
              });
            }
          }
        } catch (er) {
          console.log(er, "get repair req");
        }
        return data;
      }
    })
  );
}

function syncBuildings(buildings = []) {
  return async (dispatch) => {
    dispatch(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Getting Products service list loading`, "loading"),
      })
    );
    await getProductsServices();
    dispatch(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Getting Products service list success`, "success"),
      })
    );
    const appBuildings = groupBy(buildings, "app");
    const appProjects = Object.keys(appBuildings).reduce(
      (prev, key) => [
        ...prev,
        {
          key,
          projectIds: Object.keys(groupBy(appBuildings[key], "projectId")),
        },
      ],
      []
    );
    for (let i = 0; i < appProjects.length; i++) {
      const appProject = appProjects[i];
      for (let j = 0; j < appProject.projectIds.length; j++) {
        const { data: organizations } = await getOrganizations(
          appProject.projectIds[j]
        );
        await storeOrganizations({
          projectId: appProject.projectIds[j],
          app: appProject.key.toString(),
          data: organizations,
        });
      }
    }

    dispatch(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Getting SurveyingMoment list loading`, "loading"),
      })
    );
    const surveyingMoments = await getSurveyingMoments(buildings);
    dispatch(
      success({
        type: "UPDATE_LOG",
        log: formatLog(`Getting SurveyingMoment list success`, "success"),
      })
    );

    const allSurveyingMoments = surveyingMoments.flat();
    // await storeSurveyingMomentsInDB(allSurveyingMoments);
    await getSurveyingMomentsDetails(allSurveyingMoments, buildings);
  };
}

function getUsersObjects(availableApps = [], sync) {
  return (dispatch) => {
    dispatch(success({ type: syncsConstants.GET_USER_OBJECTS_LOADING }));
    dispatch(
      success({
        type: "UPDATE_LOG",
        log: formatLog("Getting app buildings.", "loading"),
      })
    );
    return Promise.all(
      availableApps.map(async (app) => {
        dispatch(
          success({
            type: "UPDATE_LOG",
            log: formatLog(`Get buildings for App:${app}`, "loading"),
          })
        );
        const buildings = await commonService.getBuildings(app);
        const syncProjects = await getAppsBuildings({
          app: app >= 0 ? app : "",
        });
        dispatch(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Buildings for App:${app} getting success`,
              "success"
            ),
          })
        );
        for (let s of syncProjects) {
          const index = buildings.findIndex(
            (p) => p.projectId === s.projectId && p.buildingId === s.buildingId
          );
          if (index >= 0) buildings[index] = { ...s, ...buildings[index] };
        }
        dispatch(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Store Buildings for App:${app} in LocalDB loading`,
              "loading"
            ),
          })
        );
        await storeAppsBuildings(buildings.map((p) => ({ ...p, app })));
        dispatch(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Store Buildings for App:${app} in LocalDB success`,
              "success"
            ),
            getUserObjects: true,
          })
        );
      })
    );
  };
}

async function getSurveyingMoments(buildings, fromIndexDB = false) {
  const projects = groupBy(buildings, "projectId");
  return await Promise.all(
    Object.keys(projects).map(async (projectId) => {
      if (fromIndexDB) {
        const surveys = await getAllSurveys(projectId, false);
        return surveys;
      } else {
        // dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Get Organizations for Project  ${projectId} loading`, "loading") }))
        //  await getOrganizations(projectId);
        // dispatchAction(success({ type: 'UPDATE_LOG', log: formatLog(`Get Organizations for Project  ${projectId} success`, "success") }))

        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get SurveyingMoments list for Project  ${projectId} loading`,
              "loading"
            ),
          })
        );
        try {
          const surveys = await getAllSurveys(
            projectId,
            window.navigator.onLine
          );
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get SurveyingMoments list for Project  ${projectId} success`,
                "success"
              ),
            })
          );
          return surveys.data; //.filter(s => buildings.find(b => b.buildingId === s.buildingId && s.projectId === b.projectId));
        } catch (er) {
          console.log(er);
        }
      }
    })
  );
}

async function getSurveyingMomentsDetails(moments, buildings) {
  const responseData = [];
  for (let i = 0; i < moments.length; i++) {
    // eslint-disable-next-line no-loop-func
    const promise = await new Promise(async (resolve, reject) => {
      try {
        const surveyMoment = moments[i];
        const canExists = buildings.find(
          (building) => building.buildingId === surveyMoment.buildingId
        );
        if (canExists) {
          dispatchAction(
            success({
              type: "UPDATE_LOG",
              log: formatLog(
                `Get SurveyingMoment ${surveyMoment.surveyingMomentId} loading`,
                "loading"
              ),
            })
          );
          const surveyMomentInfo = await getSurveyMomentInfo(
            {
              surveyMomentId: surveyMoment.surveyingMomentId,
              wbObjectId: surveyMoment.objectId,
            },
            window.navigator.onLine
          );

          if (surveyMomentInfo.data) {
            const { data: existingFiles } =
              await getExistingImagesFromRepairRequest(
                surveyMomentInfo.data.projectId,
                surveyMomentInfo.data.buildingId
              );
            const existingFlatData =
              Object.keys(existingFiles).reduce(
                (prev, key) => [...prev, ...(existingFiles[key] || [])],
                []
              ) || [];
            for (let i = 0; i < existingFlatData.length; i++) {
              const req = existingFlatData[i];
              for (let j = 0; j < req.attachments.length; j++) {
                const atch = req.attachments[j];
                try {
                  const isExistCache = await canRequestExistCache(
                    `${URLS.GET_REPAIR_REQUEST_ATTACHMENTS}${req.requestId}/${atch.id}`
                  );
                  if (!isExistCache)
                    await getRepairRequestsAttachments(req.requestId, atch.id);
                } catch (er) {
                  console.log(er);
                }
              }
            }
            await getWBProjectInfo(surveyMomentInfo.data.projectId);
            await handleSyncMapFiles(
              surveyMomentInfo.data.projectId,
              surveyMomentInfo.data.buildingId
            );
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Get SurveyingMoment ${surveyMoment.surveyingMomentId} success`,
                  "success"
                ),
              })
            );
            await storeMoment(surveyMomentInfo.data);
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Store SurveyingMoment ${surveyMoment.surveyingMomentId} into LocalDB`,
                  "success"
                ),
              })
            );

            const assignments = surveyMomentInfo.data.controlPoints;
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Get SurveyingMoment ${surveyMoment.surveyingMomentId} Assignments loading`,
                  "loading"
                ),
              })
            );
            await getAssignmentsDetail(assignments);
            await storeSurveyingMomentInDB(surveyMoment);
          }
          resolve(surveyMomentInfo.data);
        }
        resolve(null);
      } catch (er) {
        reject(er);
      }
    });
    responseData.push(promise);
  }
  return responseData;
}

async function syncAssignmentsAttachments(assignmentId) {
  const data = await getAssignmentsAttachments({ assignmentId, sync: 0 });
  const deleteAttachments = await getDeletedAssignmentsAttachments({
    assignmentId,
  });
  if (data) {
    let localFilesFormData = new FormData();
    let existingFilesFormData = new FormData();
    localFilesFormData.append("assignmentId", data.assignmentId);
    existingFilesFormData.append("assignmentId", data.assignmentId);
    const localFiles = data.attachments.filter((p) => !p.attachmentId);
    const existingFiles = data.attachments.filter((p) => p.attachmentId);

    if (localFiles && localFiles.length) {
      localFiles.forEach((f) => localFilesFormData.append("files", f));
      try {
        await uploadAssignmentFiles(localFilesFormData);
      } catch (er) {
        console.log(er);
      }
    }
    if (existingFiles && existingFiles.length) {
      existingFiles.forEach((f) =>
        existingFilesFormData.append("attachmentIds", f.attachmentId)
      );
      try {
        await uploadAssignmentFiles(existingFilesFormData);
      } catch (er) {
        console.log(er);
      }
    }
    try {
      await updateAssignmentsAttachments({ assignmentId }, { sync: 1 });
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`updating assignment`, "loading"),
          hasUpdate: `${new Date().getTime()}-${Math.random()}`,
        })
      );
    } catch (er) {
      console.log(er);
    }
  }

  if (deleteAttachments && deleteAttachments.length) {
    for (let i = 0; i < deleteAttachments.length; i++) {
      const deleteAttachment = deleteAttachments[i];
      if (deleteAttachment) {
        await new Promise(async (resolve, reject) => {
          try {
            await deleteAssignmentImage(
              deleteAttachment.assignmentId,
              deleteAttachment.attachmentId
            );
            await deletedAssignmentsAttachments({
              assignmentId: deleteAttachment.assignmentId,
              attachmentId: deleteAttachment.attachmentId,
            });
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(`updating assignment`, "loading"),
                hasUpdate: `${new Date().getTime()}-${Math.random()}`,
              })
            );
            resolve("success");
          } catch (er) {
            console.log(er, "er delete attachment");
            resolve(er);
          }
        });
      }
    }
  }
}

async function getAssignmentsDetail(assignments) {
  for (let i = 0; i < assignments.length; i++) {
    await new Promise(async (resolve, reject) => {
      const assignment = assignments[i];
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Get Assignment ${assignment.assignments[0].assignmentId} Attachments loading`,
            "loading"
          ),
        })
      );
      const attachmentIds =
        assignment.assignments &&
        !!assignment.assignments.length &&
        assignment.assignments
          .flatMap((p) => p.assignmentAttachments)
          .flat()
          .map((p) => ({ ...p, isPDF: p.name.split(".").pop() === "pdf" }));
      const repairRequestAttachments =
        assignment.assignments &&
        !!assignment.assignments.length &&
        assignment.assignments
          .flatMap((p) => p.repairRequests)
          .flat()
          .reduce(
            (p, c) => [
              ...p,
              ...c.attachments.map((a) => ({
                isPDF: a.name.split(".").pop() === "pdf",
                attachmentId: a.id,
                requestId: c.requestId,
              })),
            ],
            []
          );
      if (attachmentIds && attachmentIds.length) {
        for (let a = 0; a < attachmentIds.length; a++) {
          try {
            const isExistCache = await canRequestExistCache(
              `${URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL}${attachmentIds[a].id}`
            );
            if (!isExistCache)
              await getAssignmentAttachmentThumbnail(attachmentIds[a].id);
            const isExistCache1 = await canRequestExistCache(
              `${URLS.GET_ASSIGNMENT_ATTACHMENT}${attachmentIds[a].id}`
            );
            if (!isExistCache1)
              await getAssignmentAttachment(attachmentIds[a].id);
          } catch (er) {
            console.log(er);
          }
        }
      }
      if (repairRequestAttachments && repairRequestAttachments.length) {
        for (let a = 0; a < repairRequestAttachments.length; a++) {
          try {
            const isExistCache = await canRequestExistCache(
              `${URLS.GET_REPAIR_REQUEST_ATTACHMENTS}${repairRequestAttachments[a].requestId}/${repairRequestAttachments[a].attachmentId}`
            );
            if (!isExistCache)
              await getRepairRequestsAttachments(
                repairRequestAttachments[a].requestId,
                repairRequestAttachments[a].attachmentId
              );
          } catch (er) {
            console.log(er);
          }
        }
      }

      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Get Assignment Attachments success`, "success"),
        })
      );
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(`Get Assignment loading`, "loading"),
        })
      );

      try {
        const assignmentInfo = await getAssignmentInfo(
          assignment.assignments[0].assignmentId,
          window.navigator.onLine
        );
        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(`Get Assignment success`, "success"),
          })
        );
        if (window.navigator.onLine) {
          try {
            await storeAssignment(assignmentInfo.data);
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Store Assignment ${assignment.assignments[0].assignmentId} into LocalDB`,
                  "success"
                ),
              })
            );
          } catch (er) {
            dispatchAction(
              success({
                type: "UPDATE_LOG",
                log: formatLog(
                  `Store Assignment ${assignment.assignments[0].assignmentId} into LocalDB error ${er}`,
                  "error"
                ),
              })
            );
          }
        }
        resolve(assignmentInfo.data);
      } catch (er) {
        console.log(er);
        reject(er);
      }
    });
  }
}

async function assignmentCompleteHandler(selectedAssignment) {
  try {
    const internalCompletedAssignment = await getInternalCompleteAssignments({
      assignmentId: selectedAssignment.assignmentId,
      sync: 0,
    });
    if (internalCompletedAssignment) {
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Internal completed assignment ${selectedAssignment.assignmentId} loading`,
            "loading"
          ),
        })
      );
      const { isReset, ...rest } = internalCompletedAssignment;
      if (isReset)
        await resetAssignmentStatus([rest].map((p) => p.assignmentId));
      else await internalCompleteAssignments([rest].map((p) => p.assignmentId));
      await deleteInternalCompleteAssignments(rest);
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Internal completed assignment ${selectedAssignment.assignmentId} success`,
            "success"
          ),
        })
      );
    }
  } catch (er) {
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(
          `Internal completed assignment ${selectedAssignment.assignmentId} error`,
          "error"
        ),
      })
    );
  }

  try {
    const completedAssignment = await getCompleteAssignments({
      assignmentId: selectedAssignment.assignmentId,
      sync: 0,
    });
    if (completedAssignment) {
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Complete assignment ${selectedAssignment.assignmentId} loading`,
            "loading"
          ),
        })
      );
      await completeAssignments(
        [completedAssignment].map((p) => p.assignmentId)
      );
      await deleteCompleteAssignments(completedAssignment);
      dispatchAction(
        success({
          type: "UPDATE_LOG",
          log: formatLog(
            `Complete assignment ${selectedAssignment.assignmentId} success`,
            "success"
          ),
        })
      );
    }
  } catch (er) {
    dispatchAction(
      success({
        type: "UPDATE_LOG",
        log: formatLog(
          `Complete assignment ${selectedAssignment.assignmentId} error`,
          "error"
        ),
      })
    );
  }
}

const surveyingMomentsPostRequestHandler = async (buildings = []) => {
  const surveyingMoments = await getSurveyingMoments(buildings, true);
  const allSurveyingMoments = surveyingMoments.flat();
  for (let i = 0; i < allSurveyingMoments.length; i++) {
    const surveyMoment = allSurveyingMoments[i] || {};
    const isExist = buildings.find(
      (building) => surveyMoment.buildingId === building.buildingId
    );
    if (isExist) {
      // eslint-disable-next-line no-loop-func
      await new Promise(async (resolve, reject) => {
        try {
          const surveyMomentInfo = await getSurveyMomentInfo(
            {
              surveyMomentId: surveyMoment.surveyingMomentId,
              wbObjectId: surveyMoment.objectId,
            },
            false
          );
          if (surveyMomentInfo) {
            const assignments = (surveyMomentInfo.controlPoints || []).flatMap(
              (p) => p.assignments
            );
            for (let a = 0; a < assignments.length; a++) {
              const assignment = assignments[a];

              const assignmentData = await getUpdatedAssignmentInfo({
                assignmentId: assignment.assignmentId,
              });
              if (assignmentData && assignmentData.length) {
                await Promise.all(
                  assignmentData.map(async (assignmentInfo, i) => {
                    try {
                      dispatchAction(
                        success({
                          type: "UPDATE_LOG",
                          log: formatLog(`updating assignment`, "loading"),
                        })
                      );
                      if (
                        assignmentInfo.fieldId === "message" &&
                        Array.isArray(assignmentInfo.data)
                      ) {
                        for (let i = 0; i < assignmentInfo.data.length; i++) {
                          await updateAssignmentInfo({
                            assignmentId: assignmentInfo.assignmentId,
                            ...assignmentInfo.data[i],
                          });
                        }
                      } else
                        await updateAssignmentInfo({
                          assignmentId: assignmentInfo.assignmentId,
                          ...assignmentInfo.data,
                        });
                      await deleteUpdatedAssignmentInfo({
                        assignmentId: assignmentInfo.assignmentId,
                        fieldId: assignmentInfo.fieldId,
                      });
                      dispatchAction(
                        success({
                          type: "UPDATE_LOG",
                          log: formatLog(`updating assignment`, "loading"),
                          hasUpdate: `${new Date().getTime()}-${i}`,
                        })
                      );
                    } catch (er) {
                      console.log(er);
                    }
                  })
                );
              }

              await syncAssignmentsAttachments(assignment.assignmentId);
              const repairRequests = await getAssignmentsRepairRequestsFromIDB({
                assignmentId: assignment.assignmentId,
                sync: 0,
              });
              await requestSyncHandler(
                repairRequests,
                "isAssignmentRepairRequest"
              );
              await assignmentDelegatorHandler(assignment);

              await assignmentCompleteHandler(assignment);
            }
          }

          // await surveyingMomentCompleteHandler(surveyMoment);
          resolve(surveyMomentInfo);
        } catch (er) {
          reject(er);
        }
      });
    }
  }
  return allSurveyingMoments;
};

const assignmentDelegatorHandler = async (assignment) => {
  const data = await getUpdatedDelegator({
    assignmentId: assignment.assignmentId,
  });
  if (data) {
    await updateResponsiblePersonForAssignments(data.data);
    await deleteUpdatedDelegator({ assignmentId: assignment.assignmentId });
  }
};

const surveyPostRequestHandler = async (selectedSurveyType) => {
  const surveys = await getSurveysFromDB({
    isSecondSignature: selectedSurveyType.isSecondSignature ? 1 : 0,
    surveyType: selectedSurveyType.surveyType,
  });
  for (let i = 0; i < surveys.length; i++) {
    // eslint-disable-next-line no-loop-func
    await new Promise(async (resolve, reject) => {
      try {
        const survey = surveys[i];
        dispatchAction(
          success({
            type: "UPDATE_LOG",
            log: formatLog(
              `Get ${survey.surveyId} survey detail loading`,
              "loading"
            ),
          })
        );
        const surveyInfoData =
          (await getUpdatedSurveyInfo({
            surveyId: survey.surveyId,
            surveyType: selectedSurveyType.surveyType,
          })) || {};
        if (surveyInfoData && surveyInfoData.length) {
          const info = surveyInfoData.reduce(
            (p, c) => ({
              ...p,
              [c.fieldKey]: c[c.fieldKey],
              surveyId: c.surveyId,
            }),
            {}
          );
          await updateSurveyApi(info);
          await deleteUpdatedSurveyInfo({
            surveyId: survey.surveyId,
            surveyType: selectedSurveyType.surveyType,
          });
        }
        await updateSignaturesRequest(
          survey.surveyId,
          selectedSurveyType.surveyType,
          selectedSurveyType.isSecondSignature
        );
        const requests = await getRepairRequestsFromIDB({
          sync: 0,
          surveyId: survey.surveyId,
        });
        await requestSyncHandler(requests);
        resolve(surveyInfoData);
      } catch (er) {
        reject(er);
      }
    });
  }
  return surveys;
};

function success(data) {
  return { ...data };
}

const getAdditionalData = async (dispatch, app, projectId) => {
  try {
    await getLocations();
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingLocation"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    await getAvailableSurveyModules(projectId);
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorDetreminingAvailableSurveys"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getSettlementTerms();
    await storeSettlementTerms(data.map((p) => ({ ...p })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingSettlementTerms"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    await getCarryOutList();
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingRepairRequestType"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getRepairRequestTypeList();
    await storeRepairRequestTypeList(data.map((p) => ({ ...p })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingRepairRequestType"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getRepairRequestCauseList();
    await storeRepairRequestCauseList(data.map((p) => ({ ...p })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingRepairRequestCause"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getRepairRequestCauserList();
    await storeRepairRequestCauserList(data.map((p) => ({ ...p })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorRetrievingRepairRequestCause"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getOrganizationsByProject(projectId);
    await storeOrganizationsByProject(data.map((p) => ({ ...p, projectId })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorDownloadingInvolvedOrgs"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getProductsServices();
    await storeProductsServices(data.map((p) => ({ ...p })));
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorDownloadingProductService"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data } = await getRepairRequestNatureList();
    data.length > 0 &&
      (await storeRepairRequestNatureList(data.map((p) => ({ ...p }))));
  } catch (er) {
    console.log(er, "Nature List download error");
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorDownloadingRepairRequestNature"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
  try {
    const { data: organizations } = await getOrganizations(projectId);
    await storeOrganizations({
      projectId: projectId,
      app: app.toString(),
      data: organizations,
    });
  } catch (er) {
    console.log(er);
    dispatch(
      success({
        type: "UPDATE_LOG_1",
        log: formatLog(
          t("sync.survey.errorDownloadingOrganizations"),
          "error",
          Math.random().toString() + new Date().getTime() + "down"
        ),
      })
    );
  }
};

const updateExistingRepairRequest = async (requests) => {
  for (let i = 0; i < requests.length; i++) {
    const request = requests[i];
    if (request.updatedValues && request.updatedValues.length) {
      const { updatedValues, ...data } = request;
      try {
        const requestId = request.requestId;
        if (requestId) {
          await updateRepairRequestData(request, requestId);
          await workOrderUpdate(request, requestId);
          if (!request.assignmentId) {
            await updateRepairRequests(
              { surveyId: request.surveyId },
              { ...data, updatedValues: null }
            );
          } else {
            await updateAssignmentRepairRequests(
              { assignmentId: request.assignmentId },
              { ...data, updatedValues: null }
            );
          }
        }
      } catch (er) {
        console.log(er);
      }
    }
  }
};

//Sync improvement
function assignmentSyncDown(assignments) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
      buildings: { selected },
      app,
    } = getState();
    const { data: lockIds } = await lockUnits({
      type: 1,
      locks: assignments.map(({ assignmentId }) => ({ unitId: assignmentId })),
    });
    for (let a = 0; a < assignments.length; a++) {
      dispatch(
        success({
          type: "UPDATE_LOG_1",
          log: formatLog(
            t("wkb.assignment.downloadAssignment"),
            "loading",
            lockIds.locks.find((j) => j.unitId === assignments[a].assignmentId)
              .lockId + "down"
          ),
        })
      );
      await releaseUserLockIds({
        unitId: assignments[a].assignmentId,
        userId: user.id,
      });
    }
    try {
      await getAdditionalData(dispatch, app, selected.projectId);
      await storeUserLockIds(
        lockIds.locks.map((a) => ({
          ...a,
          userId: user.id,
          lockId: a.lockId || "",
        }))
      );
      const { data } = await getWBProjectInfo(selected.projectId);
      await storeWBProjectInfo({ ...data, projectId: selected.projectId });
    } catch (er) {
      console.log(er);
      for (let a = 0; a < assignments.length; a++) {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("wkb.assignment.downloadAssignment"),
              "error",
              lockIds.locks.find(
                (j) => j.unitId === assignments[a].assignmentId
              ).lockId + "down"
            ),
          })
        );
      }
      releaseLock(assignments, true);
      return;
    }
    for (let i = 0; i < assignments.length; i++) {
      try {
        await new Promise(async (resolve, reject) => {
          const assignment = assignments[i];
          const lockId = lockIds.locks.find(
            (a) => a.unitId === assignment.assignmentId
          ).lockId;
          const attachmentIds = assignment && assignment.assignmentAttachments;
          const repairRequestAttachments =
            assignment &&
            assignment.repairRequests.length &&
            assignment.repairRequests.reduce(
              (p, c) => [
                ...p,
                ...c.attachments.map((a) => ({
                  isPDF: a.name.split(".").pop() === "pdf",
                  attachmentId: a.id,
                  requestId: c.requestId,
                })),
              ],
              []
            );
          const { objectId, surveyingMomentId } =
            assignment.surveyingMomentDetails;
          //Store Moment for assignment access
          const surveyMomentInfo = await getSurveyMomentInfo(
            {
              surveyMomentId: surveyingMomentId,
              wbObjectId: objectId,
            },
            window.navigator.onLine
          );
          await storeMoment({
            ...surveyMomentInfo.data,
            assignmentId: assignment.assignmentId,
            lockedUserId: user.id,
          });
          await storeSurveyingMomentInDB({
            ...surveyMomentInfo.data,
            assignmentId: assignment.assignmentId,
            lockedUserId: user.id,
          });

          await getAndStoreRepairRequestDetails(
            assignment ? assignment.repairRequests : [],
            selected
          );
          if (attachmentIds && attachmentIds.length) {
            for (let a = 0; a < attachmentIds.length; a++) {
              let attachmentId = attachmentIds[a].id;
              // try {
              //     const isExistInIDB = await getFile(attachmentId)
              //     if (!isExistInIDB) {
              //         const data = await getAssignmentAttachmentThumbnail(attachmentId);
              //         const attachmentsData = { fileId: attachmentId, file: data }
              //         await storeFile(attachmentsData)
              //     }
              //
              // } catch (er) { console.log(er, 'getAssignmentAttachmentThumbnail') }
              try {
                const { data } = await getAssignmentAttachment(attachmentId);
                const attachmentsData = { fileId: attachmentId, file: data };
                await storeFile(attachmentsData);
              } catch (er) {
                console.log(
                  er,
                  er.response,
                  er.message,
                  attachmentId,
                  "getAssignmentAttachment"
                );
              }
            }
          }

          if (repairRequestAttachments && repairRequestAttachments.length) {
            for (let a = 0; a < repairRequestAttachments.length; a++) {
              let request = repairRequestAttachments[a];
              try {
                const isExistInIDB = await getFile(request.attachmentId);
                if (!isExistInIDB) {
                  const { data } = await getRepairRequestsAttachments(
                    request.requestId,
                    request.attachmentId
                  );
                  const attachmentsData = {
                    fileId: request.attachmentId,
                    file: data,
                  };
                  await storeFile(attachmentsData);
                }
              } catch (er) {
                console.log(er);
              }
            }
          }

          try {
            if (window.navigator.onLine) {
              try {
                const assignmentInfo = await getAssignmentInfo(
                  assignment.assignmentId,
                  window.navigator.onLine
                );
                await storeAssignment({
                  ...assignment,
                  ...assignmentInfo.data,
                  projectId: assignmentInfo.data.projectId,
                  lockId: lockId,
                  lockedUserId: user.id,
                });

                await handleSyncMapFiles(
                  assignmentInfo.data.projectId,
                  assignmentInfo.data.buildingId
                );
                dispatch(
                  success({
                    type: "UPDATE_ASSIGNMENT_LOCK",
                    lockId,
                    assignmentId: assignmentInfo.data.assignmentId,
                    userId: user.id,
                  })
                );
                dispatch(
                  success({
                    type: "UPDATE_LOG_1",
                    log: formatLog(
                      t("wkb.assignment.downloadAssignmentWithDesc", {
                        controlPointDesc: assignment.controlPointDesc,
                      }),
                      "success",
                      lockId + "down"
                    ),
                  })
                );
                resolve(assignmentInfo.data);
              } catch (er) {
                dispatch(
                  success({
                    type: "UPDATE_LOG_1",
                    log: formatLog(
                      t("wkb.assignment.downloadAssignmentWithDesc", {
                        controlPointDesc: assignment.controlPointDesc,
                      }),
                      "error",
                      lockId + "down"
                    ),
                  })
                );
                resolve("");
              }
            } else resolve("");
          } catch (er) {
            console.log(er);
            reject(er);
          }
        });
      } catch (e) {
        console.log(e);
        releaseLock([assignments[i]], true);
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("wkb.assignment.downloadAssignment"),
              "error",
              lockIds.locks.find(
                (j) => j.unitId === assignments[i].assignmentId
              ).lockId + "down"
            ),
          })
        );
      }
    }
  };
}

function assignmentSyncUp(assignments) {
  return async (dispatch, getState) => {
    for (let a = 0; a < assignments.length; a++) {
      await new Promise(async (resolve, reject) => {
        const assignment = assignments[a];
        try {
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("wkb.assignment.assignmentWithDesc", {
                  controlPointDesc: assignment.controlPointDesc,
                }),
                "loading",
                assignment.lockId + "up"
              ),
            })
          );

          const localAssignment = await getAssignmentInfo(
            assignment.assignmentId,
            false
          );
          const { data: assignmentFromNetwork } = await getAssignmentInfo(
            assignment.assignmentId,
            window.navigator.onLine
          );
          if (
            assignmentFromNetwork.internalStatus === 1 &&
            localAssignment.sync === 0
          )
            await resetAssignmentStatus([assignment.assignmentId]);

          //Sync RR(Sub unit)
          try {
            const repairRequests = await getAssignmentsRepairRequestsFromIDB({
              assignmentId: assignment.assignmentId,
              sync: 0,
            });
            await requestSyncHandler(
              repairRequests,
              "isAssignmentRepairRequest"
            );
            const allRR = await getAssignmentsRepairRequestsFromIDB({
              assignmentId: assignment.assignmentId,
            });
            await updateExistingRepairRequest(allRR);
          } catch (er) {
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.errorRepairRequestSynchronization"),
                  "error",
                  assignment.lockId + "RR" + Math.random().toString()
                ),
              })
            );
          }

          //Sync Unit
          try {
            await syncAssignmentsAttachments(assignment.assignmentId);
            const assignmentData = await getUpdatedAssignmentInfo({
              assignmentId: assignment.assignmentId,
            });
            if (assignmentData && assignmentData.length) {
              await Promise.all(
                assignmentData.map(async (assignmentInfo, i) => {
                  try {
                    if (
                      assignmentInfo.fieldId === "message" &&
                      Array.isArray(assignmentInfo.data)
                    ) {
                      for (let i = 0; i < assignmentInfo.data.length; i++) {
                        await updateAssignmentInfo({
                          assignmentId: assignmentInfo.assignmentId,
                          ...assignmentInfo.data[i],
                        });
                      }
                    } else
                      await updateAssignmentInfo({
                        assignmentId: assignmentInfo.assignmentId,
                        ...assignmentInfo.data,
                      });
                    await deleteUpdatedAssignmentInfo({
                      assignmentId: assignmentInfo.assignmentId,
                      fieldId: assignmentInfo.fieldId,
                    });
                  } catch (er) {
                    console.log(er);
                  }
                })
              );
            }
          } catch (er) {
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("wkb.assignment.assignmentWithDesc", {
                    controlPointDesc: assignment.controlPointDesc,
                  }),
                  "error",
                  assignment.lockId + "assignment" + Math.random().toString()
                ),
              })
            );
          }

          //Sync assignment status
          await assignmentCompleteHandler(assignment);

          //Store latest assignment
          const { data: newAssignment } = await getAssignmentInfo(
            assignment.assignmentId,
            window.navigator.onLine
          );
          await storeAssignment(newAssignment);
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("wkb.assignment.assignmentWithDesc", {
                  controlPointDesc: assignment.controlPointDesc,
                }),
                "success",
                assignment.lockId + "up"
              ),
            })
          );
          resolve(assignment);
        } catch (er) {
          resolve(er);
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("wkb.assignment.assignmentWithDesc", {
                  controlPointDesc: assignment.controlPointDesc,
                }),
                "error",
                assignment.lockId + "up"
              ),
            })
          );
        }
      });
    }
    dispatch(syncRelease(true));
  };
}

export function syncRelease(value) {
  return (dispatch) => {
    dispatch(success({ type: "SYNC_UP_SUCCESS", canOpenReleaseModal: value }));
  };
}

export function releaseLock(assignments, canClean = true) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(success({ type: "RELEASE_LOCK_LOADING" }));
    for (let i = 0; i < assignments.length; i++) {
      const assignment = assignments[i];
      const { objectId, surveyingMomentId } = assignment.surveyingMomentDetails;

      try {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.releaseLock.assignmentWillBeUnlocked", {
                controlPointDesc: assignment.controlPointDesc,
              }),
              "loading",
              assignment.lockId + assignment.assignmentId + "release"
            ),
          })
        );

        await releaseUnits({
          type: 1,
          locks: [{ unitId: assignment.assignmentId }],
        });
        if (canClean) {
          await deleteMoment({
            surveyingMomentId,
            objectId,
            assignmentId: assignment.assignmentId,
          });
          await deleteSurveyFromDB({
            surveyingMomentId,
            objectId,
            assignmentId: assignment.assignmentId,
          });
          await deleteAssignmentFromDB(assignment.assignmentId);
          await releaseUserLockIds({
            unitId: assignment.assignmentId,
            userId: user.id,
          });
        }
        dispatch(
          success({
            type: "UPDATE_ASSIGNMENT_LOCK",
            assignmentId: assignment.assignmentId,
            oldLockId: assignment.lockId,
            userId: user.id,
          })
        );
        dispatch(syncRelease(false));
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.releaseLock.assignmentReleased", {
                controlPointDesc: assignment.controlPointDesc,
              }),
              "success",
              assignment.lockId + assignment.assignmentId + "release"
            ),
          })
        );
      } catch (er) {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.releaseLock.assignmentUnlockFailed", {
                controlPointDesc: assignment.controlPointDesc,
              }),
              "error",
              assignment.lockId + assignment.assignmentId + "release"
            ),
          })
        );
      }
    }
    dispatch(success({ type: "RELEASE_LOCK_SUCCESS" }));
  };
}

export function releaseSurveysLock(
  surveys,
  canClean = true,
  title,
  fetchPayload = null
) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(success({ type: "RELEASE_LOCK_LOADING" }));
    for (let i = 0; i < surveys.length; i++) {
      const survey = surveys[i];
      try {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.survey.surveyForBuildingWillBeUnlocked", {
                title: title,
                buildingNoIntern: survey.buildingNoIntern,
              }),
              "loading",
              survey.lockId + survey.surveyId + "release"
            ),
          })
        );

        const { data: releaseLockRes } = await releaseUnits({
          type: 0,
          locks: [{ unitId: survey.surveyId }],
        });
        if (canClean) {
          await deleteSSRRChanges({ surveyId: survey.surveyId });
          await deleteRepairRequests({ surveyId: survey.surveyId });
          await deleteSurveyDetail({
            surveyType: survey.surveyType,
            surveyId: survey.surveyId,
          });
          await deleteSignatures({
            surveyType: survey.surveyType,
            surveyId: survey.surveyId,
            isSecondSignature: survey.isSecondSignature ? 1 : 0,
          });
          await deleteSendingEmails({
            surveyType: survey.surveyType,
            surveyId: survey.surveyId,
            isSecondSignature: survey.isSecondSignature ? 1 : 0,
          });
          await deleteSurvey({
            surveyType: survey.surveyType,
            surveyId: survey.surveyId,
          });
          await removeSecondSignature({ surveyId: survey.surveyId });
          await releaseUserLockIds({
            unitId: survey.surveyId,
            userId: user.id,
          });
        }
        if (releaseLockRes && releaseLockRes.length) {
          dispatch(
            success({
              type: "UPDATE_ASSIGNMENT_LOCK",
              surveyId: survey.surveyId,
              oldLockId: survey.lockId,
              userId: user.id,
              isSurvey: true,
            })
          );
          dispatch(syncRelease(false));
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("sync.survey.downloadSurveyForBuildingReleased", {
                  title: title,
                  buildingNoIntern: survey.buildingNoIntern,
                }),
                "success",
                survey.lockId + survey.surveyId + "release"
              ),
            })
          );
        } else {
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("sync.survey.downloadSurveyForBuildingUnlockFailed", {
                  title: title,
                  buildingNoIntern: survey.buildingNoIntern,
                }),
                "error",
                survey.lockId + survey.surveyId + "release"
              ),
            })
          );
        }
      } catch (er) {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.survey.downloadSurveyForBuildingUnlockFailed", {
                title: title,
                buildingNoIntern: survey.buildingNoIntern,
              }),
              "error",
              survey.lockId + survey.surveyId + "release"
            ),
          })
        );
      }
      if (fetchPayload) {
        dispatch(
          surveyActions.getSurveysAction({
            ...fetchPayload,
          })
        );
      }
    }
    dispatch(success({ type: "RELEASE_LOCK_SUCCESS" }));
  };
}

const getAndStoreRepairRequestDetails = async (requests, projectId) => {
  let isExistInIDB;
  for (let i = 0; i < requests.length; i++) {
    const requestId = requests[i].requestId;
    isExistInIDB = await getRepairRequestDetailsIDB(requestId);
    if (!isExistInIDB) {
      const { data } = await axios.get(
        webApiUrl +
          "api/RepairRequest/GetRepairRequestDetails/" +
          encodeURI(requestId),
        { headers: authHeader() }
      );
      await storeRepairRequestDetails(data);
    }
    isExistInIDB = await getRecipientsEmailsIDB(requestId);
    if (!isExistInIDB) {
      const { data } = await axios.get(
        webApiUrl +
          "api/RepairRequest/GetEmailsForRepairRequest/" +
          encodeURI(requestId),
        { headers: authHeader() }
      );
      await storeEmailsForRepairRequest({ ...data, requestId });
    }
  }
};

function surveysSynDown(surveys, title, isSecondSignature) {
  return async (dispatch, getState) => {
    const uniqueSyncId = new Date().getTime().toString();
    const {
      authentication: { user },
      app,
    } = getState();
    const { data: lockIds } = await lockUnits({
      type: 0,
      locks: surveys.map(({ surveyId }) => ({ unitId: surveyId })),
    });
    const projectIds = groupBy(surveys, "projectId");
    try {
      for (let a = 0; a < surveys.length; a++) {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.survey.downloadSurveyBuilding", {
                title: title.toLowerCase(),
                buildingNoIntern: surveys[a].buildingNoIntern,
              }),
              "loading",
              lockIds.locks.find((j) => j.unitId === surveys[a].surveyId)
                .lockId +
                uniqueSyncId +
                "down"
            ),
          })
        );
        await releaseUserLockIds({
          unitId: surveys[a].surveyId,
          userId: user.id,
        });
      }
      for (const projectId in projectIds) {
        await getAdditionalData(dispatch, app, projectId);
        const { data } = await getProjectLogoForReport(projectId);
        const projectLogo = { fileId: projectId + "forReport", file: data };
        await storeFile(projectLogo);
      }
      await storeUserLockIds(
        lockIds.locks.map((a) => ({
          ...a,
          userId: user.id,
          lockId: a.lockId || "",
          isSecondSignature: isSecondSignature ? 1 : 0,
        }))
      );
    } catch (e) {
      console.log(e);
      dispatch(releaseSurveysLock(surveys, true, title));
      for (let a = 0; a < surveys.length; a++) {
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.survey.downloadSurveyBuilding", {
                title: title.toLowerCase(),
                buildingNoIntern: surveys[a].buildingNoIntern,
              }),
              "error",
              lockIds.locks.find((j) => j.unitId === surveys[a].surveyId)
                .lockId +
                uniqueSyncId +
                "down"
            ),
          })
        );
      }
      return;
    }
    // eslint-disable-next-line no-loop-func
    for (let i = 0; i < surveys.length; i++) {
      const lockId = lockIds.locks.find(
        (j) => j.unitId === surveys[i].surveyId
      ).lockId;
      const survey = { ...surveys[i], lockId, isCracked: false };
      try {
        await new Promise(async (resolve, reject) => {
          const syncResolveId = lockId + uniqueSyncId + "down";
          try {
            try {
              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t("sync.survey.downloadSurveyBuilding", {
                      title: title.toLowerCase(),
                      buildingNoIntern: survey.buildingNoIntern,
                    }),
                    "loading",
                    syncResolveId + uniqueSyncId
                  ),
                })
              );
              const { data: repairRequests } = await getRepairRequestsApi(
                survey.surveyId,
                window.navigator.onLine
              );
              await storeRepairRequests(repairRequests);
              await getAndStoreRepairRequestDetails(
                repairRequests,
                survey.projectId
              );
              const attachmentsArray = repairRequests.map((p) => ({
                attachments: p.attachments.map((a) => ({
                  ...a,
                  requestId: p.requestId,
                })),
              }));
              if (attachmentsArray.length) {
                const attachments = attachmentsArray.flatMap(
                  (p) => p.attachments
                );
                for (let i = 0; i < attachments.length; i++) {
                  await new Promise(async (resolveAttachments) => {
                    try {
                      const attachment = attachments[i];
                      const isExistInIDB = await getFile(
                        attachment.attachmentId
                      );
                      if (!isExistInIDB) {
                        const { data } = await getRepairRequestsAttachments(
                          attachment.requestId,
                          attachment.attachmentId
                        );
                        const attachmentsData = {
                          fileId: attachment.attachmentId,
                          file: data,
                        };
                        await storeFile(attachmentsData);
                      }
                      resolveAttachments(attachment);
                    } catch (er) {
                      console.log(er);
                      resolveAttachments(er);
                    }
                  });
                }
              }

              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t(
                      "sync.survey.repairRequestsFromSurveyHaveBeenDownloaded",
                      {
                        title: title.toLowerCase(),
                        buildingNoIntern: survey.buildingNoIntern,
                      }
                    ),
                    "success",
                    syncResolveId + uniqueSyncId
                  ),
                })
              );
            } catch (er) {
              console.log(er, "requests");
              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t(
                      "sync.survey.repairRequestsFromSurveyHaveBeenDownloaded",
                      {
                        title: title.toLowerCase(),
                        buildingNoIntern: survey.buildingNoIntern,
                      }
                    ),
                    "error",
                    syncResolveId + uniqueSyncId
                  ),
                })
              );
              throw er;
            }

            const { data: pinImages } = await getPinLocationImage(
              survey.surveyId
            );
            console.log(pinImages, "pinImage");
            await storePinImages({
              surveyId: survey.surveyId,
              data: pinImages,
            });
            const { data } = await getSurveyDetailApi(
              survey.surveyId,
              window.navigator.onLine,
              !!survey.isSecondSignature
            );
            try {
              await handleSyncMapFiles(data.projectId, data.buildingId);
            } catch (er) {
              console.log(er, "handleSyncMapFiles");
              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t("sync.survey.buildingDrawingIsBeingDownloadedForSurvey", {
                      title: title,
                      buildingNoIntern: survey.buildingNoIntern,
                    }),
                    "error",
                    new Date().getTime().toString() + Math.random()
                  ),
                })
              );
              throw er;
            }

            try {
              const { data } = await getRecipientsEmails(survey.surveyId);
              await storeRecipientsEmails({
                ...data,
                surveyId: survey.surveyId,
              });
            } catch (er) {
              console.log(er, "getRecipientsEmails");
              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t("sync.survey.errorGetEmailsListOfSurveyForBuilding", {
                      title: title.toLowerCase(),
                      buildingNoIntern: survey.buildingNoIntern,
                    }),
                    "error",
                    new Date().getTime().toString() + Math.random()
                  ),
                })
              );
              throw er;
            }

            await storeSurveyDetails({
              ...data,
              isSecondSignature: survey.isSecondSignature ? 1 : 0,
            });
            await storeSurvey({
              ...survey,
              isSecondSignature: survey.isSecondSignature ? 1 : 0,
            });
            dispatch(
              success({
                type: "UPDATE_ASSIGNMENT_LOCK",
                lockId: survey.lockId,
                surveyId: data.surveyId,
                userId: user.id,
                isSecondSignature: survey.isSecondSignature ? 1 : 0,
                isSurvey: true,
              })
            );
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.surveyForBuildingHasBeenDownloaded", {
                    title: title.toLowerCase(),
                    buildingNoIntern: survey.buildingNoIntern,
                  }),
                  "success",
                  lockIds.locks.find((j) => j.unitId === survey.surveyId)
                    .lockId +
                    uniqueSyncId +
                    "down"
                ),
              })
            );
            resolve("success");
          } catch (er) {
            console.log(er);
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.downloadSurveyForBuilding", {
                    title: title.toLowerCase(),
                    buildingNoIntern: survey.buildingNoIntern,
                  }),
                  "error",
                  lockIds.locks.find((j) => j.unitId === survey.surveyId)
                    .lockId +
                    uniqueSyncId +
                    "down"
                ),
              })
            );
            reject("error");
          }
        });
      } catch (e) {
        console.log(e);
        dispatch(releaseSurveysLock([survey], true, title));
        dispatch(
          success({
            type: "UPDATE_LOG_1",
            log: formatLog(
              t("sync.survey.surveyForBuildingHasBeenDownloaded", {
                title: title,
                buildingNoIntern: survey.buildingNoIntern,
              }),
              "error",
              lockIds.locks.find((j) => j.unitId === survey.surveyId).lockId +
                uniqueSyncId +
                "down"
            ),
          })
        );
      }
    }
  };
}

function surveysSyncUp(surveys, isSecondSignature, title) {
  isSecondSignature = isSecondSignature ? 1 : 0;
  return async (dispatch, getState) => {
    let canReleaseLock = true;
    const uniqueSyncUpId = new Date().getTime().toString();

    // eslint-disable-next-line no-loop-func
    for (let i = 0; i < surveys.length; i++) {
      const survey = surveys[i];
      await new Promise(async (resolve) => {
        try {
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("sync.survey.uploadSurveyForBuilding", {
                  title: title.toLowerCase(),
                  buildingNoIntern: survey.buildingNoIntern,
                }),
                "loading",
                survey.lockId + uniqueSyncUpId + "up"
              ),
            })
          );

          if (!isSecondSignature) {
            // Sync RR(Sub unit)
            try {
              const requests = await getRepairRequestsFromIDB({
                sync: 0,
                surveyId: survey.surveyId,
              });
              await requestSyncHandler(requests);
            } catch (er) {
              dispatch(
                success({
                  type: "UPDATE_LOG_1",
                  log: formatLog(
                    t("sync.survey.errorSurveyRepairRequestSynchronization", {
                      title: title,
                    }),
                    "error",
                    survey.lockId + "RR" + Math.random().toString()
                  ),
                })
              );
            }
          }
          try {
            const requests = await getRepairRequestsFromIDB({
              surveyId: survey.surveyId,
            });
            await updateExistingRepairRequest(requests);
          } catch (er) {
            console.log(er);
          }
          if (isSecondSignature) {
            try {
              const canStartData = await getStartSecondSignature({
                surveyId: survey.surveyId,
              });
              if (canStartData) {
                await startSecondSignature(survey.surveyId);
                await removeSecondSignature({ surveyId: survey.surveyId });
              }
            } catch (er) {
              console.log(er);
            }
            const requests = await getSSRRChanges({
              surveyId: survey.surveyId,
            });
            if (requests && requests.length) {
              for (let r = 0; r < requests.length; r++) {
                const { surveyId, type, ...request } = requests[r];
                try {
                  const formData = new FormData();
                  // eslint-disable-next-line array-callback-return
                  Object.keys(request).map((key) => {
                    formData.append(key, request[key]);
                  });
                  if (type === "reject")
                    await addUpdateRework(formData, surveyId);
                  else await markCompleteRR(formData, surveyId);
                  await deleteSSRRChanges({ surveyId });
                } catch (er) {
                  await deleteSSRRChanges({ surveyId });
                  dispatch(
                    success({
                      type: "UPDATE_LOG_1",
                      log: formatLog(
                        t("sync.survey.surveyRepairRequestsWithType", {
                          title: title,
                          id: request.repairRequestId,
                          type: type,
                        }),
                        "error",
                        surveyId + type + Math.random().toString()
                      ),
                    })
                  );
                }
              }
            }
          }

          //Sync Unit
          try {
            const surveyInfoData =
              (await getUpdatedSurveyInfo({
                surveyId: survey.surveyId,
                surveyType: survey.surveyType,
              })) || {};
            if (surveyInfoData && surveyInfoData.length) {
              const info = surveyInfoData.reduce(
                (p, c) => ({
                  ...p,
                  [c.fieldKey]: c[c.fieldKey],
                  surveyId: c.surveyId,
                }),
                {}
              );
              await updateSurveyApi(info);
              await deleteUpdatedSurveyInfo({
                surveyId: survey.surveyId,
                surveyType: survey.surveyType,
              });
            }
          } catch (er) {
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.errorSurveyUpload", { title: title }),
                  "error",
                  survey.lockId + "SS" + Math.random().toString()
                ),
              })
            );
          }

          //Sync Signatures
          try {
            const signatures = await getSignatures({
              surveyId: survey.surveyId,
              surveyType: survey.surveyType,
              isSecondSignature: +isSecondSignature,
            });
            if (signatures) {
              await (!isSecondSignature ? updateSurveyApi : completeSignature)(
                signatures
              );
            }
          } catch (er) {
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.errorSurveySignatureSync", { title: title }),
                  "error",
                  survey.lockId + "SS" + Math.random().toString()
                ),
              })
            );
          }

          //Send Emails
          try {
            const emailsSendings = await getSendingEmails({
              surveyId: survey.surveyId,
              surveyType: survey.surveyType,
              sync: 0,
              isSecondSignature: +isSecondSignature,
            });
            if (emailsSendings) {
              await (!isSecondSignature
                ? sendRecipientsEmails
                : sendRecipientsEmailsSecondSignature)(
                survey.surveyId,
                emailsSendings.emails
              );
              await updateSendingEmails(
                {
                  surveyId: survey.surveyId,
                  surveyType: survey.surveyType,
                  sync: 0,
                  isSecondSignature: +isSecondSignature,
                },
                { sync: 1 }
              );
            }
          } catch (er) {
            dispatch(
              success({
                type: "UPDATE_LOG_1",
                log: formatLog(
                  t("sync.survey.errorSendingEmail", { title: title }),
                  "error",
                  survey.lockId + "SS" + Math.random().toString()
                ),
              })
            );
          }
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("sync.survey.surveyForBuildingUpload", {
                  title: title,
                  buildingNoIntern: survey.buildingNoIntern,
                }),
                "success",
                survey.lockId + uniqueSyncUpId + "up"
              ),
            })
          );

          resolve("success");
        } catch (er) {
          dispatch(
            success({
              type: "UPDATE_LOG_1",
              log: formatLog(
                t("sync.survey.surveyForBuildingUpload", {
                  title: title,
                  buildingNoIntern: survey.buildingNoIntern,
                }),
                "error",
                survey.lockId + uniqueSyncUpId + "up"
              ),
            })
          );
          resolve(er);
        }
      });
    }
    dispatch(syncRelease(canReleaseLock));
  };
}

export const syncsActions = {
  getUsersObjects,
  syncBuildings,
  dailySync,
  assignmentSyncDown,
  surveysSynDown,
  assignmentSyncUp,
  syncRelease,
  releaseLock,
  releaseSurveysLock,
  surveysSyncUp,
};
