import React from "react";
import { connect } from "react-redux";
import {
  Container,
  Grid,
  Typography,
  AppBar,
  TextField,
  Button,
  Toolbar,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  ListItemSecondaryAction,
  FormControlLabel,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Hidden,
} from "@mui/material";
import { withTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { Add, AttachFile, Clear } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import { history, validateFile, formatFileSize, authHeader, nl2br } from "../../_helpers";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  heading: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    cursor: "default !important",
  },
  bold: {
    fontWeight: "bold",
  },
  card: {},
  mainContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0),
    },
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    margin: theme.spacing(5, 0, 6),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  formData: {
    padding: theme.spacing(2),
  },
  fullWidth: {
    width: "100%",
  },
  stickyAppBar: {
    background: "none",
  },
});

class Page extends React.Component {
  state = {
    locationId: null,
    desc: "",
    detailedDesc: "",
    preferredAppointmentTime: "",
    sendEmail: false,
    formSubmittedOnce: false,
    locations: [],
    warningText: "",
    uploading: false,
    files: [],
  };

  componentDidMount() {
    this.GetRepairRequestLocations();
    this.GetRepairRequestAddWarningText();
  }

  GetRepairRequestLocations() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestLocations";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      locations: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ locations: findResponse });
      });
  }

  GetRepairRequestAddWarningText() {
    const url = webApiUrl + "api/RepairRequest/GetRepairRequestAddWarningText";
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    this.setState({
      locations: null,
    });

    fetch(url, requestOptions)
      .then((Response) => Response.json())
      .then((findResponse) => {
        this.setState({ warningText: findResponse.warningText });
      });
  }

  validateForm() {
    const { selected } = this.props;
    const { files, locationId, desc, detailedDesc, preferredAppointmentTime } = this.state;
    this.setState({ formSubmittedOnce: true });
    return selected && this.validateField(desc, true) && this.validateField(detailedDesc, true) && this.validateField(locationId, true) && this.validateField(preferredAppointmentTime, true);
  }

  validateField(field, validateForm = false) {
    return (!validateForm && !this.state.formSubmittedOnce) || (field && field.trim() !== "");
  }

  handleRequest = (event) => {
    const { selected, t, user } = this.props;
    const { files, locationId, desc, detailedDesc, preferredAppointmentTime, sendEmail } = this.state;
    if (this.validateForm()) {
      this.setState({ uploading: true });

      const formData = new FormData();

      formData.append("buildingId", selected.buildingId);
      formData.append("locationId", locationId);
      formData.append("desc", desc);
      formData.append("detailedDesc", detailedDesc);
      formData.append("preferredAppointmentTime", preferredAppointmentTime);
      formData.append("desc", desc);
      formData.append("SendMailToReporter", sendEmail);

      for (var i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      fetch(webApiUrl + "api/repairRequest/AddRepairRequest", {
        method: "POST",
        headers: authHeader(),
        body: formData,
      })
        .then((Response) => Response.json())
        .then((res) => {
          alert(t("repairRequest.create.successInfo"));
          history.push("/nazorg/details/" + res.requestId);
        })
        .catch((e) => {
          this.setState({ uploading: false });
          alert(t("general.api.error"));
        });
    } else {
      event.preventDefault();
    }
  };

  handleChangeTextField = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeSendEmail = () => {
    this.setState({
      sendEmail: !this.state.sendEmail,
    });
  };

  handleSelectFiles = (e) => {
    const selectedFiles = Array.from(e.target.files);
    let files = this.state.files.slice();
    for (var i = 0; i < selectedFiles.length; i++) {
      if (validateFile(selectedFiles[i], true) === true) {
        files.push(selectedFiles[i]);
      }
    }
    this.setState({ files });
  };

  handleRemoveFile = (index) => {
    let files = this.state.files.slice();
    files.splice(index, 1);
    this.setState({ files });
  };

  renderImageSelector() {
    const { t, classes } = this.props;
    const { files, uploading } = this.state;
    return (
      <Grid container item xs={12}>
        <Grid item xs={12}>
          <Typography>{t("general.images")}</Typography>
        </Grid>
        <Grid container item xs={12} alignItems="center">
          <Typography variant="body2" color="textSecondary">
            {t("general.addFile")}
          </Typography>
          <input accept="image/*" style={{ display: "none" }} id="icon-button-file" type="file" multiple onChange={this.handleSelectFiles} />
          <label htmlFor="icon-button-file" style={{ margin: 0 }}>
            {uploading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <IconButton color="inherit" aria-label="upload" component="span" size="large">
                <Add />
              </IconButton>
            )}
          </label>
        </Grid>
        <Grid container item xs={12}>
          {
            <List dense className={classes.grow}>
              {files.map((file, index) => (
                <ListItem key={index}>
                  <ListItemAvatar>
                    <Avatar>
                      <AttachFile />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={file.name} secondary={formatFileSize(file.size)} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="delete" disabled={uploading} onClick={() => this.handleRemoveFile(index)} size="large">
                      <Clear />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          }
        </Grid>
      </Grid>
    );
  }

  render() {
    const { t, classes, selected } = this.props;
    const { locations, uploading, locationId, desc, detailedDesc, preferredAppointmentTime, sendEmail, warningText } = this.state;

    return (
      <Container className={classes.mainContainer}>
        <Grid container>
          <Grid item xs={12} className={classes.container}>
            <AppBar position="sticky" expanded={false}>
              <Toolbar variant="dense">
                <Typography className={classes.bold}>{t("repairRequest.newRepairRequest")}</Typography>
              </Toolbar>
            </AppBar>
            <div className={classes.formData}>
              <Grid container spacing={1} alignItems="flex-start">
                <Grid item xs={12}>
                  <Typography>{t("general.objectAddress")}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {selected && selected.address && selected.address}
                  </Typography>
                </Grid>
                <Grid container spacing={1} item xs={12} md={6}>
                  <Grid item xs={12}>
                    <Typography>{t("general.repairRequest")}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="outlined" size="small" margin="dense" fullWidth disabled={uploading}>
                      <InputLabel id="demo-simple-select-label">{t("general.location")}</InputLabel>
                      <Select error={!this.validateField(locationId)} labelId="demo-simple-select-label" id="demo-simple-select" value={locationId} onChange={this.handleChangeTextField("locationId")} label={t("general.location")}>
                        <MenuItem value="">&nbsp;</MenuItem>
                        {locations &&
                          locations.map((location, index) => (
                            <MenuItem key={index} value={location.id}>
                              {location.name}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  {
                    <Grid item xs={12}>
                      <TextField
                        error={!this.validateField(desc)}
                        label={t("general.description")}
                        className={classes.textField}
                        inputProps={{ maxLength: 60 }}
                        value={desc}
                        onChange={this.handleChangeTextField("desc")}
                        margin="dense"
                        size="small"
                        variant="outlined"
                        fullWidth
                        disabled={uploading}
                      />
                    </Grid>
                  }
                  <Grid item xs={12}>
                    <TextField
                      error={!this.validateField(detailedDesc)}
                      label={t("general.explanation")}
                      className={classes.textField}
                      value={detailedDesc}
                      onChange={this.handleChangeTextField("detailedDesc")}
                      variant="outlined"
                      margin="dense"
                      size="small"
                      rows={2}
                      maxRows={7}
                      multiline
                      fullWidth
                      disabled={uploading}
                    />
                  </Grid>
                  <Hidden mdUp>
                    <Grid item xs={12}>
                      {this.renderImageSelector()}
                    </Grid>
                  </Hidden>
                  <Grid item xs={12}>
                    <TextField
                      error={!this.validateField(preferredAppointmentTime)}
                      label={t("repairRequest.preferredAppointmentTime")}
                      className={classes.textField}
                      inputProps={{ maxLength: 100 }}
                      value={preferredAppointmentTime}
                      onChange={this.handleChangeTextField("preferredAppointmentTime")}
                      margin="dense"
                      size="small"
                      variant="outlined"
                      fullWidth
                      disabled={uploading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={<Checkbox checked={sendEmail} onChange={this.handleChangeSendEmail} value="checkedB" color="primary" size="small" />}
                      label={<Typography variant="body2">{t("repairRequest.create.sendCopyOfRRToEmail.label")}</Typography>}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2">{nl2br(warningText)}</Typography>
                  </Grid>
                  <Grid item xs md={12}>
                    <Button color="primary" variant="outlined" disabled={uploading} fullWidth onClick={this.handleRequest}>
                      {t("general.send")}
                    </Button>
                  </Grid>
                </Grid>
                <Hidden mdDown>
                  <Grid container spacing={1} item xs={12} md={6}>
                    {this.renderImageSelector()}
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected } = buildings;
  return {
    user,
    selected,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as AddNewRepairRequestPage };
