import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Container,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  getRepairRequestStatus,
  getRepairRequests,
} from "../../../apis/aftercareApis";
import {
  getAfterCareRepairRequestColumns,
  getModifiedResponse,
} from "../../../Pages/Aftercare/Grid/util";

import useGridDataModalHandler from "../../../_hooks/useGridDataModalHandler";
import {
  getOrganizations,
  getRepairRequestCarryOutAsTypeList,
  getRepairRequestLocations,
} from "../../../apis/surveyingApis";
import MobileCustomRow from "../../../_hooks/MobileCustomRow";
import DataGridV2 from "../../../components/DataGridV2";
import {
  groupBy,
  history,
  setLocalStorageItem,
  formatDate,
  repairRequestStatusValue,
  getLocalStorageItem,
} from "../../../_helpers";
import CustomToolbar from "./CustomToolbar";
import { LOCAL_STORAGE_GRID_KEYS } from "../../../_constants";
import ColumnsSelectorV2 from "../../../components/ColumnsSelectorV2";
import AddRepairRequest from "../AddRepairRequest";

export const AfterCareRepairRequests = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme()
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [isOpenObjectModal, setIsOpenObjectModal] = useState(null);
  const [locations, setLocations] = useState([]);
  const [optionsDataLoading, setOptionsDataLoading] = useState({
    location: false,
    carryOutAsType: false,
    statusList: false,
  });
  const [carryOutAsTypeList, setCarryOutAsTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [currentView, setCurrentView] = useState("list");
  const [openColumnsSelector, setOpenColumnsSelector] = useState(false);
  const [columnSelectorType, setColumnSelectorType] = useState(null);
  const muiTableRef = useRef();
  const isPageLoad = useRef(true)

  const app = useSelector((state) => state.app);
  const { all: buildings } = useSelector((state) => state.buildings);

  const {
    loading,
    loadedRows,
    sortModel,
    handleSort,
    filterModel,
    handleFilter,
    resetData,
    handleSearch,
    searchText,
    getAllRecords,
    handleCellFilter,
    totalRecords,
    paginationModel,
    onPaginationModelChange
  } = useGridDataModalHandler({
    modifyResponse: getModifiedResponse(buildings, t),
    additionalParams: { app },
    apiCall: getRepairRequests,
    sortModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_SORT, undefined
    ),
    filterModal: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER, undefined
    ),
    paginationModel: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_PAGINATION_MODAL, undefined
    ),
    searchText: getLocalStorageItem(
      LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_SEARCH_TEXT, ""
    ),


  });

  const handleChangeView = useCallback((_, newAlignment) => {
    setCurrentView(newAlignment);
  }, []);

  const projectIds = useMemo(
    () => groupBy(buildings, "projectId") || {},
    [buildings]
  );


  useEffect(() => {
    getFilterOptions();
  }, []);

  useEffect(() => {
    if (Object.keys(projectIds)?.length > 0) {
      getOrganizations(Object.keys(projectIds))
        .then(({ data }) => {
          setOrganizations(
            data.map((singleOrganization) => {
              return {
                label: singleOrganization.name,
                value: singleOrganization.id,
              };
            })
          );
        })
        .catch((error) => {
          console.log({ error });
        });
    }
  }, [projectIds]);

  const getFilterOptions = async () => {
    try {
      setOptionsDataLoading({
        location: true,
        carryOutAsType: true,
        statusList: true,
      });

      const [locationRes, typeListRes, requestStatusRes] = await Promise.all([
        getRepairRequestLocations(),
        getRepairRequestCarryOutAsTypeList(),
        getRepairRequestStatus(),
      ]);

      const locations = locationRes.data.map((x) => {
        return { label: x.name, value: x.id };
      });
      const typeList = typeListRes.data.map((x) => {
        return { label: x.name, value: x.id };
      });
      const status = requestStatusRes.data.map((x) => {
        return { label: x.name, value: x.id };
      });

      setLocations(locations);
      setCarryOutAsTypeList(typeList);
      setStatusList(status);
    } catch (e) {
      console.log(e);
    } finally {
      setOptionsDataLoading({
        location: false,
        carryOutAsType: false,
        statusList: false,
      });
    }
  };

  const listToMapRedirectHandler = useCallback(
    async (_, selectedRequest) => {
      history.push({
        pathname: "/meldingen/map",
        state: {
          selectedRequest,
          requests: loadedRows,
          buildingId: selectedRequest.buildingId,
        },
      });
    },
    [loadedRows]
  );
 
  const columns = useMemo(() => {
    const projectNames = Object.keys(projectIds).map((projectId) => ({
      value: projectId,
      label: projectIds?.[projectId]?.[0]?.projectName,
    })).sort((a, b) =>  (a.label < b.label ? -1 : 1))

    const columns = getAfterCareRepairRequestColumns({
      t,
      listToMapRedirectHandler,
      projectNames,
      locations,
      optionsDataLoading,
      carryOutAsTypeList,
      statusList,
      organizations,
      classes,
      handleCellFilter,
    });

    let filterColumns = columns;

    const localColumnorder = getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_ORDER, [])
    if (localColumnorder.length > 0) {
      const setInorder = localColumnorder.map(columnKey => columns.find(column => column.field === columnKey));
      filterColumns = setInorder.filter(column => column !== undefined);
    }
    const localColumnWidth = getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_WIDTH, [])

    localColumnWidth.forEach((item) => {
      const columnIndex = filterColumns?.findIndex((filterItem) => filterItem.field === item.field)
      if (columnIndex !== -1) {
        filterColumns[columnIndex].width = item.width
        delete filterColumns[columnIndex].minWidth
        delete filterColumns[columnIndex].flex
      }
    })
    return filterColumns;
  }, [projectIds, t, listToMapRedirectHandler, locations, optionsDataLoading, carryOutAsTypeList, statusList, organizations, classes, handleCellFilter]);


  const handleCheckboxChange = useCallback((itemId) => {
    setCheckedItems((prevCheckedItems) => {
      if (prevCheckedItems.includes(itemId)) {
        return prevCheckedItems.filter((id) => id !== itemId);
      }
      return [...prevCheckedItems, itemId];
    });
  }, []);

  const handleTooLateFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const tooLateFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "overdue"
      );
      if (tooLateFilterIndex !== -1 &&  cloneFilterModal?.items[tooLateFilterIndex].value) {
        if(cloneFilterModal?.items[tooLateFilterIndex].value === "true") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "overdue"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        }else{
          cloneFilterModal.items[tooLateFilterIndex].value = "true"
        }
      
      } else {
        cloneFilterModal.items.push({
          field: "overdue",
          operator: "is",
          value: "true",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "overdue",
            operator: "is",
            value: "true",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newFilter
      );
    });
  }, [filterModel , handleFilter]);

  const handleAttentionFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    if (cloneFilterModal && cloneFilterModal.items?.length) {
      const attentionFilterIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "isRequiredHandling"
      );
      if (attentionFilterIndex !== -1 &&  cloneFilterModal?.items[attentionFilterIndex].value) {
        if(cloneFilterModal?.items[attentionFilterIndex].value === "true") {
          const newFilterItems = cloneFilterModal?.items?.filter(
            (item) => item.field !== "isRequiredHandling"
          );
          cloneFilterModal = {
            ...cloneFilterModal,
            items: newFilterItems,
          };
        }else{
          cloneFilterModal.items[attentionFilterIndex].value = "true"
        }
      
      }else {
        cloneFilterModal.items.push({
          field: "isRequiredHandling",
          operator: "is",
          value: "true",
        });
      }
    } else {
      cloneFilterModal = {
        items: [
          {
            field: "isRequiredHandling",
            operator: "is",
            value: "true",
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }

    handleFilter(cloneFilterModal, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newFilter
      );
    });
  }, [filterModel , handleFilter]);

  const handleNewRequestFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    // step1 get new id
    const newStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.NEW
    )?.value;

    // step2 filter is available not undefined
    if (cloneFilterModal && cloneFilterModal.items) {
      //step4 check status is available or not in filter
      const isStatusFilterAwaialableIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "statusId"
      );
      const isStatusFilterAwaialable =
        cloneFilterModal?.items?.[isStatusFilterAwaialableIndex];
      // step5 if available
      if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
        // step7 check new status is available or not
        const isNewStatusPresent = Array.isArray(isStatusFilterAwaialable.value)
          ? isStatusFilterAwaialable.value.includes(newStatusId)
          : isStatusFilterAwaialable.value === newStatusId;

        // step8 if new status available
        if (isNewStatusPresent) {
          // step 10 check value is array or not, if array then check lenght is > 2 then remove from it else filter that status
          if (
            Array.isArray(isStatusFilterAwaialable.value) &&
            isStatusFilterAwaialable.value.length > 1
          ) {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => val !== newStatusId
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          } else {
            // step 11 if stystus length 1 or only value then
            const newFilterItems = cloneFilterModal?.items?.filter(
              (item) => item.field !== "statusId"
            );
            cloneFilterModal = {
              ...cloneFilterModal,
              items: newFilterItems,
            };
          }
        } else {
          // step9 if new status not available
          if (
            Array.isArray(
              cloneFilterModal.items[isStatusFilterAwaialableIndex].value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value.push(
              newStatusId
            );
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "statusId",
              operator: "isAnyOf",
              value: [
                newStatusId,
                cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              ],
            };
          }
        }
      } else {
        // step6 if not available then add that
        if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "statusId",
            operator: "is",
            value: newStatusId,
          };
        } else {
          cloneFilterModal.items.push({
            field: "statusId",
            operator: "is",
            value: newStatusId,
          });
        }
      }
    } else {
      // step3 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "statusId",
            operator: "is",
            value: newStatusId,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newFilter
      );
    });
  }, [filterModel, statusList , handleFilter]);

  const handleInProgressRequestFilter = useCallback(() => {
    let cloneFilterModal = filterModel;
    // step1 get new id
    const inProgressStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.IN_PROGRESS
    )?.value;
    // step2 filter is available not undefined
    if (cloneFilterModal && cloneFilterModal.items) {
      //step4 check status is available or not in filter
      const isStatusFilterAwaialableIndex = cloneFilterModal?.items?.findIndex(
        (item) => item.field === "statusId"
      );
      const isStatusFilterAwaialable =
        cloneFilterModal?.items?.[isStatusFilterAwaialableIndex];
      // step5 if available
      if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
        // step7 check new status is available or not
        const isInProgresstatusPresent = Array.isArray(
          isStatusFilterAwaialable.value
        )
          ? isStatusFilterAwaialable.value.includes(inProgressStatusId)
          : isStatusFilterAwaialable.value === inProgressStatusId;

        // step8 if new status available
        if (isInProgresstatusPresent) {
          // step 10 check value is array or not, if array then check lenght is > 2 then remove from it else filter that status
          if (
            Array.isArray(isStatusFilterAwaialable.value) &&
            isStatusFilterAwaialable.value.length > 1
          ) {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => val !== inProgressStatusId
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          } else {
            // step 11 if stystus length 1 or only value then
            const newFilterItems = cloneFilterModal?.items?.filter(
              (item) => item.field !== "statusId"
            );
            cloneFilterModal = {
              ...cloneFilterModal,
              items: newFilterItems,
            };
          }
        } else {
          // step9 if new status not available
          if (
            Array.isArray(
              cloneFilterModal.items[isStatusFilterAwaialableIndex].value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value.push(
              inProgressStatusId
            );
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "statusId",
              operator: "isAnyOf",
              value: [
                inProgressStatusId,
                cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              ],
            };
          }
        }
      } else {
        // step6 if not available then add that
        if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "statusId",
            operator: "is",
            value: inProgressStatusId,
          };
        } else {
          cloneFilterModal.items.push({
            field: "statusId",
            operator: "is",
            value: inProgressStatusId,
          });
        }
      }
    } else {
      // step3 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "statusId",
            operator: "is",
            value: inProgressStatusId,
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newFilter
      );
    });
  }, [filterModel, statusList , handleFilter]);

  const handleCompletedRequestFilter = useCallback(() => {
    let cloneFilterModal = JSON.parse(JSON.stringify(filterModel));
    // step1 get  id
    const completedStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.COMPLETED
    )?.value;
    const rejectedStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.TURNED_DOWN
    )?.value;
    // step2 filter is available not undefined
    if (filterModel && filterModel.items && filterModel.items.length > 0) {
      //step4 check status is available or not in filter
      const isStatusFilterAwaialableIndex = filterModel?.items?.findIndex(
        (item) => item.field === "statusId"
      );
      const isStatusFilterAwaialable =
        filterModel?.items?.[isStatusFilterAwaialableIndex];
      // step5 if available and its array
      if (
        isStatusFilterAwaialable &&
        isStatusFilterAwaialable.value &&
        Array.isArray(isStatusFilterAwaialable.value)
      ) {
        // step7 check both present in value or not
        if (
          isStatusFilterAwaialable.value.includes(completedStatusId) &&
          isStatusFilterAwaialable.value.includes(rejectedStatusId)
        ) {
          if (isStatusFilterAwaialable.value.length === 2) {
            const newFilterItems = filterModel?.items?.filter(
              (item) => item.field !== "statusId"
            );
            cloneFilterModal = {
              ...filterModel,
              items: newFilterItems,
            };
          } else {
            const values = isStatusFilterAwaialable.value.filter(
              (val) => ![completedStatusId, rejectedStatusId].includes(val)
            );
            cloneFilterModal.items[isStatusFilterAwaialableIndex].value =
              values;
          }
        } else {
          cloneFilterModal.items[isStatusFilterAwaialableIndex].value = [
            ...new Set([
              ...cloneFilterModal.items[isStatusFilterAwaialableIndex].value,
              completedStatusId,
              rejectedStatusId,
            ]),
          ];
        }
      } else {
        // step6 if ststus available and its value also available
        if (isStatusFilterAwaialable && isStatusFilterAwaialable.value) {
          // if value is completed or rejected
          if (
            [completedStatusId, rejectedStatusId].includes(
              isStatusFilterAwaialable.value
            )
          ) {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "statusId",
              operator: "isAnyOf",
              value: [completedStatusId, rejectedStatusId],
            };
          } else {
            cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
              field: "statusId",
              operator: "isAnyOf",
              value: [
                completedStatusId,
                rejectedStatusId,
                isStatusFilterAwaialable.value,
              ],
            };
          }
        } else if (isStatusFilterAwaialable) {
          cloneFilterModal.items[isStatusFilterAwaialableIndex] = {
            field: "statusId",
            operator: "isAnyOf",
            value: [completedStatusId, rejectedStatusId],
          };
        } else {
          cloneFilterModal.items.push({
            field: "statusId",
            operator: "isAnyOf",
            value: [completedStatusId, rejectedStatusId],
          });
        }
      }
    } else {
      // step3 if not present then add it
      cloneFilterModal = {
        items: [
          {
            field: "statusId",
            operator: "isAnyOf",
            value: [rejectedStatusId, completedStatusId],
          },
        ],
        logicOperator: "and",
        quickFilterValues: [],
        quickFilterLogicOperator: "and",
      };
    }
    handleFilter({ ...cloneFilterModal }, (newFilter) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newFilter
      );
    });
  }, [filterModel, statusList , handleFilter]);

  const getSlots = useMemo(() => {
    const isTooLate = filterModel?.items?.find(
      (item) => item.field === "overdue"
    )?.value === "true";

    const newStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.NEW
    )?.value;

    const status = filterModel?.items?.find(
      (item) => item.field === "statusId"
    )?.value;

    const isNewFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(newStatusId)
        : newStatusId === status
      : undefined;

      const isAttentionFilterAvailable =  filterModel?.items?.find(
        (item) => item.field === "isRequiredHandling"
      )?.value === "true";;

    const inProgressStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.IN_PROGRESS
    )?.value;
    const isInProgressFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(inProgressStatusId)
        : inProgressStatusId === status
      : undefined;

    const completedStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.COMPLETED
    )?.value;
    const rejectedStatusId = statusList.find(
      (item) => item.label === repairRequestStatusValue.TURNED_DOWN
    )?.value;
    const isCompletedFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(completedStatusId)
        : completedStatusId === status
      : undefined;
    const isRejectedFilterAvailable = status
      ? Array.isArray(status)
        ? status.includes(rejectedStatusId)
        : rejectedStatusId === status
      : undefined;

    const quickFilterItems = [{
      title: t("repairRequest.status.new"),
      onClick: handleNewRequestFilter,
      isSelected: isNewFilterAvailable,
      iconText: "N"
    },
    {
      title: t("general.attention.label"),
      onClick: handleAttentionFilter,
      isSelected: isAttentionFilterAvailable,
      iconText: "!"
    },
    {
      title: t("general.inProcess"),
      onClick: handleInProgressRequestFilter,
      isSelected: isInProgressFilterAvailable,
      iconClassName: "fa fa-spinner"
    },
    {
      title: t("general.overdue"),
      onClick: handleTooLateFilter,
      isSelected: isTooLate,
      iconClassName: "fa fa-clock-o"
    },
    {
      title: t("repairRequest.quickfilter.complete"),
      onClick: handleCompletedRequestFilter,
      isSelected: isCompletedFilterAvailable && isRejectedFilterAvailable,
      iconClassName: "fa fa-check-circle-o"
    }]

    let slots = {
      toolbar: (props) => (
        <CustomToolbar
          {...props}
          columns={columns}
          handleChangeView={handleChangeView}
          currentView={currentView}
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          handleSearch={(value) => {
            handleSearch(value, (value) => {
              setLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_SEARCH_TEXT, value);
            })
          }}
          setIsOpenObjectModal={setIsOpenObjectModal}
          isOpenObjectModal={isOpenObjectModal}
          searchText={searchText}
          quickFilterItems={quickFilterItems}
        />
      ),
    };
    if (isTabletOrMobile && currentView === "list") {
      slots = {
        ...slots,
        row: (rest) => {
          return (
            <MobileCustomRow
              item={rest.row}
              key={rest.rowId}
              selectedCheckBoxs={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
              listToMapRedirectHandler={listToMapRedirectHandler}
            />
          );
        },
      };
    }
    return slots;
  }, [
    checkedItems,
    filterModel,
    statusList,
    isTabletOrMobile,
    currentView,
    columns,
    handleChangeView,
    handleSearch,
    isOpenObjectModal,
    searchText,
    handleTooLateFilter,
    handleAttentionFilter,
    handleNewRequestFilter,
    handleInProgressRequestFilter,
    handleCompletedRequestFilter,
    handleCheckboxChange,
    listToMapRedirectHandler,
  ]);
  const getRepairRequestsForPrintAndDownload = async () => {
    const columnNames = columns.reduce(
      (p, c) => ({ ...p, [c.field]: c.field }),
      {}
    );

    const {
      data: { results },
    } = await getAllRecords();

    const modifiedData = getModifiedResponse(buildings, t)(results);

    const data = modifiedData?.map((p) => {
      return Object.keys(columnNames).reduce((prev, key) => {
        const obj = { ...prev };
        if (key === "isRequiredHandling") {
          const array = [];
          if (p.isRequiredHandling) {
            array.push({ icon: "PriorityHigh" });
          }
          obj[key] = array;
        } else if (key === "overdue") {
          const array = [];
          if (p.isAfterCareEmployeeOrSiteManager) {
            if (p.overdue) array.push({ icon: "Schedule", color: "error" });
            else if (p.is48HoursReminder)
              array.push({ icon: "Schedule", color: "warning" });
          }
          obj[key] = array;
        } else if (key.toLowerCase().includes("date"))
          obj[key] = formatDate(new Date(p[key]));
        else if (key === "resolvers")
          obj[key] = p["resolvers"]
            ? [...new Set(p["resolvers"].map((a) => a.name))]
            : [];
        else if (key === "projectId") obj[key] = p["projectName"];
        else if (key === "carryOutAsTypeId") obj[key] = p["carryOutAsType"];
        else if (key === "statusId") obj[key] = p["status"];
        else if (key === "locationId") obj[key] = p["location"];
        else obj[key] = p[key];
        return obj;
      }, {});
    });
    return data;
  };

  const initialState = useMemo(
    () => ({
      columns: {
        columnVisibilityModel: {
          ...(getLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_VISIBILITY
            , {})),
        },
      },
    }),
    []
  );


  const filters = useMemo(() => {
    if (![undefined, null, {}, []].includes(filterModel)) {
      return {
        filterModel: filterModel,
      };
    }
    return {};
  }, [filterModel]);


  const localeText = useMemo(() => {
    let val = {};
    if (isTabletOrMobile) {
      val = {
        toolbarColumns: "",
        toolbarFilters: "",
        toolbarDensity: "",
        toolbarExport: "",
      };
    }
    return val;
  }, [isTabletOrMobile]);

  const onFilterModelChange = useCallback((newfilterModalModel) => {
    handleFilter(newfilterModalModel, (newfilterModalModel) => {
      setLocalStorageItem(
        LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
        newfilterModalModel
      );
    })
  }, [handleFilter])

  // First time loading we need to display page loading then grid loading
  useEffect(() => {
    if (!loading) {
      isPageLoad.current = loading
    }
  }, [loading])

  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <DataGridV2
        localeText={localeText}
        rowHeight={isTabletOrMobile ? 80 : 34}
        ref={muiTableRef}
        onColumnOrderChange={(params) => {
          const mapValue = columns.map((column) => column.field);
          const currentOrder = [...mapValue]
          const element = currentOrder.splice(params.oldIndex - 1, 1)[0];
          currentOrder.splice(params.targetIndex - 1, 0, element);
          setLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_ORDER, currentOrder)
        }}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        onColumnWidthChange={(params) => {
          const localColumnWidth = getLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_WIDTH, [])
          const columnIndex = localColumnWidth.findIndex(item => item.columnName === params.colDef.field);
          if (columnIndex !== -1) {
            localColumnWidth[columnIndex].width = params.width;
          } else {
            localColumnWidth.push({ field: params.colDef.field, width: params.width });
          }
          setLocalStorageItem(LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_WIDTH, localColumnWidth);
        }}
        rowSelectionModel={rowSelectionModel}
        checkboxSelection
        columns={columns}
        rows={loadedRows}
        filterMode="server"
        pagination
        rowCount={totalRecords}
        paginationModel={paginationModel}
        onPaginationModelChange={(paginationModal) =>
          onPaginationModelChange(paginationModal, (paginationModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_PAGINATION_MODAL,
              paginationModal
            );
          })}
        paginationMode="server"
        loading={loading}
        sortModel={sortModel}
        onSortModelChange={(newSortModel) =>
          handleSort(newSortModel, (newSortModal) => {
            setLocalStorageItem(
              LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_SORT,
              newSortModal
            );
          })
        }
        onFilterModelChange={onFilterModelChange}
        initialState={initialState}
        onColumnVisibilityModelChange={(newModel) =>
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_VISIBILITY,
            newModel
          )
        }
        currentView={currentView}
        slots={getSlots}
        {...filters}
      />
      <AddRepairRequest
        repairRequestCreateCallBack={resetData}
        isOpenObjectModal={isOpenObjectModal}
        setIsOpenObjectModal={(value) => setIsOpenObjectModal(value)}
      />
      {openColumnsSelector && (
        <ColumnsSelectorV2
          anchorEl={openColumnsSelector}
          title={t("general.repairRequests")}
          getData={getRepairRequestsForPrintAndDownload}
          onClose={() => {
            setColumnSelectorType(null);
            setOpenColumnsSelector(null);
          }}
          type={columnSelectorType}
          muiTableRef={muiTableRef}
          columns={columns}
          rowsSelected={rowSelectionModel}
        />
      )}
    </Container>
  );
}

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "hidden ",
    padding: 0,
  },
}));

export default AfterCareRepairRequests