import React, { useEffect, useState } from "react";
import { CircularProgress, Container, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import { getDataTableTextLabels, getLocalStorageItem, groupBy, setLocalStorageItem } from "../../_helpers";
import { useTranslation } from "react-i18next";
import MUIDataTable from "mui-datatables";
import makeStyles from "@mui/styles/makeStyles";
import { useSelector } from "react-redux";
import { getProjectsInfoForOverview } from "../../apis/buyersGuideApis";
import { ArrowBack, BookmarksOutlined, ChatOutlined, ForumOutlined } from "@mui/icons-material";

const ProjectOverview = (props) => {
  const { history } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [projects, setProjects] = useState([]);
  const [canGoBack, setCanGoBack] = useState(false);
  const [loading, setLoading] = useState(false);
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();
  const { all = [], loading: buildingLoading } = useSelector((state) => state.buildings);
  const { messageCountPerProject, savedMessagesCountPerProject } = useSelector((state) => state.dashboardCount);
  const app = useSelector((state) => state.app);
  const [sortOrder, setSortOrder] = useState({
    name: "projectNo",
    direction: "desc",
  });
  const [defaultFilters, setDefaultFilters] = useState([...Array(8)].map((a) => []));
  let [columnsVisibility, setColumnsVisibility] = useState([
    { name: "projectNo", visible: true },
    { name: "projectName", visible: true },
    { name: "place", visible: true },
    { name: "projectType", visible: true },
    { name: "projectConstructionType", visible: true },
    { name: "projectPhase", visible: true },
    { name: "noOfUnreadChats", visible: true },
    { name: "noOfUnreadMessages", visible: true },
    { name: "noOfSavedMessages", visible: true },
  ]);

  useEffect(() => {
    setProjects([]);
    setLoading(true);
    const projectsGroup = groupBy(all, "projectId");
    const projectIds = Object.keys(projectsGroup);

    if (history.location.state && history.location.state.filter) {
      let filter = Object.assign([], defaultFilters);
      if (history.location.state.filter) {
        filter = Object.assign(filter, {
          [2]: [history.location.state.filter],
        });
      }
      setCanGoBack(history.location.state.filter);
      const state = { ...history.location.state };
      delete state.filter;
      history.replace({ ...history.location, state });
      setDefaultFilters(filter);
      setLocalStorageItem(`BuyersGuide_Project_Overview_Filters`, filter);
    } else {
      const localFilters = getLocalStorageItem("BuyersGuide_Project_Overview_Filters");
      if (!localFilters) setLocalStorageItem("BuyersGuide_Project_Overview_Filters", defaultFilters);
      else setDefaultFilters(localFilters);
    }
    if (!buildingLoading) {
      getProjectsInfoForOverview(projectIds).then(({ data: response }) => {
        const data = [];
        for (let i = 0; i < projectIds.length; i++) {
          const projectId = projectIds[i];
          const project = response.find((p) => p.projectId === projectId);
          if (project) {
            data.push({
              ...project,
              noOfUnreadChats: getNoOfUnreadChats(projectId),
              noOfUnreadMessages: getNoOfUnreadMessages(projectId),
              noOfSavedMessages: getNoOfSavedMessages(projectId),
            });
          }
        }
        setProjects(data);
        setLoading(false);
      });
    }
    return () => {
      setProjects([]);
    };
  }, [history.location.key, all, app, buildingLoading]);

  useEffect(() => {
    setProjects(
      projects.map((p) => ({
        ...p,
        noOfUnreadChats: getNoOfUnreadChats(p.projectId),
        noOfUnreadMessages: getNoOfUnreadMessages(p.projectId),
        noOfSavedMessages: getNoOfSavedMessages(p.projectId),
      }))
    );
  }, [messageCountPerProject, savedMessagesCountPerProject]);

  useEffect(() => {
    if (!getLocalStorageItem("BuyersGuide_Project_Overview_ColumnsVisibility")) {
      setLocalStorageItem(`BuyersGuide_Project_Overview_ColumnsVisibility`, columnsVisibility);
    } else {
      setColumnsVisibility(getLocalStorageItem("BuyersGuide_Project_Overview_ColumnsVisibility"));
    }

    var rowsPerPage = getLocalStorageItem("BuyersGuide_Project_Overview_RowsPerPage");
    if (!!rowsPerPage && rowsPerPage > 0) {
      setRowsPerPage(rowsPerPage);
    }
  }, []);

  const getNoOfUnreadChats = (projectId) => {
    if (messageCountPerProject) {
      const object = messageCountPerProject.find((x) => x.projectId.toUpperCase() === projectId.toUpperCase());
      return object ? object.noOfChats : 0;
    }
    return 0;
  };

  const getNoOfUnreadMessages = (projectId) => {
    if (messageCountPerProject) {
      const object = messageCountPerProject.find((x) => x.projectId.toUpperCase() === projectId.toUpperCase());
      return object ? object.count : 0;
    }
    return 0;
  };

  const getNoOfSavedMessages = (projectId) => {
    if (savedMessagesCountPerProject) {
      const object = savedMessagesCountPerProject.find((x) => x.projectId.toUpperCase() === projectId.toUpperCase());
      return object ? object.count : 0;
    }
    return 0;
  };

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  const columns = [
    {
      name: "projectId",
      options: {
        display: "excluded",
        filter: false,
        download: false,
        print: false,
      },
    },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
      options: {
        display: isColumnVisible("projectNo"),
        filter: false,
      },
    },
    {
      name: "projectName",
      label: t("general.projectName"),
      options: {
        filterList: defaultFilters ? defaultFilters[2] : [],
        display: isColumnVisible("projectName"),
        filter: true,
      },
    },
    {
      name: "place",
      label: t("general.address.city"),
      options: {
        display: isColumnVisible("place"),
        filterList: defaultFilters ? defaultFilters[3] : [],
        filter: true,
      },
    },
    {
      name: "projectType",
      label: t("general.project.type"),
      options: {
        display: isColumnVisible("projectType"),
        filter: false,
      },
    },
    {
      name: "projectConstructionType",
      label: t("general.project.constructionType"),
      options: {
        display: isColumnVisible("projectConstructionType"),
        filter: false,
      },
    },
    {
      name: "projectPhase",
      label: t("general.phase"),
      options: {
        display: isColumnVisible("projectPhase"),
        filter: false,
      },
    },
    {
      name: "noOfUnreadChats",
      label: t("chat.chats"),
      options: {
        display: isColumnVisible("noOfUnreadChats"),
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return <ForumOutlined fontSize="small" />;
        },
      },
    },
    {
      name: "noOfUnreadMessages",
      label: t("chat.messages"),
      options: {
        display: isColumnVisible("noOfUnreadMessages"),
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return <ChatOutlined fontSize="small" />;
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return value > 0 ? <span className={classes.greenText}>{value}</span> : 0;
        },
      },
    },
    {
      name: "noOfSavedMessages",
      label: t("chat.importantMessages"),
      options: {
        display: isColumnVisible("noOfSavedMessages"),
        filter: false,
        customHeadLabelRender: (columnMeta) => {
          return <BookmarksOutlined fontSize="small" />;
        },
      },
    },
  ];

  let columnOrder = [];
  columnOrder =
    getLocalStorageItem("BuyersGuide_Project_Overview_ColumnOrder") ||
    columns.forEach((column, index) => {
      columnOrder.push(index);
    });

  const options = {
    draggableColumns: { enabled: true },
    filterType: "dropdown",
    responsive: "vertical",
    rowsPerPageOptions: [25, 50, 100],
    rowsPerPage: rowsPerPage,
    labelRowsPerPage: t("general.rowsPerPage"),
    download: !isExtraSmallScreen,
    print: !isExtraSmallScreen,
    columnOrder,
    sortOrder,
    onColumnSortChange: (name, direction) => {
      setSortOrder({ name, direction });
    },
    onCellClick: (cellData, { colIndex, dataIndex }, _metaData) => {
      const columnName = columns[colIndex].name;
      const selectedProject = all.filter((x) => x.projectId === projects[dataIndex].projectId)[0];

      //we can use the below part for doing a different action for a particular column
      //if (columnName === "column_name") {
      //
      //}

      let url = "/werk/" + selectedProject.projectNo;
      history.push({ pathname: url });
    },
    customToolbarSelect: (selectedRows) => <></>,
    onColumnOrderChange: (newColumnOrder, columnIndex, newPosition) => {
      setLocalStorageItem(`BuyersGuide_Project_Overview_ColumnOrder`, newColumnOrder);
    },
    onFilterChange: (identifier, list) => {
      setDefaultFilters(list);
      setLocalStorageItem(`BuyersGuide_Project_Overview_Filters`, list);
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action == "add") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(`BuyersGuide_Project_Overview_ColumnsVisibility`, listToUpdate);
      } else if (action == "remove") {
        var listToUpdate = columnsVisibility;

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(`BuyersGuide_Project_Overview_ColumnsVisibility`, listToUpdate);
      }
    },
    textLabels: getDataTableTextLabels(t, loading),
    onChangeRowsPerPage: (rowsPerPage) => {
      setRowsPerPage(rowsPerPage);
    },
  };

  const handleGoBack = () => {
    const filters = [...Array(11)].map((a) => []);
    setDefaultFilters(filters);
    setLocalStorageItem(`BuyersGuide_Project_Overview_Filters`, filters);
    history.goBack();
  };

  if (buildingLoading)
    return (
      <div className={classes.loader}>
        <CircularProgress size={25} />
      </div>
    );
  return (
    <Container maxWidth={false} className={classes.mainContainer}>
      <MUIDataTable
        className={classes.dataTable}
        title={
          <div className={classes.headerTitleContainer}>
            {canGoBack && (
              <IconButton aria-label="GoBack" color="inherit" onClick={handleGoBack} size="large">
                <ArrowBack />
              </IconButton>
            )}
            <div className={classes.headerTitleSubContainer}>
              <Typography style={{ paddingLeft: canGoBack ? 0 : 24 }} className={classes.headerTitle} variant="h6">
                {t("general.projects")}
              </Typography>
            </div>
          </div>
        }
        data={projects}
        columns={columns}
        options={options}
      />
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "visible",
    padding: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      paddingLeft: theme.spacing(0),
      "& > div": {
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTableCell-root": {
      "& .MuiButton-root > span div ": {
        textAlign: "start",
      },
      padding: theme.spacing(0, 0.5, 0, 0),
      cursor: "pointer",
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "left",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
      },
    },
  },
  grow: {
    flexGrow: 1,
  },
  fullWidth: {
    width: "100%",
  },
  headerTitleContainer: {
    display: "flex",
    alignItems: "center",
  },
  headerTitleSubContainer: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },
  headerTitle: {
    color: theme.palette.grey[800],
    fontWeight: 500,
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    whiteSpace: "nowrap",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  greenText: {
    color: theme.palette.success.main,
  },
}));

export { ProjectOverview };
