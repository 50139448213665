import React from "react";
import { connect } from "react-redux";
import { Container, Grid, Typography, AppBar, Toolbar, IconButton, MenuItem, Modal, Fade, Card, Backdrop, CardMedia, Button, TextField, FormControlLabel, Checkbox, Divider, Popover, Menu, Snackbar, Tooltip, Hidden } from "@mui/material";
import { withTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { ArrowBack, Clear, Close, Build, Home, Contacts, Assignment, Schedule, Share, FileCopyOutlined, LocationOn } from "@mui/icons-material";
import { withTranslation } from "react-i18next";
import clsx from "clsx";
import { history, nl2br, formatDate, authHeader, validateFile, formatTime, getMimeTypefromString } from "../../_helpers";
import ContactCard from "./RepairRequestContactCard";
import { Alert } from "@mui/material";
import Dropzone from "../../components/Dropzone";
import WorkOrderPDF from "./WorkOrderPDF";
import { URLS } from "../../apis/urls";
import RepairRequestForm from "../Survey/RepairRequestForm";
import DocumentViewer from "../../components/DocumentViewer";
import { commonActions } from "../../_actions/common.actions";

const { webApiUrl } = window.appConfig;

const styles = (theme) => ({
  appBar: {},
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    backgroundColor: theme.palette.background.paper,
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    padding: theme.spacing(2, 3, 4),
  },
  block: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1, 1, 3),
    "&.collapsed": {
      paddingBottom: theme.spacing(1),
    },
  },
  subHeader: {
    padding: theme.spacing(2),
    "& svg": {
      fontSize: 30,
    },
  },
  pdfButtonTitle: {
    display: "flex",
    alignItems: "center",
  },
  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  locationPreview: {
    maxWidth: "100%",
    // maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
    objectFit: "contain",
  },
  locationContainer: {
    maxWidth: "100%",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoGridRow: {
    "& > div": {
      padding: theme.spacing(0.5, 2),
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "& .MuiInputLabel-outlined": {
      whiteSpace: "nowrap",
      maxWidth: "100%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  imageGallery: {
    width: "100%",
    maxHeight: "15vw",
    height: "calc(100% + 4px)",
    overflowX: "hidden",
    overflowY: "auto",
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("xl")]: {
      maxHeight: "19vw",
    },
    [theme.breakpoints.down("lg")]: {
      maxHeight: "28vw",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "56vw",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "85vw",
    },
  },
  dropzoneContainer: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    margin: "-2px auto -4px",
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  galleryImageTile: {
    width: "25%",
    float: "left",
    padding: theme.spacing(0.5),
    position: "relative",

    "& > button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    "& > div": {
      width: "100%",
      padding: "50% 0px",
      height: 0,
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    [theme.breakpoints.down("xl")]: {
      width: "33.33%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
  },
  halfWidth: {
    width: "50%",
    verticalAlign: "top",
  },
  button: {
    "&:hover": {
      color: theme.palette.primary.contrastText,
    },
  },
  textField: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      width: 350,
      maxWidth: "100%",
    },
  },
  thumbnail: {
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    "&.big": {
      width: "100%",
      height: 0,
      padding: "50%",
      cursor: "pointer",
      //borderRadius: theme.spacing(1)
    },
  },
  thumbnailOrg: {
    width: "calc(100% - 16px)",
    margin: theme.spacing(-1, 0),
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left center",
    backgroundSize: "contain",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    //overflow: 'hidden',
    background: "none",
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0, 0, 0, 2),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: 0,
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      "& caption": {
        display: "none",
      },
      //marginBottom: theme.spacing(0.5)
    },
    "& .MuiTableCell-root": {
      padding: theme.spacing(0, 0.5, 0, 0),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "bold",
        backgroundColor: theme.palette.grey[100],
      },
    },
  },
  formControlCheckList: {
    paddingLeft: theme.spacing(0.5),
    "& .MuiTypography-body1": {
      lineHeight: 1,
    },
  },
});

class Page extends React.Component {
  state = {
    attachmentIndex: null,
    workOrder: null,
    building: null,
    shareAnchorEl: null,
    openAlert: false,
    copiedMessage: "",
    openRepairRequestMap: false,
    document:null
  };

  componentDidMount() {
    this.GetWorkOrderDetails();
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.selected || prevProps.selected.buildingId.toUpperCase() !== this.props.selected.buildingId.toUpperCase()) {
      //this.GetWorkOrderDetails();
    }
    if ((!prevState.workOrder && !!this.state.workOrder) || (!!prevState.workOrder && !!this.state.workOrder && prevState.workOrder.resolverId !== this.state.workOrder.resolverId)) {
      if (this.props.selected && this.props.selected.buildingId.toUpperCase() !== this.state.workOrder.buildingId.toUpperCase()) {
        var selectedItem = this.props.allBuildings.find((x) => x.buildingId.toUpperCase() === this.state.workOrder.buildingId.toUpperCase());
        if (selectedItem && selectedItem.projectId !== this.props.selected.projectId) {
          this.props.dispatch(commonActions.selectBuilding(selectedItem));
        } else {
          this.setState({ building: selectedItem || this.props.selected });
        }
      } else {
        this.setState({ building: this.props.selected });
      }
    }
  }

  GetWorkOrderDetails(update = false) {
    const { resolverId } = this.props.match.params;
    if (resolverId) {
      const url = webApiUrl + "api/RepairRequest/GetWorkOrderDetails/" + encodeURI(resolverId);
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };

      if (update === false) {
        this.setState({
          workOrder: null,
        });
      }

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((workOrder) => {
          if (!!workOrder.resolverId) {
            workOrder.completed = workOrder.status === 3 || workOrder.status === 4;
            this.setState({
              workOrder,
              isTemporary: workOrder.status == 0 && !workOrder.workOrderNumber,
              completeOrRejectionText: workOrder.status === 3 ? workOrder.rejectionText : workOrder.status === 4 ? workOrder.solutionText : this.state.completeOrRejectionText,
            });
            this.updateWorkOrderAsInformed();
          }
        });
    }
  }

  updateWorkOrderAsInformed() {
    const { t } = this.props;
    const { workOrder } = this.state;

    if (!!workOrder && workOrder.status === 0) {
      this.setState({ updating: "status" });

      const url = webApiUrl + `api/RepairRequest/UpdateResolverStatusAsInformed/` + encodeURI(workOrder.resolverId);
      const requestOptions = {
        method: "POST",
        headers: authHeader(),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((success) => {
          if (success) {
            this.setState({ updating: null });
            this.GetWorkOrderDetails(true);
          } else {
            this.setState({ updating: null });
          }
        })
        .catch((e) => {
          this.setState({ updating: null });
          alert(t("general.api.error"));
        });
    }
  }

  initiateHandle = (e, handleType) => {
    if (["complete", "reject"].includes(handleType)) {
      const { workOrder, isTemporary, completeOrRejectionText } = this.state;
      if (workOrder && isTemporary !== true && workOrder.completed !== true) {
        if (completeOrRejectionText && completeOrRejectionText.trim() !== "") {
          this.setState({
            anchorElHandleWorkOrder: e.currentTarget,
            handleType,
          });
        } else {
          this.setState({ completeOrRejectionTextError: true });
        }
      }
    }
  };

  handleWorkOrder = () => {
    const { t, selected } = this.props;
    const { workOrder, isTemporary, handleType, completeOrRejectionText } = this.state;
    if (workOrder && isTemporary !== true && workOrder.completed !== true) {
      if (completeOrRejectionText && completeOrRejectionText.trim() !== "" && ["complete", "reject"].includes(handleType)) {
        this.setState({ handling: true });

        let notification = this.createModelForEmailNotify();
        let isComplete = false;
        let isCompleteRepairRequest = false;
        switch (handleType) {
          case "complete":
            isComplete = true;
            break;
          case "reject":
            isComplete = false;
            break;
          default:
            break;
        }

        const model = {
          resolverId: workOrder.resolverId,
          completeOrRejectionText,
          isComplete,
          continuedWorkOrder: false,
          isCompleteRepairRequest,
          notification,
        };

        const url = webApiUrl + `api/RepairRequest/UpdateWorkOrderStatus`;
        const requestOptions = {
          method: "POST",
          headers: authHeader("application/json"),
          body: JSON.stringify(model),
        };

        fetch(url, requestOptions)
          .then((Response) => Response.json())
          .then((success) => {
            if (success) {
              this.setState({ handling: false });
              history.push("/werk/" + selected.projectNo + "/werkbonnen");
            } else {
              this.setState({ handling: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ handling: false });
            alert(t("general.api.error"));
          });
      } else {
        this.setState({ showCreateWorkOrderErrors: true });
      }
    }
  };

  createModelForEmailNotify = () => {
    const { isNotify } = this.state;
    const model = {
      isNotify: isNotify === true,
    };

    return model;
  };

  uploadAttachment = (files) => {
    let filesToUpload = [];
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i], true) === true) {
        filesToUpload.push(files[i]);
      }
    }

    if (filesToUpload.length > 0) {
      const { t } = this.props;
      const { workOrder } = this.state;
      this.setState({
        uploading: true,
      });

      if (workOrder && workOrder.completed !== true) {
        const formData = new FormData();

        for (var i = 0; i < filesToUpload.length; i++) {
          formData.append("files", filesToUpload[i]);
        }

        const url = webApiUrl + `api/RepairRequest/UploadWorkOrderImages/` + encodeURI(workOrder.resolverId);
        fetch(url, {
          method: "POST",
          headers: authHeader(),
          body: formData,
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success === true) {
              this.setState({
                uploading: false,
              });
              this.GetWorkOrderDetails(true);
            } else {
              this.setState({ uploading: false });
              alert(t("general.api.error"));
            }
          })
          .catch((e) => {
            this.setState({ uploading: false });
            alert(t("general.api.error"));
          });
      }
    }
  };

  handleRemoveAttachFile(attachmentId) {
    const { t } = this.props;
    const { workOrder } = this.state;
    if (workOrder && workOrder.completed !== true) {
      const url = webApiUrl + `api/RepairRequest/DeleteRepairRequestAttachment/${workOrder.repairRequestId}/${attachmentId}`;
      const requestOptions = {
        method: "DELETE",
        headers: authHeader("application/json"),
      };

      fetch(url, requestOptions)
        .then((Response) => Response.json())
        .then((res) => {
          if (res.success === true) {
            this.GetWorkOrderDetails(true);
          } else {
            alert(t("general.api.error"));
          }
        })
        .catch((e) => {
          alert(t("general.api.error"));
        });
    }
  }

  handleImageModalOpen = (index) => {
    const { workOrder } = this.state;

    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder.repairRequestImages[index] ? workOrder.repairRequestImages[index].attachmentId : ""),
          fileType: getMimeTypefromString(".jpg"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };

  handleImageModalClose = () => {
    this.setState({ attachmentIndex: null, document: null, resolverAttachmentIndex: null });
  };

  handleImageModal2Open = (index) => {
    const { workOrder } = this.state;
    this.setState({
      attachmentIndex: index,
      document: [
        {
          uri: URLS.GET_ATTACHMENT + encodeURI(workOrder.solutionImages[index] ? workOrder.solutionImages[index].attachmentId : ""),
          fileType: getMimeTypefromString(".jpg"), //TODO: use mimetype from server after api changes
          name: "",
        },
      ],
    });
  };

  handleShare(e) {
    this.setState({
      shareAnchorEl: e.currentTarget,
    });
  }

  blockGeneral() {
    const { t, classes, location } = this.props;
    const { workOrder, isTemporary } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={clsx(classes.subHeader, classes.pdfButtonTitle)}>
          <Build color="primary" /> &nbsp;
          <div className={classes.grow}>
            {t("general.workOrder")}
            &nbsp;
            {workOrder && (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + "-##")}
          </div>
          <WorkOrderPDF resolverId={workOrder && workOrder.resolverId} />
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.resolver") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.name && workOrder.name}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.repairRequest") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.repairRequestNo && workOrder.repairRequestNo}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.relation") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.relationName}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.description") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.description && workOrder.description}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder && workOrder.previousWorkOrderDetails && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.workOrderOld") + ": "}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.previousWorkOrderDetails.workOrderNumber}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.oldDateOfCompletion") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.previousWorkOrderDetails.handledDate && formatDate(new Date(workOrder.previousWorkOrderDetails.handledDate))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.solutionOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.previousWorkOrderDetails.solution)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.explanationOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.previousWorkOrderDetails.explantion)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.workOrderTextOld") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {nl2br(workOrder.prevWorkOrderText)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.workOrderText") + ":"}
              </Grid>
              <Grid item xs={6}>
                {nl2br(workOrder.workOrderText)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.explanation") + ":"}
              </Grid>
              <Grid item xs={6}>
                {nl2br(workOrder.explanation)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.type") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.carryOutAsType}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.repairRequestDate") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.repairRequestDate && formatDate(new Date(workOrder.repairRequestDate))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.dateNotified") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.dateNotified && formatDate(new Date(workOrder.dateNotified))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("repairRequest.targetCompletionDate.label") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.targetCompletionDate && formatDate(new Date(workOrder.targetCompletionDate))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={12}>
                {t("repairRequests.repairRequestImages") + ":"}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={12}>
                {workOrder.repairRequestImages && workOrder.repairRequestImages.length > 0 && (
                  <div className={classes.imageGallery}>
                    <Grid container spacing={1}>
                      {workOrder.repairRequestImages.map((attachment, index) => (
                        <Grid key={index} item xs={6} sm={4} xl={3}>
                          <div
                            key={index}
                            class={clsx(classes.thumbnail, "big")}
                            onClick={() => this.handleImageModalOpen(index)}
                            style={{
                              backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${attachment.attachmentId})`,
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </div>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder && workOrder.drawingPinLocation && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("general.location") + ":"}
                  </Grid>
                  <Grid item xs={6} className={classes.locationContainer}>
                    <img
                      alt="map"
                      src={`${webApiUrl}api/Dossier/GetDossierPdfImage/${workOrder.drawingPinLocation.dossierFileId}?page=${workOrder.drawingPinLocation.pageNumber}`}
                      className={classes.locationPreview}
                      onClick={() => this.setState({ openRepairRequestMap: true })}
                    />
                    <LocationOn
                      color="primary"
                      className={classes.locationMarkPreview}
                      style={{
                        left: `calc(${workOrder.drawingPinLocation.xrel} * 100% - 12px)`,
                        top: `calc(${workOrder.drawingPinLocation.yrel} * 100% - 22px)`,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockStatus() {
    const { t, classes } = this.props;
    const { workOrder, terms } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Schedule color="primary" /> &nbsp;
          {t("general.status")}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("general.status") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.status >= 0 && t("resolver.status." + workOrder.status)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container className={classes.infoGridRow}>
              <Grid item xs={6}>
                {t("workOrders.completedOn") + ":"}
              </Grid>
              <Grid item xs={6}>
                {workOrder.dateHandled && formatDate(new Date(workOrder.dateHandled))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {workOrder.isHandled === true && (
            <>
              {" "}
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedBy") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledBy}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}

          {workOrder.isHandled === true && (
            <>
              {" "}
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={6}>
                    {t("workOrders.verifiedOn") + ":"}
                  </Grid>
                  <Grid item xs={6}>
                    {workOrder.handledOn && formatDate(new Date(workOrder.handledOn)) + " " + formatTime(new Date(workOrder.handledOn))}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockObjectInfo() {
    const { t, classes } = this.props;
    const { workOrder, building } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Home color="primary" /> &nbsp;
          {t("general.objectInformation")}
        </Typography>
        {
          <Grid container>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.streetAndHouseNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address &&
                    (workOrder.address.street ? workOrder.address.street : "") + " " + (workOrder.address.houseNo ? workOrder.address.houseNo : "") + (workOrder.address.houseNoAddition ? " " + workOrder.address.houseNoAddition : "")}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.address.postcodeAndCity") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.address && (
                    <>
                      {workOrder.address.postcode ? workOrder.address.postcode + " " : ""}
                      &nbsp;
                      {workOrder.address.place ? workOrder.address.place : ""}
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.buildingNumber") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {building && building.buildingNoIntern}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.deliveryDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.deliveryDate && formatDate(new Date(workOrder.deliveryDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("repairRequests.secondSignatureDate") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.secondSignatureDate && formatDate(new Date(workOrder.secondSignatureDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("general.endOfMaintenancePeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.maintenanceEndDate && formatDate(new Date(workOrder.maintenanceEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Grid container className={classes.infoGridRow}>
                <Grid item xs={6}>
                  {t("workOrders.endOfWarrantyPeriod") + ":"}
                </Grid>
                <Grid item xs={6}>
                  {workOrder.warrantyEndDate && formatDate(new Date(workOrder.warrantyEndDate))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        }
      </div>
    );
  }

  blockWorkOrder() {
    const { t, classes } = this.props;
    const { workOrder, isTemporary, uploading, showCreateWorkOrderErrors } = this.state;
    const completionRejectionLabel = workOrder.status == 3 ? t("workOrders.reasonOfRejection") : workOrder.status == 4 ? t("workOrders.solution") : t("workOrders.workPerformed");

    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Assignment color="primary" /> &nbsp;
          {t("general.additionalInformation")}
        </Typography>
        <Grid container>
          {isTemporary !== true && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <TextField
                      label={completionRejectionLabel}
                      value={this.state.completeOrRejectionText}
                      onChange={(e) =>
                        this.setState({
                          completeOrRejectionText: e.target.value,
                        })
                      }
                      margin="dense"
                      variant="outlined"
                      multiline
                      rows={10}
                      required
                      error={this.state.completeOrRejectionTextError === true && (!this.state.completeOrRejectionText || this.state.completeOrRejectionText.trim() === "")}
                      fullWidth
                      autoFocus
                      disabled={workOrder.completed === true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
          {(!!workOrder.workOrderNumber || (workOrder.solutionImages && workOrder.solutionImages.length > 0)) && (
            <>
              <Grid item xs={12}>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    {t("general.images") + ":"}
                  </Grid>
                </Grid>
                <Grid container className={classes.infoGridRow}>
                  <Grid item xs={12}>
                    <div className={classes.imageGallery}>
                      {workOrder.completed !== true && (
                        <div className={classes.dropzoneContainer}>
                          <Dropzone onFilesAdded={this.uploadAttachment} disabled={workOrder.completed === true} uploading={uploading} accept="image/*" />
                        </div>
                      )}
                      {workOrder.solutionImages &&
                        workOrder.solutionImages.length > 0 &&
                        workOrder.solutionImages.map((file, index) => (
                          <div className={classes.galleryImageTile}>
                            <div
                              onClick={() => this.handleImageModal2Open(index)}
                              style={{
                                backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${file.attachmentId})`,
                              }}
                            ></div>
                            {workOrder.completed !== true && (
                              <Tooltip title={t("general.remove")}>
                                <IconButton aria-label="delete" size="small" disabled={uploading} onClick={() => this.handleRemoveAttachFile(file.attachmentId)}>
                                  <Clear />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </>
          )}
        </Grid>
      </div>
    );
  }

  blockContactInfo() {
    const { t, classes } = this.props;
    const { workOrder } = this.state;
    return (
      <div className={classes.block}>
        <Typography component="h2" variant="h6" className={classes.subHeader}>
          <Contacts color="primary" /> &nbsp;
          {t("general.contactDetails")}
        </Typography>
        <Grid container spacing={1}>
          {workOrder && workOrder.contactInfo && (
            <>
              {workOrder.contactInfo.buyer && (
                <>
                  {workOrder.contactInfo.buyer.type === 0 && (
                    <>
                      <Grid item xs={12}>
                        <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p1} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer1")} />
                      </Grid>
                      {workOrder.contactInfo.buyer.p2 && (
                        <Grid item xs={12}>
                          <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.p2} pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyer2")} />
                        </Grid>
                      )}
                    </>
                  )}
                  {workOrder.contactInfo.buyer.type === 1 && (
                    <Grid item xs={12}>
                      <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.buyer.org} isOrg pointOfContactType={0} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.buyerOrganization")} />
                    </Grid>
                  )}
                </>
              )}
              {workOrder.contactInfo.client && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.client} isOrg pointOfContactType={1} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("repairRequest.client")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvE && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvE} isOrg pointOfContactType={2} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvE")} />
                </Grid>
              )}
              {workOrder.contactInfo.vvEAdministrator && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.vvEAdministrator} isOrg pointOfContactType={3} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.vvEAdministrator")} />
                </Grid>
              )}
              {workOrder.contactInfo.propertyManagers &&
                workOrder.contactInfo.propertyManagers.map((propertyManager, index) => (
                  <Grid key={index} item xs={12}>
                    <ContactCard hidePointOfContactCheckbox={true} object={propertyManager} isOrg pointOfContactType={4} selectedPointOfContactType={index === 0 && workOrder.pointOfContact} subTitle={t("general.propertyManager")} />
                  </Grid>
                ))}
              {workOrder.contactInfo.employee && (
                <Grid item xs={12}>
                  <ContactCard hidePointOfContactCheckbox={true} object={workOrder.contactInfo.employee} isOrg pointOfContactType={5} selectedPointOfContactType={workOrder.pointOfContact} subTitle={t("general.employee")} />
                </Grid>
              )}
            </>
          )}
        </Grid>
      </div>
    );
  }

  render() {
    const { t, classes } = this.props;
    const { workOrder, attachmentIndex, openAlert, copiedMessage, shareAnchorEl, isTemporary, isNotify } = this.state;

    return (
      <Container maxWidth={false} className={classes.mainContainer}>
        <Grid container>
          <Grid item container xs={12} className={classes.container}>
            <AppBar position="sticky" color="inherit" className={classes.appBar}>
              <Toolbar variant="dense">
                <IconButton
                  color="inherit"
                  edge="start"
                  aria-label="go back"
                  component="span"
                  onClick={() => {
                    history.goBack();
                  }}
                  size="large"
                >
                  <ArrowBack />
                </IconButton>
                <Typography className={clsx(classes.bold, classes.grow)} noWrap>
                  {t("general.workOrder") + " " + (workOrder ? (workOrder.workOrderNumber ? workOrder.workOrderNumber : workOrder.repairRequestNo + "-##") : "")}
                </Typography>
                {workOrder && !!workOrder.workOrderNumber && (
                  <>
                    <Tooltip title={t("general.share")}>
                      <IconButton
                        color="inherit"
                        aria-label="Share"
                        component="span"
                        onClick={(e) => {
                          this.handleShare(e);
                        }}
                        size="large"
                      >
                        <Share />
                      </IconButton>
                    </Tooltip>
                    <Menu
                      id="share-menu"
                      anchorEl={shareAnchorEl}
                      keepMounted
                      open={Boolean(shareAnchorEl)}
                      onClose={() => {
                        this.setState({ shareAnchorEl: null });
                      }}
                    >
                      {workOrder.completed !== true && (
                        <MenuItem
                          onClick={() => {
                            let Unsubscribelink = `${window.location.origin}/werkbon/${this.props.match.params.resolverId}`;
                            navigator.clipboard.writeText(Unsubscribelink);
                            this.setState({
                              openAlert: true,
                              shareAnchorEl: null,
                              copiedMessage: t("workOrders.task.directLinkCopied"),
                            });
                          }}
                        >
                          <FileCopyOutlined />
                          &nbsp;
                          <span>{t("workOrders.task.directLink")}</span>
                        </MenuItem>
                      )}
                      <MenuItem
                        onClick={() => {
                          let Workorderlink = window.location.href;
                          navigator.clipboard.writeText(Workorderlink);
                          this.setState({
                            openAlert: true,
                            shareAnchorEl: null,
                            copiedMessage: t("workOrders.workOrderLinkCopied"),
                          });
                        }}
                      >
                        <FileCopyOutlined />
                        &nbsp;
                        <span>{t("workOrders.workOrderLink")}</span>
                      </MenuItem>
                    </Menu>
                    <Snackbar
                      open={openAlert}
                      autoHideDuration={6000}
                      onClose={() =>
                        this.setState({
                          openAlert: false,
                          copiedMessage: "",
                        })
                      }
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    >
                      <Alert
                        onClose={() =>
                          this.setState({
                            openAlert: false,
                            copiedMessage: "",
                          })
                        }
                        severity="success"
                      >
                        {copiedMessage}
                      </Alert>
                    </Snackbar>
                  </>
                )}
                {!!workOrder && isTemporary !== true && workOrder.completed !== true && (
                  <>
                    {
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={(e) => this.initiateHandle(e, "complete")}>
                        {t("general.button.complete")}
                      </Button>
                    }
                    {
                      <Button aria-describedby="handleWorkOrderPopup" variant="outlined" color="primary" style={{ marginLeft: 12 }} onClick={(e) => this.initiateHandle(e, "reject")}>
                        {t("general.button.reject")}
                      </Button>
                    }
                    {!!this.state.anchorElHandleWorkOrder && this.state.handleType && (
                      <Popover
                        open={true}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        id={"handleWorkOrderPopup"}
                        anchorEl={this.state.anchorElHandleWorkOrder}
                        onClose={() => {
                          this.setState({ anchorElHandleWorkOrder: null });
                        }}
                      >
                        <div style={{ padding: "16px" }}>
                          <Grid container spacing={1} direction="column">
                            <Grid item>
                              <Typography variant="h6">{t("workOrders.handle." + this.state.handleType)}</Typography>
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={isNotify === true}
                                    onChange={(e) =>
                                      this.setState({
                                        isNotify: e.target.checked === true,
                                      })
                                    }
                                    name="notify"
                                    color="primary"
                                  />
                                }
                                label={t("workOrders.notifyExecutorNow.label")}
                              />
                            </Grid>
                            <Grid item>
                              <Grid container spacing={1} justifyContent="flex-end">
                                <Grid item>
                                  <Button
                                    variant="outlined"
                                    onClick={() => {
                                      this.setState({
                                        anchorElHandleWorkOrder: null,
                                      });
                                    }}
                                  >
                                    {t("general.cancel")}
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button variant="outlined" disabled={workOrder.completed === true} onClick={() => this.handleWorkOrder()}>
                                    {t("workOrders.handle." + this.state.handleType)}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      </Popover>
                    )}
                  </>
                )}
              </Toolbar>
            </AppBar>
            {workOrder && (
              <Grid item xs={12}>
                <Grid container spacing={2} className={classes.innerContainer} alignItems="flex-start">
                  <Grid item xs={12} md={6} lg={4}>
                    {this.blockGeneral()}
                  </Grid>
                  <Hidden lgDown>
                    <Grid item xs={4}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {this.blockWorkOrder()}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Grid item xs={12} md={6} lg={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {this.blockStatus()}
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockObjectInfo()}
                      </Grid>
                      <Grid item xs={12}>
                        {this.blockContactInfo()}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Hidden lgUp>
                      <Grid item xs={12} md={6}>
                        {this.blockWorkOrder()}
                      </Grid>
                  </Hidden>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
       
    { <DocumentViewer radius={classes.docRadius} open={attachmentIndex != null && this.state.document} onClose={this.handleImageModalClose} documents={this.state.document} />}
        <RepairRequestForm
          isDrawingLocation
          isCreateRepairRequest={false}
          isReadOnly
          requestId={this.state.workOrder && this.state.workOrder.repairRequestId}
          openRepairRequestMap={this.state.openRepairRequestMap}
          onClose={(reload) => this.setState({ openRepairRequestMap: false })}
        />
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const { authentication, buildings } = state;
  const { user } = authentication;
  const { selected, all } = buildings;
  const allBuildings = all;
  return {
    user,
    selected,
    allBuildings,
  };
}

const connectedPage = connect(mapStateToProps)(withTheme(withTranslation()(withStyles(styles)(Page))));
export { connectedPage as ResolverWorkOrderDetailsPage };
