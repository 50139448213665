import {
    CircularProgress,
    Container,
    alpha,
    Grid,
    Slide,
    Snackbar,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, {useEffect, useState} from 'react'
import {Route, Switch, useHistory, useParams} from 'react-router-dom';
import DossierSidebar from './components/DossierSidebar';
import {useDispatch, useSelector} from "react-redux";
import {dossiersActions} from "../../_actions/dossiers.actions";
import {apps, userAccountTypeConstants} from "../../_constants";
import {useTranslation} from 'react-i18next';
import ObjectList from './components/ObjectList';
import DossierInformation from './DossierInformation';
import {downloadFile, formatDate, getMimeTypefromString, isValidFiles, totalFilesSizeIsValid} from '../../_helpers';
import {URLS} from '../../apis/urls';
import DocumentViewer from "../../components/DocumentViewer";
import DashboardCardView from './DashboardCardView';
import clsx from "clsx";
import { Alert } from '@mui/material';
import { uploadBackgroundImage } from '../../apis/dossiersApi';
import SelectMultipleObjectsModal from '../../components/SelectMultipleObjectsModal';
import DossierRights from './components/DossierRights';
import {getDossiersDetailPathByModule} from '../../_helpers/redirectHelper';

const { webApiUrl } = window.appConfig;

export const DossiersPage = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const theme = useTheme();
    const matchesWidthUpLg = useMediaQuery(theme.breakpoints.up('lg'));
    const matchesWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const matchesWidthDownSm = useMediaQuery(theme.breakpoints.down('md'));
    const { selectedBackground, dossierLoading, loading: isDossiersLoading, isUpdateStatus, selectedDossier, selectedDossierBuilding, availableRoles, selectedLoading, isUploading, isUploadingError } = useSelector(state => state.dossier);
    const { rights, selected, all } = useSelector(state => state.buildings);
    const { user } = useSelector(state => state.authentication);
    const app = useSelector(state => state.app);
    const dispatch = useDispatch();
    const canDrawingDossier = selectedDossier && selectedDossier.isSystem;
    const loggedInUserFromRights = canDrawingDossier || (selectedDossier && (selectedDossier.userList || []).find(u => u.loginId === user.id));
    const isBuyer = user.type === userAccountTypeConstants.buyer;
    const isExternal = canDrawingDossier || (selectedDossier && selectedDossier.isExternal && (loggedInUserFromRights || isBuyer));
    const isInternal = canDrawingDossier || (loggedInUserFromRights && loggedInUserFromRights.isInternal);
    const hasExternalEditRights = canDrawingDossier || (isExternal && (isBuyer || (loggedInUserFromRights && loggedInUserFromRights.hasExternalEditRights)))
    const accessRights = { isExternal, isInternal, hasExternalEditRights };
    const params = new URLSearchParams(window.location.search)
    const { viewType, selectedDataId } = useParams();
    const [isLoadingDocument, setIsLoadingDocument] = useState(false);
    const [isOpenImageLightBox, setIsOpenImageLightBox] = useState(false);
    const [previewDocs, setPreviewDocs] = useState([]);
    const [isOpenFilePreviewModal, setIsOpenFilePreviewModal] = useState(false);
    const [uploadingBackground, setUploadingBackground] = useState(false);
    const [isLoadingPreviewOfFile, setIsLoadingPreviewOfFile] = useState(false);
    const [previewDocument, setPreviewDocument] = useState([]);
    const [uploadErrorState, setUploadErrorState] = useState({});
    const buildingId = params.get('buildingId');
    const dossierId = params.get('dossierId') ?? selectedDataId;
    const [editDossier, setEditDossier] = useState(false);
    const [dossierUpdateType, setDossierUpdateType] = useState(null);
    const [openEditObjects, setOpenEditObjects] = useState(false);
    const [openDossierRights, setOpenDossierRights] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [settingModal, setSettingModal] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (dossierId) {
            if (buildingId && !isBuyer){
                if (!selectedDossier)
                  dispatch(
                    dossiersActions.getSelectedDossierInfo(
                      dossierId,
                      isBuyer ? selected.buildingId : buildingId,
                      viewType
                    )
                  );
                dispatch(
                  dossiersActions.getSelectedDossierBuildingInfo(
                    { dossierId, buildingId },
                    viewType
                  )
                );
            }
            else if(!buildingId && isBuyer) {
                history.push(getDossiersDetailPathByModule({
                    dossierId,
                    buildingId: selected.buildingId,
                    viewType,
                    projectId,
                    projectNo: selected.projectNo,
                    app,
                    isBuyer
                }))
            }else
                dispatch(dossiersActions.getSelectedDossierInfo(dossierId, isBuyer ? selected.buildingId : buildingId, viewType));
        } else {
            dispatch(dossiersActions.removeSelectedDossier());
        }
    }, [dossierId, buildingId]);

    useEffect(() => {
        if (!isUploading && isUploadingError) {
            setUploadErrorState({ show: true, fileUploadErrorMessage: isUploadingError })
        }
    }, [isUploading, isUploadingError])

    const handlePreviewAndDownloadImages = (type, files) => {
        if (files && files.length) {
            let documents = [];
            if (type === 'preview') {
                setIsLoadingDocument(true)
                setIsOpenImageLightBox(true);
            }
            files.forEach(file => {
                if (type === 'download') {
                    try {
                        downloadFile(URLS.GET_UPLOADED_DOSSIER_IMAGE + file.fileId, file.name + file.extension);
                    } catch (er) {
                        setIsLoadingDocument(false)
                        setPreviewDocs([]);
                    }
                } else if (type === 'preview') {
                    documents.push({
                        uri: (webApiUrl === '/' ? window.location.origin : '') + URLS.GET_UPLOADED_DOSSIER_IMAGE + file.fileId,
                        fileType: getMimeTypefromString(file.extension),
                        name: file.name + file.extension,
                        description: t('files.fileUploadedByOn', { uploadedBy: file.uploadedBy, name: file.name, modifiedOn: formatDate(new Date(file.lastModifiedOn)) })
                    });
                    if (documents.length === files.length) {
                        setIsLoadingDocument(false)
                        setPreviewDocs(documents)
                    }
                }
            });
        }
    }

    const handlePreviewOfFiles = (files) => {
        if (files && files.length) {
            let documents = [];
            setIsLoadingPreviewOfFile(true);
            setIsOpenFilePreviewModal(true);
            files.map(file => {
                documents.push(
                    {
                        uri: (webApiUrl === '/' ? window.location.origin : '') + URLS.GET_ATTACHMENT + file.fileId,
                        fileType: getMimeTypefromString(file.extension),
                        name: file.name + file.extension,
                        description: t('files.fileUploadedByOn', { uploadedBy: file.uploadedBy, name: file.name, modifiedOn: formatDate(new Date(file.lastModifiedOn)) })
                    }
                );
            })
            if (documents.length === files.length) {
                setIsLoadingPreviewOfFile(false);
                setPreviewDocument(documents)
            }
        }
    }

    const handleSelectFiles = (files, fileData, type) => {
        const canDrawingDossier = selectedDossier && selectedDossier.isSystem;
        if (canDrawingDossier) {
            setUploadErrorState(p => ({ ...p, canShowUploadError: true }));
        }
        if (!isValidFiles(files, canDrawingDossier ? "application/pdf" : false)) {
            setUploadErrorState(p => ({ ...p, show: true, fileUploadErrorMessage: 'files.total.type.notvalid.error' }));
            return;
        }
        if (totalFilesSizeIsValid(files, 25)) {
            let formData = new FormData();
            formData.append('dossierId', selectedDossier.id);
            formData.append('buildingId', buildingId || "");
            formData.append('isInternal', fileData.type === 'internal');
            formData.append('DossierFileList', []);
            formData.append('isArchived', !!fileData.isArchived);
            canDrawingDossier && formData.append('drawingLocationId', fileData.drawingLocationId);
            formData.append('isDeleted', !!fileData.isDeleted);
            files.forEach(f => formData.append('files', f));
            dispatch(dossiersActions.uploadFiles(formData, {
                key: fileData.type === 'internal' ? buildingId ? 'internalObjectFiles' : 'internalFiles' : buildingId ? 'externalObjectFiles' : 'externalFiles',
                buildingId: buildingId, canDrawingDossier, drawingLocationId: fileData.drawingLocationId
            }));
        } else setUploadErrorState(p => ({ ...p, show: true, fileUploadErrorMessage: 'files.total.size.notvalid.error' }))
    };

    const updateDossier = (key, value, updateAllDeadline) => {
        const buildingId1 = key !== 'archive' && buildingId;
        setDossierUpdateType(key);
        if (key === 'backgroundImage') {
            setUploadingBackground(true)
            uploadBackgroundImage({ dossierId: selectedDossier.id, ...value }).then(res => {
                dispatch(dossiersActions.getBackground(selectedDossier.id));
                setUploadingBackground(false)
            }).catch(er => {
                setUploadingBackground(false)
            })
            return;
        }
        dispatch(dossiersActions.updateDossierInformation({
            [key]: value,
            id: selectedDossier.id,
            buildingId: buildingId1
        }, !!buildingId1, viewType));
    };

    const updateStatus = (isClosed) => {
        dispatch(dossiersActions.updateStatus({
            dossierId: selectedDossier.id,
            isClosed,
            buildingId: buildingId || '',
            type: viewType
        }));
    };

    const handleSaveObjects = (objects, changedObjects) => {
        changedObjects.length && dispatch(dossiersActions.updateDossierBuilding({ dossierId: selectedDossier.id, changedObjects, objects }))
    }

    const handleCloseObjects = () => {
        setOpenEditObjects(false);
    }

    const handleUpdateDossierClose = () => {
        setUpdating(null)
        setEditDossier(null);
    }

    const handleUpdateDossierRights = ({ availableRoles, changedRoles }) => {
        dispatch(dossiersActions.updateDossiersRights(selectedDossier.id, changedRoles, availableRoles))
    }

    const handleCloseDossierRights = () => {
        setOpenDossierRights(false);
    }

    const isDashboard = props.match.path.includes("dashboard");
    const projectId = app === apps.aftercare ? (selectedDataId || (selectedDossier && selectedDossier.projectId)) : selected.projectId;
    return (
        <Container className={classes.mainContainer} maxWidth={false}>
            <Snackbar
                style={{ top: 120 }}
                TransitionComponent={(props) => <Slide {...props} direction="left" />}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={uploadErrorState.show}
                onClose={() => setUploadErrorState(p => ({ ...p, show: false }))}
                autoHideDuration={3000} key={'file-size-validation'}>
                <Alert elevation={6} variant="filled" severity="error">
                    {t(uploadErrorState.fileUploadErrorMessage || '')}
                </Alert>
            </Snackbar>
            <DocumentViewer loader={isLoadingDocument} open={isOpenImageLightBox}
                onClose={() => {
                    setIsOpenImageLightBox(false);
                    setPreviewDocs([]);
                }} documents={previewDocs} />

            <DocumentViewer loader={isLoadingPreviewOfFile} open={isOpenFilePreviewModal}
                onClose={() => {
                    setIsOpenFilePreviewModal(false);
                    setPreviewDocument([]);
                }} documents={previewDocument} />

            <Grid container className={classes.container}>
                {(matchesWidthUpMd || (!selectedDataId && !buildingId)) && <Grid item xs={12} md={3} className={classes.dossierSidebar}>
                    <DossierSidebar
                        updateDossier={updateDossier}
                        openDossierRights={() => {
                            setOpenDossierRights(true);
                            setDossierUpdateType("rights")
                        }}
                        openEditObjects={() => setOpenEditObjects(true)}
                        uploadingBackground={uploadingBackground}
                        canDrawingDossier={canDrawingDossier}
                        isReadOnly={!selectedDossier || !selectedDossierBuilding || selectedDossier.isArchived || selectedDossier.status === 2 || selectedDossierBuilding.status === 2}
                        selectedDossier={selectedDossier}
                        selectedDossierBuilding={selectedDossierBuilding}
                        buildingId={buildingId}
                        isBuyer={isBuyer}
                        buildings={all.filter(x => x.projectId === projectId)}
                        isDashboard={isDashboard} path={props.match.url}
                        settingModal={setSettingModal}
                    />
                </Grid>}
                {!settingModal &&
                    <>

                        <Slide direction="left" in={selectedDataId || buildingId || !!selectedDossier || matchesWidthUpLg || selectedLoading} mountOnEnter unmountOnExit>
                            <Grid item xs={12} md={9} className={clsx(classes.innerContainer, isDashboard && classes.fadeBackground)}
                                style={{
                                    display: selectedDataId || buildingId || selectedLoading || isDossiersLoading ? 'block' : !selectedDossier && !matchesWidthUpMd ? 'none' : null,
                                    zIndex: (selectedDossier || selectedDataId || buildingId) && 1103,
                                    backgroundImage: !matchesWidthDownSm && `url(${selectedBackground}), url(${webApiUrl}api/home/ProjectBackground/${projectId}), url(${webApiUrl}api/Config/WebBackground)`
                                }}
                            >
                                <Grid className={classes.blocksGrid} >
                                    {!isBuyer && !dossierLoading && !isDossiersLoading && selectedDossier && <ObjectList isBuyer={isBuyer} />}
                                    {(selectedLoading || isDossiersLoading || dossierLoading) ?
                                        <div className={classes.loader}>
                                            <CircularProgress size={30} />
                                        </div> :
                                        <Switch>
                                            {<Route
                                                exact
                                                path={`/nazorg/:viewType(dossier|building)/:selectedDataId/dashboard`}
                                                render={(rest) => <DashboardCardView
                                                    {...rest}
                                                />}
                                            />}
                                            {selectedDossier && !isDashboard && <Route
                                                exact
                                                path={`${props.match.path}`}
                                                render={(rest) => <DossierInformation
                                                    {...rest}
                                                    updateDossier={updateDossier}
                                                    buildingId={buildingId}
                                                    dossierUpdateType={dossierUpdateType}
                                                    handleGetAllImages={handlePreviewAndDownloadImages}
                                                    handlePreviewOfFiles={handlePreviewOfFiles}
                                                    updateStatus={updateStatus}
                                                    isUpdateStatus={isUpdateStatus}
                                                    handleUpdateDossierClose={handleUpdateDossierClose}
                                                    handleSelectFiles={handleSelectFiles}
                                                    selectedDossierBuilding={selectedDossierBuilding}
                                                    selectedDossier={selectedDossier}
                                                    accessRights={accessRights}
                                                    canDrawingDossier={canDrawingDossier}
                                                    selectedDossierType={"dossier main"}
                                                    edit={editDossier}
                                                    setEdit={edit => setEditDossier(edit)}
                                                    openDossierRights={() => {
                                                        setOpenDossierRights(true);
                                                        setDossierUpdateType("rights")
                                                    }}
                                                    openEditObjects={() => setOpenEditObjects(true)}
                                                    updating={updating}
                                                    uploadingBackground={uploadingBackground}
                                                    selected={selected} buildings={all} fileUploading={false}
                                                    loggedInUserFromRights={loggedInUserFromRights}
                                                />} />}
                                        </Switch>
                                    }
                                </Grid>
                            </Grid>
                        </Slide>
                    </>
                }
            </Grid>
            {
                openEditObjects &&
                <SelectMultipleObjectsModal
                    isReadOnly={!rights["dossier.generalInformation.write"]}
                    open={openEditObjects}
                    buildings={all.filter(x => x.projectId === projectId)}
                    selectedObjects={selectedDossier.buildingInfoList}
                    onSave={handleSaveObjects}
                    onClose={handleCloseObjects}
                />
            }
            {
                openDossierRights &&
                <DossierRights
                    isReadOnly={!rights["dossier.generalInformation.write"]}
                    open={openDossierRights}
                    selectedRoles={selectedDossier.userList}
                    availableRoles={availableRoles}
                    onClose={handleCloseDossierRights}
                    onUpdate={handleUpdateDossierRights}
                    isDossierExternal={selectedDossier.isExternal}
                />
            }
        </Container>
    )
};

const useStyles = makeStyles(theme => ({
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: theme.spacing(0)
    },
    container: {
        position: 'relative',
        height: '100%',
        overflow: 'hidden',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0)
        }
    },
    blocksGrid: {
        padding: theme.spacing(0),
        height: "100%",
        overflow: "auto",
        [theme.breakpoints.down('md')]: {
            padding: 0
        },
    },
    innerContainer: {
        overflow: 'auto',
        height: "100%",
        [theme.breakpoints.down('md')]: {
            backgroundColor: theme.palette.grey[100],
        },
        [theme.breakpoints.down('lg')]: {
            backgroundColor: theme.palette.grey[100],
            position: 'absolute',
            right: 0
        },
        width: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center"
    },
    dossierSidebar: {
        height: "100%"
    },
    loader: {
        flex: 1,
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    fadeBackground: {
        background: alpha(theme.palette.common.white, 0.2)
    }
}));
