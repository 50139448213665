import { createTheme } from "@mui/material/styles";
import {
  Grid,
  Hidden,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { createContext, useEffect, useState } from "react";
import { StaticDatePicker } from "@mui/x-date-pickers"
import FullCalendar from "./FullCalendar";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  cancelAppointment,
  confirmAppointment,
  deleteSlots,
  getAgendaView,
  getAllAppointments,
  updateAgendaView,
  updateOnlineMeetingLink,
} from "../../apis/agendaApis";
import { userAccountTypeConstants } from "../../_constants";
import { tabsViewTypes } from "../../_helpers";

export const CalendarContext = createContext({});
export default function Agenda() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    query: "",
    category: "",
    title: "",
    appointmentLocation: "",
    type: "",
  });
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState();
  const { selected } = useSelector((state) => state.buildings);
  const { user } = useSelector((state) => state.authentication);
  const isBuyer = user.type === userAccountTypeConstants.buyer;

  useEffect(() => {
    handleDateChange();
  }, [view]);

  const handleDateChange = (date) => {
    let newDate;
    if (tabsViewTypes[view] === "month") {
      newDate = moment(date).startOf("month");
      handleFilters({
        startDate: newDate.format("YYYY-MM-DD"),
        endDate: newDate.clone().endOf("month").format("YYYY-MM-DD"),
      });
    } else if (tabsViewTypes[view] === "week") {
      newDate = moment(date).startOf("week");
      handleFilters({
        startDate: newDate.format("YYYY-MM-DD"),
        endDate: newDate.clone().endOf("week").format("YYYY-MM-DD"),
      });
    } else if (tabsViewTypes[view] === "day") {
      handleFilters({
        startDate: moment(date).format("YYYY-MM-DD"),
        endDate: moment(date).format("YYYY-MM-DD"),
      });
    }
  };

  const handleFilters = (newFilters) => {
    if (
      (newFilters.startDate && newFilters.startDate !== filters.startDate) ||
      (newFilters.endDate && newFilters.endDate !== filters.endDate)
    ) {
      getAgendaAppointments(newFilters.startDate, newFilters.endDate);
    } else {
      applyLocalFilters(events, newFilters);
    }
  };
  const applyLocalFilters = (events, newFilters) => {
    let filteredData;
    const mergedFilters = { ...filters, ...newFilters };
    filteredData = events.filter(
      (p) =>
        (!mergedFilters.category ||
          mergedFilters.category === p.categoryTitle) &&
        (!mergedFilters.title || mergedFilters.title === p.title) &&
        (mergedFilters.appointmentLocation === "" ||
          mergedFilters.appointmentLocation === p.appointmentLocation) &&
        (mergedFilters.type === "" ||
          mergedFilters.type === p.appointmentType) &&
        (!mergedFilters.query ||
          p.title.toLowerCase().includes(mergedFilters.query.toLowerCase()) ||
          (!p.participants || p.participants.length
            ? p.participants.some((p) =>
                p.name.toLowerCase().includes(mergedFilters.query.toLowerCase())
              )
            : "") ||
          (p.address &&
            (
              p.address.street +
              " " +
              p.address.houseNo +
              " " +
              p.address.houseNoAddition +
              " " +
              p.address.postcode +
              " " +
              p.address.place
            )
              .toLowerCase()
              .includes(mergedFilters.query.toLowerCase())) ||
          p.categoryTitle
            .toLowerCase()
            .includes(mergedFilters.query.toLowerCase()))
    );
    setFilteredEvents(filteredData);
    setFilters(mergedFilters);
  };

  const getAgendaAppointments = async (startDate, endDate) => {
    setEvents([]);
    setFilteredEvents([]);
    setLoading(true);
    if (selected.projectId) {
      try {
        const events = await getAllAppointments(
          selected.projectId,
          startDate,
          endDate,
          isBuyer ? selected.buildingId : undefined
        ).then((p) =>
          p.data.map((p) => ({
            ...p,
            start: new Date(p.start),
            end: new Date(p.end),
            projectName: selected.projectName,
          }))
        );
        setLoading(false);
        setEvents(events);
        applyLocalFilters(events, { startDate, endDate });
      } catch (e) {
        setLoading(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    getAgendaAppointments(
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    );
  }, [selected.projectId]);

  const getView = async () => {
    try {
      return getAgendaView().then((p) => {
        return p;
      });
    } catch (e) {
      console.log(e);
    }
  };

  const updateView = async (view) => {
    setView(view);
    try {
      const res = await updateAgendaView(view).then((p) => p.data);
    } catch (e) {
      console.log(e);
    }
  };

  const confirmAgendaAppointment = async (data) => {
    try {
      await confirmAppointment(data).then((p) => p.data);
      getAgendaAppointments(filters.startDate, filters.endDate);
    } catch (e) {
      console.log(e);
    }
  };
  const cancelAgendaAppointment = async (data) => {
    try {
      await cancelAppointment(data).then((p) => p.data);
      getAgendaAppointments(filters.startDate, filters.endDate);
    } catch (e) {
      console.log(e);
    }
  };

  const updateMeetingLink = async (slotId, link) => {
    try {
      await updateOnlineMeetingLink({ slotId, link }).then((p) => p.data);
      getAgendaAppointments(filters.startDate, filters.endDate);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAgendaSlots = async (data) => {
    try {
      await deleteSlots(data).then((p) => p.data);
      getAgendaAppointments(filters.startDate, filters.endDate);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getAgendaAppointments(filters.startDate, filters.endDate);
  }, []);

 
  return (
    <div className={classes.container}>
      <Grid container spacing={3} className={classes.agendaGridContainer}>
        <Hidden only={["sm", "md"]}>
          <Grid item xs={12} lg={3} className={classes.muiDatePicker}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={datePickerTheme}>
                <Grid container justifyContent="space-around">
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    className={classes.inputClasses}
                    format="dd-MM-yyyy"
                    id="date-picker-inline"
                    value={new Date(filters.startDate)}
                    onChange={handleDateChange}
                    InputProps={{ classes: classes.inputClasses }}
                    KeyboardButtonProps={{
                      "aria-label": "change-date",
                    }}
                    dayOfWeekFormatter={(day, format) => {
                      if (format === "long") {
                        return day.substring(0, 3)
                      } else if (format === "short") {
                        return day.substring(0, 1)
                      } else {
                        return day
                      }
                    }}
                  />
                </Grid>
              </ThemeProvider>
            </StyledEngineProvider>
          </Grid>
        </Hidden>
        <Grid item xs={12} lg={9} className={classes.fullCalendarContainer}>
          <CalendarContext.Provider
            value={{
              handleFilters,
              deleteAgendaSlots,
              confirmAgendaAppointment,
              isBuyer,
              selected,
              cancelAgendaAppointment,
              updateMeetingLink,
              filters,
              loading,
              filteredEvents,
              updateView,
              getView,
              events,
            }}
          >
            <FullCalendar filteredEvents={filteredEvents} loading={loading} filters={filters} handleFilters={handleFilters} getAgendaAppointments={getAgendaAppointments} />
          </CalendarContext.Provider>
        </Grid>
      </Grid>
    </div>
  )
}

const datePickerTheme = createTheme({
  components: {
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          minWidth: "100%",
          borderRadius: 6,
        },
      },
    },
    MuiDateCalendar: {
      styleOverrides: {
        root: {
          maxWidth: "100%",
          width: "100%",
          minWidth: "auto",
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        header: {
          justifyContent: "space-around",
        },
        weekContainer: {
          justifyContent: "space-around",
        }
      },
    },
  },
})

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    backgroundColor: theme.palette.indigo.light,
    padding: theme.spacing(2.4),
    overflow: "hidden",
  },
  fullCalendarContainer: {
    height: "calc(100vh - 100px)",
  },
  agendaGridContainer: {
    width: "100%"
  },
  inputClasses: {
    root: {
      width: "100%",
    },
  },
  muiDatePicker: {
    "& > div > div": {
      boxShadow: theme.shadows[1],
      width: "100%",
    },
  },
}))
