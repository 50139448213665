import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  AppBar,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Hidden,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Paper,
  Popover,
  Slide,
  Snackbar,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import {
  AddCircleOutlineOutlined,
  ArrowBack,
  ArrowForwardIos,
  BeenhereOutlined,
  Cancel,
  CheckCircle,
  DeleteOutline,
  DescriptionOutlined,
  Done,
  Edit,
  GetApp,
  Image,
  Link,
  Lock,
  MailOutline,
  MapOutlined,
  MoreVert,
  PictureAsPdf,
  PriorityHigh,
  Send,
  TextFormat,
  Warning,
} from "@mui/icons-material";
import Dropzone from "../../../components/Dropzone";
import RichTextEditor from "../../Chat/RichTextEditor";
import UserInformation from "./UserInformation";
import ImagesContainer from "./ImagesContainer";
import WBProjectInfo from "./WBProjectInfo";
import {
  assignmentFileValidation,
  formatDate,
  generateLocalStorageKey,
  getAssignmentsAttachments,
  getFile,
  getLocalStorageItem,
  getNameInitials,
  isCompleteAssignment,
  isReadOnly,
  nl2br,
  removeAssignmentsAttachments,
  setLocalStorageItem,
  toBase64,
  useCanAssignmentIsReadOnly,
  useCanViewedToWBAttachments,
  useInternetStatusDetector,
} from "../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { surveyActions } from "../../../_actions";
import { Alert } from "@mui/material";
import EmojiSelector from "../../Chat/EmojiSelector";
import Markdown from "../../../components/Markdown";
import { URLS } from "../../../apis/urls";
import { fileUploadConfig } from "../../../_helpers/fileUploadConfig";
import {
  internalAssignmentStatus,
  SurveyType,
} from "../../../_helpers/enumsHelper.js";
import LinkAssignmentModal from "./LinkAssignmentModal";
import { alpha } from "@mui/material/styles";
import DocumentViewer from "../../../components/DocumentViewer";
import RepairRequestForm from "../RepairRequestForm";
import { dossiersActions } from "../../../_actions/dossiers.actions";
import DataGrid from "../../../components/DataGrid";
import { AssignmentRepairRequestCustomRow } from "../components/AssignmentRepairRequestCustomRow";
import DataGridSetting from "../../../components/DataGridSetting";
import {
  ImageColumn,
  LabelColumn,
  MultiLabelColumn,
} from "../../../components/DataColumns";
import OfflineFileWrapper from "../../../components/OfflineFileWrapper";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(1, 0),
    "& div": {
      minWidth: 40,
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.grey[700],
    },
  },
}))(MenuItem);
const { webApiUrl } = window.appConfig;

export default function AssignmentDetails({ ...props }) {
  const { t } = useTranslation();
  const params = useParams();
  const classes = useStyles();
  const [userInfoPopover, setUserInfoPopover] = useState({
    data: null,
    element: null,
    timeoutId: null,
  });
  const [imageContainerOpen, setImageContainerOpen] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const theme = useTheme();
  const [selectedDrawingFiles, setSelectedDrawingFiles] = useState({
    pages: 1,
  });
  const matchWidthDownSm = useMediaQuery(theme.breakpoints.down("md"));
  const history = useHistory();
  const dispatch = useDispatch();
  const app = useSelector((state) => state.app);
  const { selected } = useSelector((state) => state.buildings);
  const { drawingFiles } = useSelector((state) => state.dossier);
  const { user } = useSelector((state) => state.authentication);
  const {
    wbProjectInfo,
    assignmentLockIds,
    selectedAssignment,
    attachmentLoading,
    getAssignmentLoading,
    isUpdatingAssignment,
    repairRequests,
    isInternalCompleteAssignmentLoading,
    isCompleteAssignmentLoading,
    resetAssignmentLoading,
    loadingRepairRequests,
    assignments,
  } = useSelector((state) => state.surveying);

  const [defaultFilter, setDefaultFilter] = useState(null);
  const [requests, setRequests] = useState(repairRequests);
  const [requestAttachmentsData, setRequestAttachmentsData] = useState([]);
  const [fileError, setFileError] = useState({
    filesSizeIsInValid: false,
    fileUploadErrorMessage: null,
  });
  const [editRemark, setEditRemark] = useState({ popover: null, remark: "" });
  const [comment, setComment] = useState("");
  const [showRichTextEditorToolbar, setShowRichTextEditorToolbar] =
    useState(false);
  const [textToInsertInComment, setTextToInsertInComment] = useState("");
  const [richTextToInsertInComment, setRichTextToInsertInComment] =
    useState("");
  const messagesEndRef = useRef(null);
  const remarkRef = useRef(null);
  const ref = useRef(null);
  const [confirmUpdateLocation, setConfirmUpdateLocation] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [offlineAttachments, setOfflineAttachments] = useState([]);
  const [selectedAnswerOptions, setSelectedAnswerOptions] = useState("");
  const [loaderKey, setLoaderKey] = useState(null);
  const isReadOnlyAssignment = useCanAssignmentIsReadOnly();
  const canReadOnlyEvidenceSection = useCanViewedToWBAttachments();
  const [linkAssignment, setLinkAssignment] = useState({
    element: null,
    data: null,
  });
  const [openTooltip, setOpenTooltip] = useState({ element: null, image: "" });
  const [canEditRemark, setCanEditRemark] = useState(false);
  const [updateAttachments, setUpdateAttachments] = useState(false);
  const [isOpenMoreOptions, setIsOpenMoreOption] = useState(null);
  const [previewDocument, setPreviewDocument] = useState([]);
  const [isOpenDocViewer, setIsOpenDocViewer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [openRepairRequestMap, setOpenRepairRequestMap] = useState(false);
  const [canGoBack, setCanGoBack] = useState([]);
  const [drawingLocationState, setDrawingLocationState] = useState(null);
  const [isDrawingLocation, setIsDrawingLocation] = useState(false);
  const [canListType, setCanListType] = useState(false);
  const [imageSize, setImageSize] = useState(20);
  const [fontSize, setFontSize] = useState(12);
  const [offlineRepairRequestData, setOfflineRepairRequestData] =
    useState(null);
  const [savedAttachments, setSavedAttachments] = useState([]);
  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "requestId", visible: "excluded" },
    { name: "attachments", visible: true },
    { name: "number", visible: true },
    { name: "date", visible: true },
    { name: "desc", visible: true },
    { name: "resolverNames", visible: true },
    { name: "targetCompletionDate", visible: true },
    { name: "carryOutAsType", visible: true },
    { name: "status", visible: true },
  ]);

  //Online/offline detector
  const isOnline = useInternetStatusDetector();

  useEffect(() => {
    const localFilters = getLocalStorageItem(
      `Assignment_RepairRequest_Grid_Filters`
    );
    const columnsVisibilityLocal = getLocalStorageItem(
      "Assignment_RepairRequest_ColumnsVisibility"
    );
    let gridStyle = getLocalStorageItem("Assignment_RepairRequest_Grid_Style");
    gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
    const size = getLocalStorageItem("detailRepairRequestListsImageSize");
    setCanListType(gridStyle);
    if (localFilters) setDefaultFilter(localFilters);
    setImageSize((p) => +size || p);
    const fontSizeLocal = getLocalStorageItem(
      "detailRepairRequestListsFontSize"
    );
    setFontSize(+fontSizeLocal || fontSize);
    if (!columnsVisibilityLocal) {
      setLocalStorageItem(
        "Assignment_RepairRequest_ColumnsVisibility",
        columnsVisibility
      );
    } else {
      setColumnsVisibility(columnsVisibilityLocal);
    }
  }, []);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    dispatch(
      surveyActions.getAssignmentDetails(params.assignmentId, null, null)
    );
  }, [canGoBack]);

  useEffect(() => {
    if (selected) {
      dispatch(surveyActions.getWBProjectInoAction(selected.projectId));
      if (!assignments || !assignments.length)
        dispatch(surveyActions.getAssignmentsList(selected.projectId));
    }
  }, [selected]);

  useEffect(() => {
    if (!isUpdatingAssignment) {
      setLoaderKey(null);
    }
  }, [isUpdatingAssignment]);

  useEffect(() => {
    if (selectedAssignment && !getAssignmentLoading) {
      dispatch(
        surveyActions.getRepairRequests(selectedAssignment.assignmentId, true)
      );
    }
  }, [
    selectedAssignment &&
      selectedAssignment.repairRequests &&
      selectedAssignment.repairRequests.length,
    getAssignmentLoading,
  ]);

  useEffect(() => {
    if (selectedAssignment && !attachmentLoading && !getAssignmentLoading) {
      getAssignmentsAttachments({
        assignmentId: selectedAssignment.assignmentId,
        sync: 0,
      })
        .then((res) => {
          if (res && res.attachments && res.attachments.length) {
            Promise.all(
              res.attachments.map(async (p, i) => {
                if (p.id.indexOf("-") < 0) {
                  const file = await toBase64(p.file);
                  return { file, index: i, id: p.id, name: p.file.name };
                } else return p;
              })
            ).then((response) => {
              setOfflineAttachments(response);
            });
          } else setOfflineAttachments([]);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [attachmentLoading, updateAttachments, selectedAssignment]);

  useEffect(() => {
    if (isDrawingLocation && drawingLocationState) {
      setConfirmUpdateLocation(true);
      setIsDrawingLocation(false);
    }
  }, [isDrawingLocation, drawingLocationState]);

  const handleSubmitUpdatedLocation = () => {
    if (drawingLocationState) {
      dispatch(
        surveyActions.addAssignmentAttachment({
          assignmentId: params.assignmentId,
          attachments: [drawingLocationState],
        })
      );
      setDrawingLocationState(null);
    }
    setConfirmUpdateLocation(false);
  };

  useEffect(() => {
    if (selectedAssignment) {
      setEditRemark((p) => ({ ...p, remark: selectedAssignment.remark || "" }));
    }
  }, [selectedAssignment]);

  useEffect(() => {
    if (selectedAssignment) {
      setRequests(
        repairRequests.map((p) => {
          p.object = selectedAssignment.surveyingMomentDetails.object || "";
          return p;
        })
      );
    }
  }, [selectedAssignment, repairRequests]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length) {
      setSelectedDrawingFiles({
        ...drawingFiles[0],
        groupBy: drawingFiles[0].isInternal ? "Internal" : "External",
      });
    } else {
      setSelectedDrawingFiles({});
    }
  }, [drawingFiles]);

  useEffect(() => {
    if (selectedAssignment && selectedAssignment.projectId) {
      dispatch(
        dossiersActions.getDrawingFiles(
          selectedAssignment.projectId,
          selectedAssignment.buildingId
        )
      );
    }
  }, [selectedAssignment && selectedAssignment.assignmentId]);

  useEffect(() => {
    if (selectedAssignment && selectedAssignment.assignmentAttachments) {
      Promise.all(
        selectedAssignment.assignmentAttachments.map(
          async (file) => await getFile(file.id)
        )
      ).then((response) => {
        setSavedAttachments(response.filter((x) => x));
      });
    } else setSavedAttachments([]);
  }, [selectedAssignment && selectedAssignment.assignmentAttachments]);

  useEffect(() => {
    scrollToBottom();
  }, [selectedAssignment]);

  useEffect(() => {
    if (remarkRef.current && canEditRemark) remarkRef.current.focus();
  }, [canEditRemark]);

  const handleUserInfoPopoverClose = () => {
    clearTimeout(userInfoPopover.timeOutId);
    setUserInfoPopover((p) => ({ element: null }));
  };

  const handleUserInfoPopoverOpen = ({ currentTarget }, editable, data) => {
    setIsEditable(editable);
    setUserInfoPopover((p) => ({ ...p, element: currentTarget, data }));
  };

  const handleImageModalToggler = (data = [], requestId) => {
    setRequestAttachmentsData(
      data.map((p) => ({ attachmentId: p, requestId }))
    );
    setImageContainerOpen(!imageContainerOpen);
  };

  const handleSendMessages = () => {
    dispatch(
      surveyActions.updateAssignmentData({
        assignmentId: params.assignmentId,
        message: comment,
      })
    );
    setComment("");
    scrollToBottom();
    setShowRichTextEditorToolbar(false);
    setLoaderKey("message");
  };

  const listToMapRedirectHandler = (event, selectedRequest) => {
    history.push({
      pathname: `/werk/${selected.projectNo}/${
        app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"
      }`,
      state: {
        selectedRequest: null,
        buildingId: selectedAssignment && selectedAssignment.buildingId,
        requests: repairRequests.map((p) => ({
          ...p,
          textResolvers: p.resolverNames,
          attachments: p.attachments.map((a) => ({ ...a, attachmentId: a.id })),
        })),
      },
    });
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      setTimeout(() => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollTop =
            messagesEndRef.current.scrollHeight;
        }
      }, [300]);
    }
  };

  const handleTooltipClose = (event) => {
    event.stopPropagation();
    setOpenTooltip({ element: null, image: null });
  };

  const handleChangeGridStyle = (val) => {
    setCanListType(val);
    setLocalStorageItem("Assignment_RepairRequest_Grid_Style", val);
  };

  const handleResizeImage = (value) => {
    setImageSize(+value);
    setLocalStorageItem("detailRepairRequestListsImageSize", +value);
  };

  const handleResizeFont = (value) => {
    setFontSize(+value);
    setLocalStorageItem("detailRepairRequestListsFontSize", +value);
  };

  let columns = [
    {
      name: "requestId",
      options: {
        display: isColumnVisible("requestId"),
        filter: false,
        print: false,
        download: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "attachments",
      label: t("general.image"),
      options: {
        display: isColumnVisible("attachments"),
        filter: false,
        sort: false,
        print: false,
        download: false,
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          let imageURL = "";
          let attachmentId;
          if (
            request &&
            request.sync === 0 &&
            request.attachments &&
            request.attachments.length
          ) {
            imageURL = `${window.URL.createObjectURL(
              new Blob([request.attachments[0]])
            )}`;
          } else if (
            request &&
            request.sync !== 0 &&
            request.attachments &&
            request.attachments.length
          ) {
            attachmentId = request.attachments[0].id;
            imageURL = `${URLS.GET_ATTACHMENT_THUMBNAIL}${request.attachments[0].id}`;
          }
          return (
            <OfflineFileWrapper id={attachmentId} url={imageURL}>
              {(url) => <ImageColumn src={url} size={imageSize} />}
            </OfflineFileWrapper>
          );
        },
        customHeadLabelRender: (columnMeta) => {
          return (
            <div style={{ textAlign: "center" }}>
              <Image />
            </div>
          );
        },
      },
    },
    {
      name: "number",
      label: t("general.number"),
      options: {
        maxWidth: 100,
        filter: true,
        filterList: defaultFilter ? defaultFilter[2] : [],
        display: isColumnVisible("number"),
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn label={request.number} />;
        },
      },
    },
    {
      name: "date",
      label: t("general.date"),
      options: {
        filter: false,
        maxWidth: 100,
        display: isColumnVisible("date"),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn date={new Date(request.date)} />;
        },
      },
    },
    {
      name: "desc",
      label: t("general.description"),
      options: {
        maxWidth: 100,
        filter: true,
        filterList: defaultFilter ? defaultFilter[4] : [],
        display: isColumnVisible("desc"),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn label={request.desc} />;
        },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      },
    },
    {
      name: "resolverNames",
      label: t("general.resolvers"),
      options: {
        maxWidth: 100,
        filter: true,
        filterList: defaultFilter ? defaultFilter[5] : [],
        display: isColumnVisible("resolverNames"),
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <MultiLabelColumn
              data={
                request.resolverNames &&
                request.resolverNames.map((a) => ({ name: a }))
              }
            />
          );
        },
      },
    },
    {
      name: "targetCompletionDate",
      label: t("repairRequest.targetCompletionDate.shortLabel"),
      options: {
        filter: false,
        maxWidth: 100,
        display: isColumnVisible("targetCompletionDate"),
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return (
            <LabelColumn
              label={
                request.targetCompletionDate
                  ? formatDate(new Date(request.targetCompletionDate))
                  : ""
              }
            />
          );
        },
      },
    },
    // {
    //     name: 'carryOutAsType',
    //     label: t('repairRequest.type.label'),
    //     options: {
    //         maxWidth: 100,
    //         setCellHeaderProps: () => ({ className: classes.customMainHeader }),
    //         display: isColumnVisible("carryOutAsType")
    //     },
    // },
    {
      name: "status",
      label: t("general.status"),
      options: {
        maxWidth: 100,
        filter: true,
        filterList: defaultFilter ? defaultFilter[7] : [],
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
          const request = requests[rowIndex];
          if (!request) return "";
          return <LabelColumn label={request.status} />;
        },
        display: isColumnVisible("status"),
      },
    },
  ];

  function onFilterChange(_, list) {
    setDefaultFilter(list);
    setLocalStorageItem(`Assignment_RepairRequest_Grid_Filters`, list);
  }

  const handleRowClick = (selectedRow) => {
    if (
      selectedAssignment &&
      selectedRow &&
      selectedRow.sync === 0 &&
      !isCompleteAssignment(selectedAssignment.internalStatus)
    ) {
      setOpenRepairRequestMap(true);
      setOfflineRepairRequestData(selectedRow);
    } else if (app === 3 && window.navigator.onLine && selectedRow.sync !== 0) {
      history.push(
        `/werk/${selected.projectNo}/kwaliteitsborging/opname/melding/${selectedRow.requestId}`
      );
    }
  };

  const options = {
    print: false,
    download: false,
    sortingList: [
      { label: "general.buildingNumber", key: "number", type: "string" },
      { label: "general.description", key: "desc", type: "string" },
      {
        label: "repairRequest.targetCompletionDate.shortLabel",
        key: "targetCompletionDate",
        type: "date",
      },
      { label: "general.status", key: "status", type: "string" },
    ],
    customToolbar: () => {
      return (
        <>
          {
            <DataGridSetting
              canListType={canListType}
              imageSize={imageSize}
              onChangeImageSize={(val) => handleResizeImage(val)}
              fontSize={fontSize}
              onChangeFontSize={(val) => handleResizeFont(val)}
              onChangeGridViewType={(val) => handleChangeGridStyle(val)}
            />
          }

          {(selectedAssignmentLockInfo ||
            (!selectedAssignmentLockInfo && isOnline)) &&
            selectedAssignment &&
            selectedAssignment.repairRequestRight &&
            wbProjectInfo &&
            !wbProjectInfo.archivedAt && (
              <Tooltip title={t("survey.create.label")}>
                <IconButton
                  style={{ order: -1 }}
                  onClick={() => setOpenRepairRequestMap(true)}
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  size="large"
                >
                  <AddCircleOutlineOutlined />
                </IconButton>
              </Tooltip>
            )}
        </>
      );
    },
    onViewColumnsChange: (changedColumn, action) => {
      if (action === "add") {
        var listToUpdate = Object.assign([], columnsVisibility);

        var column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = true;
        } else {
          listToUpdate.push({ name: changedColumn, visible: true });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(
          "Assignment_RepairRequest_ColumnsVisibility",
          listToUpdate
        );
      } else if (action === "remove") {
        listToUpdate = Object.assign([], columnsVisibility);
        column = listToUpdate.find((x) => x.name === changedColumn);
        if (column) {
          column.visible = false;
        } else {
          listToUpdate.push({ name: changedColumn, visible: false });
        }
        setColumnsVisibility(listToUpdate);
        setLocalStorageItem(
          "Assignment_RepairRequest_ColumnsVisibility",
          listToUpdate
        );
      }
    },
    onFilterChange,
    onRowClick: (rowData, rowMeta) => {
      const requestId = rowData[0];
      const selectedRow =
        requests && requests.find((x) => x.requestId === requestId);
      if (!!selectedRow) {
        handleRowClick(selectedRow);
      }
    },
  };

  const handleRemoveAttachment = (e, attachmentId) => {
    e.stopPropagation();
    dispatch(
      surveyActions.removeAssignmentAttachment(
        params.assignmentId,
        attachmentId
      )
    );
  };
  const removeOfflineAttachmentHandler = (e, index) => {
    e.stopPropagation();
    removeAssignmentsAttachments(params.assignmentId, index)
      .then((res) => setUpdateAttachments(new Date().getTime()))
      .catch(console.log);
  };

  const handleAddAttachment = async (attachments) => {
    const allFiles = Object.assign([], selectedFiles).concat(attachments);
    const fileValidations = assignmentFileValidation(
      selectedFiles,
      attachments
    );
    if (!fileValidations.valid) {
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: fileValidations.message,
      });
      return;
    }
    if (fileValidations.valid) {
      setSelectedFiles(allFiles);
      dispatch(
        surveyActions.addAssignmentAttachment({
          assignmentId: params.assignmentId,
          attachments,
        })
      );
    }
  };

  const uploadExistingFiles = (files) => {
    dispatch(
      surveyActions.addAssignmentAttachment(
        { assignmentId: params.assignmentId, attachments: files },
        "attachmentIds"
      )
    );
  };

  const handleChangeRemark = () => {
    dispatch(
      surveyActions.updateAssignmentData({
        assignmentId: params.assignmentId,
        comments: editRemark.remark,
      })
    );
    setEditRemark((p) => ({ ...p, popover: null }));
    setLoaderKey("remark");
  };

  const handleChangeComment = (val) => {
    setComment(val);
  };

  const followUpAssignmentsHandler = (event, data, type) => {
    event.stopPropagation();
    setLinkAssignment({ element: event.currentTarget, data, type });
  };

  const followUpAssignmentsHandlerClose = (event) => {
    setLinkAssignment({ element: null });
  };

  const openLinkModal = Boolean(linkAssignment.element);
  const OpenLinkModalId = openLinkModal ? "simple-popover" : undefined;

  const selectedAssignmentLockInfo =
    selectedAssignment &&
    assignmentLockIds &&
    assignmentLockIds.find((a) => a.lockId === selectedAssignment.lockId);
  const isDownloadedAssignment =
    selectedAssignment &&
    selectedAssignmentLockInfo &&
    selectedAssignmentLockInfo.userId === user.id &&
    selectedAssignment.lockId;

  const handleInternalCompleteAssignment = (payload) => {
    dispatch(surveyActions.completeInternalAssignments(payload));
  };

  const handleResetAssignmentStatus = (assignmentIds) => {
    dispatch(surveyActions.resetAssignmentStatusAction(assignmentIds));
  };

  const handleCompleteAssignment = (payload) => {
    dispatch(surveyActions.assignmentsComplete(payload));
  };

  const handlePreviewDocument = async (files, selectedItem) => {
    if (files && files.length) {
      const documents = [];
      setIsOpenDocViewer(true);
      setSelectedFile(selectedItem);
      files.map((file, i) => {
        const extension = file.name && file.name.split(".").pop();
        let uri = "";
        if (file.attachmentId && file.attachmentId.indexOf("-") < 0) {
          uri = offlineAttachments.find((x) => x.id === file.attachmentId).file;
        } else if (!window.navigator.onLine) {
          const storedFile = savedAttachments.find(
            (x) => x.fileId === file.attachmentId
          );
          uri = storedFile && storedFile.file;
        } else {
          uri =
            (webApiUrl === "/" ? window.location.origin : "") +
            URLS.GET_ASSIGNMENT_ATTACHMENT +
            file.attachmentId;
        }
        documents.push({
          uri: uri,
          fileType:
            extension === "pdf"
              ? "application/pdf"
              : i % 2 === 0
              ? "image/jpeg"
              : "image/png", //getMimeTypefromString(file.extension),
          name: file.name,
          description: t("files.fileUploadedByOn", {
            uploadedBy: file.uploadedBy,
            name: file.name,
            modifiedOn: formatDate(new Date(file.lastModifiedOn)),
          }),
        });
      });
      if (documents.length === files.length) {
        setPreviewDocument(documents);
      }
    }
  };

  const goBack = () => {
    history.goBack();
    //    history.replace({pathname: `/werk/${selected.projectNo}/${app === 3 ? "kwaliteitsborging/" : ""}opname/opdrachten`, state: {canGoBack:history.location.state && history.location.state.canGoBack}});
  };

  if (getAssignmentLoading)
    return (
      <div className={classes.layoutLoading}>
        <CircularProgress size={20} />
      </div>
    );

  return (
    selectedAssignment && (
      <Container className={classes.mainContainer} maxWidth={false}>
        <Grid container className={classes.container}>
          <Grid item xs={12} container className={classes.innerContainer}>
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              className={classes.blocksContainer}
            >
              <AppBar
                position="sticky"
                color="inherit"
                className={classes.surveyMomentsMainHeaderSecond}
              >
                <Toolbar
                  variant="dense"
                  className={classes.surveyMomentsHeaderSecond}
                >
                  {
                    <React.Fragment>
                      {
                        <IconButton
                          className={classes.backButton}
                          edge="start"
                          aria-label="GoBack"
                          color="inherit"
                          onClick={goBack}
                          size="large"
                        >
                          <ArrowBack className={classes.arrowBlackIcon} />
                        </IconButton>
                      }
                      <Grid
                        item
                        lg={12}
                        style={{ width: "100%", overflow: "hidden" }}
                      >
                        {
                          <div
                            className={clsx(classes.grow, classes.bold)}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            noWrap
                          >
                            <>
                              <div className={classes.appHeader}>
                                <Typography
                                  noWrap
                                  title={
                                    selectedAssignment.surveyingMomentDetails &&
                                    selectedAssignment.surveyingMomentDetails
                                      .name
                                  }
                                  className={classes.appBarText}
                                >
                                  {
                                    selectedAssignment.surveyingMomentDetails
                                      .name
                                  }
                                </Typography>
                                {
                                  <>
                                    <Hidden mdUp>
                                      <ArrowForwardIos
                                        className={classes.arrowIconStyle}
                                      />
                                    </Hidden>
                                    <Typography
                                      title={t(
                                        selectedAssignment.controlPointDesc
                                      )}
                                      className={clsx(
                                        classes.appBarText,
                                        classes.appBarSubtitle
                                      )}
                                    >
                                      {selectedAssignment.controlPointDesc}
                                    </Typography>
                                    <Typography
                                      title={t(
                                        selectedAssignment.surveyingMomentDetails &&
                                          selectedAssignment
                                            .surveyingMomentDetails.object
                                      )}
                                      className={clsx(
                                        classes.appBarText,
                                        classes.appBarSubtitle
                                      )}
                                    >
                                      {
                                        selectedAssignment
                                          .surveyingMomentDetails.object
                                      }
                                    </Typography>
                                  </>
                                }
                              </div>
                              {selectedAssignment.startDate &&
                                selectedAssignment.endDate && (
                                  <Hidden only="xs">
                                    <div style={{ whiteSpace: "nowrap" }}>
                                      <Typography
                                        className={classes.appBarSubText}
                                      >
                                        {`${formatDate(
                                          new Date(
                                            selectedAssignment.startDate
                                          ),
                                          false
                                        )} naar ${formatDate(
                                          new Date(selectedAssignment.endDate),
                                          false
                                        )}`}
                                      </Typography>
                                    </div>
                                  </Hidden>
                                )}
                              <div style={{ whiteSpace: "nowrap" }}>
                                {selectedAssignment && (
                                  <>
                                    {(!wbProjectInfo ||
                                      (wbProjectInfo &&
                                        !wbProjectInfo.archivedAt)) && (
                                      <Tooltip title={t("general.more")}>
                                        <IconButton
                                          aria-label="More Options"
                                          edge="end"
                                          color="inherit"
                                          aria-owns={
                                            isOpenMoreOptions
                                              ? "More Options"
                                              : undefined
                                          }
                                          onClick={({ currentTarget }) =>
                                            setIsOpenMoreOption(currentTarget)
                                          }
                                          size="large"
                                        >
                                          <MoreVert
                                            className={classes.moreOptionIcon}
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    <Popover
                                      open={!!isOpenMoreOptions}
                                      className={classes.menuContent}
                                      disableRestoreFocus
                                      anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                      }}
                                      transformOrigin={{
                                        vertical: "top",
                                        horizontal: "right",
                                      }}
                                      id={"edit-remark"}
                                      anchorEl={isOpenMoreOptions}
                                      onClose={() => setIsOpenMoreOption(null)}
                                    >
                                      <MenuList
                                        className={classes.menuListStyle}
                                      >
                                        {!isCompleteAssignment(
                                          selectedAssignment.internalStatus
                                        ) && (
                                          <StyledMenuItem
                                            disabled={
                                              (!selectedAssignmentLockInfo &&
                                                selectedAssignment.lockId) ||
                                              (!selectedAssignmentLockInfo &&
                                                !isOnline) ||
                                              !selectedAssignment.answer ||
                                              isInternalCompleteAssignmentLoading ||
                                              (wbProjectInfo &&
                                                wbProjectInfo.archivedAt)
                                            }
                                            onClick={() =>
                                              handleInternalCompleteAssignment([
                                                selectedAssignment.assignmentId,
                                              ])
                                            }
                                          >
                                            <ListItemIcon>
                                              {isInternalCompleteAssignmentLoading ? (
                                                <CircularProgress
                                                  size={18}
                                                  style={{ minWidth: 0 }}
                                                />
                                              ) : (
                                                <ListItemIcon
                                                  style={{ minWidth: "40px" }}
                                                >
                                                  <Send fontSize="small" />
                                                </ListItemIcon>
                                              )}
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                              {t("wkb.provisionallyCompleted")}
                                            </Typography>
                                          </StyledMenuItem>
                                        )}
                                        {selectedAssignment.canSendToWB && (
                                          <Tooltip
                                            title={
                                              selectedAssignment.lockId
                                                ? t(
                                                    "wkb.assignment.disabled.sendToWKB"
                                                  )
                                                : ""
                                            }
                                          >
                                            <span className={classes.flex}>
                                              <StyledMenuItem
                                                disabled={
                                                  selectedAssignment.lockId ||
                                                  !isOnline ||
                                                  !selectedAssignment.answer ||
                                                  isReadOnly(
                                                    selectedAssignment.internalStatus
                                                  ) ||
                                                  (wbProjectInfo &&
                                                    wbProjectInfo.archivedAt)
                                                }
                                                onClick={() =>
                                                  handleCompleteAssignment([
                                                    selectedAssignment.assignmentId,
                                                  ])
                                                }
                                              >
                                                <ListItemIcon>
                                                  {isCompleteAssignmentLoading ? (
                                                    <CircularProgress
                                                      size={18}
                                                      style={{ minWidth: 0 }}
                                                    />
                                                  ) : (
                                                    <ListItemIcon
                                                      style={{
                                                        minWidth: "40px",
                                                      }}
                                                    >
                                                      <BeenhereOutlined fontSize="small" />
                                                    </ListItemIcon>
                                                  )}
                                                </ListItemIcon>
                                                <Typography variant="inherit">
                                                  {t("wkb.sendToWB")}
                                                </Typography>
                                              </StyledMenuItem>
                                            </span>
                                          </Tooltip>
                                        )}

                                        {selectedAssignment.internalStatus ===
                                          1 && (
                                          <StyledMenuItem
                                            disabled={
                                              (!selectedAssignmentLockInfo &&
                                                selectedAssignment.lockId) ||
                                              (!selectedAssignmentLockInfo &&
                                                !isOnline) ||
                                              !selectedAssignment.answer ||
                                              !wbProjectInfo ||
                                              (wbProjectInfo &&
                                                wbProjectInfo.archivedAt)
                                            }
                                            onClick={() =>
                                              handleResetAssignmentStatus([
                                                selectedAssignment.assignmentId,
                                              ])
                                            }
                                          >
                                            <ListItemIcon>
                                              {resetAssignmentLoading ? (
                                                <CircularProgress
                                                  size={18}
                                                  style={{ minWidth: 0 }}
                                                />
                                              ) : (
                                                <ListItemIcon
                                                  style={{ minWidth: "40px" }}
                                                >
                                                  <BeenhereOutlined fontSize="small" />
                                                </ListItemIcon>
                                              )}
                                            </ListItemIcon>
                                            <Typography variant="inherit">
                                              {t("wkb.resetAssignmentStatus")}
                                            </Typography>
                                          </StyledMenuItem>
                                        )}
                                      </MenuList>
                                    </Popover>
                                  </>
                                )}
                              </div>
                            </>
                          </div>
                        }
                      </Grid>
                    </React.Fragment>
                  }
                </Toolbar>
              </AppBar>
              <Grid container className={classes.foundationBlock}>
                <div style={{ width: "100%" }}>
                  <Grid container className={classes.block}>
                    <Snackbar
                      style={{ top: 120 }}
                      TransitionComponent={(props) => (
                        <Slide {...props} direction="left" />
                      )}
                      anchorOrigin={{ vertical: "top", horizontal: "right" }}
                      open={fileError.filesSizeIsInValid}
                      onClose={() =>
                        setFileError((p) => ({
                          ...p,
                          filesSizeIsInValid: false,
                          fileUploadErrorMessage: null,
                        }))
                      }
                      autoHideDuration={3000}
                      key={"file-size-validation"}
                    >
                      <Alert elevation={6} variant="filled" severity="error">
                        {t(fileError.fileUploadErrorMessage)}
                      </Alert>
                    </Snackbar>
                    <Grid item container xs={12} md={8} sm={8}>
                      <Grid xs={12} className={classes.mainInnerContainers}>
                        <Card className={classes.cardContainer}>
                          <CardContent>
                            <Typography
                              className={clsx(
                                classes.mainTitle,
                                classes.assignmentTitle
                              )}
                              gutterBottom
                            >
                              <span className={classes.grow}>
                                {t("wkb.assignments.label")}
                              </span>
                              <div className={classes.detailIconContainer}>
                                {isDownloadedAssignment && (
                                  <Tooltip
                                    title={t(`general.downloaded`, {
                                      moduleType: t("wkb.assignment.label"),
                                    })}
                                  >
                                    <GetApp
                                      color="primary"
                                      className={classes.icon}
                                    />
                                  </Tooltip>
                                )}

                                {selectedAssignment.isCracked && (
                                  <Tooltip
                                    title={t(`general.locked`, {
                                      moduleType: t("wkb.assignment.label"),
                                    })}
                                  >
                                    <Warning
                                      color="primary"
                                      className={classes.icon}
                                    />
                                  </Tooltip>
                                )}
                                {!selectedAssignmentLockInfo &&
                                  selectedAssignment.lockId && (
                                    <Tooltip
                                      title={t(`general.lockBroken`, {
                                        moduleType: t("wkb.assignment.label"),
                                      })}
                                    >
                                      <Lock
                                        color="primary"
                                        className={classes.icon}
                                      />
                                    </Tooltip>
                                  )}
                              </div>
                            </Typography>
                            <Typography
                              className={classes.subTitle}
                              gutterBottom
                            >
                              {selectedAssignment.linkedAssignment && (
                                <Link
                                  className={classes.assignmentCustomIcon}
                                  onClick={(e) =>
                                    followUpAssignmentsHandler(
                                      e,
                                      selectedAssignment.linkedAssignment,
                                      "linkedAssignment"
                                    )
                                  }
                                />
                              )}
                              {selectedAssignment.controlPointDesc}
                            </Typography>

                            <Paper className={classes.papers}>
                              <Typography
                                className={classes.content}
                                gutterBottom
                              >
                                {nl2br(selectedAssignment.text)}
                              </Typography>
                            </Paper>
                          </CardContent>
                          <CardContent
                            className={clsx(
                              classes.explanationContainer,
                              classes.removeBottomPadding
                            )}
                          >
                            <Typography
                              className={clsx(
                                classes.subTitle,
                                classes.subTitleContainer
                              )}
                              gutterBottom
                            >
                              {t("general.explanation")}
                            </Typography>
                            <Paper className={classes.papers}>
                              <Typography
                                className={classes.content}
                                gutterBottom
                              >
                                {nl2br(selectedAssignment.explanation)}
                              </Typography>
                            </Paper>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid xs={12} className={classes.mainInnerContainers}>
                        <Card className={classes.cardContainer}>
                          <CardContent className={classes.subCardContainer}>
                            <Typography
                              className={classes.mainTitle}
                              gutterBottom
                            >
                              {t("wkb.assignment.evidence")}
                            </Typography>
                            <Grid container>
                              <Grid
                                xs={12}
                                md={12}
                                lg={6}
                                className={clsx(
                                  classes.rightDivider,
                                  classes.downDividerMd
                                )}
                              >
                                <CardContent
                                  className={classes.explanationContainer}
                                  style={{ paddingLeft: 0, paddingBottom: 0 }}
                                >
                                  <Typography
                                    className={classes.BewijsSubTitle}
                                    gutterBottom
                                  >
                                    {t("wkb.assignment.attachments")}
                                  </Typography>
                                  <Grid
                                    item
                                    xs={12}
                                    className={classes.filesContainer}
                                  >
                                    <Grid
                                      container
                                      className={classes.gridContainer}
                                    >
                                      <Grid item style={{ margin: "0 auto" }}>
                                        <Grid
                                          className={classes.thumnailContainer}
                                          container
                                          justifyContent="center"
                                        >
                                          <Dropzone
                                            hideExistingImageModal={
                                              !window.navigator.onLine
                                            }
                                            uploading={attachmentLoading}
                                            isHideLocatie={
                                              drawingFiles &&
                                              !drawingFiles.length
                                            }
                                            isAssignmentFile
                                            projectId={
                                              selectedAssignment.surveyingMomentDetails &&
                                              selectedAssignment
                                                .surveyingMomentDetails
                                                .projectId
                                            }
                                            buildingId={
                                              selectedAssignment.surveyingMomentDetails &&
                                              selectedAssignment
                                                .surveyingMomentDetails
                                                .buildingId
                                            }
                                            isFundering={
                                              selectedAssignment.surveyingMomentDetails &&
                                              !selectedAssignment
                                                .surveyingMomentDetails
                                                .buildingId
                                            }
                                            existingImageModal
                                            iconClasses={classes.uploadIcon}
                                            className={classes.dropzone}
                                            withUploadFileDialog
                                            redirectRepairRequestHandler={() => {
                                              setIsDrawingLocation(true);
                                              setOpenRepairRequestMap(true);
                                            }}
                                            isSurveying
                                            disabled={
                                              isReadOnlyAssignment ||
                                              canReadOnlyEvidenceSection
                                            }
                                            title="general.choosePhotosOrAttachments"
                                            handleSelectExistingImages={
                                              uploadExistingFiles
                                            }
                                            onFilesAdded={(data) =>
                                              handleAddAttachment(data)
                                            }
                                            accept={fileUploadConfig.allowedImageFormats
                                              .concat({
                                                extension: ".pdf",
                                                mime: "application/pdf",
                                              })
                                              .map((p) => p.mime)
                                              .join()}
                                          />
                                        </Grid>
                                      </Grid>
                                      {selectedAssignment.assignmentAttachments &&
                                        selectedAssignment.assignmentAttachments.map(
                                          (p, i) => {
                                            if (p.id.indexOf("-") < 0)
                                              return null;
                                            const isPDF =
                                              p.name.split(".").pop() === "pdf";
                                            return (
                                              <Grid
                                                key={p.id}
                                                className={
                                                  classes.attachmentItemContainer
                                                }
                                                item
                                                onClick={() =>
                                                  handlePreviewDocument(
                                                    selectedAssignment.assignmentAttachments.map(
                                                      (a) => ({
                                                        attachmentId: a.id,
                                                        name: a.name,
                                                      })
                                                    ),
                                                    i
                                                  )
                                                }
                                              >
                                                <div
                                                  className={classes.overlay}
                                                >
                                                  {!selectedAssignment.lockId &&
                                                    isOnline &&
                                                    !isReadOnlyAssignment && (
                                                      <div
                                                        className={
                                                          classes.isRight
                                                        }
                                                      >
                                                        <Tooltip
                                                          arrow
                                                          title={t(
                                                            p.sentToWb
                                                              ? "wkb.assignment.internalStatus.2"
                                                              : "general.remove"
                                                          )}
                                                        >
                                                          <IconButton
                                                            className={clsx(
                                                              classes.deleteButton,
                                                              classes.noOutline
                                                            )}
                                                            size={"small"}
                                                          >
                                                            {p.sentToWb ? (
                                                              <Done
                                                                fontSize="small"
                                                                className={
                                                                  classes.colorSuccess
                                                                }
                                                              />
                                                            ) : (
                                                              <DeleteOutline
                                                                onClick={(e) =>
                                                                  handleRemoveAttachment(
                                                                    e,
                                                                    p.id
                                                                  )
                                                                }
                                                                className={
                                                                  classes.deleteIcon
                                                                }
                                                                size={"small"}
                                                              />
                                                            )}
                                                          </IconButton>
                                                        </Tooltip>
                                                      </div>
                                                    )}
                                                  {isPDF && (
                                                    <IconButton
                                                      className={clsx(
                                                        classes.deleteButton,
                                                        classes.noOutline,
                                                        classes.pdfIcon
                                                      )}
                                                      size={"small"}
                                                    >
                                                      <PictureAsPdf
                                                        color="primary"
                                                        className={
                                                          classes.pictureAsPdf
                                                        }
                                                        size={"small"}
                                                      />
                                                    </IconButton>
                                                  )}
                                                </div>
                                                <Grid item>
                                                  <Grid
                                                    className={
                                                      classes.thumnailContainer
                                                    }
                                                    container
                                                    xs={12}
                                                    justifyContent="center"
                                                  >
                                                    <OfflineFileWrapper
                                                      url={
                                                        p.id &&
                                                        `${URLS.GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL}${p.id}`
                                                      }
                                                      id={p.id}
                                                    >
                                                      {(url) => (
                                                        <div
                                                          className={
                                                            classes.thumbnail
                                                          }
                                                          style={{
                                                            backgroundImage:
                                                              p.id
                                                                ? `url(${url})`
                                                                : `url(${p.file})`,
                                                          }}
                                                        />
                                                      )}
                                                    </OfflineFileWrapper>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            );
                                          }
                                        )}
                                      {offlineAttachments &&
                                        !!offlineAttachments.length &&
                                        offlineAttachments.map((p, i) => {
                                          const index =
                                            selectedAssignment.assignmentAttachments.findIndex(
                                              (a) => a.id === p.id
                                            );
                                          const isPDF =
                                            p.name.split(".").pop() === "pdf";
                                          return (
                                            <Grid
                                              key={p.id}
                                              className={
                                                classes.attachmentItemContainer
                                              }
                                              item
                                              onClick={() =>
                                                handlePreviewDocument(
                                                  selectedAssignment.assignmentAttachments.map(
                                                    (a) => ({
                                                      attachmentId: a.id,
                                                      name: a.name,
                                                    })
                                                  ),
                                                  index
                                                )
                                              }
                                            >
                                              {
                                                <div
                                                  className={classes.overlay}
                                                >
                                                  <div
                                                    className={classes.isRight}
                                                  >
                                                    <Tooltip
                                                      title={t(
                                                        "general.remove"
                                                      )}
                                                    >
                                                      <IconButton
                                                        className={clsx(
                                                          classes.deleteButton,
                                                          classes.noOutline
                                                        )}
                                                        size={"small"}
                                                      >
                                                        <DeleteOutline
                                                          onClick={(e) =>
                                                            removeOfflineAttachmentHandler(
                                                              e,
                                                              p.id
                                                            )
                                                          }
                                                          className={
                                                            classes.deleteIcon
                                                          }
                                                          size={"small"}
                                                        />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </div>
                                                  {isPDF && (
                                                    <IconButton
                                                      className={clsx(
                                                        classes.deleteButton,
                                                        classes.noOutline,
                                                        classes.pdfIcon
                                                      )}
                                                      size={"small"}
                                                    >
                                                      <PictureAsPdf
                                                        color="primary"
                                                        className={
                                                          classes.pictureAsPdf
                                                        }
                                                        size={"small"}
                                                      />
                                                    </IconButton>
                                                  )}
                                                </div>
                                              }
                                              <Grid item>
                                                <Grid
                                                  className={
                                                    classes.thumnailContainer
                                                  }
                                                  container
                                                  xs={12}
                                                  justifyContent="center"
                                                >
                                                  <div
                                                    className={
                                                      classes.thumbnail
                                                    }
                                                    style={{
                                                      backgroundImage: `url(${p.file})`,
                                                    }}
                                                  />
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          );
                                        })}
                                    </Grid>
                                  </Grid>
                                </CardContent>
                                <Typography
                                  className={classes.fileInfoHelper}
                                  gutterBottom
                                >
                                  {t("wkb.assignment.fileUploadInfo")}
                                </Typography>
                              </Grid>
                              <Grid
                                xs={12}
                                md={12}
                                lg={6}
                                className={classes.remarkContainer}
                              >
                                <CardContent
                                  className={clsx(
                                    classes.explanationContainer,
                                    classes.removePaddingForMd
                                  )}
                                >
                                  <Typography
                                    component="div"
                                    className={classes.remarkTitle}
                                  >
                                    <Typography
                                      className={clsx(classes.subTitle)}
                                      gutterBottom
                                    >
                                      {t("general.remarks")}
                                    </Typography>
                                    {!isReadOnlyAssignment &&
                                      !canReadOnlyEvidenceSection && (
                                        <IconButton
                                          aria-owns={
                                            editRemark.popover
                                              ? "edit-remark"
                                              : undefined
                                          }
                                          size="small"
                                          aria-label="edit-remark"
                                          onClick={(e) => {
                                            if (
                                              canEditRemark &&
                                              editRemark.remark !==
                                                selectedAssignment.remark
                                            )
                                              handleChangeRemark(e);
                                            setCanEditRemark(!canEditRemark);
                                            // matchWidthDownSm ? toggleDrawerOpen(e) : setEditRemark(p => ({ ...p, popover: e.currentTarget }))
                                          }}
                                        >
                                          {isUpdatingAssignment &&
                                          loaderKey === "remark" ? (
                                            <CircularProgress
                                              size={20}
                                              className={classes.loader}
                                            />
                                          ) : canEditRemark ? (
                                            <Done fontSize="small" />
                                          ) : (
                                            <Edit fontSize="small" />
                                          )}
                                        </IconButton>
                                      )}
                                  </Typography>
                                  <TextField
                                    fullWidth
                                    inputRef={remarkRef}
                                    className={classes.remarkTextField}
                                    onClick={(e) => {
                                      !isReadOnlyAssignment &&
                                        !canEditRemark &&
                                        setCanEditRemark(true);
                                    }}
                                    inputProps={{
                                      className: classes.customTextField,
                                    }}
                                    multiline
                                    rows={4}
                                    disabled={
                                      !canEditRemark ||
                                      isReadOnlyAssignment ||
                                      canReadOnlyEvidenceSection
                                    }
                                    value={editRemark.remark}
                                    onChange={({ target: { value } }) => {
                                      setEditRemark((p) => ({
                                        ...p,
                                        remark: value,
                                      }));
                                    }}
                                    id="remark-text"
                                    variant="outlined"
                                  />
                                </CardContent>
                                <CardContent
                                  className={clsx(
                                    classes.explanationContainer,
                                    classes.removePaddingForMd
                                  )}
                                >
                                  <Typography
                                    className={clsx(
                                      classes.subTitle,
                                      classes.explanationTitle
                                    )}
                                    gutterBottom
                                  >
                                    {t("wkb.assignment.judgement")}
                                  </Typography>
                                  <Grid
                                    container
                                    className={classes.beoordelingContainer}
                                  >
                                    {selectedAssignment.answerOptions &&
                                      selectedAssignment.answerOptions.map(
                                        (answer, index) =>
                                          answer.answerOptionColor !==
                                            "oranje" && (
                                            <Grid
                                              style={{ padding: 8 }}
                                              key={answer.answerOption}
                                              item
                                              xs={6}
                                              sm={4}
                                              md={4}
                                              lg={6}
                                            >
                                              <Button
                                                selected={
                                                  answer.answerOption ===
                                                  selectedAssignment.answer
                                                }
                                                disabled={
                                                  isReadOnlyAssignment ||
                                                  canReadOnlyEvidenceSection
                                                }
                                                title={answer.answerOptionLabel}
                                                variant="outlined"
                                                onClick={() => {
                                                  setSelectedAnswerOptions(
                                                    answer.answerOption
                                                  );
                                                  dispatch(
                                                    surveyActions.updateAssignmentData(
                                                      {
                                                        assignmentId:
                                                          params.assignmentId,
                                                        answer:
                                                          answer.answerOption ===
                                                          selectedAssignment.answer
                                                            ? ""
                                                            : answer.answerOption,
                                                      },
                                                      selectedAssignment.answerOptions
                                                    )
                                                  );
                                                  setLoaderKey("answer");
                                                }}
                                                // color={answerColors[answer.answerOptionColor]}
                                                className={clsx(
                                                  classes.ratingButton,
                                                  classes[
                                                    answer.answerOptionColor
                                                  ],
                                                  answer.answerOption ===
                                                    selectedAssignment.answer &&
                                                    classes.selectedAnswer,
                                                    answer.answerOptionColor ==='rood' && classes.selectedAnswerRood  
                                                )}
                                              >
                                                {isUpdatingAssignment &&
                                                loaderKey === "answer" &&
                                                selectedAnswerOptions ===
                                                  answer.answerOption ? (
                                                  <CircularProgress
                                                    size={20}
                                                    className={classes.loader}
                                                  />
                                                ) : (
                                                  <>
                                                    {answer.answerOptionLabel}
                                                  </>
                                                )}
                                              </Button>
                                            </Grid>
                                          )
                                      )}
                                  </Grid>
                                </CardContent>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      xs={12}
                      md={4}
                      sm={4}
                      className={classes.innerContainers}
                    >
                      <Card className={classes.cardContainer}>
                        <CardContent style={{ height: "100%" }}>
                          <div className={classes.detailMainContainer}>
                            <div>
                              <Grid
                                container
                                item
                                xs={12}
                                className={classes.detailContainer}
                              >
                                <Grid
                                  item
                                  xs={6}
                                  sm={12}
                                  md={12}
                                  lg={6}
                                  className={clsx(
                                    classes.rightDivider,
                                    classes.downDividerSm
                                  )}
                                >
                                  <CardContent
                                    className={classes.explanationContainer}
                                    style={{ paddingLeft: 0, paddingBottom: 0 }}
                                  >
                                    <Typography className={classes.detailTitle}>
                                      Status
                                    </Typography>
                                    <div className={classes.detailSubContainer}>
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {t("general.status")}:{" "}
                                        <span className={classes.subTitleText}>
                                          {t(
                                            internalAssignmentStatus[
                                              selectedAssignment.internalStatus
                                            ]
                                          )}
                                          {!!selectedAssignment
                                            .followUpAssignments.length && (
                                            <DescriptionOutlined
                                              className={
                                                classes.assignmentCustomIcon
                                              }
                                              onClick={(e) =>
                                                followUpAssignmentsHandler(
                                                  e,
                                                  selectedAssignment.followUpAssignments,
                                                  "followUpAssignments"
                                                )
                                              }
                                            />
                                          )}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.detailSubContainerWrap
                                      )}
                                    >
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {`${t("wkb.wbReceivedDate")}:`}&nbsp;
                                      </Typography>
                                      {selectedAssignment.wbReceivedDate && (
                                        <Typography
                                          className={classes.detailSubTitle}
                                        >
                                          {formatDate(
                                            new Date(
                                              selectedAssignment.wbReceivedDate
                                            )
                                          )}
                                        </Typography>
                                      )}
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.detailSubContainerWrap
                                      )}
                                    >
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {`${t("wkb.wbJudgement")}:`}&nbsp;
                                      </Typography>
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        <Tooltip
                                          title={selectedAssignment.wbStatus}
                                          arrow
                                          placement="bottom"
                                        >
                                          <span
                                            className={classes.wbStatusIcon}
                                          >
                                            {selectedAssignment.wbStatus ===
                                              "[WB akkoord]" ||
                                            selectedAssignment.wbStatus ===
                                              "Akkoord" ? (
                                              <CheckCircle
                                                fontSize="small"
                                                className={classes.colorSuccess}
                                              />
                                            ) : selectedAssignment.wbStatus ===
                                                "[WB niet akkoord]" ||
                                              selectedAssignment.wbStatus ===
                                                "Niet akkoord" ? (
                                              <Cancel
                                                fontSize="small"
                                                color="error"
                                              />
                                            ) : !!selectedAssignment.wbStatus &&
                                              selectedAssignment.wbStatus !==
                                                "" ? (
                                              <PriorityHigh
                                                fontSize="small"
                                                className={classes.warning}
                                              />
                                            ) : (
                                              selectedAssignment.wbStatus
                                            )}
                                            {selectedAssignment.wbStatus}
                                          </span>
                                        </Tooltip>
                                        {/* {t(selectedAssignment.wbStatus)} */}
                                      </Typography>
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.detailSubContainerWrap
                                      )}
                                    >
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {`${t("wkb.wbJudgementDate")}:`}&nbsp;
                                      </Typography>
                                      {selectedAssignment.wbJudgmentDate && (
                                        <Typography
                                          className={classes.detailSubTitle}
                                        >
                                          {formatDate(
                                            new Date(
                                              selectedAssignment.wbJudgmentDate
                                            )
                                          )}
                                        </Typography>
                                      )}
                                    </div>
                                  </CardContent>
                                </Grid>
                                <Grid item xs={6} sm={12} md={12} lg={6}>
                                  <CardContent
                                    className={clsx(
                                      classes.explanationContainer,
                                      classes.removePaddingForSm
                                    )}
                                  >
                                    <div className={classes.titleContainer}>
                                      <Typography
                                        className={classes.detailTitle}
                                      >
                                        {t("general.info")}
                                      </Typography>
                                      <WBProjectInfo />
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.avatarContainer
                                      )}
                                    >
                                      <Typography
                                        className={clsx(
                                          classes.detailSubTitle,
                                          classes.avatarContainer
                                        )}
                                      >
                                        {t("wkb.executive")}:&nbsp;
                                      </Typography>
                                      {selectedAssignment.responsiblePerson && (
                                        <Avatar
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleUserInfoPopoverOpen(
                                              e,
                                              false,
                                              selectedAssignment.responsiblePerson
                                            );
                                          }}
                                          // onMouseEnter={(e) => handleUserInfoPopoverOpen(e, false, selectedAssignment.responsiblePerson)}
                                          // onMouseOut={() => clearTimeout(userInfoPopover.timeOutId)}
                                          className={classes.avatar}
                                          src={
                                            webApiUrl +
                                            "api/home/GetPersonPhoto/" +
                                            selectedAssignment.responsiblePerson
                                              .relationPersonId
                                          }
                                        >
                                          {getNameInitials(
                                            selectedAssignment.responsiblePerson
                                              .relationName
                                          )}
                                        </Avatar>
                                      )}
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.avatarContainer
                                      )}
                                    >
                                      <Typography
                                        className={clsx(
                                          classes.detailSubTitle,
                                          classes.avatarContainer
                                        )}
                                      >
                                        {t("wkb.assignment.delegate")}:&nbsp;
                                      </Typography>
                                      {selectedAssignment.assignedPerson ? (
                                        <Avatar
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            handleUserInfoPopoverOpen(
                                              e,
                                              selectedAssignment.canSendToWB &&
                                                !selectedAssignment.lockId,
                                              selectedAssignment.assignedPerson
                                            );
                                          }}
                                          // onMouseEnter={(e) => handleUserInfoPopoverOpen(e, true, selectedAssignment.assignedPerson)}
                                          // onMouseOut={() => clearTimeout(userInfoPopover.timeOutId)}
                                          className={classes.avatar}
                                          src={
                                            webApiUrl +
                                            "api/home/GetPersonPhoto/" +
                                            selectedAssignment.assignedPerson
                                              .relationPersonId
                                          }
                                        >
                                          {getNameInitials(
                                            selectedAssignment.assignedPerson
                                              .relationName
                                          )}
                                        </Avatar>
                                      ) : (
                                        !isReadOnlyAssignment &&
                                        isOnline &&
                                        !selectedAssignment.lockId && (
                                          <Edit
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleUserInfoPopoverOpen(
                                                e,
                                                true,
                                                selectedAssignment.assignedPerson
                                              );
                                            }}
                                            // onMouseEnter={(e) => handleUserInfoPopoverOpen(e, true, selectedAssignment.assignedPerson)}
                                            // onMouseOut={() => clearTimeout(userInfoPopover.timeOutId)}
                                            className={classes.avatar}
                                            fontSize="small"
                                          />
                                        )
                                      )}
                                    </div>

                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.detailSubContainerWrap
                                      )}
                                    >
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {t("general.startDate")}:&nbsp;
                                      </Typography>
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {formatDate(
                                          new Date(selectedAssignment.startDate)
                                        )}
                                      </Typography>
                                    </div>
                                    <div
                                      className={clsx(
                                        classes.detailSubContainer,
                                        classes.detailSubContainerWrap
                                      )}
                                    >
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {t("general.endDate")}:&nbsp;
                                      </Typography>
                                      <Typography
                                        className={classes.detailSubTitle}
                                      >
                                        {formatDate(
                                          new Date(selectedAssignment.endDate)
                                        )}
                                      </Typography>
                                    </div>
                                  </CardContent>
                                </Grid>
                              </Grid>
                            </div>
                            <Typography className={classes.detailCommentTitle}>
                              {t("wkb.assignment.internalNotes")}
                            </Typography>
                            <div className={classes.messageContainer}>
                              <Grid
                                container
                                className={clsx(
                                  classes.conversations,
                                  selectedAssignment.assignmentMessages &&
                                    !selectedAssignment.assignmentMessages
                                      .length
                                    ? classes.emptyMessage
                                    : ""
                                )}
                                ref={messagesEndRef}
                              >
                                <Grid
                                  item
                                  xs={1}
                                  className={classes.timeLine}
                                />
                                <Grid
                                  item
                                  xs={11}
                                  className={classes.timeLineText}
                                >
                                  {selectedAssignment.assignmentMessages &&
                                    selectedAssignment.assignmentMessages
                                      .sort(
                                        (a, b) =>
                                          new Date(a.dateTime) -
                                          new Date(b.dateTime)
                                      )
                                      .map((p, i) => (
                                        <React.Fragment key={p.messageId}>
                                          <div>
                                            <CardHeader
                                              className={
                                                classes.conversationUserAvatar
                                              }
                                              avatar={
                                                <Avatar
                                                  className={
                                                    classes.smallAvatar
                                                  }
                                                />
                                              }
                                              title={p.senderName}
                                            />
                                            <div
                                              className={classes.conversation}
                                            >
                                              <Paper className={classes.msIcon}>
                                                <MailOutline
                                                  style={{ fontSize: 14 }}
                                                  color="primary"
                                                />
                                              </Paper>
                                              <Markdown value={p.message} />
                                              {/* <Typography className={classes.conversationContent}>
                                                        {p.message}
                                                    </Typography> */}
                                            </div>
                                            <Typography
                                              className={
                                                classes.conversationTime
                                              }
                                            >
                                              {formatDate(
                                                new Date(p.dateTime),
                                                true,
                                                "/",
                                                false
                                              )}
                                            </Typography>
                                          </div>
                                        </React.Fragment>
                                      ))}
                                </Grid>
                              </Grid>
                              <Grid container className={classes.chatActions}>
                                <Grid
                                  container
                                  item
                                  xs={12}
                                  className={clsx(
                                    classes.chatCommentBoxContainer
                                  )}
                                >
                                  <Grid item xs={12}>
                                    <RichTextEditor
                                      AssignmentDetails
                                      label={`${t("general.message")}...`}
                                      showToolbar={showRichTextEditorToolbar}
                                      readOnly={isReadOnlyAssignment}
                                      onChange={handleChangeComment}
                                      textToInsertAtCursor={
                                        textToInsertInComment
                                      }
                                      richTextToInsertAtCursor={
                                        richTextToInsertInComment
                                      }
                                      onCompleteTextInsert={() => {
                                        setTextToInsertInComment("");
                                        setRichTextToInsertInComment("");
                                      }}
                                      value={comment}
                                    />
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  container
                                  className={classes.chatActionBoxContainer}
                                  direction="row"
                                  alignItems="flex-start"
                                  justifyContent="flex-start"
                                >
                                  <Grid item>
                                    <IconButton
                                      disabled={isReadOnlyAssignment}
                                      size="small"
                                      aria-label="Chat"
                                      color={
                                        !showRichTextEditorToolbar
                                          ? "default"
                                          : "primary"
                                      }
                                      onClick={() =>
                                        setShowRichTextEditorToolbar(
                                          !showRichTextEditorToolbar
                                        )
                                      }
                                    >
                                      <TextFormat />
                                    </IconButton>
                                  </Grid>
                                  <Grid item>
                                    <EmojiSelector
                                      disabled={isReadOnlyAssignment}
                                      size="small"
                                      onSelect={(value) =>
                                        setTextToInsertInComment(value)
                                      }
                                    />
                                  </Grid>
                                  <Grid item className={classes.grow}></Grid>
                                  <Grid item>
                                    <IconButton
                                      disabled={!comment}
                                      edge="end"
                                      size="small"
                                      aria-label="Bericht"
                                      onClick={handleSendMessages}
                                    >
                                      <Send />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={12} className={classes.innerContainers}>
                      {/* <RepairRequests /> */}
                      <Container
                        ref={ref}
                        maxWidth={false}
                        className={classes.mainContainer}
                      >
                        <DataGrid
                          canListType={canListType}
                          CustomRowRender={
                            matchWidthDownSm && !!canListType
                              ? (rest) => (
                                  <AssignmentRepairRequestCustomRow
                                    onRowClick={handleRowClick}
                                    {...props}
                                    {...rest}
                                  />
                                )
                              : null
                          }
                          options={options}
                          identifier="requestId"
                          localColumnOrdersIdentifier={generateLocalStorageKey(
                            "Assignment_RepairRequest_Table_List_Order"
                          )}
                          columns={columns}
                          columnsVisibilityIdentifier={generateLocalStorageKey(
                            "Assignment_RepairRequest_ColumnsVisibility"
                          )}
                          data={requests}
                          rowsPerPageIdentifier={generateLocalStorageKey(
                            "Assignment_RepairRequest_RowsPerPage"
                          )}
                          title={t("general.repairRequests")}
                          IconComponent={() =>
                            repairRequests &&
                            !!repairRequests.length &&
                            repairRequests.some((p) => p.drawingPinLocation) &&
                            drawingFiles &&
                            !!drawingFiles.length && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={() => listToMapRedirectHandler()}
                                size="large"
                              >
                                <MapOutlined className={classes.mapIcon} />
                              </IconButton>
                            )
                          }
                          loading={loadingRepairRequests}
                          canGoBack={false}
                          fontSize={fontSize}
                        />
                      </Container>
                    </Grid>

                    <ImagesContainer
                      data={requestAttachmentsData}
                      onSelectFiles={uploadExistingFiles}
                      open={imageContainerOpen}
                      isReadOnly={isReadOnlyAssignment}
                      onClose={() => handleImageModalToggler([])}
                      onPreview={() =>
                        handlePreviewDocument(requestAttachmentsData)
                      }
                      isImagesContainer
                    />
                    <Dialog
                      className={classes.confirmationDialog}
                      open={confirmUpdateLocation}
                      onClose={() => {
                        setConfirmUpdateLocation(false);
                        setIsDrawingLocation(false);
                        setDrawingLocationState(null);
                      }}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText
                          id="alert-dialog-description"
                          className={classes.dialogContextText}
                        >
                          {t("wkb.assignment.addLocationEvidenceAlert")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          className={classes.dialogButtonPrimary}
                          onClick={handleSubmitUpdatedLocation}
                        >
                          {t("button.action.yes")}
                        </Button>
                        <Button
                          className={classes.dialogButtonSecondary}
                          onClick={() => {
                            setIsDrawingLocation(false);
                            setDrawingLocationState(null);
                            setConfirmUpdateLocation(false);
                          }}
                        >
                          {t("button.action.no")}
                        </Button>
                      </DialogActions>
                    </Dialog>

                    <LinkAssignmentModal
                      id={OpenLinkModalId}
                      open={openLinkModal}
                      anchorEl={linkAssignment.element}
                      onClose={followUpAssignmentsHandlerClose}
                      data={linkAssignment.data}
                      type={linkAssignment.type}
                    />

                    <UserInformation
                      data={{
                        userInformation: userInfoPopover.data,
                        assignmentId: params.assignmentId,
                        id: history.location.state
                          ? history.location.state.controlPointId
                          : null,
                        selectedMomentId: params.surveyingMomentId,
                      }}
                      projectId={
                        selectedAssignment && selectedAssignment.projectId
                      }
                      repairRequestRight={isEditable}
                      isEditable={
                        !isReadOnlyAssignment && isEditable && isOnline
                      }
                      hideExtraInfo
                      requestKey="updateAssignedPersonForAssignment"
                      open={!!userInfoPopover.element}
                      anchorEl={userInfoPopover.element}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      onClose={handleUserInfoPopoverClose}
                    />

                    <Popover
                      open={openTooltip.element && openTooltip.image}
                      anchorEl={openTooltip.element}
                      onClose={handleTooltipClose}
                      disableRestoreFocus
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className={classes.tooltipImageContainer}>
                        <img src={openTooltip.image} alt="" />
                      </div>
                    </Popover>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <DocumentViewer
          selectedFile={selectedFile}
          radius={classes.docRadius}
          isSurveying
          open={isOpenDocViewer}
          onClose={() => {
            setIsOpenDocViewer(false);
            setPreviewDocument([]);
          }}
          documents={previewDocument}
        />
        <RepairRequestForm
          repairRequests={requests}
          isUpdate={!!offlineRepairRequestData}
          values={offlineRepairRequestData}
          moduleType={SurveyType.WKB}
          setSelectedDrawingFiles={setSelectedDrawingFiles}
          setCurrentPage={setCurrentPage}
          isAssignmentDetail
          buildingId={selectedAssignment && selectedAssignment.buildingId}
          projectId={selectedAssignment && selectedAssignment.projectId}
          surveyId={selectedAssignment && selectedAssignment.assignmentId}
          selectedDrawingFiles={selectedDrawingFiles}
          currentPage={currentPage}
          onPreview={handlePreviewDocument}
          setID={(id) => console.log(id)}
          openRepairRequestMap={openRepairRequestMap}
          onClose={(reload) => {
            reload && setCanGoBack(new Date().getTime());
            setOpenRepairRequestMap(false);
            offlineRepairRequestData && setOfflineRepairRequestData(null);
            setCurrentPage(1);
          }}
          setIsDrawingLocation={(val) => setIsDrawingLocation(val)}
          isDrawingLocation={isDrawingLocation}
          setDrawingLocationState={setDrawingLocationState}
          drawingLocation={
            offlineRepairRequestData && {
              xrel: +offlineRepairRequestData["DrawingPinLocationModel.Xrel"],
              yrel: +offlineRepairRequestData["DrawingPinLocationModel.Yrel"],
              dossierFileId:
                offlineRepairRequestData[
                  "DrawingPinLocationModel.DossierFileId"
                ],
            }
          }
        />
      </Container>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },

  dialogHeaderContainer: {
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
    paddingBottom: 10,
    marginBottom: 10,
  },
  grow: {
    flexGrow: 1,
  },
  bold: {
    fontWeight: "bold",
  },
  chatActions: {
    backgroundColor: theme.palette.common.white,
    position: "relative",
    zIndex: 5,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(1, 1, 0),
    },
  },
  chatActionBoxContainer: {
    padding: theme.spacing(0.5, 1),
    maxWidth: 774,
    margin: "auto",
    "& > div": {
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: theme.spacing(0),
      },
    },
  },
  replyMessageCard: {
    width: "calc(100% - 48px)",
    backgroundColor: "rgba(0,0,0,.05)",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    boxShadow: "none",
    borderLeftWidth: 4,
    borderLeftStyle: "solid",
    marginBottom: theme.spacing(0.5),
    "& .MuiCardContent-root": {
      padding: theme.spacing(1, 2),
      flexGrow: 1,
    },
    "& .MuiCardMedia-root": {
      width: 62,
      height: 62,
    },
  },
  chatCommentBoxContainer: {
    position: "absolute",
    bottom: 35,
    maxWidth: 774,
    left: 0,
    width: "100%",
    margin: "auto",
    borderLeftWidth: theme.spacing(0.5),
    borderLeftStyle: "solid",
    borderLeftColor: "transparent",
    padding: theme.spacing(0.5, 1, 0.5, 0.5),
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100%",
    },
    borderBottomWidth: theme.spacing(0.25),
    borderBottomStyle: "solid",
    borderBottomColor: theme.palette.primary.main,
    paddingBottom: theme.spacing(0.25),
  },
  cardSubTitle: {
    fontSize: 12,
    fontWeight: "bold",
    color: theme.palette.common.black,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    marginBottom: 4,
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  detailCommentContainer: {
    padding: theme.spacing(1, 0),
    paddingBottom: "10px !important",
  },
  detailCommentTitle: {
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    margin: `10px 0`,
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  detailSubContainerWrap: {
    flexDirection: "column",
    [theme.breakpoints.only("xs")]: {
      flexDirection: "row",
    },
  },
  colorSuccess: {
    color: theme.palette.success.main,
  },
  warning: {
    color: theme.palette.warning.dark,
    fill: theme.palette.warning.dark,
  },
  wbStatusIcon: {
    marginLeft: 4,
  },
  detailSubContainer: {
    padding: theme.spacing(1, 0),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    flexDirection: "row",
  },
  detailIcon: {
    fontSize: 12,
  },
  detailContainer: {
    borderBottom: `2px solid ${theme.palette.grey[300]}`,
    height: "fit-content",
  },
  detailMainContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  detailTitle: {
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  assignmentCustomIcon: {
    fill: "#36469c",
  },
  detailSubTitle: {
    fontSize: 14,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
  },
  subTitleText: {
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
    fontSize: 14,
    fontWeight: "bold",
    color: theme.palette.grey[700],
  },
  block: {
    width: "100%",
    height: "100%",
  },
  subCardContainer: {
    padding: theme.spacing(1, 2),
    paddingBottom: "0 !important",
  },
  cardContainer: {
    width: "100%",
    height: "100%",
    boxShadow: "rgba(0, 0, 0, 0.24)0px 3px 8px",
    borderRadius: 4,
    "& > .MuiCardContent-root": {
      // paddingBottom: 0,
    },
  },
  messageContainer: {
    height: "100%",
    display: "flex",
    backgroundImage: "url(/content/images/background/brickwall-bg.png)",
    paddingTop: theme.spacing(1.7),
    flexDirection: "column",

    "& .MuiButtonGroup-root": {
      boxShadow: "none",
    },
    "& .MuiInputBase-root:hover": {
      outline: "none",
    },
  },
  mapIcon: {
    fill: theme.palette.primary.main,
    fontSize: 18,
  },
  mapIconContainer: {
    background: alpha(theme.palette.primary.main, 0.25),
    borderRadius: 50,
    height: 30,
    width: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8,
    cursor: "pointer",
  },
  headerIconContainer: {
    display: "flex",
    alignItems: "center",
  },
  assignmentTitle: {
    display: "flex",
    alignItems: "center",
  },
  mainTitle: {
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[700],
    fontWeight: "bold",
    marginBottom: 0,
  },
  subTitle: {
    fontSize: 14,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    marginTop: 12,
    marginBottom: 12,
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  BewijsSubTitle: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    fontSize: 14,
    marginTop: 10,
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  papers: {
    minWidth: 131,
    flexGrow: 1,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: 6,
    boxShadow: "none",
  },
  content: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[600],
  },
  remarkTextField: {
    backgroundColor: theme.palette.grey[200],
  },
  remarkTextFieldCustom: {
    height: 92,
    minHeight: 92,
    maxHeight: 92,
    background: "#eeeeee",
    padding: "8px 11.2px",
    border: "1px solid #b0b0b0",
    borderRadius: "3px",
    overflow: "scroll",
  },
  avatar: {
    height: 20,
    width: 20,
    marginLeft: 5,
    fontSize: `${0.75}rem`,
    cursor: "pointer",
  },
  avatarContainer: {
    flexWrap: "nowrap",
    whiteSpace: "nowrap",
  },
  customTextField: {
    fontSize: 12,
    color: `${theme.palette.common.black} !important`,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  projectInfoIcon: {
    padding: 3,
    marginLeft: 5,
  },
  explanationContainer: {
    paddingTop: theme.spacing(0),
    // paddingRight: 0,
    // paddingBottom: '0 !important',
    [theme.breakpoints.only("xs")]: {
      paddingRight: theme.spacing(1),
    },
    "& .MuiInputBase-root": {
      padding: theme.spacing(1, 1.4),
    },
  },
  removeBottomPadding: {
    paddingBottom: 2,
  },
  bottomPadding: {
    padding: `${theme.spacing(2, 2)} !important`,
  },

  removePaddingForMd: {
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.only("md")]: {
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(1),
    },
    paddingRight: theme.spacing(0),
  },
  removePaddingForSm: {
    // [theme.breakpoints.down('xs')]: {
    //     paddingLeft: theme.spacing(1),
    //     paddingBottom: '0 !important',
    // },
    [theme.breakpoints.between("sm", "lg")]: {
      padding: theme.spacing(0),
    },
    // [theme.breakpoints.only('md')]: {
    //     paddingLeft: theme.spacing(0),
    //     paddingTop: theme.spacing(1),
    // },
  },
  explanationTitle: {
    marginTop: 0,
    marginBottom: 0,
  },
  subTitleContainer: {
    marginTop: 6,
    marginBottom: 6,
  },
  mainInnerContainers: {
    // maxHeight: 400,
    // minHeight: 400,
    padding: theme.spacing(1),
  },
  innerContainers: {
    padding: theme.spacing(1),
    // height: 'fit-content'
  },
  rightDivider: {
    borderRight: `2px solid ${theme.palette.grey[300]}`,
  },
  downDividerMd: {
    // paddingRight: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      borderRight: 0,
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
    [theme.breakpoints.only("md")]: {
      borderRight: 0,
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
    },
  },
  downDividerSm: {
    // [theme.breakpoints.down('xs')]: {
    //     borderRight: `2px solid ${theme.palette.grey[300]}`
    // },
    [theme.breakpoints.between("sm", "lg")]: {
      borderRight: 0,
      borderBottom: `2px solid ${theme.palette.grey[300]}`,
      paddingBottom: 10,
      marginBottom: 10,
    },
  },

  selectedAnswer: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important",
    "&::before": {
      opacity: "0.5 !important",
    },
    // "& > .MuiButton-label": {
      color: `white !important`,
      zIndex: 1,
      backgroundColor: "#9FC69F !important",
    // },
  },

  selectedAnswerRood: {
    boxShadow:
      "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important",
    "&::before": {
      opacity: "0.5 !important",
    },
      color: `white !important`,
      zIndex: 1,
      backgroundColor: "#E590B1 !important",
  },
  ratingButton: {
    background: "transparent",
    boxShadow: "none",
    position: "relative",
    display: "block",
    textOverflow: "ellipsis",
    textTransform: "none",
    whiteSpace: "nowrap",
    overflow: "hidden",
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    fontSize: 14,
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      left: 0,
      overflow: "hidden",
      opacity: 0.2,
      zIndex: 0,
      position: "absolute",
    },
    width: "100%",
  },

  groen: {
    border: `1px solid #4CAF4F`,
    color: "#4caf50",
    backgroundColor: "#D9E8D9",
    "&:hover": {
      border: `1px solid #4CAF4F`,
      backgroundColor: "#d5d5d5"
    },
    
  },
  rood: {
    border: `1px solid #f50057`,
    backgroundColor: "#F5D4E0",
    color: "#f50057",
    "&:hover": {
      border: `1px solid #f50057`,
      backgroundColor: "#d5d5d5"
    },
  },
  oranje: {
    border: `1px solid ${theme.palette.warning.main}`,
    "& > .MuiButton-label": {
      color: theme.palette.warning.main,
    },
    "&::before": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
  ratingContainer: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  attachmentItemContainer: {
    position: "relative",
    background: theme.palette.common.white,
    boxShadow: "3px 3px 4px #eee",
    height: 65,
    overflow: "hidden",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": {
      "& $overlay": {
        opacity: 1,
      },
      "& $attachmentStatus": {
        opacity: 1,
        right: 10,
      },
    },
  },
  overlay: {
    position: "absolute",
    borderRadius: 5,
    color: " #f1f1f1",
    transition: ".5s ease",
    opacity: 1,
    fontSize: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 65,
    zIndex: 1,
    top: 0,
    left: 0,
  },
  noOutline: {
    outline: "none !important",
  },
  pdfIcon: {
    position: "absolute",
    left: 0,
    bottom: 0,
  },
  thumbnail: {
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
    display: "block",
  },
  thumnailContainer: {
    width: 65,
    height: 65,
    cursor: "pointer",
    display: "flex",
    position: "relative",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  deleteIcon: {
    fill: theme.palette.grey[700],
    fontSize: 18,
  },
  pictureAsPdf: {
    // fill: theme.palette.grey[700],
    fontSize: 18,
  },
  isRight: {
    position: "absolute",
    right: 3,
    top: 2,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  filesContainer: {
    maxHeight: 245,
    overflow: "auto",
    padding: theme.spacing(1.25, 0),
  },
  gridContainer: {
    justifyContent: "space-between",
    gridTemplateColumns: "repeat(auto-fill, 65px)",
    display: "grid",
    gap: 6,
  },
  deleteButton: {
    backgroundColor: theme.palette.common.white,
    padding: theme.spacing(0.26),
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  dropzone: {
    padding: 0,
    height: "100%",
  },
  uploadIcon: {
    width: 30,
  },
  remarkTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  fileInfoHelper: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    color: theme.palette.grey[500],
    fontSize: 12,
    padding: theme.spacing(0.5, 1.7),
    paddingLeft: 0,
  },
  dataTable: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(0, 0.8),
    boxShadow: "none",
    "& > .MuiPaper-root": {
      flex: "0 1 auto",
    },
    "& > .MuiToolbar-root.MuiToolbar-regular": {
      display: "flex",
      padding: theme.spacing(0, 0, 0, 1),
      "& .header svg": {
        fontSize: 30,
      },
      "& > div": {
        padding: theme.spacing(0),
        textAlign: "right",
        flex: "1 0 auto",
        "& .MuiTypography-root": {
          textAlign: "left",
        },
      },
    },
    "& .MuiTable-root": {
      border: "8px solid transparent",
      "& .caption": {
        display: "none",
      },
      "& .MuiTableHead-root > .MuiTableRow-root": {
        background: theme.palette.common.white,
        position: "sticky",
        top: -6,
      },
    },
    "& .MuiTableCell-root": {
      color: theme.palette.grey[700],
      fontWeight: "normal",
      fontSize: 14,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
      padding: theme.spacing(1.7, 0.5, 1.7, 0.5),
      "&.MuiTableCell-paddingCheckbox": {
        paddingLeft: theme.spacing(0.5),
        "& > div": {
          justifyContent: "center",
        },
        "& .MuiCheckbox-root": {
          padding: theme.spacing(0.25),
        },
      },
      "&.MuiTableCell-head": {
        fontWeight: "normal",
        fontSize: 14,
        [theme.breakpoints.only("xs")]: {
          fontSize: 14,
        },
        [theme.breakpoints.only("sm")]: {
          fontSize: 16,
        },
        "& .MuiButtonBase-root": {
          "& .MuiButton-label": {
            color: theme.palette.grey[700],
            fontWeight: "normal",
            fontSize: 14,
            [theme.breakpoints.only("xs")]: {
              fontSize: 14,
            },
            [theme.breakpoints.only("sm")]: {
              fontSize: 16,
            },
          },
        },
      },
    },
  },
  tooltipImageContainer: {
    padding: theme.spacing(1.25),
    // maxWidth: '200px',
    // maxHeight: '200px',
    // display:'flex',
    // justifyContent:'center',
    // alignItems:'center',
    "& > img": {
      maxWidth: "200px",
      maxHeight: "200px",
      // width: '100%'
      // height: '200px',
      // width: '100%',
      // objectFit:'contain'
    },
  },
  thumbnailImage: {
    marginLeft: theme.spacing(0),
    width: 50,
    height: 50,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundColor: "rgba(0,0,0,0.1)",
    cursor: "pointer",
  },
  meldingenButton: {
    marginRight: 10,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
    "& .MuiButton-label": {
      fontSize: 14,
    },
  },
  remarkTextInfo: {
    padding: theme.spacing(2),
    height: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 535,
      overflow: "hidden",
      wordBreak: "break-word",
    },
  },
  editRemarkText: {
    maxWidth: 444,
  },
  remarkTextInfoContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  customColumnStyle: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    "& > $customColumnItemStyle:nth-child(2n)": {
      borderLeft: `2px solid ${theme.palette.grey[300]}`,
      paddingLeft: 10,
    },
  },
  cardSubText: {
    [theme.breakpoints.down("md")]: {
      fontSize: 14,
    },
  },
  customColumnItemStyle: {
    flexGrow: 1,
    paddingLeft: theme.spacing(0.5),
    fontSize: 12,
    width: "50%",
    marginBottom: 14,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  emptyMessage: {
    minHeight: "25% !important",
  },
  conversations: {
    maxHeight: 313,
    [theme.breakpoints.only("md")]: {
      maxHeight: 450,
    },
    minHeight: "55%",
    height: "inherit",
    marginLeft: -16,
    minWidth: `calc(100% + 14px)`,
    flex: 1,
    overflow: "auto",
    paddingLeft: theme.spacing(2),
    marginBottom: 35,
    position: "relative",
    overflowX: "hidden",
    scrollBehavior: "smooth",
  },
  conversation: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    borderRadius: 6,
    boxShadow: "none",
    position: "relative",
    margin: 4,
    "&::before": {
      content: '""',
      position: "absolute",
      left: -5,
      zoom: 0.6,
      top: 10,
      boxSizing: "border-box",
      border: `1em solid black`,
      borderColor: `transparent transparent ${theme.palette.common.white} ${theme.palette.common.white}`,
      // transformOrigin: `110% 0%`,
      transform: "rotate(45deg)",
    },
    fontSize: "14px !important",
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: `${theme.palette.grey[800]} !important`,
  },
  smallAvatar: {
    height: 19,
    width: 19,
  },
  conversationUserAvatar: {
    "& .MuiCardHeader-avatar": {
      marginRight: 10,
    },
    padding: theme.spacing(0, 0, 0.5, 0),
    "& .MuiTypography-root": {
      fontSize: 12,
      [theme.breakpoints.only("xs")]: {
        fontSize: 14,
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: 16,
      },
    },
  },
  conversationContent: {
    fontSize: 12,
    color: theme.palette.grey[800],
  },
  conversationTime: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    fontSize: 12,
    color: theme.palette.grey[500],
    marginBottom: 4,
    textAlign: "right",
    paddingRight: theme.spacing(0.5),
  },
  msIcon: {
    position: "absolute",
    zIndex: 1,
    borderRadius: 50,
    padding: theme.spacing(0.5),
    display: "flex",
    justifyContent: "center",
    left: -33,
    alignItems: "center",
  },
  timeLine: {
    maxWidth: 5,
    borderLeft: `5px solid ${theme.palette.grey[200]}`,
  },
  timeLineText: {
    position: "relative",
    left: 16,
    [theme.breakpoints.only("sm")]: {
      left: 8,
    },
  },
  confirmationDialog: {
    "& .MuiPaper-root": {
      width: 450,
      borderRadius: 10,
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  dialogContextText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: 0,
  },
  container: {
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0),
    },
  },
  innerContainer: {
    position: "relative",
    height: "100%",
    overflow: "hidden",
    zIndex: 1,
    [theme.breakpoints.only("xs")]: {
      height: "calc(100% - 26px)",
      position: "static",
    },
  },
  blocksContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      position: "static",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },
  foundationBlock: {
    maxHeight: "calc(100% - 48px)",
    overflow: "auto",
    height: "calc(100% - 48px)",
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  surveyMomentsMainHeaderSecond: {
    [theme.breakpoints.only("xs")]: {
      zIndex: theme.zIndex.appBar,
      justifyContent: "center",
    },
  },
  surveyMomentsHeaderSecond: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(0, 1),
    },
    [theme.breakpoints.only("xs")]: {
      maxHeight: 35,
      minHeight: 35,
    },
  },
  backButton: {
    [theme.breakpoints.down("lg")]: {
      fontSize: 18,
    },
  },
  arrowBlackIcon: {
    [theme.breakpoints.only("xs")]: {
      fontSize: 18,
    },
  },
  appHeader: {
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
  },
  appBarText: {
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  appBarSubtitle: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    lineClamp: 1,
    wordBreak: "break-all",
    display: "-webkit-box",
    boxOrient: "vertical",
    [theme.breakpoints.only("xs")]: {
      marginLeft: 9,
    },
    marginLeft: 23,
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
    },
  },
  appBarSubText: {
    // color: theme.palette.grey[200],
    fontSize: 16,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
  },
  skipIcon: {
    padding: theme.spacing(1.5),
    "&:focus": {
      outline: "none !important",
    },
  },
  moreOptionIcon: {
    fontSize: 18,
    [theme.breakpoints.only("xs")]: {
      fontSize: 15,
    },
  },
  menuContent: {
    "& .MuiPopover-paper": {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    "& ul": {
      padding: theme.spacing(2),
    },
  },
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    width: "fit-content",
    maxWidth: 325,
  },
  layoutLoading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  flex: {
    display: "flex",
  },
}));
