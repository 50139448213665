import React from "react";
import { connect } from "react-redux";
import {
    Container,
    Grid,
    Typography, AppBar, Toolbar, Table, TableBody, TableRow, TableCell, IconButton, Hidden
} from "@mui/material";
import withStyles from '@mui/styles/withStyles';
import { ArrowBack } from "@mui/icons-material"
import { withTranslation } from 'react-i18next';
import clsx from "clsx";
import { history, nl2br, formatDate, authHeader, getMimeTypefromString } from '../../_helpers';
import { userAccountRoleConstants } from "../../_constants";
import RepairRequestContactCard from "../ConstructionQuality/RepairRequestContactCard";
import { URLS } from "../../apis/urls";
import DocumentViewer from "../../components/DocumentViewer";

const { webApiUrl } = window.appConfig;

const styles = theme => ({
    appBar: {
    },
    bold: {
        fontWeight: "bold"
    },
    mainContainer: {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0)
        }
    },
    container: {
        backgroundColor: theme.palette.background.paper,
        margin: theme.spacing(5, 0, 6),
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(0)
        }
    },
    innerContainer: {
        padding: theme.spacing(2, 3, 4)
    },
    bigAvatar: {
        margin: 'auto',
        width: 120,
        height: 120
    },
    halfWidth: {
        width: '50%',
        verticalAlign: 'top'
    },
    button: {
        '&:hover': {
            color: theme.palette.primary.contrastText
        }
    },
    thumbnail: {
        width: '100%',
        minWidth: 100,
        height: 50,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left center',
        backgroundSize: 'contain',
        '&.big': {
            width: '100%',
            height: 0,
            padding: '50%',
            borderRadius: theme.spacing(1),
            backgroundPosition: 'center',
            backgroundColor: 'rgba(0,0,0,0.1)',
            backgroundSize: 'cover',
            cursor: "pointer"
        }
    },
});

const isPropertyManagerRole = (data) => (data.roles && data.roles.length === 1 && data.roles.includes(userAccountRoleConstants.propertyManager));
class Page extends React.Component {
    state = {
        repairRequest: null,
        isPropertyManager: false
    };

    componentDidMount() {
        this.GetRepairRequestDetails();
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.selected || prevProps.selected.buildingId !== this.props.selected.buildingId) {
            this.GetRepairRequestDetails();
        }
    }

    GetRepairRequestDetails() {
        let { repairRequestId } = this.props.match.params;
        const { selected, buildings } = this.props;
        if (selected && repairRequestId) {
            const url = webApiUrl + 'api/RepairRequest/GetRepairRequestDetails/' + encodeURI(repairRequestId);
            const requestOptions = {
                method: 'GET',
                headers: authHeader()
            };

            this.setState({
                repairRequest: null,
            });

            fetch(url, requestOptions)
                .then(Response => Response.json())
                .then(findResponse => {
                    if (findResponse.number) {
                        const building = buildings.find((x) => x.buildingId === findResponse.buildingId);
                        const projectBuilding = buildings.find((x) => (x.projectId === findResponse.projectId || x.buildingId === findResponse.buildingId));
                        const isPropertyManager = isPropertyManagerRole(building || projectBuilding);
                        this.setState({ repairRequest: findResponse, isPropertyManager });
                    }
                });
        }
    }

    handleImageModalClose = () => {
        this.setState({ attachmentIndex: null });
    }

    render() {
        const { t, classes } = this.props;
        const { repairRequest, isPropertyManager, attachmentIndex } = this.state;

        return (
            <Container className={classes.mainContainer}>
                <Grid container>
                    <Grid item container xs={12} className={classes.container}>
                        <AppBar position="sticky" color='inherit' className={classes.appBar}>
                            <Toolbar variant="dense">
                                <IconButton
                                    color="inherit"
                                    edge="start"
                                    aria-label="go back"
                                    component="span"
                                    onClick={history.goBack}
                                    size="large">
                                    <ArrowBack />
                                </IconButton>
                                <Typography className={classes.bold}>{t('repairRequest.repairRequestDetails') + ' ' + (repairRequest ? repairRequest.number : '')}</Typography>
                            </Toolbar>
                        </AppBar>
                        {
                            repairRequest &&
                            <Grid container spacing={2} item xs={12} className={classes.innerContainer} alignItems="flex-start">
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">{t('general.generalInformation')}</Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                    {t('general.date') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.date && formatDate(new Date(repairRequest.date))}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.type') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.repairRequestType && repairRequest.repairRequestType}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.status') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.status && repairRequest.status}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">{t('layout.navbar.address')}</Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                    {t('general.streetAndHouseNumber') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.address && (repairRequest.address.street ? repairRequest.address.street : "") + " " + (repairRequest.address.houseNo ? repairRequest.address.houseNo : "") + (repairRequest.address.houseNoAddition ? " " + repairRequest.address.houseNoAddition : "")}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.address.postcode') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.address && repairRequest.address.postcode && repairRequest.address.postcode}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.address.city') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.address && repairRequest.address.place && repairRequest.address.place}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <Typography variant="h6">{t('repairRequest.repairRequestDescription')}</Typography>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.halfWidth}>
                                                    {t('general.location') + ':'}
                                                </TableCell>
                                                    <TableCell>
                                                        {repairRequest.location && repairRequest.location}
                                                        {repairRequest.drawingPinLocation && repairRequest.drawingPinLocation.dossierFileId &&
                                                            <div
                                                                onClick={() => this.setState({ openDrawingLocation: true })}
                                                                class={clsx(classes.thumbnail, 'big')} style={{
                                                                    backgroundImage: `url(${webApiUrl}api/RepairRequest/GetPinLocationImage/${repairRequest.requestId})`
                                                                }} />
                                                        }
                                                    </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.description') + ':'}
                                                </TableCell>
                                                <TableCell>{repairRequest.desc && repairRequest.desc}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell component="th" scope="row">
                                                    {t('general.explanation') + ':'}
                                                </TableCell>
                                                <TableCell>{nl2br(repairRequest.detailDesc)}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                        {
                                            repairRequest.completed === true &&
                                            <>
                                                <br/>
                                                <Typography variant="h6">{repairRequest.status !== "Afgewezen" ? t('repairRequest.solution') : t('repairRequest.reasonOfRejection')}</Typography>
                                                <Table>
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell>
                                                                {
                                                                    nl2br(
                                                                        repairRequest.status !== "Afgewezen"
                                                                            ?
                                                                            (
                                                                                repairRequest.completionText
                                                                                    ?
                                                                                    repairRequest.completionText
                                                                                    :
                                                                                    ""
                                                                            )
                                                                            :
                                                                            (
                                                                                repairRequest.rejectionText
                                                                                    ?
                                                                                    repairRequest.rejectionText
                                                                                    :
                                                                                    ""
                                                                            )
                                                                    )
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </>
                                        }
                                </Grid>
                                {isPropertyManager && <Grid item xs={12} md={6}>
                                    <Typography variant="h6">
                                        {t('general.contactDetails')}
                                    </Typography>
                                    <Grid container spacing={1}>
                                        {
                                            repairRequest.contactInfo.buyer &&
                                            <>
                                                {
                                                    repairRequest.contactInfo.buyer.type === 0 &&
                                                    <>
                                                        <Grid item xs={12}>
                                                            <RepairRequestContactCard
                                                                hidePointOfContactCheckbox
                                                                object={repairRequest.contactInfo.buyer.p1}
                                                                pointOfContactType={0}
                                                                selectedPointOfContactType={repairRequest.pointOfContact}
                                                                subTitle={t("general.buyer1")} />
                                                        </Grid>
                                                        {
                                                            repairRequest.contactInfo.buyer.p2 &&
                                                            <Grid item xs={12}>
                                                                <RepairRequestContactCard
                                                                    hidePointOfContactCheckbox
                                                                    object={repairRequest.contactInfo.buyer.p2}
                                                                    pointOfContactType={0}
                                                                    selectedPointOfContactType={repairRequest.pointOfContact}
                                                                    subTitle={t("general.buyer2")} />
                                                            </Grid>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </Grid>
                                </Grid>}
                                {
                                    repairRequest.attachments && repairRequest.attachments.length > 0 &&
                                    <Grid item container xs={12} md={isPropertyManager ? 12 : 6} spacing={1}>
                                        <Grid item xs={12}>
                                            <Typography variant="h6">{t('general.images')}</Typography>
                                        </Grid>
                                        {
                                            repairRequest.attachments.map((attachment, index) =>
                                                <Grid item xs={6} md={isPropertyManager ? 2 : 6}>
                                                    <div
                                                        key={`${attachment.attachmentId}-${index}`}
                                                        onClick={() => this.setState({ attachmentIndex: index })}
                                                        class={clsx(classes.thumbnail, 'big')} style={{
                                                            backgroundImage: `url(${webApiUrl}api/home/getattachment/${attachment.attachmentId})`
                                                        }} />
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                }
                                {
                                    repairRequest.resolvers &&
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{t('general.resolvers')}</Typography>
                                        <div style={{ overflow: 'auto' }}>
                                            <Table>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.logo')}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {t('general.organisation')}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {t('repairRequest.phoneNumber')}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {t('repairRequest.notifiedOn')}
                                                        </TableCell>
                                                    </TableRow>
                                                    {
                                                        repairRequest.resolvers.map((resolver, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>
                                                                    <div
                                                                        class={classes.thumbnail}
                                                                        style={{
                                                                            backgroundImage: `url(${webApiUrl}api/organisation/GetOrganisationLogo/${resolver.organisatonId})`
                                                                        }}
                                                                    />
                                                                </TableCell>
                                                                <TableCell>{resolver.name && resolver.name}</TableCell>
                                                                <TableCell>{resolver.telephone && resolver.telephone}</TableCell>
                                                                <TableCell>{resolver.dateNotified && formatDate(new Date(resolver.dateNotified))}</TableCell>
                                                            </TableRow>
                                                        ))
                                                    }
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                        }
                    </Grid>
                </Grid>

                {repairRequest && repairRequest.attachments && attachmentIndex != null && (attachmentIndex >= 0 && attachmentIndex < repairRequest.attachments.length) && <DocumentViewer
                    radius={classes.docRadius}
                    isSurveying
                    open={attachmentIndex != null}
                    onClose={this.handleImageModalClose}
                    documents={[{
                        uri: URLS.GET_ATTACHMENT + encodeURI(repairRequest.attachments[attachmentIndex] ? repairRequest.attachments[attachmentIndex].attachmentId : ''),
                        fileType: getMimeTypefromString(".jpg"), //TODO: use mimetype from server after api changes
                    }]} />}

                {
                    repairRequest && repairRequest.drawingPinLocation && repairRequest.drawingPinLocation.dossierFileId && this.state.openDrawingLocation && <DocumentViewer
                        radius={classes.docRadius}
                        isSurveying
                        open={this.state.openDrawingLocation}
                        onClose={() => { this.setState({ openDrawingLocation: false }); }}
                        documents={[{
                            uri: `${webApiUrl}api/RepairRequest/GetPinLocationImage/${repairRequest.requestId}`,
                            fileType: getMimeTypefromString(".jpg"), //TODO: use mimetype from server after api changes
                        }]} />
                }
            </Container>
        );
    }
}

function mapStateToProps(state) {
    const { authentication, buildings } = state;
    const { user } = authentication;
    const { selected, all } = buildings;
    return {
        user,
        selected,
        buildings: all
    };
}

const connectedPage = connect(mapStateToProps)(withTranslation()(withStyles(styles)(Page)));
export { connectedPage as RepairRequestDetailsPage };