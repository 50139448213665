import { useMediaQuery, useTheme } from "@mui/material";

import MobileGridHeader from "./MobileGridHeader";
import DesktopGridHeader from "./DesktopGridHeader";

const CustomToolbar = ({
  setColumnSelectorType,
  setOpenColumnsSelector,
  setIsOpenObjectModal,
  isOpenObjectModal,
  handleSearch,
  searchText,
  quickFilterItems
}) => {
  const theme = useTheme();
  const isTabletOrMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <>
      {isTabletOrMobile ? (
        <MobileGridHeader
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          setIsOpenObjectModal={setIsOpenObjectModal}
          isOpenObjectModal={isOpenObjectModal}
          handleSearch={handleSearch}
          searchText={searchText}
          quickFilterItems={quickFilterItems}
        />
      ) : (
        <DesktopGridHeader
          setColumnSelectorType={setColumnSelectorType}
          setOpenColumnsSelector={setOpenColumnsSelector}
          setIsOpenObjectModal={setIsOpenObjectModal}
          isOpenObjectModal={isOpenObjectModal}
          handleSearch={handleSearch}
          searchText={searchText}
          quickFilterItems={quickFilterItems}
        />
      )}
    </>
  );
};

export default CustomToolbar;
