import { Box, LinearProgress, Typography } from '@mui/material'
import { grey } from '@mui/material/colors';
import { useTranslation } from 'react-i18next';

import MsExcelIcon from "../../../../assets/icon/ms_excel.svg";

const LinearProgressWithLabel = (props) => {
  return (
    <Box display="flex" alignItems='center'>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box>
        <Typography fontSize={12} color="text.secondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const FileUploadProgress = ({ progress = 0,fileName = "file.xlsx", fileSize = "3.5 MB" }) => {

  const { t } = useTranslation();

  return (
    <Box display='flex' p={2.75} bgcolor={grey[100]} justifyContent='center' alignItems="center" borderRadius={2} >
      <Box
        component="img"
        width={29} height={29}
        alt="excel"
        src={MsExcelIcon}
        mr={1.25}
      />
      <Box display='flex' flexDirection='column' flexGrow={1} >
        <Box display='flex' justifyContent='space-between'>
          <Typography variant='caption'>{fileName}</Typography>
          <Typography variant='caption'>{fileSize}</Typography>
        </Box>
        <Box position='relative'>
          <LinearProgressWithLabel value={progress} />
        </Box>
      </Box>
      <Box ml={2}>
        <Typography variant='caption' color="error.light">
          {t("general.cancel")}
        </Typography>
      </Box>
    </Box>
  )
}

export default FileUploadProgress