import React, { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    useMediaQuery,
    Grid,
    useTheme,
    Tooltip,
    Hidden,
    TableCell,
    TableRow,
    Box,
    Popover,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Close, Visibility } from '@mui/icons-material';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { URLS } from '../../../apis/urls';
import MUIDataTable from 'mui-datatables';
import BottomDrawer from '../../Survey/components/BottomDrawer';
import CustomBottomDrawerChildrenRenderer from '../../Survey/components/CustomBottomDrawerChildrenRenderer';
import { dossiersActions } from '../../../_actions/dossiers.actions';
import { surveyActions } from '../../../_actions';
import { formatDate, getDataTableTextLabels } from '../../../_helpers';
import ImagesContainer from '../../Survey/components/ImagesContainer';
import { useParams } from 'react-router-dom';

const { webApiUrl } = window.appConfig;


const ExistingImageModal = ({ closeUploadDialog, isAssignmentFile, isFundering = false, images, canDrawingDossier, existingImageModal, bottomDrawer, bottomDrawerClose, isSurveying, open, handleClose, isSingle, handlePreviewOfFiles, onselect = () => 0, ...props }) => {
	const classes = useStyles();
	const { t } = useTranslation();
	const theme = useTheme();
	const [isOpenAttachments, setIsOpenAttachments] = useState(false);
	const [selectedImages, setSelectedImages] = useState([]);
	const matchWidthDownSm = useMediaQuery(theme.breakpoints.down('md'));
	const matchWidthUpxs = useMediaQuery(theme.breakpoints.down('sm'));
	const matchWidthUpMd = useMediaQuery(theme.breakpoints.up('md'));
	const matchWidthDownXs = useMediaQuery(theme.breakpoints.down('sm'));
	const matchWidthOnlyXs = useMediaQuery(theme.breakpoints.only('xs'));
	const matchWidthOnlyMd = useMediaQuery(theme.breakpoints.only('md'));
	const matchWidthOnlySm = useMediaQuery(theme.breakpoints.only('sm'));
	const [filterImage, setFilterImage] = useState([])
	const [selectedRequestData, setSelectedRequestData] = useState([])
	const [imageContainerOpen, setImageContainerOpen] = useState({ element: null, data: [] })
	const dispatch = useDispatch();
	const params = useParams();

	const { buildings: { selected }, dossier: { imagesFromRepairRequest }, surveying: { selectedMoment } } = useSelector(state => state);

	useEffect(() => {
		if (canDrawingDossier) {
			const pdf = images && images.filter(p => p.extension === '.pdf')
			setFilterImage(pdf)
		} else {
			setFilterImage(images)
		}
	}, [images])

	const toggleDrawerOpen = (event, data) => {
		event.stopPropagation();
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}
	};

	const handleOpenImageModal = (id, key) => {
		setIsOpenAttachments(true);
		const selectedRequest = Object.keys(imagesFromRepairRequest).reduce((p, c) => [...p, ...imagesFromRepairRequest[c]], []).find(p => p.requestId === id);
		setSelectedRequestData(selectedRequest.attachments.map(p => ({ attachmentId: p.id, requestId: id, name: p.name })))
	}

	const handleImageContainerOpen = (e, data) => {
		const { currentTarget } = e;
		e.stopPropagation();
		setImageContainerOpen({ element: currentTarget, data });
	};

	const requestColumns = [
		{
			name: "requestId",
			options: {
				display: "excluded",
				filter: false,
				print: false,
				download: false,
			},
		},
		{
			name: "attachments",
			label: t("general.image"),
			options: {
				display: true,
				filter: false,
				sort: false,
				print: false,
				download: false,
				customBodyRender: (v, { rowData }) => {
					let imageURL = "";
					if (v[0] && !v[0].id && typeof (v[0]) !== "string") {
						imageURL = `url(${window.URL.createObjectURL(new Blob([v[0]]))})`;
					} else if (v[0]) {
						imageURL = `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${v[0].id}),url(${webApiUrl}api/home/ProjectBackground/${selected.projectId})`;
					}
					return <>
                        <Box boxShadow={1}
                            borderRadius={1}
                            className={classes.thumbnailImage}
                            aria-describedby={'image-popover'}
                            style={{ backgroundImage: imageURL }}
                            onClick={(e) => v.length && handleImageContainerOpen(e, v.map(p => ({ attachmentId: p.id, repairRequestId: rowData[0] })))}
                        // style={{ backgroundImage: `url(${p && !!p.length && p[0].attachmentUrl})`, }}
                        />
                    </>;
				}
			},
		},
		{
			name: 'number',
			label: t('general.number'),
			options: {
				display: true,
				customBodyRender: (v) => {
					return (
						<Typography className={clsx(classes.customTableCell, matchWidthUpxs && classes.requestSubTitlePrimary)}>{v}</Typography>
					)
				}
			},
		},
		{
			name: 'date',
			label: t('general.date'),
			options: {
				display: true,
				customBodyRender: (v) => {
					return (
						<Typography className={classes.customTableCell}>
							{formatDate(new Date(v))}
						</Typography>
					)
				}
			}
		},
		{
			name: 'location',
			label: t('general.objectAddress'),
			options: {
				display: true,
			}
		},
		{
			name: 'desc',
			label: t('general.shortDescription'),
			options: {
				display: true,
			}
		},
		{
			name: 'carryOutAsType',
			label: t('repairRequest.type.label'),
			options: {
				display: true,
			}
		},
		{
			name: 'status',
			label: t('general.status'),
			options: {
				display: true,
			}
		},
	];
	const requestOptions = {
		filterType: true,
		filter: false,
		search: false,
		viewColumns: false,
		selectableRows: 'none',
		textLabels: getDataTableTextLabels(t),
		print: false,
		pagination: false,
		download: false,
		responsive: (matchWidthUpxs || matchWidthDownSm) ? 'stacked' : 'scroll',
		customRowRender: (data, dataIndex, rowIndex) => {
			return (
                <React.Fragment >
					{/* <TableRow onClick={(e) => { console.log("test") }} className={classes.customMomentRow}>
						{data.map((column, i) => (
							(requestColumns[i].options.display && requestColumns[i].name !== "requestId") ? (
								<TableCell >
									{column}
								</TableCell>
							) : null
						))}
					</TableRow> */}
					<TableRow>
						<Hidden smDown>
							{data.map((column, i) => (
								requestColumns[i].name !== "requestId" && (
									<TableCell key={`${data[0]}-${i}`} onClick={(e) => handleOpenImageModal(data[0])}>
										{column}
									</TableCell>
								)
							))}
						</Hidden>
						<Hidden mdUp>
							{(matchWidthUpxs) && (
								<TableCell colSpan={1}>
									<Grid container>
										<Grid container item xs={12} sm={12} md={12}>
											<div className={classes.customColumnStyle}>
												{data.slice(1).map((column, i) => (
													<Grid
														xs={6}
														sm={3}
														md={3}
														className={classes.customColumnItemStyle}
														onClick={(e) => requestColumns[i + 1].name === "number" && handleOpenImageModal(data[0])}
													>
														<div>
															<Typography className={classes.requestSubTitle}>{requestColumns[i + 1].label}</Typography>
														</div>
														{column}
													</Grid>
												))}
											</div>
										</Grid>
									</Grid>
								</TableCell>
							)}
						</Hidden>
					</TableRow>

				</React.Fragment>
            );
		}
	};

	useEffect(() => {
		if (existingImageModal && selectedMoment)
			dispatch(dossiersActions.getImagesFromRepairRequest(selectedMoment.projectId, !isFundering ? selectedMoment.buildingId : null));
	}, [existingImageModal])

	const handleSelect = (isAll, file) => {
		let imagesData = Object.assign([], selectedImages);
		if (!isAll && !isSingle) {
			const isExistIndex = imagesData.findIndex(image => image.fileId === file.fileId);
			isExistIndex >= 0 ? imagesData.splice(isExistIndex, 1) : imagesData.push(file)
		} else if (isSingle) {
			imagesData = imagesData.find(f => f.fileId === file.fileId) ? [] : [file];
		} else if (!isSingle && isAll)
			imagesData = imagesData.length ? [] : [...file];
		setSelectedImages(imagesData)
	}

	return <>
        <Hidden smUp>
            <BottomDrawer toggler={toggleDrawerOpen} open={bottomDrawer} close={bottomDrawerClose}>
                <div>
                    <CustomBottomDrawerChildrenRenderer
                        type="repairrequests"
                        title={t("repairRequests.wkbRepairRequests")}
                        data={{ options: requestOptions, columns: requestColumns, repairRequests: imagesFromRepairRequest ? imagesFromRepairRequest.wbRepairRequests : [] }}
                        // onPreview={onPreview}
                        onClose={bottomDrawerClose}
                    />
                    <CustomBottomDrawerChildrenRenderer
                        type="repairrequests"
                        title={t("general.repairRequests")}
                        data={{ options: requestOptions, columns: requestColumns, repairRequests: imagesFromRepairRequest ? imagesFromRepairRequest.repairRequests : [] }}
                        // onPreview={onPreview}
                        onClose={bottomDrawerClose}
                    />
                </div>
            </BottomDrawer>
        </Hidden>

        <Dialog maxWidth='md' PaperProps={{ style: { width: 'auto' } }} onClose={handleClose}
            className={existingImageModal ? classes.requestContainer : ''} aria-labelledby="images-dialog-title"
            open={matchWidthUpxs && existingImageModal ? false : open} >
            {!existingImageModal ?
                <>
                    <DialogTitle id="images-dialog-title" className={classes.titleContainer}>
                        <Container className={classes.title}>
                            {!isSingle && <>
                                <Checkbox
                                    color="primary"
                                    indeterminate={(filterImage.length && selectedImages.length !== filterImage.length) && selectedImages.length}
                                    checked={filterImage.length && selectedImages.length === filterImage.length}
                                    onChange={() => handleSelect(true, filterImage)}
                                />
                                <Typography variant={'p'} className={classes.totalImagesData}>{`(${selectedImages.length}/${filterImage.length})`}</Typography>
                            </>
                            }
                            <Typography variant={'h5'} className={classes.totalImagesHeading}>{t('files.modal.heading.title')}</Typography>
                            <IconButton onClick={handleClose} aria-label="close" size="large">
                                <Close />
                            </IconButton>
                        </Container>
                    </DialogTitle>
                    <DialogContent className={classes.container}>
                        <Grid className={classes.dialogWidthController}>
                            <Grid container spacing={1}>
                                {
                                    filterImage && filterImage.map((image, index) => {
                                        // if (canDrawingDossier && image.extension !== '.pdf') return null;
                                        const isSelected = selectedImages.some(f => f.fileId === image.fileId);
                                        return (
                                            <Grid item key={index} className={classes.fileContainer} justifyContent='center'>
                                                <Tooltip title={image.name}>
                                                    <Grid item className={classes.thumnailContainer}>
                                                        <div className={classes.overlay}>
                                                            <IconButton
                                                                onClick={() => handlePreviewOfFiles([image])}
                                                                style={{ outline: 'none' }}
                                                                aria-label="image-preview"
                                                                size="large">
                                                                <Visibility className={classes.imagePreviewIcon} />
                                                            </IconButton>
                                                            {!isSelected && <Checkbox checked={isSelected}
                                                                onChange={() => handleSelect(false, image)} color="primary"
                                                                className={classes.checkBox}
                                                                style={{ color: 'white' }} />}
                                                        </div>
                                                        {isSelected && <Checkbox checked={isSelected}
                                                            onChange={() => handleSelect(false, image)} color="primary"
                                                            className={classes.checkBox}
                                                            style={{ color: 'white' }} />}
                                                        {isSelected && <Checkbox checked={isSelected}
                                                            onChange={() => handleSelect(false, image)} color="primary"
                                                            className={classes.checkBox}
                                                            style={{ zIndex: 5 }} />}
                                                        <div className={classes.thumbnail}
                                                            style={{ backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${image.fileId})` }}>{ }</div>
                                                        <Typography variant="caption" className={classes.caption} noWrap>{image.name}</Typography>
                                                    </Grid>
                                                </Tooltip>
                                            </Grid>
                                        );
                                    })}
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
                :
                <>
                    <DialogTitle id="images-dialog-title" className={classes.dialogTitle}>
                        <Grid className={classes.dialogTitleContent} container spacing={1}>
                            <Grid item className={classes.grow}>
                                <Typography variant="h5" className={classes.dialogTitleTypo}>{t('')}</Typography>
                            </Grid>
                            <Grid>
                                <IconButton
                                    onClick={handleClose}
                                    className={classes.closeButton}
                                    color="inherit"
                                    aria-label="close"
                                    size="large">
                                    <Close className={classes.iconStyle} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent className={classes.container}>
                        <Grid >
                            <Grid container spacing={0} >
                                <MUIDataTable
                                    className={classes.dataTable}
                                    title={
                                        <Typography className={classes.mainTitle} gutterBottom>
                                            {t('repairRequests.wkbRepairRequests')}
                                        </Typography>
                                    }
                                    data={imagesFromRepairRequest ? imagesFromRepairRequest.wbRepairRequests || [] : []}
                                    columns={requestColumns}
                                    options={requestOptions}
                                />
                                <MUIDataTable
                                    className={classes.dataTable}
                                    title={
                                        <Typography className={classes.mainTitle} gutterBottom>
                                            {t("general.repairRequests")}
                                        </Typography>
                                    }
                                    data={imagesFromRepairRequest ? imagesFromRepairRequest.repairRequests || [] : []}
                                    columns={requestColumns}
                                    options={requestOptions}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </>
            }
            {!!selectedImages.length && <DialogActions>
                <Button color="primary" onClick={() => {
                    onselect(selectedImages);
                    handleClose();
                }}>
                    {t('files.button.select.title')}
                </Button>
            </DialogActions>}
        </Dialog>
        {isOpenAttachments && <ImagesContainer
            data={selectedRequestData}
            onSelectFiles={(data) => {
                setIsOpenAttachments(false);
                bottomDrawerClose();
                closeUploadDialog();
                onselect(data)
            }}
            open={isOpenAttachments}
            onClose={() => {
                setIsOpenAttachments(false)
            }}
            // onPreview={() => onPreview(requestAttachmentsData)}
            isImagesContainer
        />}
        <Popover
            classes={{
                paper: classes.imageContainerPaper,
            }}
            id="image-container"
            open={!!imageContainerOpen.element}
            anchorEl={imageContainerOpen.element}
            disableRestoreFocus
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={() => setImageContainerOpen({ element: null, data: [] })}
        >
            <Grid item xs={12} className={classes.filesContainer} >
                <Grid container className={classes.gridContainer}>
                    {
                        imageContainerOpen.data && imageContainerOpen.data.map((p, i) => (
                            <Grid key={p.attachmentId} className={classes.attachmentItemContainer} item>
                                <Grid item>
                                    <Grid
                                        className={classes.thumnailContainers}
                                        container xs={12} justifyContent="center">
                                        <div className={classes.thumbnails} style={{ backgroundImage: `url(${URLS.GET_ATTACHMENT_THUMBNAIL}${p.attachmentId})` }} />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
        </Popover>
    </>;
}

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: theme.spacing(9),
		height: theme.spacing(9),
	},
	imageContainerPaper: {
		maxWidth: 382,
		width: '80%',
	},
	filesContainer: {
		overflow: 'auto',
		padding: theme.spacing(1.25),
		maxHeight: 360,
	},
	gridContainer: {
		justifyContent: 'space-around',
		gridTemplateColumns: 'repeat(auto-fill, 61px)',
		display: 'grid',
		gap: 10
	},
	thumbnails: {
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
		height: '100%',
		width: '100%',
		display: 'block',

	},
	thumnailContainers: {
		width: 61,
		height: 56,
		cursor: 'pointer',
		display: 'flex',
		position: 'relative',
		alignItems: 'center',
		flexDirection: 'column',
		justifyContent: 'space-evenly',

	},
	attachmentItemContainer: {
		position: 'relative',
		background: theme.palette.common.white,
		boxShadow: '3px 3px 4px #eee',
		height: 56,
		overflow: 'hidden',
		borderRadius: 5,
		'&:hover': {
			"& $overlay": {
				opacity: 1
			},
		},
	},
	tooltipImageContainer: {
		//maxWidth: '50vw',
		"& > img": {
			width: '100%'
		}
	},
	thumbnailImage: {
		marginLeft: theme.spacing(0),
		width: 50,
		height: 50,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundColor: 'rgba(0,0,0,0.1)',
		cursor: 'pointer'
	},
	customTableCell: {
		flexGrow: 1,
		[theme.breakpoints.only('xs')]: {
			fontSize: 14
		},
		fontSize: 16,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	customColumnItemStyle: {
		flexGrow: 1,
		paddingLeft: theme.spacing(.5),
		fontSize: 10,
		marginBottom: 14,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
	requestSubTitle: {
		fontSize: 10,
		fontWeight: 'bold',
		color: theme.palette.common.black,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		marginBottom: 4
	},
	requestSubTitlePrimary: {
		color: theme.palette.primary.main,
		fontWeight: 'bold'
	},
	customColumnStyle: {
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'center',
		width: '100%',
		[theme.breakpoints.only('xs')]: {
			'& > $customColumnItemStyle:nth-child(2n)': {
				borderLeft: `2px solid ${theme.palette.grey[300]}`,
				paddingLeft: 10
			}
		},
		[theme.breakpoints.only('sm')]: {
			'& > $customColumnItemStyle': {
				borderLeft: `2px solid ${theme.palette.grey[300]}`,
				paddingLeft: 10
			},
		},
	},
	buildingNumber: {
		fontWeight: 'normal',
		fontSize: 12,
	},
	mainTitle: {
		fontSize: 16,
		color: theme.palette.grey[700],
		fontWeight: 'bold',
		marginBottom: 0,
		[theme.breakpoints.only('xs')]: {
			fontSize: 14
		},
	},
	dataTable: {
		height: '100%',
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(0, 0.8),
		boxShadow: 'none',
		'& > .MuiPaper-root': {
			flex: '0 1 auto',
		},
		'& > .MuiToolbar-root.MuiToolbar-regular': {
			display: 'flex',
			minHeight: 'auto',
			marginTop: '20px',
			padding: theme.spacing(0, 0, 0, 1),
			'& .header svg': {
				fontSize: 30
			},
			'& > div': {
				padding: theme.spacing(0),
				textAlign: 'right',
				flex: '1 0 auto',
				'& .MuiTypography-root': {
					textAlign: 'left'
				}
			}
		},
		'& .MuiTable-root': {
			border: '8px solid transparent',
			'& .caption': {
				display: 'none'
			},
			'& .MuiTableHead-root > .MuiTableRow-root': {
				background: theme.palette.common.white,
				position: 'sticky',
				top: -6
			}
		},
		'& .MuiTableCell-root': {
			color: theme.palette.grey[700],
			fontWeight: 'normal',
			fontSize: 16,
			[theme.breakpoints.only('xs')]: {
				fontSize: 16
			},
			padding: theme.spacing(1.7, 0.5, 1.7, 0.5),
			'&.MuiTableCell-paddingCheckbox': {
				paddingLeft: theme.spacing(0.5),
				'& > div': {
					justifyContent: 'center'
				},
				'& .MuiCheckbox-root': {
					padding: theme.spacing(0.25)
				}
			},
			'&.MuiTableCell-head': {
				fontWeight: 'normal',
				fontSize: 16,
				[theme.breakpoints.only('xs')]: {
					fontSize: 14
				},
				'& .MuiButtonBase-root': {
					'& .MuiButton-label': {
						color: theme.palette.grey[700],
						fontWeight: 'normal',
						fontSize: 16,
						[theme.breakpoints.only('xs')]: {
							fontSize: 14
						},
					}
				}
			}
		}
	},
	requestContainer: {
		'& .MuiDialog-paper': {
			borderRadius: 10,
			minWidth: '70%',
			minHeight: '70%',
			[theme.breakpoints.down('md')]: {
				minWidth: '95%',
				minHeight: '70%',
			},
		},
	},
	dialogTitleContent: {
		padding: theme.spacing(1.25),
	},
	grow: {
		flexGrow: 1
	},
	dialogTitleTypo: {
		color: 'white',
		fontSize: 14,
		fontWeight: 'bold'
	},
	closeButton: {
		margin: 0,
		padding: theme.spacing(0)
	},
	iconStyle: {
		fill: 'white',
		width: '1em',
		height: '1em',
	},
	container: {
		padding: theme.spacing(1, 1.5),
	},
	dialogWidthController: {
		maxWidth: 888,
		width: 'max-content',
		[theme.breakpoints.down('lg')]: {
			maxWidth: 760,
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: 504,
		},
		[theme.breakpoints.down('sm')]: {
			maxWidth: 248,
			margin: 'auto',
		},
	},
	imageName: {
		marginLeft: 20
	},
	totalImagesData: {
		lineHeight: 1.334,
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
		[theme.breakpoints.down(360)]: {
			fontSize: '0.9rem',
		}
	},
	totalImagesHeading: {
		flexGrow: 1,
		whiteSpace: 'nowrap',
		[theme.breakpoints.down('sm')]: {
			fontSize: '1rem',
		},
		[theme.breakpoints.down(360)]: {
			fontSize: '0.9rem',
		},

	},
	dialogTitle: {
		padding: theme.spacing(.625),
		background: theme.palette.primary.main,
		'& .MuiIconButton-label > .MuiSvgIcon-root': {
			fill: theme.palette.grey[300]
		},
	},
	titleContainer: {
		padding: theme.spacing(1, 2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(1, 0),
		}
	},
	title: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: 0
	},
	caption: {
		width: '100%',
		textAlign: 'center'
	},
	thumbnail: {
		backgroundPosition: 'center',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		height: 80,
		padding: '35% 0',
		width: '100%',
	},
	checkBox: {
		position: 'absolute',
		right: 0,
		top: 0,
		padding: 0
	},
	imagePreviewIcon: {
		fill: theme.palette.common.white,
		width: '1.5em',
		height: '1.5em'
	},
	overlay: {
		position: 'absolute',
		background: 'rgba(0, 0, 0, 0.5)', /* Black see-through */
		width: '100%',
		transition: '.5s ease',
		opacity: 0,
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		zIndex: 2,
		height: '100%',
	},
	fileContainer: {
		display: 'flex',
	},
	thumnailContainer: {
		minHeight: 100,
		display: 'flex',
		height: 120,
		width: 120,
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		position: 'relative',
		cursor: 'pointer',
		'&:hover': {
			"& $overlay": {
				opacity: 1
			},
		},
	}
}));
export default ExistingImageModal;
