export const themeBreakpointDown = (window, theme, size) => {
  switch (size) {
    case "xs":
      return window.innerWidth < theme.breakpoints.values[size];
    case "sm":
      return window.innerWidth < theme.breakpoints.values[size];
    case "md":
      return window.innerWidth < theme.breakpoints.values[size];
    case "lg":
      return window.innerWidth < theme.breakpoints.values[size];
    case "xl":
      return window.innerWidth < theme.breakpoints.values[size];
    default:
      return false;
  }
};

export const themeBreakpointUp = (window, theme, size) => {
  switch (size) {
    case "xs":
      return window.innerWidth >= theme.breakpoints.values[size];
    case "sm":
      return window.innerWidth >= theme.breakpoints.values[size];
    case "md":
      return window.innerWidth >= theme.breakpoints.values[size];
    case "lg":
      return window.innerWidth >= theme.breakpoints.values[size];
    case "xl":
      return window.innerWidth >= theme.breakpoints.values[size];
    default:
      return false;
  }
};

export const themeBreakpointOnly = (window, theme, size) => {
  switch (size) {
    case "xs":
      return window.innerWidth >= theme.breakpoints.values[size] && window.innerWidth < theme.breakpoints.values["sm"];
    case "sm":
      return window.innerWidth >= theme.breakpoints.values[size] && window.innerWidth < theme.breakpoints.values["md"];
    case "md":
      return window.innerWidth >= theme.breakpoints.values[size] && window.innerWidth < theme.breakpoints.values["lg"];
    case "lg":
      return window.innerWidth >= theme.breakpoints.values[size] && window.innerWidth < theme.breakpoints.values["xl"];
    case "xl":
      return window.innerWidth >= theme.breakpoints.values[size];
    default:
      return false;
  }
};
