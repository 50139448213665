import axios from "axios";
import {fileUploadConfig} from "./fileUploadConfig";

export function formatFileSize(bytes, decimalPoint) {
	if (bytes === 0) return '0 Bytes';
	var k = 1000,
		dm = decimalPoint || 2,
		sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
		i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export async function base64ToFile(url, filename, mimeType) {
	// return (fetch(url)
	// 	.then(function (res) { return res.arrayBuffer(); })
	// 	.then(function (buf) { return new File([buf], filename, { type: mimeType }); })
	// );
	let sliceSize = 512;
	const byteCharacters = atob(url.split(',').pop());
	const byteArrays = [];

	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);

		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	return new File(byteArrays, filename, { type: mimeType });
}

export function totalFilesSizeIsValid(files, validMB) {
	const totalFilesByteSize = files.reduce((p, c) => p + c.size, 0);
	const converted = +(totalFilesByteSize / (1024 * 1024)).toFixed(2);
	return converted < validMB;
}

export const isValidFiles = (files, customType) => files.every(p =>
	customType === "images" ? fileUploadConfig.allowedImageFormats.find(f => f.mime === p.type)
		: customType ? customType === p.type : fileUploadConfig.allowedMimeTypes.find(f => f.mime === p.type));

export const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

export const changeFileNameExtension = (fileName, extension) => {
	let newName = fileName;
	if (fileName.includes('.')) {
		const nameArr = fileName.split('.');
		nameArr.pop();
		newName = nameArr.join('.');
	}
  return newName + '.'+ extension;
}

//Mime types. Some keys are duplicated.
export function getMimeTypefromString(extension) {
	let mimeType = fileUploadConfig.allowedImageFormats.find(p => p.extension === extension);
	if (!mimeType) mimeType = fileUploadConfig.allowedMimeTypes.find(p => p.extension === extension);
	if (!mimeType) return 'application/octet-stream';
	return mimeType.mime;
}


export const downloadFile = async (url, download="") => {
	const file = await axios.get(url, { responseType: 'blob' });
	if (file) {
		const { data } = file;
		const url = window.URL.createObjectURL(data);
		let link = document.createElement("a");
		link.href = url;
		link.download = download;
		document.body.appendChild(link).click();
		setTimeout(() => window.URL.revokeObjectURL(url), 200);
	}
}

export const createAndDownloadTextFile = async ({content, fileName="text"}) => {
	const element = document.createElement("a");
	const file = new Blob([content], {type: 'text/plain'});
	element.href = URL.createObjectURL(file);
	element.download = `${fileName}.txt`;
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
}

export const fileToBlob = (file) => {
	return new Promise(async (resolve, reject) => {
		const reader = new FileReader();
		reader.onload = function (e) {
			const blob = new Blob([e.target.result], { type: file.type });
			return resolve(blob);
		};
		reader.onerror = function (e) {
			return reject(e)
		}
		reader.readAsArrayBuffer(file);
	})
}

export const compressImage = (input, maxWidth = 1000, maxHeight = 1000) => {
    return new Promise((resolve, reject) => {
        let img = new Image();
        img.crossOrigin = 'anonymous';
        img.onload = () => {
            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');
            let width = img.width;
            let height = img.height;

            if (maxWidth && width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
            }

            if (maxHeight && height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            let quality = 1;
            canvas.toBlob(blob => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    resolve(reader.result);
                };
            }, 'image/jpeg', quality);
        };
        img.src = input;
    });
}


export const storageQuotaInfo = () => {

	if ('storage' in navigator && 'estimate' in navigator.storage) {
		return navigator.storage.estimate();
	}

	const temporaryStorage = navigator.temporaryStorage ||
		navigator.webkitTemporaryStorage ||
		navigator.mozTemporaryStorage ||
		navigator.msTemporaryStorage || navigator.persistentStorage ||
		navigator.webkitPersistentStorage ||
		navigator.mozPersistentStorage ||
		navigator.msPersistentStorage ||
		undefined;

	if (!temporaryStorage) return Promise.resolve({ usage: 0, quota: 0 });

	return new Promise(function (resolve, reject) {
		temporaryStorage.queryUsageAndQuota(function (usage, quota) { resolve({ usage: usage, quota: quota }) }, reject);
	});

	// if ('storage' in navigator && 'estimate' in navigator.storage) {
	// 	return navigator.storage.estimate();
	// }

	// if ('webkitTemporaryStorage' in navigator && 'queryUsageAndQuota' in navigator.webkitTemporaryStorage) {
	// 	return new Promise(function (resolve, reject) {
	// 		navigator.webkitTemporaryStorage.queryUsageAndQuota(
	// 			function (usage, quota) { resolve({ usage: usage, quota: quota }) },
	// 			reject
	// 		);
	// 	});
	// }
	// return Promise.resolve({ usage: 0, quota: 0 });
}
