import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  Slide,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
  Hidden,
  Snackbar,
  Chip,
  AppBar,
  FormControlLabel,
  Checkbox,
  Popover,
  ImageList,
  ImageListItem,
  ButtonBase,
  alpha,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  AddCircleOutline,
  Delete,
  DeleteOutline,
  LocationOn,
  Map,
  NotInterested,
  SkipNextOutlined,
  SkipPreviousOutlined,
  Warning,
  ZoomIn,
  ZoomOut,
} from "@mui/icons-material";
import UploadFiles from "../Dossiers/Common/UploadFiles";
import Close from "@mui/icons-material/Close";
import clsx from "clsx";
import Dropzone from "../../components/Dropzone";
import { TextField } from "@mui/material";
import SelectOrganizations from "./components/SelectOrganizations";
import { useTheme } from "@mui/material";
import Camera from "./components/Camera";
import { CircularProgress } from "@mui/material";
import {
  base64ToFile,
  isValidFiles,
  RepairRequestCarryTypes,
  storeRepairRequest,
  toBase64,
  totalFilesSizeIsValid,
  sortAlphaNumerically,
  nl2br,
  getFile,
  formatDate,
} from "../../_helpers";
import { useSelector } from "react-redux";
import {
  createRepairRequest,
  getRepairRequestLocations,
  getSettlementTerms,
  getOrganizations,
} from "../../apis/surveyingApis";
import { Alert } from "@mui/material";
import { URLS } from "../../apis/urls";
import PinLocation from "../../assets/icon/pin.png";
import ImageEditor from "../../components/ImageEditor";
import { userAccountTypeConstants } from "../../_constants";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import axios from "axios";

const { webApiUrl } = window.appConfig;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RepairRequestForm({
  isBuyerOrPropertyManager,
  repairRequests,
  setIsDrawingLocation = () => {},
  isUpdate,
  values,
  canSelectSortManual,
  drawingLocation,
  updateOrAddLocation,
  setCordinates = () => {},
  isReadOnly,
  requestId,
  moduleType,
  setSelectedDrawingFiles,
  setCurrentPage,
  isDrawingLocation,
  setDrawingLocationState = () => {},
  openRepairRequestMap,
  onClose = () => {},
  selectedDrawingFiles,
  currentPage,
  projectId,
  isAssignmentDetail,
  buildingId,
  surveyId,
  setIsRepairRequest,
  redirectToMomentDetail,
  setID = () => {},
  onPreview,
  isCreateRepairRequest = true,
  ...props
}) {
  const [canShowDrawingMap, setCanShowDrawingMap] = useState(
    openRepairRequestMap && !isUpdate
  );
  const { t } = useTranslation();
  const [locationCoordinates, setLocationCoordinates] = useState({
    left: -100,
    top: -100,
  });
  const [previousLocationCoordinates, setPreviousLocationCoordinates] = useState({
    left: -100,
    top: -100,
  });
  const [openConfirmation, stOpenConfirmation] = useState(false);
  const [isConfirmedLocation, setIsConfirmedLocation] = useState(false);
  const [isOpenResolver, setIsOpenResolver] = useState(null);
  const [canvasImage, setCanvasImage] = useState(null);
  const [openCameraDrawer, setOpenCameraDrawer] = useState(false);
  const [canvas, setCanvas] = useState({ current: null });
  const [measure, setMeasure] = useState({ current: null });
  const classes = useStyles({ canvasImage });
  const [originalValue, setOriginalValue] = useState({});
  const [clientValue, setClientValue] = useState();
  const [canvasContainerSize, setCanvasContainerSize] = useState({
    height: "100%",
    width: "100%",
  });
  const muiTheme = useTheme();
  const matchWidthDownXs = useMediaQuery(muiTheme.breakpoints.down("sm"));
  const [organizationLoading, setOrganizationLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [termsList, setTermsList] = useState([]);
  const [selected, setSelected] = useState(null);
  //Redux State
  const app = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.authentication);
  const { all } = useSelector((state) => state.buildings);
  const { selectedAssignment, selectedInspection, assignmentLockIds } =
    useSelector((state) => state.surveying);
  const { drawingFiles, drawingFilesLoading, isDrawingFiles } = useSelector(
    (state) => state.dossier
  );

  const [isAddRepairRequest, setIsAddRepairRequest] = useState(false);
  const [oplossers, setOplossers] = useState();
  const [fileError, setFileError] = useState({
    filesSizeIsInValid: false,
    fileUploadErrorMessage: null,
  });
  const [transformComponentRef, setTransformComponentRef] = useState({});
  const transformRef = useRef(null);
  const [allOrgs, setAllOrgs] = useState([]);
  const [canvasImageLoading, setCanvasImageLoading] = useState(false);
  const [openEditor, setOpenEditor] = useState(false);
  const [canChangeSelectedMap, setCanChangeSelectedMap] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);
  const isBuyer = user && user.type === userAccountTypeConstants.buyer;
  const [request, setRequest] = useState({
    isOpen: isUpdate,
    attachments: [],
    location: { x: 10, y: 10 },
    shortDesc: "",
    detailedDesc: "",
    term: "",
    Soort: "",
    locationId: "",
    canShowMarker: false,
  });
  const [closeAutocomplete, setCloseAutocomplete] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [warningText, setWarningText] = useState("");
  const [drawingFileUrl, setDrawingFileUrl] = useState("");
  const [drawingFileUrls, setDrawingFileUrls] = useState({});
  const [mapSwitcherAnchorEl, setMapSwitcherAnchorEl] = useState(null);

  //ref nodes init
  const canvasRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setCanvas({ current: node });
        }, 100);
      }
    },
    [openRepairRequestMap]
  );
  const transformComponentRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setTransformComponentRef({ current: node });
        }, 100);
      }
    },
    [openRepairRequestMap]
  );
  const measureRefNode = useCallback(
    (node) => {
      if (node !== null) {
        setTimeout(() => {
          setMeasure({ current: node });
        }, 100);
      }
    },
    [openRepairRequestMap]
  );

  useEffect(() => {
    if (!openRepairRequestMap && !isDrawingLocation && !isUpdate) {
      setRequest((p) => ({
        ...p,
        isOpen: isUpdate,
        attachments: [],
        location: { x: 10, y: 10 },
        shortDesc: "",
        detailedDesc: "",
        Soort: "",
        locationId: "",
        canShowMarker: false,
      }));
    }
  }, [openRepairRequestMap, isUpdate]);

  useEffect(() => {
    const setTermsAndLocation = (res) => {
      setTermsList(res);
      const defaultSelected =
        res && res.length ? res.find((p) => p.isDefault) : {};
      setRequest((p) => ({ ...p, term: defaultSelected.termId }));
    };
    if (openRepairRequestMap && !isDrawingLocation) {
      !isBuyer &&
        !isBuyerOrPropertyManager &&
        getSettlementTerms()
          .then((res) => {
            setTermsAndLocation(res.data);
          })
          .catch(console.log);

      getRepairRequestLocations()
        .then((res) => {
          setLocations(res.data);
        })
        .catch(console.log);
    }
  }, [openRepairRequestMap]);

  useEffect(() => {
    if (all && projectId) {
      setSelected(
        all.find(
          (a) =>
            a.projectId === projectId &&
            (!buildingId || buildingId === a.buildingId)
        )
      );
    }
  }, [projectId, all, buildingId]);

  useEffect(() => {
    (async () => {
      if (
        (isDrawingFiles || isDrawingLocation) &&
        !drawingFilesLoading &&
        openRepairRequestMap
      ) {
        const data = isUpdate
          ? {
              Soort: values.values,
              attachments: await Promise.all(
                (values.attachments || []).map(async (file) => ({
                  file,
                  attachmentUrl: await toBase64(file),
                  attachmentId: Math.random().toString(),
                }))
              ),
              detailedDesc: values.detailedDesc,
              location: {
                x: +values["DrawingPinLocationModel.Xrel"],
                y: +values["DrawingPinLocationModel.Yrel"],
              },
              locationId: values.locationId,
              shortDesc: values.desc,
              term: values.completionTermId,
            }
          : {};
        !isDrawingLocation &&
          setRequest((prev) => ({
            ...prev,
            isOpen: !drawingFiles.length || isUpdate,
            ...data,
          }));
        setCanShowDrawingMap(
          (!!drawingFiles.length || isDrawingLocation) && !isUpdate
        );
        if (
          drawingLocation &&
          drawingLocation.dossierFileId === selectedDrawingFiles.dossierFileId
        )
          setRequest((p) => ({ ...p, canShowMarker: true, ...data }));
      }
    })();
  }, [drawingFilesLoading, drawingFiles, openRepairRequestMap, isUpdate]);

  useEffect(() => {
    (async () => {
      let objDrawingFileUrls = Object.assign({});
      if (drawingFiles) {
        for (var i = 0; i < drawingFiles.length; i++) {
          var imageURL = await getDrawingFileURL(drawingFiles[i].dossierFileId);
          objDrawingFileUrls[drawingFiles[i].dossierFileId] = imageURL;
        }
      }
      setDrawingFileUrls(objDrawingFileUrls);
    })();
  }, [window.navigator.onLine, drawingFiles]);

  useEffect(() => {
    if (isUpdate && values) {
      setCurrentPage(+values["DrawingPinLocationModel.PageNumber"]);
      setLocationCoordinates((a) => ({
        ...a,
        xRel: +values["DrawingPinLocationModel.Xrel"],
        yRel: +values["DrawingPinLocationModel.Yrel"],
      }));
      getOrganizations(selected && selected.projectId, app.toString())
        .then((res) => {
          setAllOrgs(res.data);
          const data = JSON.parse(values.resolverOrganisations);
          if (data)
            setOplossers(
              data.reduce(
                (p, c) => ({
                  ...p,
                  orgIds: [...p.orgIds, c.organisationId],
                  relationIds: [
                    ...p.relationIds,
                    { orgId: c.organisationId, relationId: c.relationId },
                  ],
                }),
                { orgIds: [], relationIds: [] }
              )
            );
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [isUpdate, values]);

  useEffect(() => {
    if (drawingFiles && drawingFiles.length && openRepairRequestMap) {
      const localFile = JSON.parse(
        localStorage.getItem("Repair_Request_Drawing_File_" + moduleType)
      );
      if (!localFile)
        localStorage.setItem(
          "Repair_Request_Drawing_File_" + moduleType,
          JSON.stringify(selectedDrawingFiles)
        );
      else {
        const isExist = drawingFiles.find(
          (a) => a.dossierFileId === localFile.dossierFileId
        );
        if (
          isExist && !isCreateRepairRequest &&
          (!selectedDrawingFiles ||
            selectedDrawingFiles.dossierFileId !== localFile.dossierFileId)
        )
          setSelectedDrawingFiles(isExist);
      }
    }
  }, [drawingFiles, openRepairRequestMap]);

  useEffect(() => {
    if (request.isOpen)
      window.addEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
    else
      window.removeEventListener("beforeunload", beforeUnloadListener, {
        capture: true,
      });
  }, [request.isOpen]);

  useEffect(() => {
    if (!selectedDrawingFiles || !selectedDrawingFiles.dossierFileId)
      setDrawingFileUrl("");
    else getCurrentDrawingFileURL().then(setDrawingFileUrl);
  }, [window.navigator.onLine, selectedDrawingFiles]);

  useEffect(() => {
    if (request.isOpen && isBuyerOrPropertyManager) {
      axios
        .get(`${webApiUrl}api/RepairRequest/GetRepairRequestAddWarningText`)
        .then(({ data }) => {
          setWarningText(data.warningText);
        })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [request.isOpen, isBuyerOrPropertyManager]);

  const beforeUnloadListener = useCallback((event) => {
    event.preventDefault();
    return (event.returnValue = "Are you sure you want to exit?"); // only development use, not showing in front-end
  }, []);

  useEffect(() => {
    const img = new Image();
    if (isReadOnly && !updateOrAddLocation) {
      var imgSrc = `${webApiUrl}api/RepairRequest/GetPinLocationImage/${requestId}`;
      img.src = imgSrc;
      setDrawingFileUrl(imgSrc);
    } else if (
      selectedDrawingFiles &&
      selectedDrawingFiles.dossierFileId &&
      currentPage
    ) {
      img.src = drawingFileUrl;
    } else img.src = "";
    img.crossOrigin = "Anonymous";
    setCanvasImageLoading(true);
    img.onload = () => {
      if (measure.current) {
        measure.current.appendChild(img);
        setOriginalValue({ height: img.height, width: img.width });
        measure.current.removeChild(img);
        centerViewHandler();
      }
      setTimeout(() => setCanvasImage(img), 500);
    };
  }, [
    selectedDrawingFiles && selectedDrawingFiles.dossierFileId,
    drawingFileUrl,
    currentPage,
    measure,
    canvas.current,
  ]);

  useEffect(() => {
    if (canvasImage && canvas.current && transformComponentRef.current) {
      if (!clientValue) {
        setClientValue({
          clientHeight: canvas.current.clientHeight,
          clientWidth: canvas.current.clientWidth,
        });
      }
      let { clientHeight, clientWidth } = clientValue || canvas.current;
      const wrh = originalValue.width / originalValue.height;
      let newHeight = clientHeight;
      let newWidth = newHeight * wrh;
      if (
        transformComponentRef.current &&
        newWidth > transformComponentRef.current.clientWidth
      ) {
        newWidth = transformComponentRef.current.clientWidth;
        newHeight = newWidth / wrh;
      }
      canvas.current.width = newWidth;
      canvas.current.height = newHeight;
      setCanvasContainerSize({ height: newHeight, width: newWidth });
      centerViewHandler();
      if (drawingLocation)
        setLocationCoordinates((p) => ({
          ...p,
          xRel: drawingLocation.xrel,
          yRel: drawingLocation.yrel,
          top: newHeight * drawingLocation.yrel - 22,
          left: newWidth * drawingLocation.xrel - 12,
        }));
      setCanvasImageLoading(false);
    }
  }, [canvasImage, canvas, transformComponentRef]);

  const centerViewHandler = () => {
    const ref = transformRef.current;
    setTimeout(() => {
      if (ref) {
        ref.centerView(1);
      }
    }, 1000);
  };

  const locationChangeHandler = (e, scale) => {
    const rect = canvas.current.getBoundingClientRect();
    const x = (e.clientX - rect.left) / scale - 12;
    const y = (e.clientY - rect.top) / scale - 24 + 1 * scale;
    setLocationCoordinates({
      top: y,
      left: x,
      xRel: (e.clientX - rect.left) / scale / canvasContainerSize.width,
      yRel: (e.clientY - rect.top) / scale / canvasContainerSize.height,
    });
    setCordinates({
      top: y,
      left: x,
      xRel: (e.clientX - rect.left) / scale / canvasContainerSize.width,
      yRel: (e.clientY - rect.top) / scale / canvasContainerSize.height,
    });
    stOpenConfirmation(true);
    setPreviousLocationCoordinates(locationCoordinates);
  };

  const handleCloseConfirmationDialog = (val) => {
    setIsConfirmedLocation(val);
    stOpenConfirmation(false);
    setRequest({
      ...request,
      isOpen: false,
      attachments: [],
      location: { x: 10, y: 10 },
      shortDesc: "",
      permission: "",
      // term: '',
      // Soort: '',
      locationId: "",
    });
    setLocationCoordinates({
      ...previousLocationCoordinates
    });
      
  };

  const resetLocationCoordinates = () => {
    setLocationCoordinates({
      top: -100,
      left: -100,
    });
  };

  const handleSkipMapLocation = () => {
    setIsConfirmedLocation(false);
    stOpenConfirmation(false);
    setRequest((p) => ({
      ...p,
      isOpen: true,
    }));
    setCanShowDrawingMap(false);
  };

  const handleChange = (field, value) => {
    setRequest((p) => ({ ...p, [field]: value }));
  };

  const handleUploadFiles = (files) => {
    if (!isValidFiles(files, "images")) {
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.type.notvalid.error",
      });
      return;
    }
    if (totalFilesSizeIsValid(files, 25)) {
      if (files.length) {
        setRequest((p) => ({ ...p, isOpen: true }));
        fileUploadHandler(files);
      }
    } else
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.size.notvalid.error",
      });
  };

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      if (!drawingFiles.length) {
        setIsAddRepairRequest(false);
        setRequest((p) => ({ ...p, isOpen: false }));
        setOplossers(null);
        onClose();
        return;
      }
      setOplossers(null);
      onClose();
      setCanShowDrawingMap(false);
      setRequest((p) => ({ ...p, isOpen: false, canShowMarker: false }));
    }
  };

  const closeCameraHandler = () => {
    setOpenCameraDrawer(false);
    stOpenConfirmation(false);
  };

  const cameraFileSelectHandler = async (file) => {
    const data = [...request.attachments];
    setOpenCameraDrawer(false);
    stOpenConfirmation(false);
    setOpenEditor(true);
    setUploadedImage(file);
    // const attachmentUrl = await toBase64(file);
    // data.push({ file, attachmentUrl, attachmentId: Math.random().toString() });
    // setRequest(p => ({ ...p, isOpen: true, attachments: data }));
  };

  const validateForm = () => {
    const { shortDesc: desc, term, detailedDesc, Soort, locationId } = request;
    return (
      validateField(desc, true) &&
      // && validateField(detailedDesc, true)
      validateField(locationId, true)
      // && validateField(term, true)
      // && validateField(Soort, true)
    );
  };
  const hasImages = isBuyerOrPropertyManager ? request.attachments.length >0 :true;
  
  const validateField = (field, isValid = false) => {
    return !isValid || (field && field.trim() !== "");
  };

  const handleConfirm = async () => {
    if (isDrawingLocation) {
      if (drawingLocation || updateOrAddLocation) {
        setIsConfirmedLocation(false);
        stOpenConfirmation(false);
        setCordinates(locationCoordinates);
        onClose(true);
        setCanShowDrawingMap(false);
      } else {
        const canv = document.createElement("CANVAS"); //create canvas
        canv.height = canvasContainerSize.height;
        canv.width = canvasContainerSize.width;
        const ctx = canv.getContext("2d");
        ctx.webkitImageSmoothingEnabled = false;
        ctx.mozImageSmoothingEnabled = false;
        ctx.imageSmoothingEnabled = false;
        ctx.rect(0, 0, canvasContainerSize.width, canvasContainerSize.height);
        ctx.fillStyle = "white";
        ctx.fill();
        //console.log(canvasImage.height, canvasImage.width);//We should use this instead of using canvasContainerSize for creating final image.
        ctx.drawImage(
          canvasImage,
          0,
          0,
          canvasContainerSize.width,
          canvasContainerSize.height
        );
        const img1 = new Image(); // Create new img element
        img1.src = PinLocation;
        img1.onload = async () => {
          await ctx.drawImage(
            img1,
            locationCoordinates.left,
            locationCoordinates.top
          );
          const img = await base64ToFile(
            canv.toDataURL("image/jpeg"),
            selectedDrawingFiles && selectedDrawingFiles.name + ".jpg",
            "image/jpeg"
          );
          setIsConfirmedLocation(false);
          stOpenConfirmation(false);
          setIsDrawingLocation(true);
          setDrawingLocationState(img);
          setCordinates(locationCoordinates);
          onClose(true);
          setCanShowDrawingMap(false);
        };
      }
    } else {
      setIsConfirmedLocation(false);
      stOpenConfirmation(false);
      setRequest((p) => ({
        ...p,
        isOpen: true,
      }));
      setCanShowDrawingMap(false);
    }
  };

  const handleRemoveDrawingLocation = () => {
    setIsConfirmedLocation(false);
    stOpenConfirmation(false);
    setRequest((p) => ({
      ...p,
      isOpen: true,
    }));
    setCanShowDrawingMap(false);
    resetLocationCoordinates();
  };

  const getRepairRequestSequenceNumber = () => {
    const generateNumber = (buildingNoIntern) => {
      const char = { 0: "V", 1: "O", 2: "I", 4: "W" };
      const sortedRequests = repairRequests
        .filter((p) => !!p.numberOffline)
        .sort((a, b) =>
          sortAlphaNumerically(a.numberOffline, b.numberOffline, "desc")
        );
      const number = sortedRequests.length
        ? +sortedRequests[0].numberOffline.split(char[moduleType])[1] + 1
        : repairRequests.length + 1;
      return `${buildingNoIntern}.${char[moduleType]}${number
        .toString()
        .padStart(3, "0")}`;
    };
    return generateNumber(
      isAssignmentDetail
        ? `${selected.projectNo}-${selectedAssignment.surveyingMomentDetails.object}`
        : selectedInspection.buildingNoIntern
    );
  };

  const handleRequest = async (event) => {
    const lockId = isAssignmentDetail
      ? selectedAssignment.lockId
      : selectedInspection && selectedInspection.lockId;
    const lockInfo =
      lockId &&
      assignmentLockIds &&
      assignmentLockIds.find((a) => a.lockId === lockId);
    const isDownloadedSurvey =
      lockInfo && lockInfo.userId === user.id && lockId;
    setIsAddRepairRequest(true);
    const formData = new FormData();
    const resolverNames = [];
    const textResolvers = [];
    const resolverOrganisations = oplossers
      ? oplossers.orgIds.map((orgId) => {
          const selectedOrg = allOrgs.find((p) => p.id === orgId) || {};
          const selectedPerson =
            oplossers.relationIds.find((p) => p.orgId === orgId) || {};
          resolverNames.push(selectedOrg.name);
          textResolvers.push(selectedOrg.name);
          return {
            organisationId: orgId,
            relationId: selectedPerson.relationId,
            createWorkorder: selectedPerson.createWorkOrder,
            notify: selectedPerson.notify,
            resolverId: selectedPerson.resolverId,
          };
        })
      : [];

    formData.append(
      "resolverOrganisations",
      JSON.stringify(resolverOrganisations)
    );
    if (buildingId) formData.append("buildingId", buildingId);
    else formData.append("projectId", projectId);

    formData.append("locationId", request.locationId);
    formData.append("desc", request.shortDesc);
    !isBuyer &&
      !isBuyerOrPropertyManager &&
      request.term &&
      formData.append("completionTermId", request.term);
    isBuyerOrPropertyManager &&
      formData.append("SendMailToReporter", sendEmail);

    formData.append("detailedDesc", request.detailedDesc);
    (surveyId || isAssignmentDetail) &&
      formData.append(
        isAssignmentDetail ? "assignmentId" : "surveyId",
        surveyId
      );
    if (selectedDrawingFiles && selectedDrawingFiles.dossierFileId && locationCoordinates.xRel >= 0 && locationCoordinates.yRel >= 0) {
      formData.append(
        "DrawingPinLocationModel.DossierFileId",
        selectedDrawingFiles.dossierFileId
      );
      formData.append("DrawingPinLocationModel.PageNumber", currentPage);
      formData.append(
        "DrawingPinLocationModel.Xrel",
        locationCoordinates.xRel.toFixed(5)
      );
      formData.append(
        "DrawingPinLocationModel.Yrel",
        locationCoordinates.yRel.toFixed(5)
      );
    }
    for (var i = 0; i < request.attachments.length; i++) {
      formData.append("files", request.attachments[i].file);
    }
    if (isDownloadedSurvey) {
      let body = {};
      for (let key of formData.keys())
        body[key === "files" ? "attachments" : key] =
          formData[key === "files" ? "getAll" : "get"](key);
      const location = locations.find((p) => p.id === request.locationId) || {};
      const number = getRepairRequestSequenceNumber();
      // remove location info from old values for offline update
        let {
            ["DrawingPinLocationModel.DossierFileId"]: _1,
            ["DrawingPinLocationModel.PageNumber"]: _2,
            ["DrawingPinLocationModel.Xrel"]: _3,
            ["DrawingPinLocationModel.Yrel"]: _4,
            ...updatedValues
        } = values || {};
      storeRepairRequest(
        {
          requestId: new Date().getTime().toString(),
          date: new Date(),
          status: "Nieuw",
          projectId,
          surveyId,
          number,
          OfflineNo: number,
          numberOffline: number,
          ...updatedValues,
          ...body,
          sync: 0,
          location: location.name,
          completed: 0,
          resolverNames,
          textResolvers,
          carryOutAsType: RepairRequestCarryTypes[moduleType],
        },
        isAssignmentDetail,
        selectedAssignment
      )
        .then(() => {
          setIsAddRepairRequest(false);
          handleClose();
          onClose(true);
        })
        .catch((err) => {
          console.log(err, "add repair req error");
          setIsAddRepairRequest(false);
          onClose(true);
          alert(t("general.api.error"));
        });
    } else {
      createRepairRequest(formData)
        .then(async (res) => {
          setIsAddRepairRequest(false);
          handleClose();
          onClose(true);
        })
        .catch((e) => {
          setIsAddRepairRequest(false);
          onClose(true);
          alert(t("general.api.error"));
        });
    }
  };

         const fileUploadHandler = (data, isExistingImage) => {
    let attachments = [...request.attachments];
    data = Array.from(data);
    if (isExistingImage) {
      setRequest((p) => ({ ...p, attachments: attachments.concat(data) }));
      return;
    }
    if (!isValidFiles(data, "images")) {
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.type.notvalid.error",
      });
      return;
    }
    if (
      totalFilesSizeIsValid([...attachments.map((f) => f.file), ...data], 25)
    ) {
      setOpenEditor(true);
      setUploadedImage(data[0]);
      // uploadEditedFile(data,attachments)
    } else
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.size.notvalid.error",
      });
  };

  const uploadEditedFile = async (file) => {
    const attachmentUrl = await toBase64(file);
    const attachmentId = Math.random().toString();
    let attachments = [
      ...request.attachments,
      { file, attachmentUrl, attachmentId },
    ];
    setOpenEditor(false);
    setUploadedImage(null);
    if (
      totalFilesSizeIsValid(
        attachments.map((p) => p.file),
        25
      )
    ) {
      setRequest((p) => ({ ...p, attachments: attachments }));
    } else
      setFileError({
        filesSizeIsInValid: true,
        fileUploadErrorMessage: "files.total.size.notvalid.error",
      });
  };

  const handleCloseEditor = () => {
    // uploadEditedFile(uploadedImage);
    setOpenEditor(false);
    setUploadedImage(null);
  };

  const handleSelectPerson = (data, organizations) => {
    setAllOrgs(organizations);
    setOplossers(data);
    setIsOpenResolver(null);
  };

  const handleRemoveImage = (e, index) => {
    e.stopPropagation();
    const data = Object.assign([], request.attachments);
    data.splice(index, 1);
    setRequest((p) => ({ ...p, attachments: data }));
  };

  const handleNextMoments = () => {
    if (selectedDrawingFiles.pages === currentPage) return;
    if (selectedDrawingFiles.pages > currentPage)
      setCurrentPage(currentPage + 1);
  };

  const handlePrevMoments = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  const handleChangeSendEmail = () => {
    setSendEmail((p) => !p);
  };

  const mainDialogCloseHandler = () => {
    if (request.canShowMarker && !isDrawingLocation) {
      setRequest((p) => ({ ...p, canShowMarker: false, isOpen: true }));
    } else {
      onClose();
      setRequest((p) => ({ ...p, canShowMarker: false, isOpen: false }));
    }
    setCanShowDrawingMap(false);
  };

  const getCurrentDrawingFileURL = async () => {
    return await getDrawingFileURL(
      selectedDrawingFiles.dossierFileId,
      currentPage
    );
  };

  const getDrawingFileURL = async (dossierFileId, pageNumber = 1) => {
    let imageURL = "";
    if (window.navigator.onLine) {
      imageURL =
        URLS.GET_DOSSIER_PDF_IMAGE + dossierFileId + `?page=${pageNumber}`;
    } else {
      var result = await getFile(dossierFileId);
      imageURL = result.file;
    }
    return imageURL;
  };

  const handleClickMapSwitcher = (event) => {
    setMapSwitcherAnchorEl(event.currentTarget);
  };

  const handleCloseMapSwitcherPopover = () => {
    setMapSwitcherAnchorEl(null);
  };

  const openMapSwitcherPopover = Boolean(mapSwitcherAnchorEl);
  const mapSwitcherPopoverId = openMapSwitcherPopover
    ? "mapSwitcherPopover"
    : undefined;

    const isImageRequired =isBuyerOrPropertyManager && !hasImages

  return (
    <>
      <Dialog
        open={canShowDrawingMap}
        onClose={mainDialogCloseHandler}
        className={classes.mapDailog}
        aria-labelledby="alert-dialog-title"
        TransitionComponent={Transition}
        aria-describedby="alert-dialog-description"
        fullScreen
      >
        <TransformWrapper
          initialScale={1}
          minScale={1}
          maxScale={15}
          centerOnInit={true}
          centerZoomedOut={true}
          wheel={{ disabled: false }}
        >
          {({ zoomIn, zoomOut, resetTransform, state: scale, ...rest }) => (
            <>
              <div className={classes.fullHeight}>
                <AppBar
                  position="sticky"
                  className={classes.surveyMomentsMainHeaderSecond}
                >
                  <Grid container>
                  <Grid item xs={10} md={10} lg={10} className={classes.documentNameContainer}>
                    <div className={classes.buttonsContainer}>
                      <Tooltip title={t("repairRequest.drawing.zoomIn")}>
                        <IconButton
                          disabled={scale.scale === 15}
                          onClick={() => zoomIn()}
                          aria-label="ZoomIn"
                          edge="end"
                          color="inherit"
                          className={classes.zoomButton}
                          size="large"
                        >
                          <ZoomIn />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("repairRequest.drawing.zoomOut")}>
                        <IconButton
                          disabled={scale.scale === 1}
                          onClick={() => zoomOut()}
                          aria-label="ZoomOut"
                          edge="end"
                          color="inherit"
                          className={classes.zoomButton}
                          size="large"
                        >
                          <ZoomOut />
                        </IconButton>
                      </Tooltip>
                    {!isReadOnly && (
                        <>
                          <Tooltip
                            title={t("repairRequest.drawing.changeLocation")}
                          >
                            <IconButton
                              onClick={(e) => handleClickMapSwitcher(e)}
                              aria-label={t(
                                "repairRequest.drawing.changeLocation"
                              )}
                              edge="end"
                              color="inherit"
                              className={classes.zoomButton}
                              size="large"
                            >
                              <Map />
                            </IconButton>
                          </Tooltip>
                        </>
                      )}
                    </div> 
                    <div className={classes.appBarContainer}>
                      <div className={classes.grow}>
                        <Typography
                          className={clsx(classes.title)}
                          title={t("repairRequest.drawing.documentName")}
                          onClick={(e) => handleClickMapSwitcher(e)}
                        >
                          {selectedDrawingFiles && selectedDrawingFiles.name}
                        </Typography>
                      </div>
                    </div>
                    {!isReadOnly && (
                    <Popover
                            id={mapSwitcherPopoverId}
                            open={openMapSwitcherPopover}
                            anchorEl={mapSwitcherAnchorEl}
                            onClose={handleCloseMapSwitcherPopover}
                            disableRestoreFocus
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <ImageList
                              sx={{height: 'auto',  minHeight: "350px"}}
                              className={classes.gridListMain}
                            >
                              {drawingFiles &&
                                drawingFiles
                                  .sort((a, b) =>
                                    sortAlphaNumerically(
                                      (a.isInternal ? "0" : "1") + a.name,
                                      (b.isInternal ? "0" : "1") + b.name,
                                      "asc"
                                    )
                                  )
                                  .map((drawingFile, index) => (
                                    <ImageListItem
                                      key={index}
                                      cols={2}
                                      className={classes.gridListTile}
                                    >
                                      {drawingFileUrls[
                                        drawingFile.dossierFileId
                                      ] && (
                                        <img
                                          className={classes.gridListImage}
                                          src={`${
                                            drawingFileUrls[
                                              drawingFile.dossierFileId
                                            ]
                                          }${
                                            drawingFileUrls[
                                              drawingFile.dossierFileId
                                            ].indexOf("data:") === 0
                                              ? ""
                                              : "&thumbnailSize=160"
                                          }`}
                                          alt={drawingFile.name}
                                        />
                                      )}
                                      <div className={classes.titleWrapMain}>
                                        <span className={classes.spanWrapMain}>
                                          {drawingFile.isInternal
                                            ? `${t("general.internal")}`
                                            : `${t("general.buyer")}`}
                                        </span>
                                      </div>
                                      <ButtonBase
                                        className={classes.buttonBaseMain}
                                        onClick={(e, v) => {
                                          if(!!selectedDrawingFiles && drawingFile.dossierFileId !== selectedDrawingFiles.dossierFileId) {
                                            resetLocationCoordinates();
                                          }
                                          setSelectedDrawingFiles(drawingFile);
                                          localStorage.setItem(
                                            "Repair_Request_Drawing_File_" +
                                              moduleType,
                                            JSON.stringify(drawingFile)
                                          );
                                          setRequest((p) => ({
                                            ...p,
                                            canShowMarker: false,
                                          }));
                                          !canChangeSelectedMap &&
                                            setCanChangeSelectedMap(true);
                                          handleCloseMapSwitcherPopover();
                                        }}
                                      >
                                        <div className={classes.titleWrap}>
                                          <span
                                            className={classes.spanWrapMain}
                                          >
                                            {drawingFile.name}
                                          </span>
                                        </div>
                                      </ButtonBase>
                                    </ImageListItem>
                                  ))}
                            </ImageList>
                          </Popover>
                    )}
                    </Grid>
                    <Grid item xs={2} md={2} lg={2} className={classes.closeSkipButton}>
                    <Tooltip title={isCreateRepairRequest && isReadOnly !== true && !isDrawingLocation ? t("repairRequest.drawing.skipMapSelection"): t("Close")}>
                      <IconButton
                        onClick={isCreateRepairRequest && isReadOnly !== true && !isDrawingLocation ? handleSkipMapLocation : mainDialogCloseHandler}
                        aria-label="close"
                        edge="end"
                        color="inherit"
                        className={classes.iconStyle}
                        style={{ marginLeft: 0 }}
                        size="large"
                      >
                        <Close />
                      </IconButton>
                    </Tooltip>
                    </Grid>
                    </Grid>
                </AppBar>

                <div className={classes.root}>
                  <div ref={measureRefNode} className={classes.measure}></div>
                  <TransformComponent
                    wrapperClass={classes.wrapperClass}
                    contentClass={clsx(classes.contentClass)}
                  >
                    <div
                      ref={transformComponentRefNode}
                      style={{
                        ...canvasContainerSize,
                        width: "100%",
                        margin: "auto",
                      }}
                    >
                      <div
                        className={clsx(
                          classes.docContainer,
                          classes.canvasContainer
                        )}
                        style={{ ...canvasContainerSize }}
                      >
                        <img
                          alt={"repair-request-map"}
                          src={drawingFileUrl}
                          onClick={(e) => {
                            !isReadOnly &&
                              locationChangeHandler(e, scale.scale);
                          }}
                          ref={canvasRefNode}
                          className={clsx(
                            classes.docContainer,
                            classes.drawingMapImage
                          )}
                          style={{ ...canvasContainerSize }}
                        />
                        {!canvasImageLoading &&
                          (!isReadOnly || updateOrAddLocation) &&
                          selectedDrawingFiles &&
                          selectedDrawingFiles.dossierFileId && (
                            <>
                              {((drawingLocation &&
                                drawingLocation.dossierFileId ===
                                  selectedDrawingFiles.dossierFileId) ||
                                openConfirmation ||
                                request.isOpen ||
                                request.canShowMarker) && (
                                <LocationOn
                                  color="primary"
                                  className={classes.locationOn}
                                  style={{
                                    transform: `scale(${1 / scale.scale})`,
                                    left: `calc(${locationCoordinates.xRel} * 100% - 12px)`,
                                    top: `calc(${locationCoordinates.yRel} * 100% - 13px)`,
                                  }}
                                />
                              )}
                              {!request.canShowMarker &&
                                !canChangeSelectedMap &&
                                drawingLocation &&
                                drawingLocation.dossierFileId !==
                                  selectedDrawingFiles.dossierFileId && (
                                  <Tooltip
                                    arrow
                                    title={t(
                                      "repairRequest.drawing.location.oldFileWarning"
                                    )}
                                  >
                                    <Warning
                                      color="primary"
                                      className={classes.locationOn}
                                      style={{
                                        transform: `scale(${1 / scale.scale})`,
                                        left: `calc(${drawingLocation.xrel} * 100% - 12px)`,
                                        top: `calc(${drawingLocation.yrel} * 100% - 13px)`,
                                      }}
                                    />
                                  </Tooltip>
                                )}
                            </>
                          )}
                      </div>
                    </div>
                    {canvasImageLoading && (
                      <div className={classes.loaderComponent}>
                        {" "}
                        <CircularProgress size={35} />
                      </div>
                    )}
                  </TransformComponent>
                  <Dialog
                    className={classes.confirmationDialog}
                    open={openConfirmation}
                    onClose={() => handleCloseConfirmationDialog(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogContent>
                      <DialogContentText
                        id="alert-dialog-description"
                        className={classes.dialogContextText}
                      >
                        {t("repairRequest.drawing.locationConfirmation")}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        className={classes.dialogButtonPrimary}
                        onClick={handleConfirm}
                      >
                        {t("button.action.yes")}
                      </Button>
                      <Button
                        className={classes.dialogButtonSecondary}
                        onClick={() => handleCloseConfirmationDialog(false)}
                      >
                        {t("button.action.no")}
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </Dialog>
      <Dialog
        onClose={handleClose}
        fullScreen={matchWidthDownXs}
        TransitionComponent={Transition}
        fullWidth
        className={classes.requestContainer}
        maxWidth="md"
        open={!!request.isOpen}
        disbaleEscapeKeyDown
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogTitle className={classes.dialogTitle} id="dialog-objects-title">
          <Grid className={classes.dialogTitleContent} container spacing={1}>
            <Grid item className={classes.grow}>
              <Typography variant="h5" className={classes.dialogTitleTypo}>
                {t("repairRequest.newRepairRequest")}
              </Typography>
            </Grid>
            <Grid>
              <IconButton
                onClick={handleClose}
                className={classes.closeButton}
                color="inherit"
                aria-label="close"
                size="large"
              >
                <Close className={classes.iconStyle} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Snackbar
            TransitionComponent={(props) => (
              <Slide {...props} direction="left" />
            )}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={fileError.filesSizeIsInValid}
            onClose={() =>
              setFileError((p) => ({ ...p, filesSizeIsInValid: false }))
            }
            autoHideDuration={3000}
            key={"file-size-validation"}
          >
            <Alert elevation={6} variant="filled" severity="error">
              {t(fileError.fileUploadErrorMessage)}
            </Alert>
          </Snackbar>
          <form noValidate autoComplete="off" style={{ width: "100%" }}>
            <Grid container>
              {selected && (
                <Grid
                  container
                  xs={12}
                  className={classes.objectInformationContainer}
                >
                  <Grid xs={12}>
                    <CardContent style={{ paddingBottom: 0 }}>
                      <Typography className={classes.subTitle} gutterBottom>
                        {t("general.objectInformation")}
                      </Typography>
                    </CardContent>
                  </Grid>
                  <Grid container xs={12}>
                    <CardContent className={classes.objectContainer}>
                      {buildingId && (
                        <>
                          <div className={classes.objectInfoItem}>
                            <Typography
                              className={clsx(classes.subTitle)}
                              color={"primary"}
                              gutterBottom
                            >
                              {t("general.objectAddress")}
                            </Typography>
                            <Typography
                              className={clsx(
                                classes.subTitle,
                                classes.objectAddressFields
                              )}
                              gutterBottom
                            >
                              {selected.addressModel
                                ? `${selected.addressModel.street || ""} ${
                                    selected.addressModel.houseNo || ""
                                  } ${
                                    selected.addressModel.houseNoAddition || ""
                                  } ${selected.addressModel.postcode || ""} ${
                                    selected.addressModel.place || ""
                                  }`
                                : ""}
                            </Typography>
                          </div>
                          <div style={{ display: "flex" }}>
                            <div className={classes.objectInfoItem}>
                              <Typography
                                className={clsx(classes.subTitle)}
                                color={"primary"}
                                gutterBottom
                              >
                                {t("general.buildingNumber")}
                              </Typography>
                              <Typography
                                className={clsx(
                                  classes.subTitle,
                                  classes.objectAddressFields
                                )}
                                gutterBottom
                              >
                                {selected.buildingNoIntern}
                              </Typography>
                            </div>
                            <div className={classes.objectInfoItem}>
                              <Typography
                                className={clsx(classes.subTitle)}
                                color={"primary"}
                                gutterBottom
                              >
                                {t("general.buyer")}
                              </Typography>
                              <Typography
                                className={clsx(
                                  classes.subTitle,
                                  classes.objectAddressFields
                                )}
                                gutterBottom
                              >
                                {selected.buyerRenterName}
                              </Typography>
                            </div>
                            {selected.deliveryDate && (
                              <div className={classes.objectInfoItem}>
                              <Typography
                                className={clsx(classes.subTitle)}
                                color={"primary"}
                                gutterBottom
                              >
                                {t("workOrders.deliveryDate")}
                              </Typography>
                              <Typography
                                className={clsx(
                                  classes.subTitle,
                                  classes.objectAddressFields
                                )}
                                gutterBottom
                              >
                                {formatDate(new Date(selected.deliveryDate), false)}
                              </Typography>
                            </div>
                            )}
                            
                          </div>
                        </>
                      )}
                      {!buildingId && (
                        <>
                          <div className={classes.objectInfoItem}>
                            <Typography
                              className={clsx(classes.subTitle)}
                              color={"primary"}
                              gutterBottom
                            >
                              {t("general.projectName")}
                            </Typography>
                            <Typography
                              className={clsx(
                                classes.subTitle,
                                classes.objectAddressFields
                              )}
                              gutterBottom
                            >
                              {selected.projectName.replace(
                                selected.projectNo + " - ",
                                ""
                              )}
                            </Typography>
                          </div>
                          <div className={classes.objectInfoItem}>
                            <Typography
                              className={clsx(classes.subTitle)}
                              color={"primary"}
                              gutterBottom
                            >
                              {t("general.projectNumber")}
                            </Typography>
                            <Typography
                              className={clsx(
                                classes.subTitle,
                                classes.objectAddressFields
                              )}
                              gutterBottom
                            >
                              {selected.projectNo}
                            </Typography>
                          </div>
                        </>
                      )}
                    </CardContent>
                  </Grid>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={clsx(classes.rightDivider, classes.downDividerMd)}
              >
                <CardContent
                  className={classes.explanationContainer}
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                >
             <Typography  className={classes.subTitle} gutterBottom>
             { isBuyerOrPropertyManager && <Typography variant="span"> * </Typography>} {t("repairRequest.addImages")}
                 {isImageRequired && <Typography variant="span" color="red" sx={{marginLeft:"4px"}}>{t("general.required")} </Typography>}
                  </Typography>
                  <Grid item xs={12} className={classes.filesContainer}>
                    <Grid
                      className={classes.attachmentItemContainer}
                      style={{ marginLeft: 0 }}
                      container
                      justifyContent="center"
                    >
                      <Dropzone
                        openCamera={false}
                        iconClasses={classes.uploadIcon}
                        className={classes.dropzone}
                        multiple={false}
                        // withUploadFileDialog
                        existingImageModal
                        accept="image/*"
                        isSurveying
                        isHideLocatie
                        title="general.choosePhotosOrAttachments"
                        handleSelectExistingImages={(data) =>
                          fileUploadHandler(data, true)
                        }
                        onFilesAdded={fileUploadHandler}
                      />
                    </Grid>
                    <Grid container className={classes.gridContainer}>
                      {request.attachments &&
                        request.attachments.map((p, i) => (
                          <Grid
                            key={p.attachmentId}
                            className={classes.attachmentItemContainer}
                            style={{ minWidth: 77 }}
                            item
                            onClick={() => onPreview([p])}
                          >
                            {
                              <div className={classes.overlay}>
                                <div className={classes.isRight}>
                                  <Tooltip title={t("general.remove")}>
                                    <IconButton
                                      className={clsx(
                                        classes.deleteButton,
                                        classes.noOutline
                                      )}
                                      size={"small"}
                                    >
                                      <DeleteOutline
                                        className={classes.deleteIcon}
                                        size={"small"}
                                        onClick={(e) => handleRemoveImage(e, i)}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </div>
                            }
                            <Grid item className={classes.thumnailContainer}>
                              <Grid
                                className={classes.thumnailContainer}
                                container
                                xs={12}
                                justifyContent="center"
                              >
                                {p.attachmentUrl ? (
                                  <img
                                    className={classes.thumbnail}
                                    alt="img"
                                    src={p.attachmentUrl}
                                  />
                                ) : (
                                  <img
                                    className={classes.thumbnail}
                                    alt="img"
                                    src={`${webApiUrl}api/RepairRequest/GetImage/${p.requestId}/${p.attachmentId}`}
                                  />
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                    </Grid>
                  </Grid>
                  <Hidden smDown>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        name="shortDesc"
                        inputProps={{ maxLength: 60 }}
                        value={request.shortDesc}
                        onChange={({ target: { value, name } }) =>
                          handleChange(name, value)
                        }
                        className={classes.textField}
                        fullWidth
                        size="small"
                        id="outlined-basic"
                        label={t("general.shortDescription")}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.textField}
                        fullWidth
                        size="small"
                        id="outlined-basic"
                        label={t("general.explanation")}
                        name="detailedDesc"
                        value={request.detailedDesc}
                        onChange={({ target: { value, name } }) =>
                          handleChange(name, value)
                        }
                        variant="outlined"
                        multiline
                      />
                    </Grid>
                  </Hidden>
                  {!isBuyerOrPropertyManager &&
                    user.type !== userAccountTypeConstants.buyer && (
                      <Grid item xs={12} className={classes.chipContainer}>
                        <Hidden smDown>
                          {
                            <Button
                              disabled={organizationLoading}
                              onClick={({ currentTarget }) =>
                                setIsOpenResolver(currentTarget)
                              }
                              className={classes.oplossersButton}
                              startIcon={
                                organizationLoading ? (
                                  <CircularProgress size={18} />
                                ) : (
                                  <AddCircleOutline />
                                )
                              }
                            >
                              {t("general.resolvers")}
                            </Button>
                          }
                          {oplossers &&
                            oplossers.orgIds.map((orgId) => {
                              const selectedOrg =
                                allOrgs.find((p) => p.id === orgId) || {};
                              const person = selectedOrg.relations.find(
                                (a) =>
                                  a.id ===
                                  (
                                    oplossers.relationIds.find(
                                      (p) => p.orgId === orgId
                                    ) || {}
                                  ).relationId
                              );
                              return (
                                <Chip
                                  className={classes.chip}
                                  label={
                                    <div className={classes.oplosserContainer}>
                                      {person ? (
                                        <>
                                          <span>{person.name}</span>
                                          <span>{selectedOrg.name}</span>
                                        </>
                                      ) : (
                                        <>{selectedOrg.name}</>
                                      )}
                                    </div>
                                  }
                                  onDelete={(e) =>
                                    setOplossers((p) => ({
                                      ...p,
                                      orgIds: p.orgIds.filter(
                                        (a) => a !== orgId
                                      ),
                                    }))
                                  }
                                />
                              );
                            })}
                        </Hidden>
                        <SelectOrganizations
                          projectId={projectId}
                          oplossers={oplossers}
                          onSubmit={(data, organizations) =>
                            handleSelectPerson(data, organizations)
                          }
                          anchorOrigin={{
                            vertical: "center",
                            horizontal: "right",
                          }}
                          transformOrigin={{
                            vertical: "center",
                            horizontal: "left",
                          }}
                          selectOrg
                          // hideExtraInfo
                          loading={organizationLoading}
                          setLoading={(val) => setOrganizationLoading(val)}
                          anchorEl={isOpenResolver}
                          onClose={() => setIsOpenResolver(null)}
                        />
                      </Grid>
                    )}
                </CardContent>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                className={classes.remarkContainer}
              >
                <CardContent
                  style={{ paddingTop: 0, paddingBottom: 0 }}
                  className={clsx(
                    classes.explanationContainer,
                    classes.removePaddingForMd
                  )}
                >
                  <Typography
                    className={clsx(classes.subTitle, classes.explanationTitle)}
                    gutterBottom
                  >
                    {t("general.location")}
                  </Typography>
                  <Grid container xs={12}>
                    {selectedDrawingFiles &&
                      selectedDrawingFiles.dossierFileId && locationCoordinates.xRel >= 0 && locationCoordinates.yRel >= 0 ? (
                        <Grid
                          item
                          xs={12}
                          className={classes.locationContainer}
                        >
                          <img
                            alt="map"
                            src={drawingFileUrl}
                            className={classes.locationPreview}
                            onClick={() => {
                              setRequest((p) => ({
                                ...p,
                                isOpen: false,
                                canShowMarker: true,
                              }));
                              setCanShowDrawingMap(openRepairRequestMap);
                            }}
                          />
                           
                            <Tooltip title={t("repairRequest.drawing.removeMapLocation")}>
                            <IconButton
                              aria-label="remove"
                              edge="end"
                              color="secondary"
                              style={{
                                position: "absolute",
                                right: 0,
                                top: 0,
                              }}
                              onClick={handleRemoveDrawingLocation}
                              size="small"
                            >
                              <Delete />
                            </IconButton>
                          </Tooltip>  
                          <LocationOn
                            color="primary"
                            className={classes.locationMarkPreview}
                            style={{
                              left: `calc(${locationCoordinates.xRel} * 100% - 12px)`,
                              top: `calc(${locationCoordinates.yRel} * 100% - 22px)`,
                            }}
                          />
                        </Grid>)
                      : (<Grid
                        item
                        xs={12}
                        style={{ paddingBottom: 10 }}
                        >
                          {
                            selectedDrawingFiles &&
                            selectedDrawingFiles.dossierFileId && (
                              <Tooltip title={t('repairRequest.drawing.noLocationSelected')}>
                                  <IconButton
                                    className={classes.addLocationBtn}
                                    aria-label="no-location"
                                    onClick={() => {
                                      setRequest((p) => ({
                                        ...p,
                                        isOpen: false,
                                        canShowMarker: false,
                                      }));
                                      setCanShowDrawingMap(openRepairRequestMap);
                                    }}
                                    color="primary"
                                    fontSize="large"
                                  >
                                <LocationOn />
                              </IconButton>
                            </Tooltip>
                            )
                          }
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-select-currency"
                        select
                        fullWidth
                        className={classes.textField}
                        size="small"
                        name="locationId"
                        value={request.locationId}
                        label={t("files.button.select.title")}
                        onChange={({ target: { name, value } }) =>
                          handleChange(name, value)
                        }
                        variant="outlined"
                      >
                        {locations.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    {!isBuyerOrPropertyManager && !isBuyer && (
                      <Grid item xs={12}>
                        <TextField
                          id="outlined-select-currency"
                          select
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.textField}
                          fullWidth
                          size="small"
                          label={t("repairRequest.term")}
                          name="term"
                          value={request.term}
                          onChange={({ target: { value, name } }) =>
                            handleChange(name, value.toString())
                          }
                          variant="outlined"
                        >
                          {termsList.map((field, i) => (
                            <MenuItem key={field.termId} value={field.termId}>
                              {t("repairRequest.termWithDescription.label", {
                                Description: field.description,
                                noOfDays: field.noOfDays,
                              })}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                    )}
                    <Hidden smUp>
                      <Grid item xs={12}>
                        <TextField
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          name="shortDesc"
                          inputProps={{ maxLength: 60 }}
                          value={request.shortDesc}
                          onChange={({ target: { value, name } }) =>
                            handleChange(name, value)
                          }
                          className={classes.textField}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          id="outlined-basic"
                          label={t("general.shortDescription")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          margin="normal"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          className={classes.textField}
                          fullWidth
                          size="small"
                          autoComplete="off"
                          id="outlined-basic"
                          label={t("general.explanation")}
                          name="detailedDesc"
                          value={request.detailedDesc}
                          onChange={({ target: { value, name } }) =>
                            handleChange(name, value)
                          }
                          variant="outlined"
                          multiline
                        />
                      </Grid>
                      {!isBuyerOrPropertyManager &&
                        user.type !== userAccountTypeConstants.buyer && (
                          <Grid item xs={12}>
                            <Button
                              onClick={({ currentTarget }) =>
                                setIsOpenResolver(currentTarget)
                              }
                              className={classes.oplossersButton}
                              startIcon={<AddCircleOutline />}
                            >
                              <Typography>{t("general.resolvers")}</Typography>
                            </Button>
                            {oplossers &&
                              oplossers.orgIds.map((orgId) => {
                                const selectedOrg =
                                  allOrgs.find((p) => p.id === orgId) || {};
                                const person = selectedOrg.relations.find(
                                  (a) =>
                                    a.id ===
                                    (
                                      oplossers.relationIds.find(
                                        (p) => p.orgId === orgId
                                      ) || {}
                                    ).relationId
                                );
                                return (
                                  <Chip
                                    className={classes.chip}
                                    label={
                                      <div
                                        className={classes.oplosserContainer}
                                      >
                                        {person ? (
                                          <>
                                            <span>{person.name}</span>
                                            <span>{selectedOrg.name}</span>
                                          </>
                                        ) : (
                                          <>{selectedOrg.name}</>
                                        )}
                                      </div>
                                    }
                                    onDelete={(e) =>
                                      setOplossers((p) => ({
                                        ...p,
                                        orgIds: p.orgIds.filter(
                                          (a) => a !== orgId
                                        ),
                                      }))
                                    }
                                  />
                                );
                              })}
                          </Grid>
                        )}
                    </Hidden>
                  </Grid>
                </CardContent>
              </Grid>
              {isBuyerOrPropertyManager && (
                <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sendEmail}
                          onChange={handleChangeSendEmail}
                          value="checkedB"
                          color="primary"
                          size="small"
                        />
                      }
                      label={
                        <Typography className={classes.subTitle}>
                          {t("repairRequest.create.sendCopyOfRRToEmail.label")}
                        </Typography>
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.subTitle}>
                      {nl2br(warningText)}
                    </Typography>
                  </Grid>
                </CardContent>
              )}
            </Grid>
          </form>
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            disabled={!validateForm() || isAddRepairRequest || !hasImages}
            variant="outlined"
            color="primary"
            onClick={handleRequest}
            className={classes.addButton}
            startIcon={isAddRepairRequest && <CircularProgress size={18} />}
          >
            <Typography>
              {t(isUpdate ? "general.modify" : "general.add")}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>
      <UploadFiles
        accept="image/*"
        handleSubmit={handleUploadFiles}
        title={"Uploads"}
        handlePreviewOfFiles={onPreview}
        isHideLocatie
        open={isConfirmedLocation}
        onClose={() => {
          setIsConfirmedLocation(false);
          setRequest((prev) => ({ ...prev, isOpen: true }));
        }}
        isSurveying
      />
      <Hidden only={["md", "lg", "xl"]}>
        <Camera
          closeDrawer={cameraFileSelectHandler}
          open={openCameraDrawer}
          close={closeCameraHandler}
        />
      </Hidden>
      {openEditor && uploadedImage && (
        <Dialog
          disableEnforceFocus
          aria-labelledby="alert-dialog-title"
          TransitionComponent={Transition}
          aria-describedby="alert-dialog-description"
          fullScreen
          onClose={handleCloseEditor}
          open={openEditor}
          scroll="paper"
        >
          <ImageEditor
            file={uploadedImage}
            onClose={handleCloseEditor}
            onGetImage={uploadEditedFile}
          />
        </Dialog>
      )}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  autoComplete: {
    width: 190,
    [theme.breakpoints.down("md")]: {
      width: 160,
    },
  },
  closeSkipButton: {
    display: "flex",
    justifyContent: "flex-end",
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  zoomButton: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 5px`,
    height: "28px",
    width: "28px",
    "&:focus": {
      outline: "none !important",
    },
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      overflow: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
      backgroundColor: theme.palette.grey[100],
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  
  skipButton: {
    borderRadius: 5,
    overflow: "hidden",
    padding: theme.spacing(0.5),
    margin: `0 5px`,
    height: "28px",
    width: "100%",
    "&:focus": {
      outline: "none !important",
    },
    "&::before": {
      content: '""',
      height: "100%",
      width: "100%",
      overflow: "hidden",
      opacity: 0.2,
      zIndex: "-1",
      position: "absolute",
      backgroundColor: theme.palette.grey[100],
    },
    "& .MuiSvgIcon-root": {
      fontSize: 18,
    },
  },
  autoTrim: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  autoCompleteInput: {
    marginTop: 0,
    marginBottom: 0,
    "& > .MuiAutocomplete-inputRoot": {
      background: theme.palette.common.white,
      "& > input": {
        fontSize: 12,
        maxWidth: 150,
      },
    },
  },
  root: {
    background: theme.palette.common.white,
    height: "100%",
    overflow: "auto",
    position: "relative",
    display: "flex",
    flexGrow: 1,
  },
  appBarSubTitle: {
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  appBarContainer: {
    display: "flex",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("sm")]: {
      flexFlow: "row wrap",
    },
    flexDirection: "row",
    justifyContent: "end",
    alignItems: "center",
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  surveyMomentsMainHeaderSecond: {
    borderRadius: "0 !important",
    margin: "0 !important",
    padding: theme.spacing(1),
    [theme.breakpoints.up("xs")]: {
      height: "initial !important",
    },
    height: "100px !important",
    width: "100% !important",
  },
  documentNameContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  oplosserContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  chip: {
    height: "fit-content",
    padding: theme.spacing(0.6),
    margin: 5,
  },
  chipContainer: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontWeight: "bold",
  },
  wrapperClass: {
    height: "100%",
    width: "100%",
  },
  fullHeight: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  contentClass: {
    height: "100%",
    width: "100%",
  },
  docContainer: {
    position: "relative",
    height: "100%",
    width: "100%",
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
  },
  drawingMapImage: {
    pointerEvents: "auto !important",
  },
  canvasContainer: {
    height: "calc(100% - 35px)",
    margin: "auto",
  },
  locationOn: {
    position: "absolute",
    zIndex: 1,
  },
  requestContainer: {
    "& .MuiPaper-root": {
      borderRadius: 10,
      width: "auto",
    },
  },
  confirmationDialog: {
    "& .MuiPaper-root": {
      width: 450,
      borderRadius: 10,
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  mapDailog: {
    "& .MuiBackdrop-root": {
      backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    "& .MuiPaper-root": {
      width: "100%",
      height: "100%",
      [theme.breakpoints.up("md")]: {
        width: "100%",
        height: "88%",
        borderRadius: 10,
        marginLeft: 18,
        marginTop: 60,
        marginRight: 18,
      },
    },
    "& .MuiDialogContent-root": {
      padding: theme.spacing(3.75, 3.75, 0, 3.75),
      "& p": {
        color: theme.palette.grey[700],
        textAlign: "center",
      },
    },
    "& .MuiDialogActions-root": {
      display: "flex",
      justifyContent: "center",

      "& .MuiButtonBase-root": {
        [theme.breakpoints.down("sm")]: {
          margin: theme.spacing(1, 1, 2, 1),
        },
        margin: "10px 17px 25px 17px",
      },
    },
  },
  dialogContextText: {
    [theme.breakpoints.down("sm")]: {
      fontSize: 12,
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.primary.main,
    },
  },
  measure: {
    position: "absolute",
  },
  loaderComponent: {
    height: "100%",
    width: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "#ffffffd6",
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    "&:hover": {
      background: theme.palette.grey[500],
    },
  },
  dialogTitle: {
    padding: theme.spacing(0.625),
    background: theme.palette.primary.main,
    "& .MuiIconButton-label > .MuiSvgIcon-root": {
      fill: theme.palette.grey[300],
    },
  },
  dialogTitleTypo: {
    color: "white",
    fontSize: 16,
    fontWeight: "bold",
  },
  dialogTitleContent: {
    padding: theme.spacing(1.25),
  },
  closeButton: {
    margin: 0,
    padding: theme.spacing(0),
  },
  iconStyle: {
    fill: "white",
    width: "1em",
    height: "1em",
  },
  dialogContent: {
    padding: theme.spacing(1.25),
    minHeight: "40vh",
    display: "flex",
    width: 700,
    maxWidth: "100%",
  },
  subTitle: {
    fontSize: 12,
  },
  objectAddressFields: {
    color: theme.palette.grey[500],
  },
  filesContainer: {
    display: "flex",
    margin: `5px 0`,
  },
  gridContainer: {
    display: "flex",
    flexWrap: "nowrap",
    overflow: "auto",
  },
  attachmentItemContainer: {
    position: "relative",
    margin: "0 4px",
    background: theme.palette.grey[100],
    boxShadow: "3px 3px 4px #eee",
    height: 88,
    maxWidth: 88,
    borderRadius: 5,
    "&:hover": {
      "& $overlay": {
        opacity: 1,
      },
      "& $attachmentStatus": {
        opacity: 1,
        right: 10,
      },
    },
  },
  rightDivider: {
    overflow: "auto",
    [theme.breakpoints.up("sm")]: {
      borderRight: `2px solid ${theme.palette.grey[300]}`,
    },
  },
  objectInformationContainer: {
    marginBottom: 10,
    borderBottom: `1px dotted ${theme.palette.grey[300]}`,
  },

  overlay: {
    position: "absolute",
    borderRadius: 5,
    color: " #f1f1f1",
    transition: ".5s ease",
    opacity: 0,
    fontSize: "20px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  thumnailContainer: {
    width: "100%",
    height: "100%",
  },
  thumbnail: {
    width: "100%",
    height: "100%",
    OObjectFit: "cover",
  },
  deleteIcon: {
    fill: theme.palette.grey[700],
    fontSize: 18,
  },
  isRight: {
    position: "absolute",
    right: 3,
    top: 2,
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  noOutline: {
    outline: "none !important",
  },
  deleteButton: {
    backgroundColor: theme.palette.common.white,
    "&:hover": {
      backgroundColor: theme.palette.common.white,
    },
  },
  textField: {
    "& > label": {
      color: theme.palette.primary.dark,
    },
    "& input": {
      textOverflow: "ellipsis",
      fontSize: 12,
    },
    "& textarea": {
      fontSize: 12,
    },
    "& .MuiOutlinedInput-input": {
      fontSize: 12,
    },
  },
  oplossersButton: {
    borderRadius: 50,
    margin: theme.spacing(1, 0, 1, 0),
    boxShadow: `3px 3px 6px ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[300],
    "& .MuiButton-label": {
      paddingRight: theme.spacing(1),
      fontWeight: "normal",
      fontSize: 14,
      color: theme.palette.grey[800],
      "& .MuiButton-startIcon": {
        marginRight: 10,
        marginLeft: 3,
        color: theme.palette.common.black,
      },
    },
  },
  locationContainer: {
    maxWidth: "fit-content",
    width: "100%",
    position: "relative",
    marginBottom: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  locationPreview: {
    maxWidth: 250,
    maxHeight: 200,
    cursor: "pointer",
    borderRadius: 6,
    border: `1px solid ${theme.palette.grey[300]}`,
    boxShadow: `1px 1px 3px ${theme.palette.grey[400]}`,
  },
  locationMarkPreview: {
    position: "absolute",
  },
  addButton: {
    width: 246,
    maxWidth: "80%",
    marginBottom: 10,
  },
  objectInfoItem: {
    marginRight: 30,
  },
  objectContainer: {
    paddingTop: 0,
    paddingBottom: `0 !important`,
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  gridListMain: {
    width: 160,
    margin: 0,
  },
  gridListTile: {
    padding: theme.spacing(0),
    backgroundColor: theme.palette.action.disabledBackground,
    marginBottom: theme.spacing(0),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "&:last-child": {
      marginBottom: theme.spacing(0),
    },
  },
  gridListImage: {
    maxWidth: "50%",
    maxHeight: "100%",
    width: "auto",
    height: "auto",
    position: "absolute",
  },
  titleWrapMain: {
    position: "absolute",
    top: 5,
    right: 5,
    padding: theme.spacing(0.25, 1.25),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 4,
    color: theme.palette.common.white,
    background: theme.palette.text.secondary,
  },
  titleWrap: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: 20,
    right: 5,
    padding: theme.spacing(0.25, 1.25),
    display: "flex",
    background: theme.palette.text.secondary,
    alignItems: "center",
    justifyContent: "start",
    color: theme.palette.common.white,
    borderRadius: 4,
  },
  spanWrapMain: {
    marginLeft: theme.spacing(0),
    fontSize: 11,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  gridListMain: {
    width: 160,
    margin: 0,
  },
  buttonBaseMain: {
    height: "100%",
    width: "100%",
  },
  mapIcon: {
    opacity: 0.3,
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  addLocationBtn: {
    marginLeft: theme.spacing(1),
    marginTop: 2,
    backgroundColor: alpha(theme.palette.primary.main, 0.25),
  },
}));
