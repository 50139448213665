import { Avatar, Checkbox, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { URLS } from "../../apis/urls";
import clsx from "clsx";
import { formatDate, history } from "../../_helpers";
import { useSelector } from "react-redux";
import { LocationOnOutlined, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../components/CustomRowStyles";
import { IconButton } from "@mui/material";
import { MultiLabelColumn } from "../../components/DataColumns";

const RepairRequestCustomRow = (props) => {
    const {
        setRowsSelected,
        rowsSelected,
        columns,
        data,
        identifier,
        currentRow,
        dataIndex
    } = props;
  const classes = { ...useRowStyles(), ...useStyles() };
  const { selected } = useSelector(state => state.buildings);
  const app = useSelector(state => state.app);
  const { t } = useTranslation();
  const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
    const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};

  if (!selectedRow || !columns.length) return "";
  const attachmentSrc = selectedRow.attachments && selectedRow.attachments.length ? URLS.GET_ATTACHMENT_THUMBNAIL + selectedRow.attachments[0].attachmentId : null;
  const completedWorkorders = selectedRow.resolvers ? selectedRow.resolvers.filter(a => a.status === 4).length : 0;

  const listToMapRedirectHandler = (e) => {
    e.stopPropagation();
    e.preventDefault();
    history.push({
      pathname: `/werk/${selected.projectNo}/${app === 3 ? "kwaliteitsborging/meldingen/map" : "meldingen/map"}`,
      state: {
        selectedRequest: selectedRow,
        requests: data,
        buildingId: selectedRow.buildingId
      }
    })
  }

  return (
    <React.Fragment>
        <TableRow onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          history.push(`/werk/${selected.projectNo}/kwaliteitsborging/${selectedRow.requestId}`);
        }} className={clsx(classes.customGridRow)}>
          <TableCell>
            <div className={classes.customSmallDeviceRowContainer}>
              <div className={classes.verticallyCenterContainer}>
                <Avatar
                  src={attachmentSrc}
                  className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')}>
                  {selectedRow.buildingNoExtern}
                </Avatar>
              </div>
              <div className={clsx(classes.detailContainer, classes.maxWidth_70)}>
                <Typography className={classes.requestTitle}>
                  {`${selectedRow.number} - ${selectedRow.desc}`}
                </Typography>
                <Typography className={clsx(classes.requestTypeLabel, classes.requestTypeContainer)}>
                  {selectedRow.carryOutAsType}
                  {selectedRow.resolvers && !!selectedRow.resolvers.length &&
                    <>
                      <div style={{ marginLeft: 10 }} className={classes.werkbonnenContainer}>
                        <span>{`${completedWorkorders}/${selectedRow.resolvers.length} ${t("general.workOrders")}`}</span>
                      </div>
                    </>
                  }
                </Typography>
                <div className={classes.requestContainer}>
                  <div>
                    <Typography className={classes.requestBuildingLabel}>
                      <IconButton
                        color="primary"
                        disabled={!selectedRow.drawingPinLocation}
                        className={clsx(classes.locationIconContainer, classes.locationButton, !selectedRow.drawingPinLocation && classes.disabledLocationBtn)}
                        aria-label="Repair request map redirect"
                        component="span"
                        onClick={listToMapRedirectHandler}
                        size="large">
                        <LocationOnOutlined className={clsx(classes.locationIcon, classes.locationButtonText)} />
                      </IconButton>
                      <span>
                        {selectedRow.buildingNoIntern}
                      </span>

                    </Typography>
                  </div>
                  <div className={classes.labelSpacing}>
                    <MultiLabelColumn data={selectedRow.textResolvers} />
                  </div>
                </div>
              </div>
              <div className={clsx(classes.detailContainer, classes.rightAlignedContainer)}>
                <div>
                  <Typography noWrap className={classes.flexContainer}>
                    {selectedRow.isRequiredHandling !== true && (
                      <>
                        <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                          <PriorityHigh color="secondary" fontSize="small" size="small" />
                        </Tooltip>
                        <div className={classes.borderRight} />
                      </>
                    )}
                    {selectedRow.overdue ? (
                      <>
                        <Tooltip title={<Typography>{t("general.overdue")}</Typography>}>
                          <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                        </Tooltip>
                        <div className={classes.borderRight} />
                      </>
                    ) : selectedRow.is48HoursReminder ? (
                      <>
                        <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                          <Schedule fontSize="small" size="small"
                            className={clsx(classes.warning, classes.marginRight_5)} />
                        </Tooltip>
                        <div className={classes.borderRight} />
                      </>
                    ) : ("")}
                    <Checkbox size="small" className={clsx(classes.customCheckbox, classes.noPaddingContainer)}
                      checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                        const list = Object.assign([], rowsSelected.list);
                        const isExist = list.findIndex(p => p === dataIndex);
                        const rowsData = Object.assign([], rowsSelected.data);
                        if (isExist >= 0) {
                          list.splice(isExist, 1)
                          rowsData.splice(isExist, 1)
                        } else {
                          rowsData.push({ [identifier]: selectedRow[identifier] })
                          list.push(dataIndex);
                        }
                        ;

                        setRowsSelected({ ...rowsSelected, data: rowsData, list });
                      }} color='primary' onClick={(e) => e.stopPropagation()} />
                  </Typography>
                </div>
                <Typography
                  className={classes.requestStatusLabel}
                  style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                  {selectedRow.status}
                </Typography>
                <Typography className={classes.requestBuildingLabel}>
                  {formatDate(new Date(selectedRow.date))}
                </Typography>
              </div>
            </div>
          </TableCell>
        </TableRow>
    </React.Fragment>
  );
};

export default RepairRequestCustomRow;

const useStyles = makeStyles(theme => ({
  avatarThumbnail: {
    background: theme.palette.primary.main,
    fontSize: 15,
    fontWeight: 'bold',
    borderRadius: 6,
    boxShadow: "none"
  },
  labelSpacing: {
    marginLeft: 5
  },
  overflowEllipse: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 1,
    "-webkit-box-orient": "vertical",
  },

  locationButton: {
    padding: 0,
    height: 18,
    width: 18,
  },
  locationButtonText: {
    fontSize: 14,
  },
  disabledLocationBtn: {
    background: `${theme.palette.grey[400]} !important`
  },
  requestContainer: {
    display: 'flex'
  },
}));