import Dexie from 'dexie';
import { Buffer } from 'buffer';

//IndexedDB Meta Information
const DB_VERSION = 4.4;
const DB_NAME = "opname-store";
export const db = new Dexie(DB_NAME);
//Create Collections Schemas
db.version(DB_VERSION).stores({
    moment: '++id,surveyingMomentId,objectId,lockId',
    assignments: '++id,assignmentId,lockId,projectId',
    momentsByApp: '[projectId+objectId+surveyingMomentId],projectId,objectId,surveyingMomentId,lockId,lockedUserId,assignmentId',
    attachments: '[surveyId+buildingId+projectId+requestId],surveyId,buildingId,projectId,sync,requestId',
    assignmentsAttachments: 'assignmentId,sync,projectId,buildingId',
    deletedAssignmentsAttachments: '[assignmentId+attachmentId],assignmentId,attachmentId',
    appsBuildings: '[app+projectId+buildingId],app,projectId,buildingId,sync',
    surveys: '[surveyType+surveyId+buildingId+isSecondSignature],surveyType,surveyId,buildingId,isSecondSignature',
    surveyDetails: '[surveyType+surveyId+buildingId],surveyType,surveyId,buildingId,isSecondSignature',
    repairRequest: '[requestId+surveyId],surveyId,requestId',
    assignmentsRepairRequest: '[assignmentId+requestId],assignmentId,requestId',
    signatureRequests: '[surveyId+surveyType+sync+isSecondSignature],surveyId,surveyType,sync,isSecondSignature',
    emailsSendingRequests: '[surveyId+surveyType+sync+isSecondSignature],surveyId,surveyType,sync,isSecondSignature',
    internalCompleteSurveyingMoments: 'surveyingMomentId,sync',
    completeSurveyingMoments: 'surveyingMomentId,sync',
    internalCompleteAssignments: 'assignmentId,sync',
    completeAssignments: 'assignmentId,sync',
    availableModulesByAppProject: '[app+projectId],app,projectId',
    updateAssignmentInfo: '[assignmentId+fieldId],assignmentId,fieldId',
    updateSurveyInfo: '[surveyId+surveyType+fieldKey],surveyId,surveyType,fieldKey',
    workOrders: '[requestId+resolverId],requestId,resolverId',
    offlineReports: '[surveyId+surveyType+isSecondSignature],surveyId,surveyType,isSecondSignature,sync',
    organizations: '[app+projectId],app,projectId',
    changedDelegators: '[assignmentId+surveyingMomentId],assignmentId,surveyingMomentId',
    userLockIds: '[userId+lockId],userId,lockId,isSecondSignature',
    secondSignatureRepairRequestUpdate: '[repairRequestId+surveyId],repairRequestId,surveyId',
    startSecondSignature: '[surveyId],surveyId',
    files: '[fileId],fileId,file',
    repairRequestLocation: '[id],id,name',
    settlementTerms: '[termId],termId,description,noOfDays,isDefault,workingDays',
    repairRequestCarryOutAsTypeList: '[id],id,name',
    productsServices: '[id],id,code,description',
    drawingFile: '[dossierFileId],dossierFileId,name,isInternal,pages,projectId,buildingId',
    repairRequestTypeList: '[id],id,name',
    repairRequestNatureList: '[id],id,name',
    repairRequestCauseList: '[id],id,name',
    repairRequestCauserList: '[id],id,name',
    organizationsByProject: '[id],id,name,projectId',
    repairRequestDetails: '[requestId],surveyId,requestId',
    recipientsEmails: '[surveyId],surveyId,to,cc,bcc',
    emailsForRepairRequest: '[requestId],requestId',
    WBProjectInfo: '[projectId],projectId,wbProjectId',
    userObjects: '[app],app,buildingId,projectId',
    pinImages: '[surveyId],surveyId',

});
db.open();
db.on('populate', function (populate) {
    populate.on("error", (er) => console.log(er, "error"))
    console.log(populate, "populate");
    return populate;
});

export const DELETE_DATABASE = async () => {
    await db.close();
    return db.delete().then(() => {
        console.log("Database successfully deleted");
    })
};

//Methods
export const startSecondSignatureDB = async (surveyId) => {
    let survey = await getSurveyDetails({ surveyId, surveyType: 1, isSecondSignature: 1 });
    let surveyT = await geSurvey({ surveyId, surveyType: 1, isSecondSignature: 1 });
    if (surveyT && survey) {
        surveyT.isSecondSignatureInitiated = true;
        survey.isSecondSignatureInitiated = true;
        await updateSurveyInDB({ surveyId, surveyType: 1, isSecondSignature: 1 }, survey);
        await updateSurveyDetails({ surveyId, surveyType: 1, isSecondSignature: 1 }, survey);
    }
    return db.startSecondSignature.put({ ...survey, surveyId });
};
export const getStartSecondSignature = async (filter) => db.startSecondSignature.get(filter)
export const removeSecondSignature = async (filter) => db.startSecondSignature.where(filter).delete();

export const getSSRRChanges = async (filter) => db.secondSignatureRepairRequestUpdate.where(filter).toArray();
export const deleteSSRRChanges = async (filter) => db.secondSignatureRepairRequestUpdate.where(filter).delete();
export const storeSSRRChanges = async (data) => db.secondSignatureRepairRequestUpdate.put(data);
// export const storeSSRRBulkChanges = async (data) => db.secondSignatureRepairRequestUpdate.bulkPut(data);

export const getUserLockId = async (filter) => db.userLockIds.get(filter);
export const getUserLockIds = async (filter) => db.userLockIds.where(filter).toArray();
export const storeUserLockIds = async (data) => db.userLockIds.bulkPut(data);
export const releaseUserLockIds = async (filter) => db.userLockIds.where(filter).delete();

export const getOrganizationsFromIDB = async (filter) => db.organizations.get(filter)
export const storeOrganizations = async (data) => db.organizations.put(data)

export const storeUpdatedDelegator = async (data) => db.changedDelegators.put(data)
export const getUpdatedDelegator = async (filter) => db.changedDelegators.get(filter)
export const deleteUpdatedDelegator = async (filter) => db.changedDelegators.where(filter).delete();

export const storeOfflineReport = async (data) => db.offlineReports.put(data);
export const getOfflineReport = async (filter) => db.offlineReports.get(filter);
export const deleteOfflineReport = async (filter) => db.offlineReports.where(filter).delete();

export const deleteWorkOrder = async (filter) => db.workOrders.where(filter).delete();
export const updateWorkOrderDetail = (filter, values) => db.workOrders.where(filter).modify(values);
export const createWorkOrder = async (data) => db.workOrders.put(data);
export const getWorkOrder = async (filter) => db.workOrders.get(filter);
export const getWorkOrders = async (filter) => db.workOrders.where(filter).toArray();

export const storeAvailableModulesInDB = async (data) => db.availableModulesByAppProject.put(data)
export const getAvailableModulesInDB = async (filter) => db.availableModulesByAppProject.get(filter);

export const getMoment = async (data) => db.moment.get({ surveyingMomentId: data.surveyingMomentId || '', objectId: data.objectId || '' })
export const deleteMoment = async (filter) => db.moment.where(filter).delete();
export const updateExistingMoment = async (selectedMoment) => await db.moment.where("surveyingMomentId", "objectId").equals(selectedMoment.surveyingMomentId, selectedMoment.objectId).modify(selectedMoment);
export const storeMoment = async (data) => {
    const oldData = await getMoment(data);
    if (oldData)
        return db.moment.where("surveyingMomentId", "objectId").equals(data.surveyingMomentId, data.objectId).modify(data)
    else
        return db.moment.put(data)
};

export const getAssignment = async (assignmentId = '') => db.assignments.get({ assignmentId });
export const getAssignments = async (projectId = '') => db.assignments.where({ projectId }).toArray();
export const updateAssignment = async (data) => db.assignments.where("assignmentId").equals(data.assignmentId).modify(data);
export const deleteAssignmentFromDB = async (assignmentId) => db.assignments.where({ assignmentId }).delete();
export const updateAssignmentsData = async (payload, senderName, selectedMoment, answerOptions) => {
    const { assignmentId, ...data } = payload;
    const key = Object.keys(data)[0];
    let sendData = {}
    if (key === "message") {
        let assignment = await getAssignment(assignmentId)
        sendData = {
            assignmentMessages: [...assignment.assignmentMessages, { ...data, dateTime: new Date(), senderName }]
        }
    } else {
        sendData = {
            [key === 'comments' ? 'remark' : key]: data[key]
        }
    }
    return await updateAssignment({ ...sendData, assignmentId });
};
export const storeAssignment = async (data) => {
    const oldData = await getAssignment(data.assignmentId);
    const { repairRequests = [], ...assignmentData } = data;
    const requests = repairRequests.map(p => ({ ...p, assignmentId: data.assignmentId }));
    // console.log(requests, 'requests')
    await storeAssignmentsRepairRequests(requests);
    if (oldData)
        return updateAssignment(assignmentData)
    else
        return db.assignments.put(assignmentData)
};


export const storeAttachments = async (data) => {
    const attachmentData = { ...data, attachments: data.attachments && (await Promise.all(data.attachments.map(async f => ({ name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) };
    return db.attachments.put(attachmentData);
}
export const updateAttachments = async (filter, data) => db.attachments.where(filter).modify(data);
export const getAttachments = async (filter) => {
    if (Object.keys(filter).length)
        return db.attachments.where(filter).toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (new File([f.arrayBuffer], f.name, { type: f.type }))) })));
    return db.attachments.toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (new File([f.arrayBuffer], f.name, { type: f.type }))) })));
};

export const storeRepairRequestTypeList = async (data) => db.repairRequestTypeList.bulkPut(data)
export const deleteRepairRequestTypeList = async (fileId) => db.repairRequestTypeList.where({ fileId }).delete()
export const getRepairRequestTypeListFromIDB = async () => db.repairRequestTypeList.toArray(p => p)
export const updateRepairRequestTypeList = async (filter, values) => db.repairRequestTypeList.where(filter).modify(values)

export const storeRepairRequestCauseList = async (data) => db.repairRequestCauseList.bulkPut(data)
export const deleteRepairRequestCauseList = async (fileId) => db.repairRequestCauseList.where({ fileId }).delete()
export const getRepairRequestCauseListFromIDB = async () => db.repairRequestCauseList.toArray(p => p)
export const updateRepairRequestCauseList = async (filter, values) => db.repairRequestCauseList.where(filter).modify(values)

export const storeRepairRequestNatureList = async (data) => db.repairRequestNatureList.bulkPut(data)
export const deleteRepairRequestNatureList = async (fileId) => db.repairRequestNatureList.where({ fileId }).delete()
export const getRepairRequestNatureListIDB = async () => db.repairRequestNatureList.toArray(p => p)
export const updateRepairRequestNatureList = async (filter, values) => db.repairRequestNatureList.where(filter).modify(values)

export const storeOrganizationsByProject = async (data) => db.organizationsByProject.bulkPut(data)
export const deleteOrganizationsByProject = async (fileId) => db.organizationsByProject.where({ fileId }).delete()
export const getOrganizationsByProjectFromIDB = async (filter) => db.organizationsByProject.get(filter)
export const updateOrganizationsByProject = async (filter, values) => db.organizationsByProject.where(filter).modify(values)

export const storeRepairRequestCauserList = async (data) => db.repairRequestCauserList.bulkPut(data)
export const deleteRepairRequestCauserList = async (fileId) => db.repairRequestCauserList.where({ fileId }).delete()
export const getRepairRequestCauserListFromIDB = async () => db.repairRequestCauserList.toArray(p => p)
export const updateRepairRequestCauserList = async (filter, values) => db.repairRequestCauserList.where(filter).modify(values)

export const storeFile = async (data) => db.files.put({ ...data, file: "data:" + data.file.type + ";base64, " + Buffer.from(data.file, 'base64').toString('base64') })
export const deleteFile = async (fileId) => db.files.where({ fileId }).delete()
export const getFile = async (fileId) => db.files.get({ fileId })
export const updateFile = async (filter, values) => db.files.where(filter).modify(values)

export const storeUserObjects = async (data) => db.userObjects.put(data)
export const deleteUserObjects = async (fileId) => db.userObjects.where({ fileId }).delete()
export const getUserObjects = async (app) => db.userObjects.get({ app })
export const updateUserObjects = async (filter, values) => db.userObjects.where(filter).modify(values)

export const storePinImages = async (data) => db.pinImages.put(data)
export const deletePinImages = async (surveyId) => db.pinImages.where({ surveyId }).delete()
export const getPinImages = async (surveyId) => db.pinImages.get({ surveyId })
export const updatePinImages = async (filter, values) => db.pinImages.where(filter).modify(values)


export const storeWBProjectInfo = async (data) => db.WBProjectInfo.put(data)
export const deleteWBProjectInfo = async (projectId) => db.WBProjectInfo.where({ projectId }).delete()
export const getWBProjectInfoIDB = async (projectId) => db.WBProjectInfo.get({ projectId })
export const updateWBProjectInfo = async (filter, values) => db.WBProjectInfo.where(filter).modify(values)

export const storeRepairRequestDetails = async (data) => db.repairRequestDetails.put(data)
export const deleteRepairRequestDetails = async (fileId) => db.repairRequestDetails.where({ fileId }).delete()
export const getRepairRequestDetailsIDB = async (requestId) => db.repairRequestDetails.get({ requestId })
export const updateRepairRequestDetailsIDB = async (filter, values) => db.repairRequestDetails.where(filter).modify(values)

export const storeRecipientsEmails = async (data) => db.recipientsEmails.put(data)
export const deleteRecipientsEmails = async (fileId) => db.recipientsEmails.where({ fileId }).delete()
export const getRecipientsEmailsIDB = async (filter) => db.recipientsEmails.get(filter)
export const updateRecipientsEmails = async (filter, values) => db.recipientsEmails.where(filter).modify(values)

export const storeEmailsForRepairRequest = async (data) => db.emailsForRepairRequest.put(data)
export const deleteEmailsForRepairRequest = async (fileId) => db.emailsForRepairRequest.where({ fileId }).delete()
export const getEmailsForRepairRequestIDB = async (filter) => db.emailsForRepairRequest.get(filter)
export const updateEmailsForRepairRequest = async (filter, values) => db.emailsForRepairRequest.where(filter).modify(values)

export const storeDrawingFile = async (data) => db.drawingFile.bulkPut(data)
export const deleteDrawingFile = async (fileId) => db.drawingFile.where({ fileId }).delete()
export const getDrawingFilesFromIDB = async (filter) => db.drawingFile.where(filter).toArray(p => p)
export const updateDrawingFile = async (filter, values) => db.drawingFile.where(filter).modify(values)

export const storeRepairRequestLocation = async (data) => db.repairRequestLocation.bulkPut(data)
export const deleteRepairRequestLocation = async (fileId) => db.repairRequestLocation.where({ fileId }).delete()
export const getRepairRequestLocationFromIDB = async () => db.repairRequestLocation.toArray(p => p)
export const updateRepairRequestLocation = async (filter, values) => db.repairRequestLocation.where(filter).modify(values)

export const storeSettlementTerms = async (data) => db.settlementTerms.bulkPut(data)
export const deleteSettlementTerms = async (fileId) => db.settlementTerms.where({ fileId }).delete()
export const getSettlementTermsFromIDB = async () => db.settlementTerms.toArray(p => p)
export const updateSettlementTerms = async (filter, values) => db.settlementTerms.where(filter).modify(values)

export const storeRepairRequestCarryOutAsTypeList = async (data) => db.repairRequestCarryOutAsTypeList.bulkPut(data)
export const deleteRepairRequestCarryOutAsTypeList = async (fileId) => db.repairRequestCarryOutAsTypeList.where({ fileId }).delete()
export const getRepairRequestCarryOutAsTypeListIDB = async () => db.repairRequestCarryOutAsTypeList.toArray(p => p)
export const updateRepairRequestCarryOutAsTypeList = async (filter, values) => db.repairRequestCarryOutAsTypeList.where(filter).modify(values)

export const storeProductsServices = async (data) => db.productsServices.bulkPut(data)
export const deleteProductsServices = async (fileId) => db.productsServices.where({ fileId }).delete()
export const getProductsServicesIDB = async () => db.productsServices.toArray(p => p)
export const updateProductsServices = async (filter, values) => db.productsServices.where(filter).modify(values)

export const storeAppsBuildings = (data) => db.appsBuildings.bulkPut(data);
export const updateBuilding = async (filter, values) => {
    if (filter)
        return db.appsBuildings.where(filter).modify(values);
    return db.appsBuildings.where('app').aboveOrEqual(0).modify(values);
};
export const getAppsBuildings = async (filter) => {
    if (filter)
        return db.appsBuildings.where(filter).toArray()
    return db.appsBuildings.toArray()
};


export const updateAssignmentsAttachments = (filter, values) => db.assignmentsAttachments.where(filter).modify(values);
export const storeAssignmentAttachments = async (data) => {
    const getExistingData = await db.assignmentsAttachments.get({ assignmentId: data.assignmentId, sync: 0 });
    const assignment = (await getAssignment(data.assignmentId)) || {};

    const newAttachments = data.attachments && (await Promise.all(data.attachments.map(async f => ({ id: new Date().getTime().toString(), name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() }))));

    updateAssignment({ ...assignment, assignmentAttachments: assignment.assignmentAttachments.concat(newAttachments.map(a => ({ id: a.id, name: a.name, type: a.type }))) });
    if (getExistingData) {
        return db.assignmentsAttachments.put({ ...data, attachments: getExistingData.attachments.concat(newAttachments) })
    }
    return db.assignmentsAttachments.put({ ...data, attachments: newAttachments });
};
export function getAssignmentsAttachments(filter) {
    return db.assignmentsAttachments.get(filter)
        .then(x => x && ({
            ...x,
            attachments: x.attachments && x.attachments.map(f => ({ id: f.id, file: new File([f.arrayBuffer], f.name, { type: f.type }) }))
        }));
};
export async function removeAssignmentsAttachments(assignmentId, attachmentId) {
    const data = await db.assignmentsAttachments.get({ assignmentId, sync: 0 });
    const assignment = (await getAssignment(data.assignmentId)) || {};
    if (assignment) {
        const index = assignment.assignmentAttachments.findIndex(a => a.id === attachmentId);
        if (index >= 0) {
            assignment.assignmentAttachments.splice(index, 1);
            await updateAssignment(assignment);
        };
    }
    const index2 = data.attachments.findIndex(a => a.id === attachmentId);
    if (index2 >= 0) {
        data.attachments.splice(index2, 1);
    };
    return db.assignmentsAttachments.put({ ...data });
};
export function getAssignmentsAttachmentsArray(filter) {
    if (Object.keys(filter).length)
        return db.assignmentsAttachments.where(filter).toArray();
    else
        return db.assignmentsAttachments.toArray();
};


export const storeSurveys = async (data) => db.surveys.bulkPut(data);
export const geSurvey = async (filter) => db.surveys.get(filter);
export const storeSurvey = async (data) => db.surveys.put(data);
export const deleteSurvey = async (filter) => db.surveys.where(filter).delete();
export const updateSurveyInDB = async (filter, data) => db.surveys.where(filter).modify({ ...data, isSecondSignature: data.isSecondSignature ? 1 : 0 });
export const getSurveys = async (filter) => {
    if (filter)
        return db.surveys.where(filter).toArray()
    return db.surveys.toArray()
};


export const storeSurveyDetails = async (data) => db.surveyDetails.put(data);
export const deleteSurveyDetail = async (filter) => db.surveyDetails.where(filter).delete();
export const updateSurveyDetails = async (filter, data) => db.surveyDetails.where(filter).modify({ ...data, isSecondSignature: data.isSecondSignature ? 1 : 0 });
export const storeSurveyDetailsInBulk = async (data) => db.surveyDetails.bulkPut(data);
export const getSurveyDetails = async (filter) => {
    if (filter)
        return db.surveyDetails.get(filter)
    return db.surveyDetails.toArray()
};


export const storeRepairRequests = async (data) => {
    var updatedData = data && (await Promise.all(data.map(async d => ({ ...d, attachments: d.attachments && (await Promise.all(d.attachments.map(async f => (f.attachmentId ? f : { name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) }))));
    db.repairRequest.bulkPut(updatedData)
};
export const deleteRepairRequests = async (filter) => db.repairRequest.where(filter).delete();
export const updateRepairRequests = async (filter, data) => db.repairRequest.where(filter).modify(data);
export const updateRepairRequestDetails = async (filter, data) => {
    const rrData = { ...data, attachments: data.attachments && (await Promise.all(data.attachments.map(async f => (f.attachmentId ? f : { name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) };
    if (filter.assignmentId) return db.assignmentsRepairRequest.where(filter).modify(rrData);
    return db.repairRequest.where(filter).modify(rrData);
};
export const getRepairRequest = (requestId) => {
    return new Promise(async (resolve, reject) => {
        if (requestId) {
            try {
                let data = await db.assignmentsRepairRequest.get({ requestId }).then(x => x && ({
                    ...x,
                    attachments: x.attachments && x.attachments.map(f => f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))
                }));
                if (!data) {
                    data = await db.repairRequest.get({ requestId }).then(x => x && ({
                        ...x,
                        attachments: x.attachments && x.attachments.map(f => f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))
                    }));
                }
                if (data)
                    resolve(data);
                else
                    reject({ error: 'Repair request not found' });
            } catch (er) {
                reject(er);
            }
        }
        reject({ error: "request id is wrong", code: 404 });
    })
};
export const storeRepairRequest = async (data, isAssignmentDetail, selectedAssignment) => {
    if (data.sync === 0) {
        const attachments = {
            [isAssignmentDetail ? 'assignmentId' : 'surveyId']: data[isAssignmentDetail ? 'assignmentId' : 'surveyId'],
            buildingId: data.buildingId || data.surveyId + "-fundering",
            projectId: data.projectId,
            requestId: data.requestId,
            surveyId: data.surveyId,
            sync: data.sync,
            documents: data.attachments ? data.attachments.length : 0,
            attachments: data.attachments || []
        }
        await storeAttachments(attachments);
    }
    if (isAssignmentDetail) {
        if (selectedAssignment && selectedAssignment.lockId) {
            // console.log(selectedMoment, selectedMoment.controlPoints)
            if (!selectedAssignment.repairRequests) selectedAssignment.repairRequests = [];
            selectedAssignment.repairRequests.push(data);
            await updateAssignment(selectedAssignment);
        }
        const rrData = { ...data, attachments: data.attachments && (await Promise.all(data.attachments.map(async f => (f.attachmentId ? f : { name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) };
        return db.assignmentsRepairRequest.put(rrData)
    }
    const rrData = { ...data, attachments: data.attachments && (await Promise.all(data.attachments.map(async f => (f.attachmentId ? f : { name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) };
    return db.repairRequest.put(rrData);
};
export const getRepairRequestsFromIDB = async (filter) => {
    if (filter) {
        return db.repairRequest.where(filter).toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))) })));
    }
    return db.repairRequest.toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))) })));
};


export const storeAssignmentsRepairRequests = async (data) => {
    var updatedData = data && (await Promise.all(data.map(async d => ({ ...d, attachments: d.attachments && (await Promise.all(d.attachments.map(async f => (f.attachmentId ? f : { name: f.name, type: f.type, arrayBuffer: await f.arrayBuffer() })))) }))));
    db.assignmentsRepairRequest.bulkPut(updatedData);
}
export const deleteAssignmentsRepairRequest = async (filter) => db.assignmentsRepairRequest.where(filter).delete();
export const updateAssignmentRepairRequests = async (filter, data) => db.assignmentsRepairRequest.where(filter).modify(data);
export const getAssignmentsRepairRequestsFromIDB = async (filter) => {
    if (filter)
        return db.assignmentsRepairRequest.where(filter).toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))) })));
    return db.assignmentsRepairRequest.toArray().then(x => x.map(d => ({ ...d, attachments: d.attachments && d.attachments.map(f => (f.attachmentId ? f : new File([f.arrayBuffer], f.name, { type: f.type }))) })));
};


export const storeSignatures = async (data) => db.signatureRequests.put(data);
export const updateSignatures = async (filter, data) => db.signatureRequests.where(filter).modify({ ...data, isSecondSignature: data.isSecondSignature ? 1 : 0 });
export const deleteSignatures = async (filter) => db.signatureRequests.where(filter).delete();
export const getSignatures = async (filter) => db.signatureRequests.get(filter);

export const storeSendingEmails = async (data) => db.emailsSendingRequests.put(data);
export const updateSendingEmails = async (filter, data) => db.emailsSendingRequests.where(filter).modify(data);
export const deleteSendingEmails = async (filter) => db.emailsSendingRequests.where(filter).delete();
export const getSendingEmails = async (filter) => db.emailsSendingRequests.get(filter);

export const putDeletedAssignmentsAttachments = async (data) => db.deletedAssignmentsAttachments.put(data);
export const deletedAssignmentsAttachments = async (filter) => db.deletedAssignmentsAttachments.where(filter).delete();
export const getDeletedAssignmentsAttachments = async (filter) => db.deletedAssignmentsAttachments.where(filter).toArray();

export const getUpdatedAssignmentInfo = async (filter) => db.updateAssignmentInfo.where(filter).toArray();
export const deleteUpdatedAssignmentInfo = async (filter) => db.updateAssignmentInfo.where(filter).delete();
export const storeUpdatedAssignmentInfo = async (data) => db.updateAssignmentInfo.put(data);

export const getUpdatedSurveyInfo = async (filter) => db.updateSurveyInfo.where(filter).toArray();
export const deleteUpdatedSurveyInfo = async (filter) => db.updateSurveyInfo.where(filter).delete();
export const storeUpdatedSurveyInfo = async (data) => {
    return db.updateSurveyInfo.put(data);
};


export const storeInternalCompleteSurveyingMoments = async (data) => {
    const moments = data.map(p => ({ surveyingMomentId: p.surveyingMomentId, sync: 0 }));
    return db.internalCompleteSurveyingMoments.bulkPut(moments);
};
export const getInternalCompleteSurveyingMoments = async (filter) => {
    if (filter)
        return db.internalCompleteSurveyingMoments.get(filter);
    return db.internalCompleteSurveyingMoments.toArray();
};
export const deleteInternalCompleteSurveyingMoments = async (filter) => {
    return db.internalCompleteSurveyingMoments.where(filter).delete();
};


export const storeCompleteSurveyingMoments = async (data) => {
    const moments = data.map(p => ({ surveyingMomentId: p.surveyingMomentId, sync: 0 }));
    return db.completeSurveyingMoments.bulkPut(moments);
};
export const getCompleteSurveyingMoments = async (filter) => {
    if (filter)
        return db.completeSurveyingMoments.get(filter);
    return db.completeSurveyingMoments.toArray();
};
export const deleteCompleteSurveyingMoments = async (filter) => {
    return db.completeSurveyingMoments.where(filter).delete();
};


export const storeInternalCompleteAssignments = async (data, selectedAssignment) => {
    const moments = data.map(p => ({ assignmentId: p, sync: 0 }));
    for (let i = 0; i < data.length; i++) {
        const assignment = await getAssignment(data[i])
        if (assignment) {
            await updateAssignment({
                ...assignment,
                internalStatus: 1,
                sync: 0
            });
        }
    }
    // selectedMoment.controlPoints.forEach(p => {
    //     const isExit = p.assignments.findIndex(a => data.find(assignmentId => assignmentId === a.assignmentId));
    //     if (isExit >= 0) {
    //         p.assignments[isExit].internalStatus = 1;
    //     }
    //     return p;
    // });
    // await updateExistingMoment(selectedMoment);
    return db.internalCompleteAssignments.bulkPut(moments);
};
export const storeInternalResetAssignmentsStatus = async (data, selectedMoment, selectedAssignment) => {
    const moments = data.map(p => ({ assignmentId: p, sync: 0, isReset: true }));
    for (let i = 0; i < data.length; i++) {
        const assignment = await getAssignment(data[i])
        if (assignment) {
            assignment.internalStatus = 0;
            await updateAssignment({ ...assignment, sync: 0 })
        }
    }
    // selectedMoment.controlPoints.forEach(p => {
    //     const isExit = p.assignments.findIndex(a => data.find(assignmentId => assignmentId === a.assignmentId));
    //     if (isExit >= 0) {
    //         p.assignments[isExit].internalStatus = 0;
    //     }
    //     return p;
    // });
    for (let i = 0; i < data.length; i++) {
        const oldData = await getInternalCompleteAssignments({ assignmentId: data[i], sync: 0 });
        if (oldData && !oldData.isReset) await deleteInternalCompleteAssignments(oldData)
    }
    // await updateExistingMoment(selectedMoment);
    return db.internalCompleteAssignments.bulkPut(moments);
};
export const getInternalCompleteAssignments = async (filter) => {
    if (filter)
        return db.internalCompleteAssignments.get(filter);
    return db.internalCompleteAssignments.toArray();
};
export const deleteInternalCompleteAssignments = async (filter) => {
    return db.internalCompleteAssignments.where(filter).delete();
};


export const storeCompleteAssignments = async (data) => {
    const moments = data.map(p => ({ assignmentId: p, sync: 0 }));
    return db.completeAssignments.bulkPut(moments);
};
export const getCompleteAssignments = async (filter) => {
    if (filter)
        return db.completeAssignments.get(filter);
    return db.completeAssignments.toArray();
};
export const deleteCompleteAssignments = async (filter) => {
    return db.completeAssignments.where(filter).delete();
};


export const storeSurveyingMomentsInDB = async (data) => {
    return db.momentsByApp.bulkPut(data);
};
export const storeSurveyingMomentInDB = async (data) => {
    return db.momentsByApp.put(data);
};
export const getSurveysFromDB = (projectId = "", lockedUserId = "") => {
    return db.momentsByApp.where({ projectId, lockedUserId }).toArray();
};
export const deleteSurveyFromDB = (filter) => {
    return db.momentsByApp.where(filter).delete();
};


export const updateResponsiblePersonForAssignmentsOffline = async (data, selected, isMulti, app, selectedMoment, orgId) => {
    const { data: orgs } = await getOrganizationsFromIDB({ app: app.toString(), projectId: selectedMoment.projectId });
    const org = orgs.find(p => p.id === orgId)
    const selectedPerson = org.relations.find(r => r.loginId === data.loginId);
    if (isMulti) {
        selectedMoment.controlPoints.forEach(p => {
            const isExist = p.assignments.findIndex(a => selected.find(id => id === a.assignmentId))
            if (isExist >= 0) {
                p.assignments[isExist].assignedPerson = {
                    ...data,
                    relationName: selectedPerson.name,
                    name: org.name,
                };
                p.assignedPersonPlainText = p.assignments[isExist].assignedPerson.relationName;
            }
            return p;
        });
    }
    if (selected && selected.assignmentId) {
        const index = selectedMoment.controlPoints.findIndex(p => p.controlPointId === selected.id);
        if (index >= 0) {
            const ind = selectedMoment.controlPoints[index].assignments.findIndex(p => p.assignmentId === selected.assignmentId)
            if (ind >= 0) {
                selectedMoment.controlPoints[index].assignments[ind].assignedPerson = {
                    ...data,
                    relationName: selectedPerson.name,
                    name: org.name,
                };;
                selectedMoment.controlPoints[index].assignedPersonPlainText = selectedPerson.name;
            }
        }
    }

    for (let i = 0; i < data.ids.length; i++) {
        const assignment = await getAssignment(data.ids[i])
        if (assignment) {
            assignment.assignedPerson = {
                ...data,
                relationName: selectedPerson.name,
                name: org.name,
            };
            // console.log(assignment)
            await updateAssignment({ ...assignment, sync: 0 })
            await storeUpdatedDelegator({
                assignmentId: assignment.assignmentId, surveyingMomentId: selectedMoment.surveyingMomentId, data: {
                    ...data,
                    ids: [assignment.assignmentId]
                }
            });
        }
    }
    await updateExistingMoment(selectedMoment);
    return new Promise((resolve, reject) => resolve({ data: { data: { ...data, relationName: selectedPerson.name, name: org.name } } }));
}

export const updateLastViewOffline = async (surveyingMomentId, assignmentId, selectedMoment, selectedAssignment, selected) => {
    if (assignmentId && selectedMoment) {
        selectedAssignment = { ...selectedAssignment, hasUpdate: false, sync: 0 }
        // selectedMoment.controlPoints.forEach(controlPont => {
        //     const index = controlPont.assignments.findIndex(p => p.assignmentId === assignmentId);
        //     if (index >= 0) {
        //         controlPont.assignments[index].hasUpdate = false;
        //     }
        //     return controlPont;
        // });
        // console.log(selectedAssignment)
        await updateAssignment(selectedAssignment)
    };

    const surveys = await getSurveysFromDB(selected.projectId)
    if (selectedMoment && selectedMoment.controlPoints) {
        const hasUpdate = selectedMoment.controlPoints.some(controlPont => controlPont.assignments.some(p => p.hasUpdate === false) === false);
        surveys.forEach(p => {
            if (p.surveyingMomentId === surveyingMomentId) {
                p.hasUpdate = hasUpdate;
            }
            return p;
        });
    }
    await updateExistingMoment(selectedMoment);
    return storeSurveyingMomentsInDB(surveys);
}
