import {
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Input,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { AddCircleOutlineOutlined, ArrowUpward, AssignmentOutlined, Backup, GetApp, History, Lock, LockOpen, Person, PictureAsPdf, SwapVert, Warning, DateRange } from "@mui/icons-material"
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  debounce,
  formatDate,
  formatTime,
  generateLocalStorageKey,
  getDataTableTextLabels,
  getLocalStorageItem,
  getMimeTypefromString,
  getSurveys as getSurveysFromIndexDB,
  getSurveyTooltipKey,
  groupBy,
  isCompletedSurvey,
  setLocalStorageItem,
  SurveyStatus,
  SurveyType,
  useCanChangeExecutorForSurveys,
  useCanLockedInspections,
  useCanReleaseInspections,
  useCanSyncDownInspections,
  useCanSyncUpInspections,
  useInternetStatusDetector,
  useIsSurveyCompleted
} from '../../../_helpers';
import { URLS } from "../../../apis/urls";
import { red } from '@mui/material/colors';
import DocumentViewer from '../../../components/DocumentViewer';
import { pdf } from '@react-pdf/renderer';
import { usePDFReport } from './PDFReport';
import { surveyActions, syncsActions } from '../../../_actions';
import SelectSingleObjectModal from "../../../components/SelectSingleObjectModal";
import { apps, surveyConstants, userAccountRoleConstants, userAccountTypeConstants } from '../../../_constants';
import SyncInfoModal from './SyncInfoModal';
import DataGrid from '../../../components/DataGrid';
import SelectCustom from '../../../components/Select';
import SurveysListCustomRow from './SurveysListCustomRow';
import { LabelColumn } from '../../../components/DataColumns';
import DataGridSetting from '../../../components/DataGridSetting';
import ReleaseLockConfirmModal from "./ReleaseLockConfirmModal";
import clsx from "clsx";
import { useHistory } from 'react-router-dom';
import { DatePicker } from "@mui/x-date-pickers"
import SelectResponsiblePerson from './SelectResponsiblePerson';
import { getSurveys as getSurveysApi, updateExecutorForSurvey } from '../../../apis/surveyingApis';

const extraInformationFilter = [
  { id: 1, label: "survey.filter.download" },
  { id: 2, label: "survey.filter.locked" },
]

const STATUSES = [
  { status: 0, label: "survey.status.0" },
  { status: 1, label: "survey.status.1" },
  { status: 2, label: "survey.status.2" },
  { status: 3, label: "survey.status.3" },
];

const SORTING_KEYS_SURVEYS = {
  projectName: "projectname",
  projectNo: "projectno",
  date: "date",
  buildingNoIntern: "buildingnointern",
  address: "address",
  buyerRenter1: "buyerrenter1",
  buyerRenter2: "buyerrenter2",
  executedBy: "executor",
  internalStatusPlainText: "status",
};


const createDate = (date) => new Date(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`)


const getAppliedSurveysFilters = (filters, lockIds = []) => {
  return Object.keys(filters || {}).reduce((p, c) => {
    switch (c) {
      case 'date': {
        if (!filters[c][0]) return p;
        const date = new Date(filters[c][0]);
        const dateString = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
        return { ...p, dateFrom: dateString, dateTo: dateString };
      }
      case 'buildingNoIntern': {
        return { ...p, buildingIds: filters[c].map(p => p.buildingId) };
      }
      case 'projectName': {
        return { ...p, projectIds: filters[c].map(p => p.projectId) };
      }
      case 'internalStatusPlainText': {
        return { ...p, statuses: filters[c].map(p => p.status) };
      }
      case 'extraInformation': {
        switch (filters[c][0] && filters[c][0].id) {
          case 1:
            return { ...p, downloaded: true, lockIds }
          case 2:
            return { ...p, locked: true, lockIds }
          default:
            return { ...p }
        }
      }
      default:
        return p;
    }
  }, {});
}


export default function SurveysLists({
  moduleType,
  moduleTitle,
  gridDataTitle,
  createInspection,
  isSecondSignatureSurvey,
  title,
  preDeliveryOrInspectionPDF,
  onlyOfflinePDFVersion,
  canShowPdfColumn,
  showTimeColumns,
  deliveries,
  pathKey,
  parentUrl,
  data,
  ...props }) {
  const history = useHistory()
  const { t } = useTranslation();
  const muiTheme = useTheme();
  const matchWidthOnlyXs = useMediaQuery(muiTheme.breakpoints.only('xs'));
  const matchWidthOnlyMd = useMediaQuery(muiTheme.breakpoints.only('md'));
  const matchWidthOnlySm = useMediaQuery(muiTheme.breakpoints.only('sm'));
  const matchWidthDownSm = useMediaQuery(muiTheme.breakpoints.down('md'));
  const classes = useStyles({ matchWidthOnlyXs, matchWidthOnlySm, matchWidthOnlyMd })
  const anchorElForExecutor = useRef(null);
  const [openExecutorPopover, setOpenExecutorPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [rowsSelected, setRowsSelected] = useState({ data: [], list: [] });
  const [isOpenDocViewer, setISOpenDocViewer] = useState(false);
  const [previewDocument, setPreviewDocument] = useState([]);
  const [projectFiltersData, setProjectFiltersData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [buildingFiltersData, setBuildingFiltersData] = useState([]);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [sortData, setSortData] = useState({ name: "date", direction: "desc" });
  const [pdfMenuLoading, setPDFMenuLoading] = useState(false);
  const ref = useRef(null)
  const app = useSelector(state => state.app);
  const [columnsVisibility, setColumnsVisibility] = useState([
    { name: "surveyId", visible: "excluded" },
    { name: "iconsColumns", visible: true },
    { name: "projectName", visible: app === apps.aftercare },
    { name: "projectNo", visible: app === apps.aftercare },
    { name: "buildingNoIntern", visible: true },
    { name: "address", visible: true },
    { name: "buyerRenter1", visible: true },
    { name: "buyerRenter2", visible: true },
    { name: "internalStatusPlainText", visible: true },
    { name: "date", visible: true },
    { name: "executorCompanyName", visible: true },
    { name: "executedBy", visible: true },
    { name: "startTime", visible: true },
    { name: "endTime", visible: true },
  ]);
  const { user } = useSelector(state => state.authentication);
  const { selected, all } = useSelector(state => state.buildings);
  const { getSurveysLoading, reportLoading, selectedInspection, assignmentLockIds, pageMetaData: currentPageInfo = { index: 0 } } = useSelector(state => state.surveying);
  const pdfReport = usePDFReport(isSecondSignatureSurvey, preDeliveryOrInspectionPDF, preDeliveryOrInspectionPDF);
  const dispatch = useDispatch();
  const [isOpenObjectModal, setIsOpenObjectModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [openSyncInfoModal, setOpenSyncInfoModal] = useState(false);
  const [openReleaseLockConfirmModal, setOpenReleaseLockConfirmModal] = useState(false);
  const [canListType, setCanListType] = useState(false);
  const [filters, setFilters] = useState({});
  const [inspections, setInspections] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [organizationsLoading, setOrganizationsLoading] = useState(false);
  const [lockIds, setLockIds] = useState([]);
  const isBuyer = user && user.type === userAccountTypeConstants.buyer;

  // use custome hooks
  const isOnline = useInternetStatusDetector();
  const canSyncUpInspections = useCanSyncUpInspections(rowsSelected);
  const canLockedInspections = useCanLockedInspections(rowsSelected);
  const isSelectedSurveyComplete = useIsSurveyCompleted(rowsSelected);
  const canReleaseInspection = useCanReleaseInspections(rowsSelected);
  const canSyncDownInspections = useCanSyncDownInspections(rowsSelected);
  const canChangeExecutor = useCanChangeExecutorForSurveys(rowsSelected, moduleType);
  const orderByObject = data.orderBy ? { orderBy: SORTING_KEYS_SURVEYS[data.orderBy] } : {};

  useEffect(() => {
    getSurveysFromIndexDB({ surveyType: moduleType, isSecondSignature: isSecondSignatureSurvey ? 1 : 0 }).then(res => {
      setLockIds(res.map(a => a.lockId))
    }).catch(er => {
      console.log(er, "er");
    });
  }, [moduleType, isSecondSignatureSurvey, assignmentLockIds]);

  useEffect(() => {
    setBuildings(all.filter(a => (!a.roles || a.roles.length !== 1 || !a.roles.includes(userAccountRoleConstants.propertyManager))));
  }, [all]);

  useEffect(() => {
    if (data && data.length) {
      setInspections(data.map(a => ({
        ...a,
        internalStatusPlainText: SurveyStatus[isSecondSignatureSurvey ? !a.isSecondSignatureInitiated ? 0 : a.secondSignatureDate ? a.status : 1 : a.status]
      })))
    } else setInspections([]);
  }, [data]);

  const isColumnVisible = (columnName) => {
    const isSmallScreen = true;
    var column = columnsVisibility.find((x) => x.name === columnName);
    if (column) {
      return column.visible;
    } else if (isSmallScreen) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    let rowsPerPageLocal = getLocalStorageItem("SurveysList_RowsPerPage_" + gridDataTitle);
    if (!rowsPerPageLocal) {
      setLocalStorageItem("SurveysList_RowsPerPage_" + gridDataTitle, rowsPerPage);
      rowsPerPageLocal = rowsPerPage;
    } else setRowsPerPage(rowsPerPageLocal);

    let localFilters = getLocalStorageItem(`SurveysList_Columns_Filters_Updated_${gridDataTitle}`) || {};
    const columnsVisibilityLocal = getLocalStorageItem("SurveysList_ColumnsVisibility_" + gridDataTitle);
    if (!columnsVisibilityLocal) {
      setLocalStorageItem("SurveysList_ColumnsVisibility_" + gridDataTitle, columnsVisibility);
    } else {
      setColumnsVisibility(columnsVisibilityLocal);
    }
    let sortDataLocal = getLocalStorageItem("SurveysList_SortOrder_" + gridDataTitle);
    if (sortDataLocal) setSortData(sortDataLocal);
    else sortDataLocal = sortData;
    if (all && all.length) {
      const projectIds = groupBy(buildings, "projectId") || {};
      setProjectFiltersData(Object.keys(projectIds).map(a => projectIds[a][0]));
      if (app !== apps.aftercare && (!localFilters || !localFilters.projectName)) {
        localFilters.projectName = [selected];
      }
      if (localFilters) {
        setFilters(localFilters);
      }
      getSurveysFromIndexDB({ surveyType: moduleType, isSecondSignature: isSecondSignatureSurvey ? 1 : 0 }).then(res => {
        const filter = getAppliedSurveysFilters(localFilters, res.map(a => a.lockId));
        //Call api
        getSurveys(rowsPerPageLocal, undefined, undefined, sortDataLocal ? { ...filter, orderBy: sortDataLocal.name, orderByAsc: sortDataLocal.direction === "asc" } : filter)
      }).catch(er => {
        const filter = getAppliedSurveysFilters(localFilters);
        //Call api
        getSurveys(rowsPerPageLocal, undefined, undefined, sortDataLocal ? { ...filter, orderBy: sortDataLocal.name, orderByAsc: sortDataLocal.direction === "asc" } : filter)
      });
    }

  }, [history.location.key, isOnline, buildings]);

  useEffect(() => {
    if (filters && filters.projectName) {
      const projectFilter = filters.projectName;
      setBuildingFiltersData(projectFilter ? buildings.filter(a => !!projectFilter.find(p => p.projectId === a.projectId)).sort((a, b) => a.buildingNoExtern - b.buildingNoExtern) : []);
    }
  }, [filters.projectName && filters.projectName, buildings]);

  useEffect(() => {
    let gridStyle = getLocalStorageItem("SurveysList_Grid_Style" + gridDataTitle);
    gridStyle = gridStyle === null ? matchWidthDownSm : gridStyle;
    setCanListType(gridStyle);
  }, [matchWidthDownSm]);


  useEffect(() => {
    if (selectedInspection)
      dispatch(surveyActions.customDispatcher({ type: surveyConstants.GET_INSPECTION_DETAIL_SUCCESS, data: null }));
  }, [selectedInspection]);

  useEffect(() => {
    if (!reportLoading && pdfMenuLoading) {
      pdf(pdfReport).toBlob().then(async blob => {
        const url = await window.URL.createObjectURL(blob);
        setPreviewDocument([{
          uri: url,
          fileType: 'application/pdf',
          name: (moduleType === SurveyType.Delivery ? (isSecondSignatureSurvey ? "PvO_met_tweede_handtekening_": "PvO_") : (moduleTitle + "_")) + formatDate(new Date()) + "_" + new Date().getTime() + ".pdf"
        }]);
        setISOpenDocViewer(true);
        setPDFMenuLoading(false)
      }).catch(er => {
        setPDFMenuLoading(false)
      })
    }
  }, [reportLoading]);

  const handleShowPDF = (surveyId) => {
    dispatch(surveyActions.showOfflineSurveyPDFReport(surveyId, preDeliveryOrInspectionPDF, isSecondSignatureSurvey || preDeliveryOrInspectionPDF))
  }

  const StyledMenuItem = withStyles((theme) => ({
    root: {
      whiteSpace: 'pre-wrap',
      padding: theme.spacing(1),
      '& div': {
        minWidth: 30,
        color: theme.palette.grey[700]
      },
      '& span': {
        color: theme.palette.grey[700],
        fontSize: 12,
      }
    },
  }))(MenuItem);


  const pdfColumn = canShowPdfColumn ? [{
    name: 'pdf',
    label: t('general.pdf'),
    options: {
      display: isColumnVisible("pdf"),
      filter: false,
      print: false,
      sort: false,
      download: false,
      maxWidth: 100,
      setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
            const survey = inspections[rowIndex];
        if (!survey) return "";
        const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === survey.lockId && ((isSecondSignatureSurvey ? 1 : 0) === +a.isSecondSignature));
        const isDownloaded = survey && lockInfo && lockInfo.userId === user.id && survey.lockId;
        return (isSecondSignatureSurvey ? survey.secondSignatureDate : (survey.status === 2 || survey.status === 3)) ?
          <IconButton
            className={classes.pdfButton}
            onClick={(e) => {
              e.stopPropagation()
              if (isDownloaded || onlyOfflinePDFVersion) {
                setPDFMenuLoading(survey.surveyId);
                handleShowPDF(survey.surveyId);
              } else {
                setPreviewDocument([{
                  uri: URLS[isSecondSignatureSurvey ? "GET_SURVEY_REPORT_FOR_SECOND_SIGNATURE" : "GET_SURVEY_REPORT"] + survey.surveyId,
                  fileType: getMimeTypefromString(".pdf"),
                  name: survey.buildingNoIntern,
                }]);
                setISOpenDocViewer(true);
              }
            }}
            size="large">
            {pdfMenuLoading === survey.surveyId ? <CircularProgress size={20} /> :
              <PictureAsPdf className={classes.pdfIcon} />}
          </IconButton> : null;
      }
    }
  }] : [];

  const timeColumns = showTimeColumns ? [{
    name: 'startTime',
    label: t('survey.startTime'),
    options: {
      display: isColumnVisible("startTime"),
      filter: false,
      sort: false,
      maxWidth: 100,
      setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
            const survey = inspections[rowIndex];
            if (!survey) return "";
            return !!survey.startTime && <LabelColumn label={formatTime(survey.startTime)} />
        },
    }
  }, {
    name: 'endTime',
    label: t('survey.endTime'),
    options: {
      display: isColumnVisible("endTime"),
      filter: false,
      sort: false,
      maxWidth: 100,
      setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        customBodyRenderLite: (rowIndex) => {
            const survey = inspections[rowIndex];
            if (!survey) return "";
            return !!survey.endTime && <LabelColumn label={formatTime(survey.endTime)} />
        },
    }
  }] : [];


  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      color: '#fff',
      fontSize: 14,
    },
  }))(Tooltip);


  let columns = [
    {
      name: "surveyId",
      options: {
        display: isColumnVisible("surveyId"),
        filter: false,
        sort: false,
        print: false,
        download: false,
        maxWidth: 100,
      },
    },
    {
      name: "iconsColumns",
      label: " ",
      options: {
        maxWidth: 60,
        display: isColumnVisible("iconsColumns"),
        filter: false,
        sort: false,
        download: false,
        print: false,
        customBodyRenderLite: (rowIndex) => {
          const survey = inspections[rowIndex];
          if (!survey) return "";
          const { isCracked, lockId } = survey;
          const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId && ((isSecondSignatureSurvey ? 1 : 0) === +a.isSecondSignature));
          if (!isSecondSignatureSurvey && moduleType === SurveyType.Delivery && !lockInfo && isCompletedSurvey(survey.status)) return null;
          return (
            <div className={classes.iconHeader}>
              {lockInfo && lockInfo.userId === user.id && lockId &&
                <Tooltip title={t(getSurveyTooltipKey(), { moduleType: moduleTitle })}>
                  <GetApp color='primary' className={classes.icon} />
                </Tooltip>}

              {isCracked && <Tooltip
                title={t(getSurveyTooltipKey("locked"), { moduleType: moduleTitle })}>
                <Warning color='primary' className={classes.icon} />
              </Tooltip>}

              {!lockInfo && lockId &&
                <Tooltip title={t(getSurveyTooltipKey("broken"), { moduleType: moduleTitle })}>
                  <Lock color='primary' className={classes.icon} />
                </Tooltip>}
            </div>
          );
        },
      },
    },

    {
      name: "projectName",
      label: t("general.projectName"),
      options: {
        display: isColumnVisible("projectName"),
        filterList: filters ? filters.projectName : [],
        filter: true,
        maxWidth: 120,
        sort: true,
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              const projectName = survey.projectName.replace(`${survey.projectNo} - `, "");
              return <LabelColumn tooltipLabel={projectName} label={projectName} />;
          },
        filterType: "custom",
        filterOptions: {
          names: projectFiltersData,
          logic: (value, filters, row) => {
            if (!isOnline) {
              if (filters.length && !filters.find(a => value.includes(a.projectName)) && value) return true;
              return false;
            };
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index].length ? filterList[index][0] : null;
            return (
              <SelectCustom
                label={t("general.projectName")}
                sortDirection="desc"
                searchKey="projectName"
                selectProps={{
                  value,
                  onChange: (e, newValue) => {
                    onChange(newValue ? [newValue] : [], index, column);
                  },
                }}
                idKey={"projectId"}
                labelKey={"projectName"}
                options={projectFiltersData.map(a => ({ ...a, projectName: a.projectName.replace(`${a.projectNo} - `, "") }))}
              />
            );
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map((record) => {
              if (record) {
                const projectName = record.projectName.replace(`${record.projectNo} - `, "");
                return <span key={projectName}>{projectName}</span>
              }
            })
          }
        }
      },
    },
    {
      name: "projectNo",
      label: t("general.projectNumber"),
      options: {
        display: isColumnVisible("projectNo"),
        filter: false,
        maxWidth: 120,
        sort: true,
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn tooltipLabel={survey.projectNo} label={survey.projectNo} />
          },
      },
    },

    {
      name: "buildingNoIntern",
      label: t('general.buildingNumber'),
      options: {
        display: isColumnVisible("buildingNoIntern"),
        filter: true,
        sort: true,
        filterList: filters ? filters.buildingNoIntern : [],
        maxWidth: 120,
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.buildingNoIntern} />
          },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
        filterType: "custom",
        filterOptions: {
          names: buildingFiltersData,
          logic: (value, filters, row) => {
            if (!isOnline) {
              if (filters.length && !filters.find(a => a.buildingNoIntern.includes(value)) && value) return true;
              return false;
            };
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const disabled = !filters || (!filters.projectName || (filters.projectName && !filters.projectName.length));
            return (
              <SelectCustom
                label={t("general.buildingNumber")}
                searchKey="buildingNoIntern"
                selectProps={{
                  input: <Input />,
                  multiple: true,
                  value: filters ? filters.buildingNoIntern : [],
                  onChange: (e, newValue) => {
                    onChange(newValue, index, column);
                  },
                  disabled
                }}
                selectedList={filters ? filters.buildingNoIntern : []}
                idKey={"buildingId"}
                labelKey={"buildingNoIntern"}
                options={buildingFiltersData}
              />

            );
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(({ buildingNoIntern }) => <span key={buildingNoIntern}>{buildingNoIntern}</span>)
          }
        }
      },
    },
    {
      name: 'address',
      label: t('general.objectAddress'),
      options: {
        display: isColumnVisible("address"),
        filter: false,
        sort: true,
        maxWidth: 120,
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.address} tooltipLabel={survey.address} />
          },
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
      }
    },
    {
      name: 'buyerRenter1',
      label: t('general.buyer1'),
      options: {
        display: isColumnVisible("buyerRenter1"),
        filter: false,
        sort: true,
        maxWidth: 120,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.buyerRenter1} tooltipLabel={survey.buyerRenter1} />
          },
      }
    },
    {
      name: 'buyerRenter2',
      label: t('general.buyer2'),
      options: {
        display: isColumnVisible("buyerRenter2"),
        sort: true,
        filter: false,
        maxWidth: 120,
        setCellHeaderProps: () => ({ className: classes.customMainHeader, }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.buyerRenter2} tooltipLabel={survey.buyerRenter2} />
          },
      }
    },
    {
      name: 'internalStatusPlainText',
      label: t('general.status'),
      options: {
        display: isColumnVisible("internalStatusPlainText"),
        filterList: filters ? filters.internalStatusPlainText : [],
        filter: true,
        sort: true,
        maxWidth: 120,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return (
                  <LightTooltip title={t(survey.internalStatusPlainText)}>
                      <Chip label={t(survey.internalStatusPlainText)} className={classes.customTableChip} />
                  </LightTooltip>
              )
          },
        filterType: "custom",
        filterOptions: {
          names: STATUSES,
          logic: (value, filters, row) => {
            if (!isOnline) {
              if (filters.length && !filters.find(a => a.label.includes(value)) && value) return true;
              return false;
            };
            return false;
          },
          display: (filterList, onChange, index, column) => {
            return (
              <SelectCustom
                label={t("general.status")}
                searchKey="label"
                selectProps={{
                  multiple: true,
                  value: filterList[index],
                  onChange: (e, newValue) => {
                    onChange(newValue, index, column);
                  },
                }}
                selectedList={filterList[index]}
                idKey={"label"}
                labelKey={"label"}
                options={STATUSES}
              />
            );
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a.label}>{t(a.label)}</span>)
          }
        }
      }
    },
    {
      name: 'date',
      label: t('general.date'),
      options: {
        display: isColumnVisible("date"),
        filter: true,
        sort: true,
        filterList: filters ? filters.date : [],
        maxWidth: 100,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn date={new Date(survey.date)} />
          },
        filterType: "custom",
        filterOptions: {
          logic: (value, filters, row) => {
            if (!isOnline) {
              if (filters.length && !filters.find(a => {
                return createDate(new Date(a)).getTime() === createDate(new Date(value)).getTime()
              })) return true;
              return false;
            };
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index] && filterList[index].length ? filterList[index][0] || null : null;
            return (
              <>
                <div className={clsx(classes.datePickerContainer, !value && classes.shrinkDateLabel)}>
                  <DatePicker
                    clearable
                    variant="inline"
                    id="startDate-time-picker"
                    label={t("general.date")}
                    format="dd-MM-yyyy"
                    helperText={""}
                    value={value ? new Date(value) : value}
                    onChange={(date) => {
                      onChange([new Date(date).toJSON()], index, column)
                    }}
                    autoOk
                    ampm={false}
                    name="startDateTime"
                    slotProps={{
                      openPickerButton: { edge: "start", size: "large" },
                      textField: {
                        variant: "standard",
                        size: "large",
                      },
                    }}
                    slots={{
                      openPickerButton: IconButton,
                      openPickerIcon: DateRange,
                    }}
                  />
                </div>
              </>
            )
          },
        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => a && <span key={a}>{formatDate(new Date(a))}</span>)
          }
        }
      }
    },
    ...timeColumns,
    {
      name: 'executorCompanyName',
      label: t('general.company'),
      options: {
        display: isColumnVisible("executorCompanyName"),
        filter: false,
        maxWidth: 120,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.executorCompanyName} tooltipLabel={survey.executorCompanyName} />
          },
      }
    },
    {
      name: 'executedBy',
      label: t('survey.responsible'),
      options: {
        display: isColumnVisible("executedBy"),
        sort: true,
        filter: false,
        maxWidth: 150,
        setCellHeaderProps: () => ({ className: classes.customMainHeader }),
          customBodyRenderLite: (rowIndex) => {
              const survey = inspections[rowIndex];
              if (!survey) return "";
              return <LabelColumn label={survey.executedBy} tooltipLabel={survey.executedBy} />
          },
      }
    },
    ...pdfColumn,
    {
      name: 'extraInformation',
      label: t('survey.extraInformation'),
      options: {
        display: "excluded",
        filter: true,
        sort: false,
        download: false,
        filterType: "custom",
        filterList: filters ? filters.extraInformation : [],
        filterOptions: {
          names: extraInformationFilter,
          logic: (value, filters, row) => {
            if (!isOnline) {
              const appliedFilter = filters.length && filters[0];
              const survey = inspections ? inspections.find(r => r.surveyId === row[0]) : {};
              if (!survey) return false;
              const { lockId } = survey;
              const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === lockId);
              //false - show row
              //true - hide row
              switch (appliedFilter.label) {
                case extraInformationFilter[0].label: {
                  return !(lockInfo && lockId && lockInfo.userId === user.id);
                }
                case extraInformationFilter[1].label: {
                  return !(!lockInfo && lockId);
                }
                default: {
                  return false;
                }
              }
            }
            return false;
          },
          display: (filterList, onChange, index, column) => {
            const value = filterList[index].length ? filterList[index][0] : null;
            return (
              <SelectCustom
                label={t("survey.extraInformation")}
                searchKey="label"
                selectProps={{
                  value,
                  onChange: (e, newValue) => {
                    onChange(newValue ? [newValue] : [], index, column);
                  },
                }}
                idKey={"label"}
                labelKey={"label"}
                options={extraInformationFilter}
              />

            );
          },

        },
        customFilterListOptions: {
          render: function (record) {
            return record.map(a => <span key={a.label}>{t(a.label)}</span>)
          }
        }
      }
    },
  ];


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseObjectModal = () => {
    setIsOpenObjectModal(false);
  }

  const handleSaveObject = (buildingId) => {
    const objectData = buildingId ? { buildingId, } : {}
    dispatch(surveyActions.addSurvey({
      surveyType: 2,
      projectId: selected.projectId,
      ...objectData,
      date: new Date(),
      status: 0,
      executorSignature: {
        name: "",
        content: ""
      },
      buyerRenter1Signature: {
        name: "",
        content: ""
      },
      buyerRenter2Signature: {
        name: "",
        content: ""
      }
    }))
    handleCloseObjectModal();
  }

  const inspectionsSyncDownHandler = () => {
    setOpenSyncInfoModal(true);
    const surveysLists = rowsSelected.data.map(({ surveyId }) => inspections.find(a => a.surveyId === surveyId));
    dispatch(syncsActions.surveysSynDown(surveysLists, moduleTitle, isSecondSignatureSurvey));
  }

  const inspectionsSyncUpHandler = () => {
    setOpenSyncInfoModal(true);
    const surveysLists = rowsSelected.data.map(({ surveyId }) => inspections.find(a => a.surveyId === surveyId));
    dispatch(syncsActions.surveysSyncUp(surveysLists, isSecondSignatureSurvey, moduleTitle));
  }

  const openReleaseLockConfirmationModal = () => {
    setOpenReleaseLockConfirmModal(true);
  }
  const releaseLockHandler = () => {
    setOpenReleaseLockConfirmModal(false);
    setOpenSyncInfoModal(true);
    const surveysLists = rowsSelected.data.map(({ surveyId }) => inspections.find(a => a.surveyId === surveyId));

    const canClean = surveysLists.some(p => {
      const lockInfo = assignmentLockIds && assignmentLockIds.find(a => a.lockId === p.lockId);
      return lockInfo && lockInfo.userId === user.id;
    });
    dispatch(syncsActions.releaseSurveysLock(surveysLists, canClean, moduleTitle, {
      surveyType: moduleType,
      isSecondSignature: isSecondSignatureSurvey,
      search,
      index: currentPageInfo.index,
      rowsPerPage,
      ...data,
      ...orderByObject,
    }));
  }

  const handleInfoSyncClose = () => {
    setOpenSyncInfoModal(false);
    handleClose();
  }

  const handleChangeGridStyle = (val) => {
    setCanListType(val);
    setLocalStorageItem("SurveysList_Grid_Style" + gridDataTitle, val);
  }

  const search = debounce((newRowsPerPage, page, searchQuery, data) => {
    getSurveys(newRowsPerPage, page, searchQuery, data);
    setSearchText(searchQuery)
  }, 500);

  const updateSort = (sort) => {
    if (sort) {
      const sortedData = sortData ? {
        orderBy: sort.name,
        orderByAsc: sortData.name === sort.name ? sortData.direction === "asc" ? false : true : true,
      } : { orderBy: sort.name, orderByAsc: true };
      const sortBy = { name: sortedData.orderBy, direction: sortedData.orderByAsc ? "asc" : "desc" };
      setLocalStorageItem('SurveysList_SortOrder_' + gridDataTitle, sortBy);
      setSortData(sortBy);
      const data = getAppliedSurveysFilters(filters, lockIds);
      //Call Api
      if (isOnline) {
        getSurveys(rowsPerPage, currentPageInfo.index, searchText, { ...data, ...sortedData })
      };
    }
  };

  function onFilterChange(identifier, list, type, index) {
    const changedFilter = { ...filters };
    const sortedData = sortData ? {
      orderBy: sortData.name,
      orderByAsc: sortData.direction === "asc",
    } : {};
    if (type !== "reset" && !list[2].length) {
      list[4] = [];
      changedFilter.buildingNoIntern = [];
    }
    if (type === "reset") for (let key in changedFilter) changedFilter[key] = [];
    else changedFilter[identifier] = list[index];
    setFilters(changedFilter);
    setLocalStorageItem(`SurveysList_Columns_Filters_Updated_${gridDataTitle}`, changedFilter);
    const data = getAppliedSurveysFilters(changedFilter, lockIds);
    isOnline && getSurveys(rowsPerPage, currentPageInfo.index, searchText, { ...data, ...sortedData });
  }

  function onViewColumnsChange(changedColumn, action) {
    if (action == "add") {
      var listToUpdate = Object.assign([], columnsVisibility);

      var column = listToUpdate.find((x) => x.name === changedColumn);
      if (column) {
        column.visible = true;
      } else {
        listToUpdate.push({ name: changedColumn, visible: true });
      }
      setColumnsVisibility(listToUpdate);
      setLocalStorageItem("SurveysList_ColumnsVisibility_" + gridDataTitle, listToUpdate);
    } else if (action == "remove") {
      var listToUpdate = Object.assign([], columnsVisibility);

      var column = listToUpdate.find((x) => x.name === changedColumn);
      if (column) {
        column.visible = false;
      } else {
        listToUpdate.push({ name: changedColumn, visible: false });
      }
      setColumnsVisibility(listToUpdate);
      setLocalStorageItem("SurveysList_ColumnsVisibility_" + gridDataTitle, listToUpdate);
    }
  };
  function customToolbar() {
    return <>
      <Menu
        key="menu"
        id="simple-menu"
        className={classes.menuContent}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={1}
        getContentAnchorEl={null}
        disableRestoreFocus
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuList className={classes.menuListStyle}>

          {!canSyncUpInspections && canSyncDownInspections && <StyledMenuItem
            onClick={inspectionsSyncDownHandler}
            disabled={!rowsSelected.list.length || !isOnline || canLockedInspections || isSelectedSurveyComplete} dense>
            <ListItemIcon>
              <GetApp fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('sync.down')}</Typography>
          </StyledMenuItem>}
          {canSyncUpInspections && <StyledMenuItem
            onClick={inspectionsSyncUpHandler}
            disabled={!rowsSelected.list.length || !isOnline || !canSyncUpInspections || canLockedInspections}
            dense>
            <ListItemIcon>
              <Backup fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('sync.up')}</Typography>
          </StyledMenuItem>}
          {!isBuyer && (moduleType === SurveyType.Delivery || moduleType === SurveyType.PreDelivery) && !!rowsSelected.list.length && <StyledMenuItem
            ref={anchorElForExecutor}
            onClick={() => setOpenExecutorPopover(p => !p)}
            disabled={!canChangeExecutor}
            dense
          >
            <ListItemIcon>
              {organizationsLoading ? <CircularProgress size={18} style={{ minWidth: 0 }} /> : <Person fontSize="small" />}
            </ListItemIcon>
            <Typography variant="inherit">{t('survey.responsible')}</Typography>
          </StyledMenuItem>}
          <StyledMenuItem onClick={() => setOpenSyncInfoModal(true)} dense>
            <ListItemIcon>
              <History fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('sync.logs')}</Typography>
          </StyledMenuItem>
          {canReleaseInspection && <StyledMenuItem
            disabled={!isOnline}
            onClick={openReleaseLockConfirmationModal}
            dense>
            <ListItemIcon>
              <LockOpen fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">{t('sync.release')}</Typography>
          </StyledMenuItem>}
        </MenuList>

      </Menu>
      {canListType && matchWidthDownSm && <>
        <IconButton
          aria-label="sort"
          aria-controls="sort-menu"
          aria-haspopup="true"
          onClick={(e) => setSortAnchorEl(e.currentTarget)}
          size="large">
          <SwapVert />
        </IconButton>
        <Menu
          id="sort-menu"
          className={classes.menuContent}
          anchorEl={sortAnchorEl}
          keepMounted
          open={Boolean(sortAnchorEl)}
          onClose={() => setSortAnchorEl(null)}
          elevation={1}
          getContentAnchorEl={null}
          disableRestoreFocus
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuList className={classes.menuListStyle} style={{ padding: 0 }}>
            {columns.map((column, i) => {
              if (!column || !column.options || column.options.display === "excluded" || !column.options.sort) return null;
              return (
                <StyledMenuItem
                  key={column.name}
                  selected={sortData && sortData.name === column.name}
                  onClick={() => updateSort(column)}
                  dense
                >
                  {sortData && <ListItemIcon>
                    <ListItemIcon className={classes.sortListIconStyle}>
                      {sortData.name === column.name &&
                        <ArrowUpward fontSize="small" className={clsx(classes.expandCollapseIcon, sortData.direction === 'asc' && classes.expandLess)} />}
                    </ListItemIcon>
                  </ListItemIcon>}
                  <Typography variant="inherit">{t(column.label)}</Typography>
                </StyledMenuItem>
              )
            }
            )}
          </MenuList>
        </Menu>
      </>}

      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <AssignmentOutlined />
      </IconButton>
      {createInspection && window.navigator.onLine && moduleType === 2 && <Tooltip title={t('survey.create.label')}>
        <IconButton
          onClick={() => setIsOpenObjectModal(true)}
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          size="large">
          <AddCircleOutlineOutlined />
        </IconButton>
      </Tooltip>}
      {matchWidthDownSm && <DataGridSetting
        canListType={canListType}
        hideImageSizePicker
        onChangeGridViewType={(val) => handleChangeGridStyle(val)}
      />}


    </>;
  };

    const handleRowClick = selectedRow => {
        history.push(`${props.match.url}/${selectedRow.surveyId}`)
    }

  const options = {
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: [5, 10, 20, 50, 75, 100],
    customToolbar,
    download: true,
    print: true,
    onViewColumnsChange,
    onFilterChange,
    pagination: true,
    serverSide: isOnline,
    count: currentPageInfo && currentPageInfo.totalResults,
    textLabels: getDataTableTextLabels(t, getSurveysLoading),
    page: currentPageInfo && currentPageInfo.index,
    sortOrder: sortData,
    onTableChange: (action, tableState) => {
      let data = getAppliedSurveysFilters(filters, lockIds);
      const newRowsPerPage = tableState.rowsPerPage;
      const page = tableState.page;
      const tableSortData = Object.keys(tableState.sortOrder).length ? tableState.sortOrder : null;
      if (tableSortData) {
        data = Object.assign(data, {
          orderBy: tableSortData.name,
          orderByAsc: tableSortData.direction === "asc",
        });
      };
      switch (action) {
        case "changePage": {
          isOnline && getSurveys(newRowsPerPage, page, tableState.searchText, data);
          break;
        }
        case "changeRowsPerPage": {
          isOnline && getSurveys(newRowsPerPage, page, tableState.searchText, data);
          setRowsPerPage(newRowsPerPage);
          setLocalStorageItem('SurveysList_RowsPerPage_' + gridDataTitle, newRowsPerPage);
          break;
        }
        case "search": {
          isOnline && search(newRowsPerPage, page, tableState.searchText, data);
          break;
        }
        case "sort": {
          if (tableSortData && isOnline) {
            getSurveys(newRowsPerPage, page, tableState.searchText, data);
          };
          setLocalStorageItem('SurveysList_SortOrder_' + gridDataTitle, tableSortData);
          setSortData(tableSortData)
          break;
        }
        default: {
          break;
        }
      }
    },
      onRowClick: (rowData, rowMeta) => {
          const surveyId = rowData[0];
          const selectedRow = inspections && inspections.find((x) => x.surveyId === surveyId);
          if (!!selectedRow) {
              handleRowClick(selectedRow);
          }
      },
  };

  const getSurveys = (rowsPerPage = 20, page = 0, search = "", data = {}) => {
    if (!search) search = "";
    if (all && all.length) {
      const orderByObject = data.orderBy ? { orderBy: SORTING_KEYS_SURVEYS[data.orderBy] } : {};
      dispatch(surveyActions.getSurveysAction({
        surveyType: moduleType,
        isSecondSignature: isSecondSignatureSurvey,
        search,
        index: page,
        rowsPerPage,
        ...data,
        ...orderByObject,
      }));
    }
  }

  const handleChangeExecutor = async (data) => {
    try {
      await updateExecutorForSurvey(rowsSelected.data.map(s => s.surveyId), data.loginId, !!isSecondSignatureSurvey)
    } catch (e) { }
    const sortedData = sortData ? {
      orderBy: sortData.name,
      orderByAsc: sortData.direction === "asc",
    } : {};
    const filterData = { ...getAppliedSurveysFilters(filters), ...sortedData };
    getSurveys(rowsPerPage, currentPageInfo.index, searchText, filterData)
    setIsSubmitting(false);
  }



  const getSurveysForPrintAndDownload = async (tableRef) => {
    if (isOnline) {
      const sortDataLocal = sortData ? {
        orderBy: sortData.name,
        orderByAsc: sortData.direction === "asc",
      } : {};
      const columnNames = columns.filter(c => c && c.options && c.options.display !== "excluded").reduce((p, c) => ({ ...p, [c.name]: c.name }), {});
      const data = { ...getAppliedSurveysFilters(filters), ...sortDataLocal };
      const { data: { results } } = await getSurveysApi({
        app,
        rowsPerPage,
        search: searchText,
        ...data
      });
      return results.map(p => {
        return Object.keys(columnNames).reduce((prev, key) => {
          const obj = { ...prev };
          if (key === "internalStatusPlainText") obj[key] = t(SurveyStatus[isSecondSignatureSurvey ? !p.isSecondSignatureInitiated ? 0 : p.secondSignatureDate ? p.status : 1 : p.status])
          else if (key.toLowerCase().includes("date")) obj[key] = formatDate(new Date(p[key]))
          else if (key.toLowerCase().includes("time")) obj[key] = formatTime(p[key])
          else obj[key] = t(p[key]);
          return obj
        }, {});
      });
    } else {
      const { displayData, data: tableData, columns } = tableRef.current.state;
      const data = displayData.map(a => tableData.find(p => p.index === a.dataIndex));
      return data.map(a => {
        const survey = inspections.find((x) => x.surveyId === a.data[0]);
        return columns.reduce((prev, c, i) => {
          const selectedCellData = a.data[i];
          const obj = { ...prev };
          if (c.name.toLowerCase().includes("date")) obj[c.name] = formatDate(new Date(survey[c.name]))
          else if (c.name.toLowerCase().includes("time")) obj[c.name] = survey[c.name] ? formatTime(survey[c.name]) : "-"
          else obj[c.name] = t(selectedCellData);
          return obj
        }, {})
      });
    }
  }


  return (
    <div style={{ height: '100%' }}>
      <Container ref={ref} maxWidth={false} className={classes.mainContainer}>
        <DataGrid
          canListType={canListType}
                  CustomRowRender={
                      matchWidthDownSm && !!canListType ?
                          (rest) => <SurveysListCustomRow
                              moduleType={moduleTitle}
                              onRowClick={handleRowClick}
                              {...props} {...rest} gridDataTitle={gridDataTitle} />
                          : null
                  }
          options={options}
          onRowSelect={(data) => {
            setRowsSelected(data)
          }}
          identifier="surveyId"
          localColumnOrdersIdentifier={generateLocalStorageKey("SurveyList_Table_Order" + gridDataTitle)}
          localColumnSortIdentifier={generateLocalStorageKey("SurveyList_Table_Sort" + gridDataTitle)}
          columns={columns}
          columnsVisibilityIdentifier={generateLocalStorageKey("SurveysList_ColumnsVisibility_" + gridDataTitle)}
          rowsPerPageIdentifier={generateLocalStorageKey("SurveysList_RowsPerPage_" + gridDataTitle)}
          data={inspections}
          title={title}
          loading={getSurveysLoading}
          canGoBack={false}
          getData={getSurveysForPrintAndDownload}
          fontSize={12}
        />
      </Container>
      <DocumentViewer
        radius={classes.docRadius}
        isSurveying
        open={isOpenDocViewer}
        onClose={() => {
          setISOpenDocViewer(false)
          setPreviewDocument([]);
          dispatch(surveyActions.clearOfflineSurveyPDFReport());
        }}
        documents={previewDocument} />

      {isOpenObjectModal &&
        <SelectSingleObjectModal open={isOpenObjectModal}
          buildings={all.filter(p => p.projectId === selected.projectId)}
          onSave={handleSaveObject}
          onClose={handleCloseObjectModal} />
      }
      {openSyncInfoModal && <SyncInfoModal title={title} selectedSurveys={rowsSelected.data} open={openSyncInfoModal}
        onClose={handleInfoSyncClose} />}

      {openReleaseLockConfirmModal && <ReleaseLockConfirmModal
        open={openReleaseLockConfirmModal}
        moduleTitle={moduleTitle}
        onClose={(release) => release ? releaseLockHandler() : setOpenReleaseLockConfirmModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      />}
      {
        openExecutorPopover && <SelectResponsiblePerson
          isSubmitting={isSubmitting}
          isSecondSignature={isSecondSignatureSurvey}
          customTitle={'survey.responsible'}
          surveyIds={rowsSelected.data.map(a => a.surveyId)}
          changeExecutorForSurvey
          hideExtraInfo
          projectId={((inspections && inspections.find(p => p.surveyId === (rowsSelected.data.length && rowsSelected.data[0].surveyId))) || {}).projectId}
          onSubmit={(values) => handleChangeExecutor(values)}
          customOplosser
          setLoading={(val) => {
            setOrganizationsLoading(val)
            setIsSubmitting(true);
          }}
          loading={organizationsLoading}
          anchorEl={openExecutorPopover ? () => anchorElForExecutor.current : null}
          onClose={() => {
            setOpenExecutorPopover(false);
          }} />
      }
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  docRadius: {
    "& > .MuiDialog-container > div:nth-child(1)": {
      borderRadius: 10,
    },
  },
  pdfIcon: {
    fill: red[800],
  },
  pdfButton: {
    padding: 0,
  },
  customTableChip: {
    maxWidth: 120,
    maxHeight: 18,
    fontSize: 12,
    lineHeight: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.only("sm")]: {
      maxWidth: 160,
      maxHeight: 20,
      fontSize: 12,
    },
    [theme.breakpoints.only("xs")]: {
      maxWidth: "fit-content",
      maxHeight: 20,
      fontSize: 12,
    },
  },
  bold: {
    fontWeight: "bold",
  },
  grow: {
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  customMainHeader: {
    // fontSize: 12,
    "& span": {
      width: "fit-content",
    },
    "& .MuiButtonBase-root": {
      [theme.breakpoints.down("md")]: {
        // fontSize: 12,
      },
      // fontSize: 12,
      padding: 0,
      margin: 0,
      whiteSpace: "nowrap ",
    },
  },
  menuContent: {
    "& .MuiPopover-paper": {
      borderRadius: 10,
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    "& ul": {
      padding: theme.spacing(0),
    },
    "& ul > ul": {
      // padding: theme.spacing(0.5, 2),
    },
  },
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    width: 200,
    height: "fit-content",
  },
  mainContainer: {
    height: "100%",
    width: "100%",
    overflow: "auto",
    padding: theme.spacing(0),
    background: theme.palette.common.white,
  },
  iconHeader: {
    display: "flex",
  },
  expandCollapseIcon: {
    fill: theme.palette.grey[700],
    transition: "0.6s all",
    transform: "rotate(0deg)",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  },
  expandLess: {
    transform: "rotate(-180deg)",
  },
  sortListIconStyle: {
    width: 40,
    minWidth: 40,
    justifyContent: "center",
  },
  datePickerContainer: {
    display: "flex",
    "& > div": {
      padding: `${theme.spacing(0)} !important`,
      boxShadow: "none !important",
      width: "100%",
    },
    "& > div > div": {
      height: 35,
      "& input": {
        textOverflow: "ellipsis",
      },
    },
    "& .MuiInputAdornment-root": {
      padding: `${theme.spacing(0)} !important`,
      height: 0,
    },
  },
  shrinkDateLabel: {
    "& > div > label": {
      transform: "translate(0, 28px) scale(1)",
    },
  },
}))

