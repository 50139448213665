import React, { useEffect, useState } from 'react'
import { Container, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import MUIDataTable from 'mui-datatables'
import { useTranslation } from 'react-i18next';
import ImportCufXml from './components/ImportCufXml';
import { getDataTableTextLabels, formatDate } from '../../../_helpers';
import axios from 'axios';
import { LinkColumn } from '../../../components/DataColumns';

export default function ImportCufXmlList() {
    const classes = useStyles();
    const { t } = useTranslation();
    const [openImport, setOpenImport] = useState(false);
    const [importCufXmlList, setImportCufXmlList] = useState([])
    const { webApiUrl } = window.appConfig;
    const [loading, setLoading] = useState({ getCufXmlList: false })

    useEffect(() => {
        updateList();
    }, [])

    const updateList = () => {
        setLoading(p => ({ ...p, getCufXmlList: true }))
        const url = webApiUrl + 'api/ImportExport/GetCufXmlList';
        axios.get(url)
            .then(response => {
                setImportCufXmlList(response.data)
                setLoading(p => ({ ...p, getCufXmlList: false }))
            })
            .catch(error => {
                setLoading(p => ({ ...p, getCufXmlList: false }))
            });
    }

    const columns = [
        {
            name: "id",
            options: {
                display: "excluded",
                filter: false,
                print: false,
                download: false,
            },
        },
        {
            name: 'source',
            label: t('settings.importCufXml.source'),
            options: {
                display: true,
                filter: true,
                sort: true,
                customBodyRender: (v, { rowData }) => {
                    return (
                        <>
                            <Typography>{v.toString()}</Typography>
                        </>
                    )
                }
            }
        },
        {
            name: 'insertedOn',
            label: t('settings.importCufXml.insertedOn'),
            options: {
                display: true,
                filter: true,
                sort: true,
                customBodyRender: (v, { rowData }) => {
                    return (
                        <>
                            <Typography>{formatDate(new Date(v), true)}</Typography>
                        </>
                    )
                }
            }
        },
        {
            name: 'insertedBy',
            label: t('settings.importCufXml.insertedBy'),
            options: {
                display: true,
                filter: true,
                sort: true,
                customBodyRender: (v, { rowData }) => {
                    return (
                        <>
                            <Typography>{v.toString()}</Typography>
                        </>
                    )
                }
            }
        },
        {
            name: 'fileName',
            label: t('settings.importCufXml.fileName'),
            options: {
                display: true,
                filter: true,
                sort: true,
                customBodyRender: (v, { rowData }) => {
                    return (
                        <LinkColumn
                            label={v.toString()}
                            download={v.toString()}
                            target="_blank"
                            to={`${webApiUrl}api/ImportExport/GetCufXmlFile/${rowData[0]}`}
                        />
                    )
                }
            }
        },
    ];

    const options = {
        filterType: 'dropdown',
        draggableColumns: { enabled: true },
        print: true,
        download: true,
        pagination: true,
        textLabels: getDataTableTextLabels(t, loading.getCufXmlList),
        customToolbar: () => {
            return (
                <ImportCufXml
                    canOpen={openImport}
                    onClose={(refresh) => {
                        setOpenImport(false)
                        if (refresh)
                            updateList();
                    }} />
            )
        }
    };

    return (
        <Container maxWidth={false} elevation={0} className={classes.mainContainer}>
            <MUIDataTable
                title={t("settings.importCufXml.title")}
                data={importCufXmlList ? importCufXmlList : []}
                className={classes.dataTable}
                columns={columns}
                options={options}
            />
        </Container>
    )
}


const useStyles = makeStyles((theme) => ({
    '@global': {
        '.MuiButtonBase-root:focus': {
            outline: 0,
        },
    },
    mainContainer: {
        height: '100%',
        width: '100%',
        overflow: 'auto',
        padding: theme.spacing(0),
        background: theme.palette.common.white
    },
    dataTable: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        '& > .MuiPaper-root': {
            flex: '0 1 auto',
        },
        '& > .MuiToolbar-root.MuiToolbar-regular': {
            display: 'flex',
            '& > div': {
                padding: 0,
                textAlign: 'right',
                flex: '1 0 auto',
                '& .MuiTypography-root': {
                    textAlign: 'left'
                }
            }
        },
        '& .MuiTableCell-root': {
            padding: theme.spacing(1, 0.5, 1, 0),
            '&.MuiTableCell-paddingCheckbox': {
                paddingLeft: theme.spacing(0.5),
                '& > div': {
                    justifyContent: 'center'
                },
                '& .MuiCheckbox-root': {
                    padding: theme.spacing(0.25)
                }
            },
            '&.MuiTableCell-head': {
                fontWeight: 'bold'
            }
        },
    },
}))