import { ListItemIcon, Menu, MenuItem } from "@mui/material";

const CellMenu = ({ cellSubMenuOptions, row, anchorEl, handleMenuClose, }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
    >
      {cellSubMenuOptions?.options?.map(({ label, icon, onClick: OnItemClick }) => <MenuItem onClick={() => { OnItemClick(row, cellSubMenuOptions?.extraData); handleMenuClose(); }}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>} {label}
      </MenuItem>)}
    </Menu>
  );
};

export default CellMenu