export const isReadOnly = (status) => status !== 1;

export const SurveyType = {
	PreDelivery: 0,
	Delivery: 1,
	Inspection: 2,
	SecondSignature: 3,
	WKB: 4,
}


export const AgendaAppointmentParticipantStatus = {
	/// <summary>
	/// uitgenodigd
	/// </summary>
	Invited: 0,
	/// <summary>
	/// bevestigd
	/// </summary>
	Confirmed: 1,
	/// <summary>
	/// geweigerd
	/// </summary>
	Refused: 2,
	/// <summary>
	/// geannuleerd
	/// </summary>
	Cancelled: 3
}

export const RepairRequestCarryTypes = {
	0: "Voorschouw",
	1: "Opleverpunt",
	2: "Inspectie",
	3: "Overige",
	4: "Wkb",
}

export const SurveyStatus = {
	0: "survey.status.0",
	1: "survey.status.1",
	2: "survey.status.2",
	3: "survey.status.3"
};

export const internalSurveyingMomentStatus = {
	0: "wkb.surveyingMoment.internalStatus.0",
	1: "wkb.surveyingMoment.internalStatus.1",
	2: "wkb.surveyingMoment.internalStatus.2",
	3: "wkb.surveyingMoment.internalStatus.3"
};

export const internalAssignmentStatus = {
	0: "wkb.assignment.internalStatus.0",
	1: "wkb.assignment.internalStatus.1",
	2: "wkb.assignment.internalStatus.2",
	3: "wkb.assignment.internalStatus.3"
}

export const ModulesConstants = {
	0: "module.0",
	1: "module.1",
	2: "module.2",
	3: "module.3",
	4: "module.4"
};

export const sortTypes = {
	1: "Opleverpunt",
	2: "Inspectie",
	3: "SecondSignature",
	4: "Opdracht",
	0: "PreDelivery",
};

export const tabsViewTypes = {
	0: "day",
	1: "week",
	2: "month",
	3: "agenda",
};

export const viewTypes = {
	day: 0,
	week: 1,
	month: 2,
	agenda: 3,
};

export const AssignmentsSortTypes = {
	0: { label: 'Control Point', key: 'controlPoints' },
	1: { label: 'Assignment Name', key: 'name' },
	2: { label: 'Start Date', key: 'startDate' },
	3: { label: 'End Date', key: 'endDate' },
	4: { label: 'Status', key: 'status' }
};

export const wbMomentStatus = {
	notApproved: 'Niet akkoord',
	approved: 'akkoord',
	notApplicable: 'N.v.t.'
};

export const isCompletedSurvey = (status) => (status === 2 || status === 3);
export const isCompleteAssignment = (status) => (status === 1 || status === 2 || status === 3);
export const getWBStatus = ({ wbStatus, completedAssignments, totalAssignments }) => {
	if (wbStatus) return wbMomentStatus.approved;
	if (completedAssignments !== totalAssignments) return wbMomentStatus.notApplicable;
	if (!wbStatus) return wbMomentStatus.notApproved;
};

export const WKB_API_INTERVAL_TIME = 1000 * 60;


export const statusColors = {
	"In behandeling": "#FA6B00",
	"Nieuw": "#3F51B5",
	"Afgehandeld": "#008135",
}

export const resolverStatus = {
	New: 0,
	Informed: 1,
	Pending: 2,
	TurnedDown: 3,
	Completed: 4,
  };

	export const repairRequestStatusValue = {
		NEW:"Nieuw",
		IN_PROGRESS:"In behandeling",
		COMPLETED : "Afgehandeld",
		TURNED_DOWN :"Afgewezen"
	}