import {
  addUpdateRework,
  completeSignature,
  mapBuildingToWBObject,
  completeSurveyingMoments,
  deleteAssignmentImage,
  getAllSurveys,
  getAssignmentInfo,
  getSurveyDetailApi,
  getObjectsByProjectId,
  getObjectsListByProjectId,
  getProjects,
  getRepairRequestsApi,
  getSurveyMomentInfo,
  sendRecipientsEmails,
  sendRecipientsEmailsSecondSignature,
  updateAssignmentInfo,
  updateResponsiblePerson,
  updateResponsiblePersonForAssignments,
  updateSurveyApi,
  updateSurveyMomentLastView,
  uploadAssignmentFiles,
  internalCompleteSurveyingMoments,
  internalCompleteAssignments,
  completeAssignments,
  getSettlementTerms,
  getApplicationManagerActionItems,
  linkEmailToLogin,
  confirmProjectLinking,
  resetAssignmentStatus,
  getProjectsForLinking,
  getWBProjectInfo,
  startSecondSignature,
  markCompleteRR,
  deleteRepairRequest,
  getAssignmentsByProjectId,
  getSurveys,
  updateExecutorForSurvey,
  getPinLocationImage,
} from "../apis/surveyingApis";
import { surveyConstants } from "../_constants";
import {
  storeInternalCompleteSurveyingMoments,
  updateRepairRequests,
  storeRepairRequests,
  getRepairRequestsFromIDB,
  updateSurveyInDB,
  updateSignatures,
  storeSignatures,
  storeSendingEmails,
  storeAssignmentAttachments,
  getAssignmentsRepairRequestsFromIDB,
  storeCompleteSurveyingMoments,
  storeInternalCompleteAssignments,
  storeCompleteAssignments,
  updateAssignment,
  storeUpdatedSurveyInfo,
  updateSurveyDetails,
  shortenSurveyingMomentTitle,
  updateAssignmentsData,
  storeInternalResetAssignmentsStatus,
  updateExistingMoment,
  updateLastViewOffline,
  storeAssignmentsRepairRequests,
  getUserLockIds,
  getUserLockId,
  getMoment,
  getAssignment,
  deleteMoment,
  deleteSurveyFromDB,
  geSurvey,
  deleteSurveyDetail,
  deleteSurvey,
  getSurveyDetails,
  deleteRepairRequests,
  deleteSSRRChanges,
  removeSecondSignature,
  deleteSignatures,
  deleteSendingEmails,
  deleteAssignmentFromDB,
  storeSSRRChanges,
  startSecondSignatureDB,
  storeUpdatedAssignmentInfo,
  getUpdatedAssignmentInfo,
  putDeletedAssignmentsAttachments,
} from "../_helpers";

export const surveyActions = {
  getSettlementTerm,
  getSurveyingMoments,
  getMomentInfo,
  getAssignmentDetails,
  clearAssignmentDetails,
  removeAssignmentAttachment,
  addAssignmentAttachment,
  uploadAssignmentAttachment,
  mapBuildingObject,
  getObjectList,
  success,
  updateAssignedPersonForAssignment,
  completeMoments,
  updateResponsiblePersonMoments,
  updateAssignmentData,
  getAllObjectsByProjectId,
  getAllProjects,
  customDispatcher,
  getSurveysAction,
  getSurveyDetailAction,
  addSurvey,
  getRepairRequests,
  updateSurvey,
  sendMails,
  completeSurveyingMomentsInternal,
  completeInternalAssignments,
  assignmentsComplete,
  updateSurveyInfo,
  getApplicationManagerActionItem,
  linkEmail,
  confirmProject,
  resetAssignmentStatusAction,
  updateLinkProject,
  getProjectsForLinkingAction,
  getWBProjectInoAction,
  sortAssignments,
  showOfflineSurveyPDFReport,
  clearOfflineSurveyPDFReport,
  startSecondSignatureAction,
  storeRepairRequestsImages,
  addOrUpdateRework,
  markCompleteRRActions,
  deleteRepairRequestAction,
  getAssignmentsList,
  updateExecutorForSurveyAction,
};

function clearOfflineSurveyPDFReport() {
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.CLEAR_PDF_REPORT_DETAILS_DATA }));
  };
}

function storeRepairRequestsImages(data) {
  return (dispatch) => {
    dispatch(success({ type: surveyConstants.STORE_IMAGES, data }));
  };
}

function showOfflineSurveyPDFReport(
  surveyId,
  canGetPinImage,
  isSecondSignature
) {
  const isOnline = window.navigator.onLine;
  return async (dispatch) => {
    dispatch(loading({ type: surveyConstants.GET_PDF_REPORT_DETAILS_LOADING }));
    if (canGetPinImage) {
      try {
        let images = getPinLocationImage(surveyId).then((p) => p.data);
        dispatch(
          success({
            type: surveyConstants.GET_INSPECTION_PIN_IMAGES_SUCCESS,
            images,
          })
        );
      } catch (er) {
        dispatch(
          success({
            type: surveyConstants.GET_INSPECTION_PIN_IMAGES_SUCCESS,
            images: [],
          })
        );
      }
    }
    getSurveyDetailApi(surveyId, isOnline, isSecondSignature)
      .then(async (response) => {
        let resData = isOnline ? response.data : response;
        const survey = await getSurveyDetails({
          surveyId,
          isSecondSignature: isSecondSignature ? 1 : 0,
        });
        if (survey) {
          resData = {
            ...resData,
            ...survey,
            isSecondSignature,
          };
        }
        getRepairRequestsApi(surveyId, isOnline)
          .then(async (res) => {
            res = res.data;
            if (isOnline) {
              const surveyRequests = await getRepairRequestsFromIDB({
                surveyId,
              });
              const data =
                surveyRequests && surveyRequests.filter((r) => r.sync === 0);
              if (resData && data && data.length) res = res.concat(data);
              res = res.map((a) => {
                const isExistData =
                  surveyRequests &&
                  surveyRequests.find((r) => r.requestId === a.requestId);
                if (isExistData)
                  a = { ...a, ...isExistData, resolvers: a.resolvers };
                return a;
              });
            }
            dispatch(
              success({
                type: surveyConstants.GET_PDF_REPORT_DETAIL_SUCCESS,
                data: {
                  inspectionDetail: Object.assign(resData, {
                    isSecondSignature,
                  }),
                  repairRequests: res.sort(
                    (p, c) => new Date(c.date) - new Date(p.date)
                  ),
                },
              })
            );
          })
          .catch((error) => {
            dispatch(
              throwError({
                type: surveyConstants.GET_PDF_REPORT_DETAIL_ERROR,
                error,
              })
            );
          });
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_PDF_REPORT_DETAIL_ERROR,
            error,
          })
        );
      });
  };
}

function addOrUpdateRework(data, requestId) {
  return async (dispatch, getState) => {
    const {
      surveying: { selectedInspection },
    } = getState();
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.ADD_UPDATE_REWORK_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: selectedInspection.surveyId,
      isSecondSignature: selectedInspection.isSecondSignature ? 1 : 0,
    });
    if (!lockInfo) {
      addUpdateRework(data, selectedInspection.surveyId)
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_UPDATE_REWORK_SUCCESS,
              data: { requestId },
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.ADD_UPDATE_REWORK_ERROR, error })
          );
        });
    } else if (lockInfo && lockInfo.lockId === selectedInspection.lockId) {
      let body = {
        surveyId: selectedInspection.surveyId,
        type: "reject",
      };
      for (let key of data.keys()) body[key] = data.get(key);
      storeSSRRChanges(body)
        .then(async () => {
          await updateRepairRequests(
            { requestId },
            {
              completed: false,
              completedByBuyer: false,
              isRework: true,
              status: "In behandeling",
            }
          );
          dispatch(
            success({
              type: surveyConstants.ADD_UPDATE_REWORK_SUCCESS,
              data: { requestId },
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.ADD_UPDATE_REWORK_ERROR, error })
          );
        });
    }
  };
}

function markCompleteRRActions(data) {
  return async (dispatch, getState) => {
    const {
      surveying: { selectedInspection },
    } = getState();
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.COMPLETE_RR_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: selectedInspection.surveyId,
      isSecondSignature: selectedInspection.isSecondSignature ? 1 : 0,
    });

    for (let i = 0; i < data.length; i++) {
      const request = data[i];
      const formData = new FormData();
      formData.append("repairRequestId", request.requestId);
      formData.append("desc", data.length === 1 ? request.remark : "");
      try {
        if (!lockInfo) {
          await markCompleteRR(formData, selectedInspection.surveyId);
        } else if (lockInfo && lockInfo.lockId === selectedInspection.lockId) {
          let body = {
            surveyId: selectedInspection.surveyId,
            type: "accept",
          };
          for (let key of formData.keys()) body[key] = formData.get(key);
          await storeSSRRChanges(body);
        }
        await updateRepairRequests(
          { requestId: request.requestId },
          {
            completed: true,
            completedByBuyer: true,
            isRework: true,
            status: "Afgehandeld",
          }
        );
        dispatch(
          success({
            type: surveyConstants.COMPLETE_MULTI_RR_SUCCESS,
            data: [request],
          })
        );
      } catch (er) {
        console.log(er);
        // dispatch(success({ type: surveyConstants.COMPLETE_MULTI_RR_SUCCESS, data }));
      }
    }
  };
}

function deleteRepairRequestAction(requestId, isMulti) {
  const data = isMulti ? requestId : [requestId];
  return async (dispatch, getState) => {
    const {
      surveying: { selectedInspection },
    } = getState();
    const {
      authentication: { user },
    } = getState();
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: selectedInspection.surveyId,
      isSecondSignature: selectedInspection.isSecondSignature ? 1 : 0,
    });

    dispatch(loading({ type: surveyConstants.DELETE_RR_LOADING }));
    try {
      for (let i = 0; i < data.length; i++) {
        const request = data[i];
        if (lockInfo && lockInfo.lockId === selectedInspection.lockId)
          await deleteRepairRequests({ requestId: request.requestId });
        else await deleteRepairRequest(request.requestId, selectedInspection);
        dispatch(
          success({
            type: surveyConstants.DELETE_RR_SUCCESS,
            data: { requestId: request.requestId },
          })
        );
      }
    } catch (error) {
      dispatch(throwError({ type: surveyConstants.DELETE_RR_ERROR, error }));
    }
  };
}

function updateExecutorForSurveyAction(survey, updatedData) {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_LOADING })
    );
    updateExecutorForSurvey(
      [survey.surveyId],
      updatedData.loginId,
      !!survey.isSecondSignature
    )
      .then(({ data: res }) => {
        dispatch(
          success({
            type: surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_SUCCESS,
            survey,
            updatedData,
          })
        );
        dispatch(
          surveyActions.getSurveyDetailAction(
            survey.surveyId,
            survey.isSecondSignature
          )
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.UPDATE_EXECUTOR_FOR_SURVEY_ERROR,
            error,
          })
        );
      });
  };
}

function getApplicationManagerActionItem() {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.GET_APPLICATION_MANAGER_LOADING })
    );
    getApplicationManagerActionItems()
      .then((applicationItems) => {
        dispatch(
          success({
            type: surveyConstants.GET_APPLICATION_MANAGER_ACTION_ITEMS_SUCCESS,
            applicationItems,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_APPLICATION_MANAGER_ACTION_ITEMS_ERROR,
            error,
          })
        );
      });
  };
}

function sortAssignments(key, sortType) {
  return (dispatch) => {
    dispatch(
      success({
        type: surveyConstants.UPDATE_ASSIGNMENT_SORT_ORDER,
        sortType,
        key,
      })
    );
  };
}

function getProjectsForLinkingAction() {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.GET_PROJECTS_FOR_LINKING_LOADING })
    );
    getProjectsForLinking()
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.GET_PROJECTS_FOR_LINKING_SUCCESS,
            data: res.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_PROJECTS_FOR_LINKING_ERROR,
            error,
          })
        );
      });
  };
}
function linkEmail(email, loginId, oldLoginId, data) {
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.LINK_EMAIL_LOADING }));
    linkEmailToLogin(email, loginId, oldLoginId)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.LINK_EMAIL_SUCCESS,
            email,
            data,
            loginId,
          })
        );
      })
      .catch((error) => {
        dispatch(throwError({ type: surveyConstants.LINK_EMAIL_ERROR, error }));
      });
  };
}
function confirmProject(projectId, wbProjectId) {
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.CONFIRM_PROJECT_LOADING }));
    confirmProjectLinking(projectId, wbProjectId)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.CONFIRM_PROJECT_SUCCESS,
            projectId,
            wbProjectId,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.CONFIRM_PROJECT_ERROR, error })
        );
      });
  };
}
function updateLinkProject(project, wbProjectId) {
  return (dispatch) => {
    dispatch(
      success({
        type: surveyConstants.UPDATE_LINK_PROJECT,
        project,
        wbProjectId,
      })
    );
  };
}
function getSettlementTerm() {
  return (dispatch) => {
    getSettlementTerms()
      .then((res) => {
        dispatch(
          success({ type: surveyConstants.GET_SETTLEMENT_SUCCESS, res })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.GET_SETTLEMENT_ERROR, error })
        );
      });
  };
}
function getSurveyingMoments(projectId, selectedApp, refresh = true) {
  const isOnline = window.navigator.onLine;
  return (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(
      loading({ type: surveyConstants.GET_SURVEYS_MOMENTS_LOADING, refresh })
    );
    getAllSurveys(projectId, isOnline, user.id)
      .then(async (res) => {
        res = isOnline
          ? res.data.map((p) => {
              p.name = shortenSurveyingMomentTitle(p.name);
              return p;
            })
          : res.map((p) => {
              p.name = shortenSurveyingMomentTitle(p.name);
              return p;
            });
        dispatch(
          success({
            type: surveyConstants.GET_SURVEYS_MOMENTS_SUCCESS,
            surveys: res || [],
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.GET_SURVEYS_MOMENTS_ERROR, error })
        );
      });
  };
}

function updateMomentLastView(surveyingMomentId, assignmentId, data) {
  return (dispatch, getState) => {
    const {
      buildings: { selected },
      surveying: { selectedMoment, selectedAssignment },
    } = getState();
    if (window.navigator.onLine)
      updateSurveyMomentLastView(surveyingMomentId, assignmentId).then(
        (res) => {
          dispatch(
            success({
              type: surveyConstants.UPDATE_MOMENT_LAST_VIEW,
              assignmentId,
              surveyingMomentId,
            })
          );
        }
      );
    else {
      updateLastViewOffline(
        surveyingMomentId,
        assignmentId,
        selectedMoment,
        selectedAssignment,
        selected
      )
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.UPDATE_MOMENT_LAST_VIEW,
              assignmentId,
              surveyingMomentId,
            })
          );
        })
        .catch((er) => {
          console.log(er, "er");
        });
    }
  };
}

function getObjectList(projectId) {
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.GET_OBJECTS_LIST_LOADING }));
    getObjectsListByProjectId(projectId)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.GET_OBJECTS_LIST_SUCCESS,
            objects: res.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.GET_OBJECTS_LIST_ERROR, error })
        );
      });
  };
}

function getAssignmentsList(projectId) {
  return (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.GET_ASSIGNMENTS_LOADING }));
    getAssignmentsByProjectId(projectId)
      .then(async (res) => {
        const isOnline = window.navigator.onLine;
        res = res.data;
        if (isOnline) {
          for (let i = 0; i < res.length; i++) {
            const assignment = res[i];
            const assignmentIndex = res.findIndex(
              (a) => a.assignmentId === assignment.assignmentId
            );
            const oldAssignment = await getAssignment(assignment.assignmentId);
            if (oldAssignment) {
              const lockInfo = await getUserLockId({
                userId: user.id,
                unitId: assignment.assignmentId,
              });
              if (lockInfo)
                res[assignmentIndex] = Object.assign(res[assignmentIndex], {
                  isCracked: res[assignmentIndex].lockId !== lockInfo.lockId,
                });
              if (res[assignmentIndex].lockId === lockInfo.lockId) {
                res[assignmentIndex] = {
                  ...res[assignmentIndex],
                  ...oldAssignment,
                };
                await updateAssignment(res[assignmentIndex]);
              } else {
                await deleteMoment({
                  surveyingMomentId:
                    assignment.surveyingMomentDetails.surveyingMomentId,
                  objectId: assignment.surveyingMomentDetails.objectId,
                });
                await deleteSurveyFromDB({
                  surveyingMomentId:
                    assignment.surveyingMomentDetails.surveyingMomentId,
                  objectId: assignment.surveyingMomentDetails.objectId,
                });
                await deleteAssignmentFromDB(assignment.assignmentId);
              }
            }
          }
        }
        const userLockIds = await getUserLockIds({ userId: user.id });
        dispatch(
          success({
            type: surveyConstants.UPDATE_ASSIGNMENTS_LOCK_IDS,
            userLockIds,
          })
        );
        dispatch(
          success({
            type: surveyConstants.GET_ASSIGNMENTS_SUCCESS,
            assignments: res,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.GET_ASSIGNMENTS_ERROR, error })
        );
      });
  };
}

function getMomentInfo(data, moment, refresh = true) {
  const isOnline = window.navigator.onLine;
  return (dispatch, getState) => {
    const {
      authentication: { user },
      //   surveying: { selectedAssignment, selectedMoment },
    } = getState();

    dispatch(loading({ type: surveyConstants.GET_MOMENT_LOADING, refresh }));
    getSurveyMomentInfo(data, isOnline)
      .then(async (res) => {
        if (res.data && isOnline)
          res.data.name = shortenSurveyingMomentTitle(res.data.name);
        else if (!isOnline && res)
          res.name = shortenSurveyingMomentTitle(res.name);
        let data = res ? (isOnline ? res.data : res) : moment;
        if (isOnline) {
          const assignments = data.controlPoints
            .flatMap((p) =>
              p.assignments.map((a) => ({
                ...a,
                controlPointId: p.controlPointId,
              }))
            )
            .flat();
          for (let i = 0; i < assignments.length; i++) {
            let assignment = assignments[i];
            const ctrIndex = data.controlPoints.findIndex(
              (c) => c.controlPointId === assignment.controlPointId
            );
            const lockInfo = await getUserLockId({
              userId: user.id,
              unitId: assignment.assignmentId,
            });
            if (lockInfo)
              assignment = Object.assign(assignment, {
                isCracked: assignment.lockId !== lockInfo.lockId,
              });
            const assignmentIndex = data.controlPoints[
              ctrIndex
            ].assignments.findIndex(
              (a) => a.assignmentId === assignment.assignmentId
            );
            data.controlPoints[ctrIndex].assignments[assignmentIndex] =
              assignment;
          }
          const oldMoment = await getMoment({
            surveyingMomentId: data.surveyingMomentId,
            objectId: data.objectId,
          });
          if (oldMoment) {
            data.controlPoints.forEach((p) => {
              const oldControlPoint = oldMoment.controlPoints.find(
                (c) => c.controlPointId === p.controlPointId
              );
              if (oldControlPoint && oldControlPoint.assignments.length) {
                p.assignments = p.assignments.map((a) => {
                  const assignment =
                    oldControlPoint.assignments.find(
                      (c) => c.assignmentId === a.assignmentId
                    ) || {};
                  return {
                    ...assignment,
                    lockId: a.lockId,
                  };
                });
              }
              return p;
            });
            oldMoment.controlPoints.forEach((p) => {
              const ctrPoint = data.controlPoints.find(
                (c) => c.controlPointId === p.controlPointId
              );
              const crackedAssignments = ctrPoint.assignments.filter(
                (a) => a.isCracked
              );
              if (crackedAssignments.length) p.assignments = [];
              return p;
            });

            if (
              !oldMoment.controlPoints.flatMap((p) => p.assignments).flat()
                .length
            ) {
              await deleteMoment({
                surveyingMomentId: data.surveyingMomentId,
                objectId: data.objectId,
              });
              await deleteSurveyFromDB({
                surveyingMomentId: data.surveyingMomentId,
                objectId: data.objectId,
              });
            } else {
              await updateExistingMoment(oldMoment);
            }
          }
        }
        const userLockIds = await getUserLockIds({ userId: user.id });
        dispatch(
          success({
            type: surveyConstants.UPDATE_ASSIGNMENTS_LOCK_IDS,
            userLockIds,
          })
        );
        dispatch(
          success({ type: surveyConstants.GET_MOMENT_SUCCESS, moment: data })
        );
        dispatch(updateMomentLastView(data.surveyingMomentId));
      })
      .catch((error) => {
        dispatch(throwError({ type: surveyConstants.GET_MOMENT_ERROR, error }));
      });
  };
}

function getAssignmentDetails(
  assignmentId,
  surveyingMomentId,
  objectId,
  refresh = true
) {
  const isOnline = window.navigator.onLine;
  return (dispatch, getState) => {
    const {
      surveying: { selectedMoment },
      authentication: { user },
    } = getState();
    dispatch(
      loading({ type: surveyConstants.GET_ASSIGNMENT_LOADING, refresh })
    );
    getAssignmentInfo(assignmentId, isOnline)
      .then(async (res) => {
        const { repairRequests = [], ...assignmentData } = isOnline
          ? res.data
          : res;
        const requests = repairRequests.map((p) => ({
          ...p,
          assignmentId: assignmentData.assignmentId,
          attachments:
            p.attachments &&
            p.attachments.map((a) => ({ ...a, attachmentId: a.id })),
        }));
        let data = res ? (isOnline ? res.data : res) : null;
        if (isOnline) {
          const assignment = await getAssignment(assignmentId);
          if (assignment)
            data = {
              ...assignment,
              lockId: data.lockId,
              isCracked: assignment.lockId !== data.lockId,
            };
        }
        await storeAssignmentsRepairRequests(requests);
        const userLockIds = await getUserLockIds({ userId: user.id });
        dispatch(
          success({
            type: surveyConstants.UPDATE_ASSIGNMENTS_LOCK_IDS,
            userLockIds,
          })
        );
        dispatch(
          success({
            type: surveyConstants.GET_ASSIGNMENT_SUCCESS,
            assignment: data,
          })
        );
        surveyingMomentId &&
          dispatch(
            updateMomentLastView(selectedMoment.surveyingMomentId, assignmentId)
          );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          throwError({ type: surveyConstants.GET_ASSIGNMENT_ERROR, error })
        );
      });
  };
}

function clearAssignmentDetails() {
  return (dispatch) => {
    dispatch(
      success({
        type: surveyConstants.GET_ASSIGNMENT_SUCCESS,
        assignment: null,
      })
    );
  };
}

function removeAssignmentAttachment(assignmentId, attachmentId) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
      surveying: { selectedAssignment: assignment },
    } = getState();
    dispatch(
      loading({ type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_LOADING })
    );
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: assignmentId,
    });
    if (!lockInfo) {
      deleteAssignmentImage(assignmentId, attachmentId)
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachmentId,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    } else if (lockInfo && lockInfo.lockId === assignment.lockId) {
      putDeletedAssignmentsAttachments({ assignmentId, attachmentId })
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachmentId,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    }
  };
}

function addAssignmentAttachment(data, key) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
      surveying: { selectedAssignment: assignment },
    } = getState();
    const selectedAssignment = Object.assign({}, assignment);
    dispatch(
      loading({ type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_LOADING })
    );
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: data.assignmentId,
    });
    if (!lockInfo) {
      let formData = new FormData();
      formData.append("assignmentId", data.assignmentId);
      data.attachments.forEach((f) =>
        formData.append(
          key ? key : "files",
          key === "attachmentIds" ? f.attachmentId : f
        )
      );
      uploadAssignmentFiles(formData)
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachments: res.data,
            })
          );
          selectedAssignment.assignmentAttachments =
            selectedAssignment.assignmentAttachments.concat(
              res.data.uploadedAttachments
            );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    } else if (lockInfo && lockInfo.lockId === assignment.lockId) {
      storeAssignmentAttachments({
        ...data,
        projectId: selectedAssignment.projectId,
        buildingId: selectedAssignment.buildingId,
        sync: 0,
      })
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachments: { uploadedAttachments: [] },
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    }
  };
}
function uploadAssignmentAttachment(data) {
  return (dispatch) => {
    const isOnline = window.navigator.onLine;
    dispatch(
      loading({ type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_LOADING })
    );
    if (isOnline) {
      let formData = new FormData();
      formData.append("assignmentId", data.assignmentId);
      data.attachments.forEach((f) =>
        formData.append("attachmentIds", f.attachmentId)
      );
      uploadAssignmentFiles(formData)
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachments: res.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    } else {
      storeAssignmentAttachments({ ...data, sync: 0 })
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_ASSIGNMENT_ATTACHMENT_SUCCESS,
              attachments: { uploadedAttachments: [] },
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.REMOVE_ASSIGNMENT_ATTACHMENT_ERROR,
              error,
            })
          );
        });
    }
  };
}

function mapBuildingObject(data) {
  return (dispatch) => {
    // dispatch(loading({ type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_LOADING }));
    mapBuildingToWBObject(data)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.MAP_BUILDING_TO_OBJECT_SUCCESS,
            data: res.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.MAP_BUILDING_TO_OBJECT_ERROR,
            error,
          })
        );
      });
  };
}

function getWBProjectInoAction(projectId) {
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.GET_WB_PROJECT_INFO_LOADING }));
    getWBProjectInfo(projectId)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.GET_WB_PROJECT_INFO_SUCCESS,
            data: res.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({ type: surveyConstants.GET_WB_PROJECT_INFO_ERROR, error })
        );
      });
  };
}

function completeMoments(data) {
  const isOnline = window.navigator.onLine;
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_LOADING })
    );
    if (isOnline) {
      completeSurveyingMoments(data)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_SUCCESS,
              data,
              list: res.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_ERROR,
              error,
            })
          );
        });
    } else {
      storeCompleteSurveyingMoments(data)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_SUCCESS,
              data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.COMPLETE_SURVEYING_MOMENTS_ERROR,
              error,
            })
          );
        });
    }
  };
}

function completeSurveyingMomentsInternal(data) {
  const isOnline = window.navigator.onLine;
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.INTERNAL_SURVEYING_MOMENTS_LOADING })
    );
    if (isOnline) {
      internalCompleteSurveyingMoments(data)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.INTERNAL_SURVEYING_MOMENTS_SUCCESS,
              data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.INTERNAL_SURVEYING_MOMENTS_ERROR,
              error,
            })
          );
        });
    } else {
      storeInternalCompleteSurveyingMoments(data)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.INTERNAL_SURVEYING_MOMENTS_SUCCESS,
              data: res.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.INTERNAL_SURVEYING_MOMENTS_ERROR,
              error,
            })
          );
        });
    }
  };
}

function completeInternalAssignments(data) {
  return async (dispatch, getState) => {
    dispatch(
      loading({ type: surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_LOADING })
    );
    const {
      surveying: { selectedAssignment, assignments },
    } = getState();

    const assignmentsGroups = data.reduce(
      (p, assignmentId) => {
        const assignment = assignments.find(
          (a) => a.assignmentId === assignmentId
        );
        if (assignment.lockId)
          return { ...p, locked: [...p.locked, assignmentId] };
        else return { ...p, unlocked: [...p.unlocked, assignmentId] };
      },
      { locked: [], unlocked: [] }
    );

    const keys = Object.keys(assignmentsGroups);

    for (let i = 0; i < keys.length; i++) {
      const assignments = assignmentsGroups[keys[i]];
      try {
        if (assignments && assignments.length && keys[i] === "unlocked") {
          const res = await internalCompleteAssignments(assignments);
          dispatch(
            success({
              type: surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_SUCCESS,
              data: res.data,
              assignmentIds: assignments,
            })
          );
        } else if (assignments && assignments.length && keys[i] === "locked") {
          await storeInternalCompleteAssignments(
            assignments,
            selectedAssignment
          );
          dispatch(
            success({
              type: surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_SUCCESS,
              assignmentIds: assignments,
            })
          );
        }
      } catch (error) {
        dispatch(
          throwError({
            type: surveyConstants.INTERNAL_COMPLETE_ASSIGNMENT_ERROR,
            error,
          })
        );
      }
    }
  };
}
function resetAssignmentStatusAction(assignmentIds) {
  return async (dispatch, getState) => {
    dispatch(
      loading({ type: surveyConstants.RESET_ASSIGNMENT_STATUS_LOADING })
    );
    const {
      surveying: { selectedMoment, selectedAssignment, assignments },
    } = getState();

    const assignmentsGroups = assignmentIds.reduce(
      (p, assignmentId) => {
        const assignment = assignments.find(
          (a) => a.assignmentId === assignmentId
        );
        if (assignment.lockId)
          return { ...p, locked: [...p.locked, assignmentId] };
        else return { ...p, unlocked: [...p.unlocked, assignmentId] };
      },
      { locked: [], unlocked: [] }
    );

    const keys = Object.keys(assignmentsGroups);

    for (let i = 0; i < keys.length; i++) {
      const assignments = assignmentsGroups[keys[i]];
      try {
        if (assignments && assignments.length && keys[i] === "unlocked")
          await resetAssignmentStatus(assignments);
        else if (assignments && assignments.length && keys[i] === "locked")
          await storeInternalResetAssignmentsStatus(
            assignmentIds,
            selectedMoment,
            selectedAssignment
          );
        dispatch(
          success({
            type: surveyConstants.RESET_ASSIGNMENT_STATUS_SUCCESS,
            assignmentIds: assignments,
          })
        );
      } catch (error) {
        dispatch(
          throwError({
            type: surveyConstants.RESET_ASSIGNMENT_STATUS_ERROR,
            error,
          })
        );
      }
    }
  };
}

function assignmentsComplete(data) {
  const isOnline = window.navigator.onLine;

  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.COMPLETE_ASSIGNMENT_LOADING }));
    if (isOnline) {
      completeAssignments(data)
        .then(async (res) => {
          for (let i = 0; i < data.length; i++) {
            const assignment = await getAssignment(data[i]);
            if (assignment)
              await updateAssignment({
                ...assignment,
                internalStatus: 2,
                sync: 0,
              });
          }
          dispatch(
            success({
              type: surveyConstants.COMPLETE_ASSIGNMENT_SUCCESS,
              data: res.data,
              assignmentIds: data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.COMPLETE_ASSIGNMENT_ERROR,
              error,
            })
          );
        });
    } else {
      storeCompleteAssignments(data)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.COMPLETE_ASSIGNMENT_SUCCESS,
              data: res.data,
              assignmentIds: data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.COMPLETE_ASSIGNMENT_ERROR,
              error,
            })
          );
        });
    }
  };
}

function updateAssignedPersonForAssignment(data, selected, isMulti, orgId) {
  return (dispatch, getState) => {
    const {
      app,
      surveying: { selectedMoment },
    } = getState();
    dispatch(
      loading({ type: surveyConstants.UPDATE_RESPONSIBLE_PERSON_LOADING })
    );
    updateResponsiblePersonForAssignments(
      data,
      selected,
      isMulti,
      app,
      Object.assign({}, selectedMoment),
      orgId
    )
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.UPDATE_RESPONSIBLE_PERSON_ASSIGNMENT_SUCCESS,
            data: res.data,
            selected,
            isMulti,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.UPDATE_RESPONSIBLE_PERSON_ERROR,
            error,
          })
        );
      });
  };
}

function updateResponsiblePersonMoments(data, selected, isMulti) {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.UPDATE_PERSON_FOR_MOMENT_LOADING })
    );
    updateResponsiblePerson(data)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.UPDATE_PERSON_FOR_MOMENT_SUCCESS,
            data: res.data,
            selected,
            isMulti,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.UPDATE_PERSON_FOR_MOMENT_ERROR,
            error,
          })
        );
      });
  };
}

function updateAssignmentData(data, answerOption) {
  return async (dispatch, getState) => {
    const {
      authentication: {
        user: { name, ...user },
      },
      surveying: { selectedMoment, selectedAssignment },
    } = getState();
    dispatch(loading({ type: surveyConstants.UPDATE_ASSIGNMENT_INFO_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: selectedAssignment.assignmentId,
    });
    if (!lockInfo) {
      updateAssignmentInfo(data, name)
        .then(async (res) => {
          dispatch(
            success({
              type: surveyConstants.UPDATE_ASSIGNMENT_INFO_SUCCESS,
              data,
              senderName: name,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.UPDATE_ASSIGNMENT_INFO_ERROR,
              error,
            })
          );
        });
    } else if (lockInfo && lockInfo.lockId === selectedAssignment.lockId) {
      const { assignmentId, ...storeData } = data;
      let payload = {
        assignmentId: selectedAssignment.assignmentId,
        fieldId: Object.keys(storeData)[0],
        data: storeData,
        senderName: name,
      };
      if (Object.keys(storeData)[0] === "message") {
        const assignmentData = await getUpdatedAssignmentInfo({ assignmentId });
        if (assignmentData) {
          const index = assignmentData.findIndex(
            (a) => a.fieldId === "message"
          );
          if (index >= 0)
            payload.data = Array.isArray(assignmentData[index].data)
              ? assignmentData[index].data.concat(payload.data)
              : [assignmentData[index].data, payload.data];
        } else {
          payload.data = [payload.data];
        }
      }
      storeUpdatedAssignmentInfo(payload)
        .then(async () => {
          dispatch(
            success({
              type: surveyConstants.UPDATE_ASSIGNMENT_INFO_SUCCESS,
              data,
              senderName: name,
            })
          );
          await updateAssignmentsData(data, name, selectedMoment, answerOption);
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.UPDATE_ASSIGNMENT_INFO_ERROR,
              error,
            })
          );
        });
    }
  };
}

function getAllObjectsByProjectId(projectId) {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_LOADING })
    );
    getObjectsByProjectId(projectId)
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_SUCCESS,
            data: res.data.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_ERROR,
            error,
          })
        );
      });
  };
}

function getAllProjects() {
  return (dispatch) => {
    dispatch(
      loading({ type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_LOADING })
    );
    getProjects()
      .then((res) => {
        dispatch(
          success({
            type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_SUCCESS,
            data: res.data,
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_ALL_OBJECTS_BY_PROJECTID_ERROR,
            error,
          })
        );
      });
  };
}

function getSurveysAction(data) {
  const { isSecondSignature } = data;
  const isOnline = window.navigator.onLine;
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.GET_INSPECTIONS_LOADING }));
    getSurveys(data)
      .then(async (res) => {
        const { results, ...response } = Object.assign(
          {},
          isOnline ? res.data : {}
        );
        res = isOnline
          ? res.data.results.map((p) => ({
              ...p,
              isSecondSignature: isSecondSignature ? 1 : 0,
            }))
          : res;
        if (isOnline) {
          for (let i = 0; i < res.length; i++) {
            let survey = res[i];
            const lockInfo = await getUserLockId({
              userId: user.id,
              unitId: survey.surveyId,
              isSecondSignature: isSecondSignature ? 1 : 0,
            });
            const surveyRequests = await getRepairRequestsFromIDB({
              surveyId: survey.surveyId,
            });
            if (lockInfo && lockInfo.userId === user.id) {
              survey = Object.assign(survey, {
                isCracked: survey.lockId !== lockInfo.lockId,
                repairRequestsCount: surveyRequests.length,
              });
            }
            const oldSurvey = await geSurvey({
              surveyId: survey.surveyId,
              isSecondSignature: isSecondSignature ? 1 : 0,
              surveyType: survey.surveyType,
            });
            if (oldSurvey) {
              if (survey.isCracked) {
                await deleteSurveyDetail({
                  surveyId: survey.surveyId,
                  isSecondSignature: isSecondSignature ? 1 : 0,
                  surveyType: survey.surveyType,
                });
                await deleteSurvey({
                  surveyId: survey.surveyId,
                  isSecondSignature: isSecondSignature ? 1 : 0,
                  surveyType: survey.surveyType,
                });
                await deleteRepairRequests({ surveyId: survey.surveyId });
                await deleteSSRRChanges({ surveyId: survey.surveyId });
                await removeSecondSignature({ surveyId: survey.surveyId });
                await deleteSignatures({
                  surveyType: survey.surveyType,
                  surveyId: survey.surveyId,
                  isSecondSignature: oldSurvey.isSecondSignature ? 1 : 0,
                });
                await deleteSendingEmails({
                  surveyType: survey.surveyType,
                  surveyId: survey.surveyId,
                  isSecondSignature: oldSurvey.isSecondSignature ? 1 : 0,
                });
                res[i] = survey;
              } else {
                res[i] = {
                  ...oldSurvey,
                  repairRequestsCount: surveyRequests.length,
                };
              }
            }
          }
        } else {
          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            const surveyRequests = await getRepairRequestsFromIDB({
              surveyId: element.surveyId,
            });
            res[index] = {
              ...element,
              repairRequestsCount: surveyRequests.length,
            };
          }
        }
        const userLockIds = await getUserLockIds({ userId: user.id });
        dispatch(
          success({
            type: surveyConstants.UPDATE_ASSIGNMENTS_LOCK_IDS,
            userLockIds,
          })
        );
        dispatch(
          success({
            type: surveyConstants.GET_INSPECTIONS_SUCCESS,
            pageMetaData: response,
            data: res,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        dispatch(
          throwError({ type: surveyConstants.GET_INSPECTIONS_ERROR, error })
        );
      });
  };
}

function getSurveyDetailAction(surveyId, isSecondSignature = false) {
  const isOnline = window.navigator.onLine;
  return (dispatch) => {
    dispatch(loading({ type: surveyConstants.GET_INSPECTION_DETAIL_LOADING }));
    getSurveyDetailApi(surveyId, isOnline, isSecondSignature)
      .then(async (response) => {
        let res = isOnline ? response.data : response;
        if (isOnline) {
          const oldSurvey = await geSurvey({
            surveyId: res.surveyId,
            surveyType: res.surveyType,
            isSecondSignature: +isSecondSignature,
          });
          if (oldSurvey) {
            if (res.lockId !== oldSurvey.lockId) {
              await deleteSurveyDetail({
                surveyId: res.surveyId,
                surveyType: res.surveyType,
              });
              await deleteSurvey({
                surveyId: res.surveyId,
                surveyType: res.surveyType,
              });
              await deleteRepairRequests({ surveyId: res.surveyId });
              await deleteSSRRChanges({ surveyId: res.surveyId });
              await removeSecondSignature({ surveyId });
              await deleteSignatures({
                surveyType: res.surveyType,
                surveyId: res.surveyId,
                isSecondSignature: oldSurvey.isSecondSignature ? 1 : 0,
              });
              await deleteSendingEmails({
                surveyType: res.surveyType,
                surveyId: res.surveyId,
                isSecondSignature: oldSurvey.isSecondSignature ? 1 : 0,
              });
            }
            const survey = await getSurveyDetails({
              surveyId,
              isSecondSignature: isSecondSignature ? 1 : 0,
            });
            if (survey) {
              res = {
                ...res,
                isSecondSignature,
                ...survey,
              };
            }
          }
        }
        dispatch(
          success({
            type: surveyConstants.GET_INSPECTION_DETAIL_SUCCESS,
            data: Object.assign(res, { isSecondSignature }),
          })
        );
      })
      .catch((error) => {
        dispatch(
          throwError({
            type: surveyConstants.GET_INSPECTION_DETAIL_ERROR,
            error,
          })
        );
      });
  };
}

function getRepairRequests(id, isAssignmentRequest) {
  const isOnline = window.navigator.onLine;
  return async (dispatch) => {
    dispatch(loading({ type: surveyConstants.GET_REPAIR_REQUEST_LOADING }));
    if (isAssignmentRequest) {
      getAssignmentsRepairRequestsFromIDB({ assignmentId: id })
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.GET_REPAIR_REQUEST_SUCCESS,
              data: res.sort((p, c) => new Date(c.date) - new Date(p.date)),
            })
          );
        })
        .catch((er) => {
          dispatch(
            throwError({ type: surveyConstants.GET_REPAIR_REQUEST_ERROR, er })
          );
        });
    } else {
      getRepairRequestsApi(id, isOnline)
        .then(async (res) => {
          res = res.data;
          if (isOnline) {
            const surveyRequests = await getRepairRequestsFromIDB({
              surveyId: id,
            });
            const inspection = await getSurveyDetails({ surveyId: id });
            const data =
              surveyRequests && surveyRequests.filter((r) => r.sync === 0);
            if (data && data.length) res = res.concat(data);
            res = res.map((a) => {
              const isExistData =
                surveyRequests &&
                surveyRequests.find((r) => r.requestId === a.requestId);
              if (isExistData)
                a = { ...a, ...isExistData, resolvers: a.resolvers };
              return a;
            });
            if (inspection) {
              await storeRepairRequests(res);
            }
          }
          dispatch(
            success({
              type: surveyConstants.GET_REPAIR_REQUEST_SUCCESS,
              data: res.sort((p, c) => new Date(c.date) - new Date(p.date)),
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({
              type: surveyConstants.GET_REPAIR_REQUEST_ERROR,
              error,
            })
          );
        });
    }
  };
}

function startSecondSignatureAction(surveyId) {
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    const {
      surveying: { selectedInspection },
    } = getState();
    dispatch(loading({ type: surveyConstants.START_SIGNATURE_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: surveyId,
      isSecondSignature: selectedInspection.isSecondSignature ? 1 : 0,
    });
    if (!lockInfo) {
      startSecondSignature(surveyId)
        .then((res) => {
          dispatch(
            throwError({ type: surveyConstants.START_SIGNATURE_SUCCESS })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.START_SIGNATURE_ERROR, error })
          );
        });
    } else if (lockInfo && lockInfo.lockId === selectedInspection.lockId) {
      startSecondSignatureDB(surveyId)
        .then(() => {
          dispatch(
            throwError({ type: surveyConstants.START_SIGNATURE_SUCCESS })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.START_SIGNATURE_ERROR, error })
          );
        });
    }
  };
}
function updateSurvey(
  { offlineReport = {}, ...data },
  surveyType,
  isSecondSignature,
  sync = 0
) {
  const isOnline = window.navigator.onLine;
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.UPDATE_SURVEY_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: data.surveyId,
      isSecondSignature: isSecondSignature ? 1 : 0,
    });
    try {
      if (!lockInfo) {
        await (!isSecondSignature ? updateSurveyApi : completeSignature)(
          data,
          isOnline
        );
      } else if (lockInfo && lockInfo.lockId === data.lockId) {
        await storeSignatures({
          ...data,
          offlineReport,
          surveyType,
          sync,
          isSecondSignature: isSecondSignature ? 1 : 0,
        });
        await updateSurveyInDB(
          {
            surveyType,
            surveyId: data.surveyId,
            isSecondSignature: isSecondSignature ? 1 : 0,
          },
          data
        );
        await updateSurveyDetails(
          {
            surveyType,
            surveyId: data.surveyId,
            isSecondSignature: isSecondSignature ? 1 : 0,
          },
          { ...data, offlineReport }
        );
      }
      dispatch(
        success({
          type: surveyConstants.UPDATE_SURVEY_SUCCESS,
          data: { ...data, offlineReport },
        })
      );
    } catch (error) {
      dispatch(
        throwError({ type: surveyConstants.UPDATE_SURVEY_ERROR, error })
      );
    }
  };
}

function updateSurveyInfo(data, surveyType, fieldKey) {
  const isOnline = window.navigator.onLine;
  return async (dispatch, getState) => {
    const {
      authentication: { user },
    } = getState();
    dispatch(loading({ type: surveyConstants.UPDATE_SURVEY_LOADING }));
    const updatedData = Object.keys(data).reduce(
      (p, c) => (c === "surveyId" ? { ...p } : { ...p, [c]: data[c] }),
      {}
    );
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: data.surveyId,
      isSecondSignature: data.isSecondSignature ? 1 : 0,
    });
    if (!lockInfo) {
      updateSurveyApi(data, isOnline)
        .then(async (res) => {
          dispatch(
            success({ type: surveyConstants.UPDATE_SURVEY_SUCCESS, data })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.UPDATE_SURVEY_ERROR, error })
          );
        });
    } else if (lockInfo && lockInfo.lockId === data.lockId) {
      storeUpdatedSurveyInfo({
        ...data,
        surveyType,
        fieldKey,
      })
        .then(async (res) => {
          await updateSignatures(
            {
              surveyType,
              surveyId: data.surveyId,
              sync: 0,
              isSecondSignature: 0,
            },
            updatedData
          );
          await updateSurveyInDB(
            { surveyType, surveyId: data.surveyId },
            updatedData
          );
          await updateSurveyDetails(
            { surveyId: data.surveyId, surveyType, isSecondSignature: 0 },
            updatedData
          );
          dispatch(
            success({ type: surveyConstants.UPDATE_SURVEY_SUCCESS, data })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.UPDATE_SURVEY_ERROR, error })
          );
        });
    }
  };
}

function addSurvey(data) {
  const isOnline = window.navigator.onLine;
  return async (dispatch) => {
    dispatch(loading({ type: surveyConstants.ADD_SURVEY_LOADING }));
    if (isOnline) {
      updateSurveyApi(data, isOnline)
        .then((res) => {
          dispatch(
            success({
              type: surveyConstants.ADD_SURVEY_SUCCESS,
              data: res.data,
            })
          );
        })
        .catch((error) => {
          dispatch(
            throwError({ type: surveyConstants.ADD_SURVEY_ERROR, error })
          );
        });
    }
  };
}

function sendMails(surveyId, data, surveyType, isSecondSignature) {
  const isOnline = window.navigator.onLine;
  return async (dispatch, getState) => {
    const {
      authentication: { user },
      surveying: { selectedInspection },
    } = getState();
    dispatch(loading({ type: surveyConstants.SENDING_EMAILS_LOADING }));
    const lockInfo = await getUserLockId({
      userId: user.id,
      unitId: surveyId,
      isSecondSignature: selectedInspection.isSecondSignature ? 1 : 0,
    });
    try {
      let res = null;
      if (!lockInfo) {
        res = await (!isSecondSignature
          ? sendRecipientsEmails
          : sendRecipientsEmailsSecondSignature)(surveyId, data, isOnline);
      } else if (lockInfo && lockInfo.lockId === selectedInspection.lockId) {
        await storeSendingEmails({
          emails: data,
          surveyId,
          surveyType,
          sync: 0,
          isSecondSignature: +isSecondSignature,
        });
        await updateSurveyInDB(
          {
            surveyType,
            surveyId: data.surveyId,
            isSecondSignature: +isSecondSignature,
          },
          { ...selectedInspection, status: 3 }
        );
        await updateSurveyDetails(
          {
            surveyType,
            surveyId: data.surveyId,
            isSecondSignature: +isSecondSignature,
          },
          { ...selectedInspection, status: 3 }
        );
        res = { data: { surveryStatus: 3 } };
      }
      dispatch(
        success({
          type: surveyConstants.SENDING_EMAILS_SUCCESS,
          data: res.data,
          surveyId,
        })
      );
    } catch (error) {
      dispatch(
        throwError({ type: surveyConstants.SENDING_EMAILS_ERROR, error })
      );
    }
  };
}

function loading(data) {
  return { ...data };
}
function throwError(data) {
  return { ...data };
}
function success(data) {
  return { ...data };
}

function customDispatcher(data) {
  return { ...data };
}
