import { Avatar, Checkbox, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import clsx from "clsx";
import { formatDate, history } from "../../../_helpers";
import { LocationOnOutlined, PriorityHigh, Schedule } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useRowStyles } from "../../../components/CustomRowStyles";

export const SurveyingMomentCustomRow = (props) => {
    const { onRowClick, setRowsSelected, rowsSelected, columns, data, identifier, currentRow, dataIndex } = props;
    const classes = { ...useRowStyles(), ...useStyles() };
    const { t } = useTranslation();

    const currentRowWithIndex = currentRow.map((columnData, index) => ({ columnData, index }));
    const selectedRow = data ? data.find(d => d[identifier] === currentRowWithIndex[0].columnData) : {};

    if (!selectedRow || !columns.length) return "";
    
    return (
        <React.Fragment >
                <TableRow onClick={(e) => {
                    e.stopPropagation();
                    onRowClick(selectedRow);
                }} className={clsx(classes.customGridRow)}>
                    <TableCell>
                        <div className={classes.customSmallDeviceRowContainer}>
                            <div className={classes.verticallyCenterContainer}>
                                <div className={classes.relativeContainer}>
                                    <Avatar
                                        className={clsx(classes.thumbnail, classes.avatarThumbnail, 'thumb-exception')} >
                                        {isNaN(selectedRow.object) ? selectedRow.object[0] : selectedRow.object}
                                    </Avatar>
                                </div>
                            </div>
                            <div className={classes.columnContainer}>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={clsx(classes.requestTitle, classes.grow)}>
                                            {selectedRow.name && selectedRow.name}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography noWrap className={classes.flexContainer}>
                                            {selectedRow.hasUpdate && (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.actionRequired")}</Typography>}>
                                                        <PriorityHigh color="secondary" fontSize="small" size="small" />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            )}
                                            {selectedRow.overdue ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.tooLate")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" color="error" className={classes.marginRight_5} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : selectedRow.is48HoursReminder ? (
                                                <>
                                                    <Tooltip title={<Typography>{t("general.48hours")}</Typography>}>
                                                        <Schedule fontSize="small" size="small" className={clsx(classes.warning, classes.marginRight_5)} />
                                                    </Tooltip>
                                                    <div className={classes.borderRight} />
                                                </>
                                            ) : ("")}
                                            <Checkbox size="small" className={clsx(classes.customCheckbox, classes.noPaddingContainer)} checked={rowsSelected.list.includes(dataIndex)} onChange={(e) => {
                                                const list = Object.assign([], rowsSelected.list);
                                                const isExist = list.findIndex(p => p === dataIndex);
                                                const rowsData = Object.assign([], rowsSelected.data);
                                                if (isExist >= 0) {
                                                    list.splice(isExist, 1)
                                                    rowsData.splice(isExist, 1)
                                                } else {
                                                    rowsData.push({ [identifier]: selectedRow[identifier] })
                                                    list.push(dataIndex);
                                                };

                                                setRowsSelected({ ...rowsSelected, data: rowsData, list });
                                            }} color='primary' onClick={(e) => e.stopPropagation()} />
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={classes.requestTypeLabel}>
                                            {`${selectedRow.completedAssignments}/${selectedRow.totalAssignments} ${t("wkb.assignments.label")}`}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography
                                            className={classes.requestStatusLabel}
                                            style={{ color: selectedRow.completed ? "#2DA800" : selectedRow.overdue ? "#f00" : "#5C5C5C" }}>
                                            {t(selectedRow.internalStatusPlainText)}
                                        </Typography>
                                    </div>
                                </div>
                                <div className={clsx(classes.detailContainer, classes.descriptionContainer, classes.maxWidth_100)}>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel}>
                                            <div className={classes.locationIconContainer}>
                                                <LocationOnOutlined className={classes.locationIcon} />
                                            </div>
                                            <span>
                                                {selectedRow.object}
                                            </span>
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography className={classes.requestBuildingLabel} >
                                            {formatDate(new Date(selectedRow.startDate))} - {formatDate(new Date(selectedRow.endDate))}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TableCell>
                </TableRow>
        </React.Fragment>
    );
};

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1
    },
    descriptionContainer: {
        flexDirection: "row"
    },
    offlineIconContainer: {
        position: "absolute",
        right: 0,
        top: 0
    },
    offlineIcon: {
        fontSize: 14,
        fill: theme.palette.common.white
    },
    avatarThumbnail: {
        background: theme.palette.primary.main,
        fontSize: 15,
        fontWeight: 'bold',
        borderRadius: 6,
        boxShadow: "0px 0px 3px #eee"
    },
    imageBadgeContainer1: {
        bottom: 0,
        right: 0,
        borderRadius: 0,
        borderBottomRightRadius: 6,
        borderTopLeftRadius: 6,
        fontSize: 10,
        fontWeight: "bold",
        textTransform: "capitalize"
    },
    overflowEllipse: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": 1,
        "-webkit-box-orient": "vertical",
    }
}));