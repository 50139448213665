import { Box } from "@mui/material";
import {
  ContentCopy,
  Image,
  MapOutlined,
  PriorityHigh,
  Schedule,
  FilterAlt
} from "@mui/icons-material";
import * as XLSX from "xlsx";

import { LOCAL_STORAGE_GRID_KEYS, userAccountRoleConstants } from "../../../_constants";
import {
  LabelColumnV2,
  LinkColumnV2,
  MultiLabelColumnV2,
  PriorityIconColumnV2,
  Overdue,
  Attention,
  HoverImage
} from "../../../components/DataColumnsV2";
import { formatDate, nl2br, setLocalStorageItem } from "../../../_helpers";

export const getAfterCareRepairRequestColumns = ({
  t,
  listToMapRedirectHandler,
  projectNames,
  locations,
  carryOutAsTypeList,
  statusList,
  organizations,
  classes,
  handleCellFilter,
}) => {
  const options = [
    {
      label: t("general.copy"),
      icon:
        <ContentCopy fontSize="small" />,
      onClick: (rowData, extraData) => {
        let text = ""
        switch (extraData.fieldName) {
          case "projectId":
            text = rowData.projectName
            break;
          case "date":
            text = formatDate(new Date(rowData.date))
            break;
          case "targetCompletionDate":
            text = formatDate(new Date(rowData.targetCompletionDate))
            break;
          case "resolvers":
            text = rowData.resolvers?.map(r => r.name).join(",")
            break;
          default:
            text = rowData[extraData.fieldName]
            break;
        }
        navigator.clipboard.writeText(text)
      }
    },
    {
      label: t("general.quickFilter"),
      icon: <FilterAlt />,
      onClick: (rowData, extraData) => {
        let value = ""
        const quickFilters = []
        const addFilter = ({ value, fieldId, type }) => quickFilters.push({ fieldId: fieldId || extraData.fieldName, value, operator: Array.isArray(value) && value.length > 1 ? "isAnyOf" : undefined, type: type || extraData.type })

        switch (extraData.fieldName) {
          case "date":
            value = formatDate(new Date(rowData.date)).split("-").reverse().join("-")
            addFilter({ value })
            break;
          case "targetCompletionDate":
            value = formatDate(new Date(rowData.targetCompletionDate)).split("-").reverse().join("-")
            addFilter({ value })
            break;
          case "locationId":
            value = locations?.find(building => building?.label === rowData[extraData.fieldName])?.value
            addFilter({ value })
            break;
          case "carryOutAsTypeId":
            value = carryOutAsTypeList?.find(building => building?.label === rowData[extraData.fieldName])?.value
            addFilter({ value })
            break;
          case "statusId":
            value = statusList?.find(building => building?.label === rowData[extraData.fieldName])?.value
            addFilter({ value })
            break;
          case "resolvers":
            const resolverIds = new Set(rowData[extraData.fieldName]?.map((resolver) => resolver.organisatonId) || [])
            value = [...resolverIds]
            addFilter({ value })
            break;
          case "priority":
          case "overdue":
            value = rowData[extraData.fieldName]?.toString()
            addFilter({ value })
            break;
          case "isRequiredHandling":
            value = rowData?.isRequiredHandling?.toString()
            addFilter({ value })
            break;
          default:
            value = rowData[extraData.fieldName]
            addFilter({ value })
            break;
        }
        handleCellFilter(quickFilters, (filterModal) => {
          setLocalStorageItem(
            LOCAL_STORAGE_GRID_KEYS.REPAIR_REQUEST_COLUMN_FILTER,
            filterModal
          );
        })
      }
    }]
  return [
    {
      download: true,
      print: true,
      field: "projectId",
      headerName: t("general.projectName"),
      minWidth: 175,
      flex: 1,
      align: "start",
      hidden: true,
      renderCell: (params) => {
        const request = params.row;
        return (
          <LabelColumnV2
            label={request.projectName}
            cellSubMenuOptions={{ options, extraData: { fieldName: "projectId", type: "singleSelect" } }}
            request={request}
          />
        );
      },

      type: "singleSelect",
      valueOptions: projectNames,
    },
    {
      download: false,
      print: true,
      field: "isRequiredHandling",
      headerName: t("general.attention.label"),
      maxWidth: 70,
      minWidth: 60,
      resizable: false,
      sortable: false,
      flex: 1,
      type: "boolean",
      renderHeader: () => (
        <Box mr={0.6}>
          <PriorityHigh color="secondary" size="small" />
        </Box>
      ),
      renderCell: (params) => {
        const request = params.row;
        return (
          <Attention request={request} t={t} options={options} classes={classes} />
        );
      },
    },
    {
      download: false,
      print: true,
      field: "overdue",
      headerName: t("general.overdue"),
      minWidth: 70,
      maxWidth: 70,
      resizable: false,
      sortable: false,
      flex: 1,
      type: "boolean",
      renderHeader: () => (
        <Box mr={0.6}>
          <Schedule fontSize="small" />
        </Box>
      ),
      renderCell: (params) => {
        const request = params.row;
        return (
          <Overdue request={request} t={t} options={options} classes={classes} />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "projectNo",
      headerName: t("general.projectNumber"),
      editable: false,
      flex: 1,
      minWidth: 55,
      align: "start",
      renderCell: (params) => {
        const request = params.row;
        const field = params.field;
        return (
          <LabelColumnV2
            label={request[field === "projectId" ? "projectName" : field]}
            cellSubMenuOptions={{ options, extraData: { fieldName: "projectNo" } }}
            request={request}
          />
        );
      },
    },
    {
      download: false,
      print: true,
      field: "attachments",
      headerName: t("general.image"),
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "start",
      filterable: false,
      minWidth: 45,
      maxWidth: 60,
      sortable: false,
      renderCell: (params) => {
        const imageId = params?.row?.attachments?.[0]?.attachmentId;
        return (
          <HoverImage defaultSize={20} imageId={imageId} index={params.id} />
        );
      },
      renderHeader: () => {
        return (
          <Box component="div" textAlign="center">
            <Image />
          </Box>
        );
      },
    },
    {
      download: true,
      print: true,
      field: "number",
      headerName: t("general.number"),
      editable: false,
      flex: 1,
      align: "start",
      minWidth: 80,
      renderCell: (params) => {
        const request = params.row;
        return (
          <LinkColumnV2
            disabled={request.disabled}
            label={request.number}
            to={`/nazorg/${request.isAfterCareEmployeeOrSiteManager ? "melding" : "details"
              }/${request.requestId}`}
            cellSubMenuOptions={{ options, extraData: { fieldName: "number" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "date",
      type: "date",
      headerName: t("general.date"),
      minWidth: 83,
      flex: 1,
      align: "start",
      valueGetter: (params) => {
        const request = params.row;
        if (!request) return "";
        return new Date(request.date);
      },
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return <LabelColumnV2 date={request.date} cellSubMenuOptions={{ options, extraData: { fieldName: "date", type: "date" } }}
          request={request} />;
      },
    },
    {
      download: true,
      print: true,
      field: "buildingNoIntern",
      headerName: t("general.buildingNumber"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 70,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.buildingNoIntern}
            cellSubMenuOptions={{ options, extraData: { fieldName: "buildingNoIntern" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressText",
      headerName: t("general.objectAddress"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 200,
      sortable: false,
      align: "start",
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressText}
            cellSubMenuOptions={{ options, extraData: { fieldName: "addressText" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressPostcode",
      headerName: t("general.address.postcode"),
      flex: 1,
      align: "start",
      minWidth: 75,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressPostcode}
            cellSubMenuOptions={{ options, extraData: { fieldName: "addressPostcode" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "addressPlace",
      headerName: t("general.address.city"),
      flex: 1,
      align: "start",
      editable: false,
      minWidth: 108,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.addressPlace}
            cellSubMenuOptions={{ options, extraData: { fieldName: "addressPlace" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "locationId",
      headerName: t("general.location"),
      flex: 1,
      align: "start",
      minWidth: 108,
      editable: false,
      disableRowSelectionOnClick: true,
      type: "singleSelect",
      valueOptions: locations,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.location}
            Icon={MapOutlined}
            showIcon={request.drawingPinLocation}
            clickable
            onClick={(e) =>
              !request.disabled && listToMapRedirectHandler(e, request)
            }
            cellSubMenuOptions={{ options, extraData: { fieldName: "locationId", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "desc",
      headerName: t("general.description"),
      flex: 1,
      align: "start",
      minWidth: 265,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            tooltipLabel={nl2br(request.detailDesc)}
            label={request.desc}
            cellSubMenuOptions={{ options, extraData: { fieldName: "desc" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "resolvers",
      headerName: t("general.resolvers"),
      minWidth: 142,
      editable: false,
      disableRowSelectionOnClick: true,
      type: "singleSelect",
      flex: 1,
      align: "start",
      sortable: false,
      valueOptions: organizations,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <MultiLabelColumnV2
            data={request.resolvers}
            cellSubMenuOptions={{ options, extraData: { fieldName: "resolvers", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "targetCompletionDate",
      headerName: t("repairRequest.targetCompletionDate.shortLabel"),
      type: "date",
      minWidth: 83,
      valueGetter: (params) => {
        const request = params.row;
        if (!request || !request.isAfterCareEmployeeOrSiteManager) return "";
        return new Date(request.targetCompletionDate);
      },
      editable: false,
      flex: 1,
      align: "start",
      cursor: "pointer",
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return !request.isAfterCareEmployeeOrSiteManager ? (
          "-"
        ) : (
          <LabelColumnV2 date={request.targetCompletionDate} cellSubMenuOptions={{ options, extraData: { fieldName: "targetCompletionDate", type: "date" } }}
            request={request} />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "carryOutAsTypeId",
      headerName: t("general.type"),
      flex: 1,
      align: "start",
      type: "singleSelect",
      minWidth: 90,
      valueOptions: carryOutAsTypeList,
      renderCell: (params) => {
        const request = params.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.carryOutAsType}
            cellSubMenuOptions={{ options, extraData: { fieldName: "carryOutAsTypeId", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "statusId",
      headerName: t("general.status"),
      editable: false,
      type: "singleSelect",
      flex: 1,
      minWidth: 90,
      align: "start",
      valueOptions: statusList,
      renderCell: (parmes) => {
        const request = parmes.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            color={
              !request.isAfterCareEmployeeOrSiteManager
                ? "black"
                : request.completed
                  ? "#2DA800"
                  : request.overdue
                    ? "#f00"
                    : "black"
            }
            label={request.status}
            cellSubMenuOptions={{ options, extraData: { fieldName: "statusId", type: "singleSelect" } }}
            request={request}
          />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "priority",
      headerName: t("repairRequest.priority.short"),
      type: "singleSelect",
      editable: false,
      flex: 1,
      align: "start",
      minWidth: 30,
      valueOptions: [
        { value: "0", label: t("repairRequest.priority.0") },
        { value: "1", label: t("repairRequest.priority.1") },
        { value: "2", label: t("repairRequest.priority.2") },
      ],
      sortable: true,
      renderCell: (parmes) => {
        const request = parmes.row;
        if (!request) return "";
        return !request.isAfterCareEmployeeOrSiteManager ? (
          "-"
        ) : (
          <PriorityIconColumnV2 request={request} value={request.priority} cellSubMenuOptions={{ options: options.filter((op) => op.label !== t("general.copy")), extraData: { fieldName: "priority", type: "singleSelect" } }} />
        );
      },
    },
    {
      download: true,
      print: true,
      field: "buyerRenterName",
      headerName: t("general.buyer"),
      flex: 1,
      align: "start",
      minWidth: 265,
      renderCell: (parmes) => {
        const request = parmes.row;
        if (!request) return "";
        return (
          <LabelColumnV2
            label={request.buyerRenterName}
            cellSubMenuOptions={{ options, extraData: { fieldName: "buyerRenterName" } }}
            request={request}
          />
        );
      },
    },
  ]
};

const isAfterCareEmployeeOrSiteManagerRole = (data) =>
  data &&
  data.roles &&
  (data.roles.includes(userAccountRoleConstants.aftercareEmployee) ||
    data.roles.includes(userAccountRoleConstants.siteManager));

export const getModifiedResponse = (buildings, t) => {
  return (res) => {
    const data = Object.assign([], res);
    data.forEach((request, index) => {
      const building = buildings.find(
        (x) => x.buildingId === request.buildingId
      );
      const projectBuilding = buildings.find(
        (x) =>
          x.projectId === request.projectId ||
          x.buildingId === request.buildingId
      );
      request.projectName =
        projectBuilding &&
        projectBuilding.projectName.replace(
          `${projectBuilding.projectNo} - `,
          ""
        );
      request.projectNo = projectBuilding && projectBuilding.projectNo;

      request.disabled = !building && !projectBuilding;

      request.isAfterCareEmployeeOrSiteManager =
        isAfterCareEmployeeOrSiteManagerRole(building || projectBuilding);

      request.id = index;
      request.priorityText =
        request.priority >= 0 && request.priority <= 2
          ? t("repairRequest.priority." + request.priority)
          : "";
    });
    return data;
  };
};

export const jsonToExcel = (jsonData, fileName = "exported_data.xlsx") => {
  const worksheet = XLSX.utils.json_to_sheet(jsonData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet 1");
  XLSX.writeFile(workbook, fileName);
};

