export const isFilterValid = (filterModal) => {
  if (!filterModal) {
    return true
  }
  if (filterModal?.items?.length === 0) {
    return true
  }
  let isvalid = false

  for (let index = 0; index < filterModal?.items.length; index++) {
    const element = filterModal?.items[index];
    if (["isEmpty", "isNotEmpty"].includes(element.operator)) {
      isvalid = true
      continue
    }
    const isValueString = !!element?.value && (typeof element.value) == 'string';
    isvalid = Boolean(element.field && element.operator && ![null, undefined].includes(element.value) && (!isValueString || element?.value?.trim()))
    if (!isvalid) {
      break;
    }
  }
  return isvalid
}


export const getOprator = (type) => {
  switch (type) {
    case "string":
      return "equals"
    case "singleSelect":
      return "is"
    case "date":
      return "is"
    default:
      break;
  }
}