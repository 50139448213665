import React, { useEffect, useState, useCallback } from "react";
import { Dialog, DialogContent, IconButton, Slide, DialogActions, Button, Checkbox, CircularProgress, alpha, FormControlLabel, Grid, useMediaQuery, useTheme } from "@mui/material"
import makeStyles from "@mui/styles/makeStyles";
import {
  CheckCircleOutlined,
  Close,
  WarningOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import clsx from "clsx";
import { syncsActions } from "../../../_actions";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SyncInfoModal({
  open,
  title,
  onClose,
  selectedAssignments,
  selectedSurveys,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();
  const matchWidthDownXs = useMediaQuery(theme.breakpoints.down("sm"));
  const { t } = useTranslation();
  const { syncLogs, canOpenReleaseModal, releaseLockLoading } = useSelector(
    (state) => state.syncs
  );
  const { inspections, assignments } = useSelector((state) => state.surveying);
  const dispatch = useDispatch();
  const [selectedRecordsToRelease, setSelectedRecordsToRelease] = useState([]);
  const [logsContainerRef, setLogContainerRef] = useState(null);

  const logsContainerRefNode = useCallback(
    (node) => {
      setTimeout(() => {
        if (node !== null && open) {
          setLogContainerRef(node);
        }
      }, 70);
    },
    [open]
  );

  useEffect(() => {
    if (canOpenReleaseModal) {
      handleClose();
    }
  }, []);

  useEffect(() => {
    setSelectedRecordsToRelease(
      (selectedAssignments &&
        selectedAssignments.map(({ assignmentId }) => assignmentId)) ||
        (selectedSurveys && selectedSurveys.map(({ surveyId }) => surveyId))
    );
  }, [selectedAssignments, selectedSurveys]);

  const handleClose = () => {
    dispatch(syncsActions.syncRelease(false));
  };

  //Scroll to bottom in logs container
  useEffect(() => {
    if (open && logsContainerRef)
      logsContainerRef.scrollTop = logsContainerRef.scrollHeight;
  }, [syncLogs, open, logsContainerRef]);

  const handleReleaseLockAndClean = () => {
    const data = selectedRecordsToRelease.map((assignmentId) =>
      assignments.find((a) => a.assignmentId === assignmentId)
    );
    dispatch(syncsActions.releaseLock(data, true, title));
  };

  const handleReleaseLockAndCleanSurveys = () => {
    const surveys = selectedSurveys.map(({ surveyId }) =>
      inspections.find((a) => a.surveyId === surveyId)
    );
    dispatch(syncsActions.releaseSurveysLock(surveys, true, title));
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        className={classes.dialogRoot}
        fullScreen={matchWidthDownXs}
        TransitionComponent={Transition}
        fullWidth
        maxWidth="md"
        aria-labelledby="form-dialog-title"
        scroll="paper"
      >
        <DialogContent>
          <div className={classes.paper}>
            <div className={classes.logHeader}>
              <h2 className={classes.logHeaderTitle}>{t("sync.logs")}</h2>
              <div>
                <IconButton
                  aria-label="close"
                  className={classes.closeIcon}
                  onClick={onClose}
                  size="large"
                >
                  <Close />
                </IconButton>
              </div>
            </div>
            <div className={classes.logContainer} ref={logsContainerRefNode}>
              {syncLogs.map((log, i) => {
                return (
                  <Grid
                    key={`${log.time}-${i}`}
                    container
                    className={clsx(classes.logTable, classes[log.type])}
                  >
                    <Grid item xs={4} sm={3} md={2} lg={2} xl={2}>
                      <span className={clsx(classes.tableDate)}>
                        {log.time}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sm={8}
                      md={9}
                      lg={9}
                      xl={9}
                      className={classes.logContain}
                    >
                      <span className={clsx(classes.tableDate)}>
                        {log.message}
                      </span>
                    </Grid>
                    <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                      <span className={classes.tableIcon}>
                        {log.type === "loading" && (
                          <CircularProgress size={20} color={"primary"} />
                        )}
                        {log.type === "success" && (
                          <CheckCircleOutlined
                            className={classes.successIcon}
                          />
                        )}
                        {log.type === "error" && (
                          <WarningOutlined className={classes.errorIcon} />
                        )}
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {canOpenReleaseModal && (
        <Dialog
          open={canOpenReleaseModal}
          onClose={handleClose}
          className={classes.dialogRoot}
          fullScreen={matchWidthDownXs}
          TransitionComponent={Transition}
          fullWidth
          maxWidth="sm"
          aria-labelledby="form-dialog-title"
          scroll="paper"
        >
          <DialogContent>
            <div className={classes.paper}>
              <div className={classes.logHeader}>
                <h2 className={classes.logHeaderTitle}>{t("survey.unLock")}</h2>
                <div>
                  <IconButton
                    aria-label="close"
                    className={classes.closeIcon}
                    onClick={handleClose}
                    size="large"
                  >
                    <Close />
                  </IconButton>
                </div>
              </div>
              {selectedSurveys && (
                <div className={classes.logContainer}>
                  {selectedSurveys.map(({ surveyId: id }) => {
                    const survey = inspections.find((a) => a.surveyId === id);
                    return (
                      <div
                        key={`${id}-${new Date().getTime()}-${Math.random()}`}
                      >
                        {survey && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedRecordsToRelease.includes(id)}
                                onChange={() => {
                                  const isExist =
                                    selectedRecordsToRelease.includes(id);
                                  if (isExist)
                                    setSelectedRecordsToRelease(
                                      selectedRecordsToRelease.filter(
                                        (a) => a !== id
                                      )
                                    );
                                  else
                                    setSelectedRecordsToRelease(
                                      selectedRecordsToRelease.concat(id)
                                    );
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={t(survey.buildingNoIntern)}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {selectedAssignments && (
                <div
                  style={{ padding: "10px", height: "100%", overflow: "auto" }}
                >
                  {selectedAssignments.map(({ assignmentId }) => {
                    const assignment = assignments.find(
                      (a) => a.assignmentId === assignmentId
                    );
                    return (
                      <div key={assignmentId}>
                        {assignment && (
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedRecordsToRelease.includes(
                                  assignmentId
                                )}
                                onChange={() => {
                                  const isExist =
                                    selectedRecordsToRelease.includes(
                                      assignmentId
                                    );
                                  if (isExist)
                                    setSelectedRecordsToRelease(
                                      selectedRecordsToRelease.filter(
                                        (a) => a !== assignmentId
                                      )
                                    );
                                  else
                                    setSelectedRecordsToRelease(
                                      selectedRecordsToRelease.concat(
                                        assignmentId
                                      )
                                    );
                                }}
                                name="checkedB"
                                color="primary"
                              />
                            }
                            label={
                              t("wkb.assignment.label") + " " + assignment.text
                            }
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={releaseLockLoading || !selectedRecordsToRelease.length}
              variant="outlined"
              color="primary"
              onClick={
                selectedSurveys
                  ? handleReleaseLockAndCleanSurveys
                  : handleReleaseLockAndClean
              }
            >
              {releaseLockLoading ? (
                <CircularProgress size={20} color="primary" />
              ) : (
                t("survey.unLock")
              )}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

SyncInfoModal.defaultProps = {
  open: false,
  onClose: () => {},
};

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    "& .MuiDialogContent-root": {
      padding: theme.spacing(0),
    },
    "& .MuiPaper-root.MuiDialog-paper": {
      borderRadius: 10,
      [theme.breakpoints.up("sm")]: {
        height: "80%",
      },
    },
  },
  logHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.primary.main,
  },
  logHeaderTitle: {
    fontSize: 16,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    fontWeight: "bold",
    margin: 0,
    color: theme.palette.common.white,
  },
  closeIcon: {
    padding: 0,
    color: theme.palette.common.white,
  },
  paper: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
    boxShadow: theme.shadows[5],
    background: theme.palette.grey[200],
  },
  grow: {
    flexGrow: 1,
  },
  logTable: {
    display: "flex",
    padding: theme.spacing(1, 2),
    alignItems: "center",
    margin: "5px 0",
  },
  success: {
    backgroundColor: alpha(theme.palette.success.main, 0.1),
    border: `1px solid ${alpha(theme.palette.success.main, 0.5)}`,
    // color: theme.palette.success.main,
    borderWidth: "1px 0px 1px 0px",
  },
  loading: {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
    // color: theme.palette.primary.main,
    borderWidth: "1px 0px 1px 0px",
  },
  error: {
    backgroundColor: alpha(theme.palette.error.main, 0.1),
    border: `1px solid ${alpha(theme.palette.error.main, 0.5)}`,
    // color: theme.palette.error.main,
    borderWidth: "1px 0px 1px 0px",
  },
  tableDate: {
    paddingRight: theme.spacing(2),
    color: theme.palette.grey[800],
    fontSize: 14,
    letterSpacing: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
  },
  arrowRightIcon: {
    marginLeft: 5,
    marginRight: 3,
  },
  tableIcon: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  successIcon: {
    fill: theme.palette.success.main,
  },
  errorIcon: {
    fill: theme.palette.error.main,
  },
  logContain: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  logContainer: {
    padding: 1,
    height: "calc(100% - 40px)",
    overflow: "auto",
    scrollBehavior: "smooth",
  },
}));
