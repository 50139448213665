import React, { useEffect, useState } from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { AddCircleOutlineOutlined, Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Grid,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';

const useStyles = makeStyles(theme => ({
  '@global': {
    '.MuiButtonBase-root:focus': {
      outline: 0,
    },
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  dialogHeaderTitle: {
    fontSize: '14px !important',
    color: theme.palette.common.white
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(0.5),
    color: theme.palette.grey[500],
  },
  closeIcon: {
    fill: theme.palette.common.white,
    fontSize: 24,
  },

  grow: {
    flexGrow: 1
  },
  bold: {
    fontWeight: "bold"
  },
  mainContainer: {
    height: '100%',
    width: '100%',
    overflow: 'auto',
    padding: 0
  },
  dataTable: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& > .MuiPaper-root': {
      flex: '0 1 auto',
    },
    '& > .MuiToolbar-root.MuiToolbar-regular': {
      display: 'flex',
      '& > div': {
        padding: 0,
        textAlign: 'right',
        flex: '1 0 auto',
        '& .MuiTypography-root': {
          textAlign: 'left'
        }
      }
    },
    '& .MuiTableCell-root': {
      padding: theme.spacing(0),
      '&.MuiTableCell-paddingCheckbox': {
        paddingLeft: theme.spacing(0.5),
        '& > div': {
          justifyContent: 'center'
        },
        '& .MuiCheckbox-root': {
          padding: theme.spacing(0.25)
        }
      },
      '&.MuiTableCell-head': {
        fontWeight: 'bold'
      }
    },
  },
  fullWidth: {
    width: '100%'
  },
  dataSyncDialog: {
    '& .MuiPaper-rounded': {
      borderRadius: 10,
      minWidth: '384px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '270px',
      }
    }
  },
  dataSyncDialogContainer: {
    padding: theme.spacing(2)
  },
  recordButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '15px 0 10px 0',
    columnGap: 10

  },
  recordButton: {
    padding: theme.spacing(0.8, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.8, 5),
    },
    [theme.breakpoints.only('xs')]: {
      padding: theme.spacing(0.8, 3),
    },
  },
  dialogButtonPrimary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    width: '100%',
    background: theme.palette.primary.main,
    padding: theme.spacing(0.875, 1.25),
    color: `${theme.palette.common.white} !important`,
    '&:hover': {
      background: theme.palette.primary.main,
    }
  },
  dialogButtonSecondary: {
    [theme.breakpoints.down('sm')]: {
      minWidth: 80,
      padding: theme.spacing(0.3),
    },
    minWidth: 100,
    width: '100%',
    background: theme.palette.grey[500],
    padding: theme.spacing(0.875, 1.25),
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.grey[500],
    }
  },
  checkboxTitle: {
    marginBottom: 0,
    '& .MuiTypography-root': {
      fontSize: 12,
      fontWeight: 'bold',
      color: theme.palette.common.black

    },
  },
  errorMessage: {
    fontSize: 12
  },
}));



export default function CreateDrawingLocationName({ state, canOpen, onSave = () => { }, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false)
  const [modalData, setModalData] = useState({ name: "", forBuilding: false, forProject: false, })
  const [canShowError, setCanShowError] = useState(false);
  useEffect(() => {
    if (state) {
      setModalData(state);
    }
  }, [state]);

  useEffect(() => {
    if (canOpen) {
      setOpen(canOpen);
    }
  }, [canOpen]);

  useEffect(() => {
    if (canShowError)
      setCanShowError(!(modalData.forProject || modalData.forBuilding));
  }, [modalData.forProject, modalData.forBuilding]);

  const handleChange = (field, value) => {
    setModalData(p => ({ ...p, [field]: value }))
  }

  const modalTogglerHandler = (data, dataIndex) => {
    setOpen(p => !p);
    if (open) {
      setModalData({ name: "", forBuilding: false, forProject: false, })
      setCanShowError(false);
    }
  };


  const DialogTitle = (props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {open ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={() => {
              modalTogglerHandler();
              onSave();
            }}
            size="large">
            <Close className={classes.closeIcon} />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  };

  return <>
    <IconButton
      style={{ order: -1 }}
      onClick={() => setOpen(p => ({ ...p, modal: true }))}
      aria-label="more"
      aria-controls="long-menu"
      aria-haspopup="true"
      size="large">
      <AddCircleOutlineOutlined />
    </IconButton>
    {!!open ?
      <Dialog onClose={() => { modalTogglerHandler(); onSave(); }} aria-labelledby="customized-dialog-title" className={classes.dataSyncDialog} open={open}>
        <DialogTitle classes={classes} id="customized-dialog-title" onClose={() => { modalTogglerHandler(); onSave(); }}>
          <Typography className={classes.dialogHeaderTitle}>{t('drawing.dossier.modal.add.title')}</Typography>
        </DialogTitle>
        <DialogContent dividers className={classes.dataSyncDialogContainer}>
          <Grid item xs={12}>
            <TextField id="outlined-basic"
              className={classes.textField}
              margin="normal"
              fullWidth
              name='name'
              size='small'
              label={t("general.name")}
              value={modalData.name}
              variant="outlined"
              onChange={({ target: { value, name } }) => handleChange(name, value.toString())}
            />
          </Grid>
          <Grid item xs={12}>
            <div style={{ display: 'flex' }}>
              <FormControlLabel
                checked={modalData.forProject}
                className={classes.checkboxTitle}
                control={<Checkbox color="primary" />}
                label={t("general.project")}
                labelPlacement="end"
                name='forProject'
                onChange={({ target: { checked, name } }) => handleChange(name, checked)}
              />
              <FormControlLabel
                checked={modalData.forBuilding}
                className={classes.checkboxTitle}
                control={<Checkbox color="primary" />}
                label={t('general.object')}
                labelPlacement="end"
                name='forBuilding'
                onChange={({ target: { checked, name } }) => handleChange(name, checked)}
              />
            </div>
          </Grid>
          {canShowError &&
            <Grid item xs={12}>
              <Typography color={'secondary'} variant="h6" className={classes.errorMessage}>{t("general.drawingLocation.pleaseSelectOne.error")}</Typography>
            </Grid>}
          <div className={classes.recordButtonContainer}>
            <Button onClick={() => { modalTogglerHandler(); onSave(); }}
              className={classes.dialogButtonSecondary}>
              {t('general.cancel')}
            </Button>
            <Button
              disabled={canShowError}
              onClick={() => {
                const canError = !(modalData.forProject || modalData.forBuilding);
                if (canError) {
                  setCanShowError(canError)
                } else {
                  onSave(modalData);
                  modalTogglerHandler();
                }
              }}
              className={classes.dialogButtonPrimary}
            >
              {t('general.save')}
            </Button>
          </div>
        </DialogContent>
      </Dialog> : null}
  </>;
}
